import { useRef } from "react";
import { Modal, Input } from "antd";

interface TranslationModalProps {
  text: string;
  visible: boolean;
  onSave: (text: string) => void;
  onCancel: () => void;
}

type TMP = React.FC<TranslationModalProps>;

const TranslationModal: TMP = ({ text, visible, onCancel, onSave }: any) => {
  const inputRef = useRef(null);

  const handleSave = (): void => {
    const input: any = inputRef.current.input;
    const currentValue = input ? input.value : text;

    onSave(currentValue);
  };

  return (
    <Modal
      onOk={handleSave}
      onCancel={onCancel}
      visible={visible}
      closable={false}
      destroyOnClose
    >
      <Input defaultValue={text} ref={inputRef} />
    </Modal>
  );
};

export default TranslationModal;
