import { Button } from "ebs-design";

import { Flex, Status, WhiteSpace } from "components";
import * as Icons from "components/icons";
import models from "models";
import { formattedDateTime, TableColumnBuilder } from "utils";

export const departmentsColumns = (
  editDepartmentModal: React.Dispatch<models.Department>,
  deleteDepartmentModal: React.Dispatch<number | string | undefined>,
  t: (str: string) => string,
) =>
  new TableColumnBuilder<models.User>()
    .add(t("department.department_id"), "id")
    .add(t("common.name"), "title")
    .add(t("common.description"), "description")
    .add(t("common.creation_date"), "", (row: models.Department) => (
      <>{formattedDateTime(row?.timestamp)}</>
    ))
    .add(t("common.status"), "is_active", (status) => (
      <Status
        status={status}
        value={status ? models.StatusValue.ACTIVE : models.StatusValue.INACTIVE}
      />
    ))
    .add("", "", (row) => (
      <Flex justify="flex-end">
        <Button
          onClick={() => editDepartmentModal(row)}
          type="light"
          size="small"
          icon={() => <Icons.Edit size="small" />}
        />
        <WhiteSpace h="8px" />
        <Button
          onClick={() => deleteDepartmentModal(row?.id)}
          type="light"
          size="small"
          icon={() => <Icons.Trash size="small" />}
        />
      </Flex>
    ))
    .getColumns();

export const departmentsSortOptions = [
  { title: "Name", value: "title" },
  { title: "Creation date", value: "timestamp" },
  { title: "Description", value: "description" },
];
