import models from "models";

export const getUserApplications = (apps?: models.Apps[]) => {
  const defaultApps = Object.values(models.Apps);

  const ArrayOfAviableApps =
    defaultApps?.map((v) => ({
      [models.Apps[v]]: apps?.includes(v),
    })) || [];

  return Object.assign({}, ...ArrayOfAviableApps);
};
