import { createSvgIcon } from "../utils";

export const Inventory = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 1.25C4.58579 1.25 4.25 1.58579 4.25 2C4.25 2.41421 4.58579 2.75 5 2.75V1.25ZM7 2.75C7.41421 2.75 7.75 2.41421 7.75 2C7.75 1.58579 7.41421 1.25 7 1.25V2.75ZM6.75 14C6.75 13.5858 6.41421 13.25 6 13.25C5.58579 13.25 5.25 13.5858 5.25 14H6.75ZM5.25 14.01C5.25 14.4242 5.58579 14.76 6 14.76C6.41421 14.76 6.75 14.4242 6.75 14.01H5.25ZM2 1.75H10V0.25H2V1.75ZM10 1.75C10.1381 1.75 10.25 1.86193 10.25 2H11.75C11.75 1.0335 10.9665 0.25 10 0.25V1.75ZM10.25 2V16H11.75V2H10.25ZM10.25 16C10.25 16.1381 10.1381 16.25 10 16.25V17.75C10.9665 17.75 11.75 16.9665 11.75 16H10.25ZM10 16.25H2V17.75H10V16.25ZM2 16.25C1.86193 16.25 1.75 16.1381 1.75 16H0.25C0.25 16.9665 1.0335 17.75 2 17.75V16.25ZM1.75 16V2H0.25V16H1.75ZM1.75 2C1.75 1.86193 1.86193 1.75 2 1.75V0.25C1.0335 0.25 0.25 1.0335 0.25 2H1.75ZM5 2.75H7V1.25H5V2.75ZM5.25 14V14.01H6.75V14H5.25Z"
      fill="currentColor"
    />
  </svg>,
);
