import { createSvgIcon } from "../utils";

export const FileXls = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="40"
    viewBox="0 0 30 40"
    fill="none"
  >
    <path
      d="M0.5 36V4C0.5 2.067 2.067 0.5 4 0.5H17.9C18.7987 0.5 19.663 0.845712 20.3138 1.46552L28.4138 9.1798C29.1074 9.8404 29.5 10.7564 29.5 11.7143V36C29.5 37.933 27.933 39.5 26 39.5H4C2.067 39.5 0.5 37.933 0.5 36Z"
      fill="white"
      stroke="#E5E9EB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 14C9.20435 14 8.44129 14.3161 7.87868 14.8787C7.31607 15.4413 7 16.2044 7 17V23C7 23.7956 7.31607 24.5587 7.87868 25.1213C8.44129 25.6839 9.20435 26 10 26H20C20.7956 26 21.5587 25.6839 22.1213 25.1213C22.6839 24.5587 23 23.7956 23 23V17C23 16.2044 22.6839 15.4413 22.1213 14.8787C21.5587 14.3161 20.7956 14 20 14H10ZM9 23V22H14V24H10C9.73478 24 9.48043 23.8946 9.29289 23.7071C9.10536 23.5196 9 23.2652 9 23ZM16 24H20C20.2652 24 20.5196 23.8946 20.7071 23.7071C20.8946 23.5196 21 23.2652 21 23V22H16V24ZM16 20H21V18H16V20ZM14 18H9V20H14V18Z"
      fill="#47D16C"
    />
  </svg>,
);
