import cn from "classnames";
import { nanoid } from "nanoid";

import { useControlledState } from "hooks";
import { makeBEM } from "utils";

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  wrap?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  onChange?: (v: boolean) => void;
}

const bem = makeBEM("custom-checkbox");

export const Checkbox = ({
  wrap,
  indeterminate,
  checked,
  onChange,
  defaultChecked,
  ...props
}: Props) => {
  // Generate a unique id for the checkbox
  const id = nanoid();
  const [value, setValue] = useControlledState(checked, onChange);

  return (
    <div
      className={wrap ? bem("wrapper") : ""}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        id={id}
        type="checkbox"
        className={bem(props.className, {
          indeterminate,
        })}
        defaultChecked={defaultChecked}
        checked={value}
        onChange={(e) => setValue(e.target.checked)}
        {...props}
      />
      <label
        htmlFor={id}
        className={cn(bem("label"), { "not-allowed": props.disabled })}
      />
    </div>
  );
};
