import React from "react";
import { Form, Input } from "antd";
import { Properties } from "types";

import { formItemStyle } from "../helpers";

const ContactUs = ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "address"]}
        label="Address central office"
        style={formItemStyle}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "phone"]}
        label="Direct phone"
        style={formItemStyle}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "email"]}
        label="E-mail"
        style={formItemStyle}
      >
        <Input />
      </Form.Item>
    </>
  );
};

export default ContactUs;
