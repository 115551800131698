import { AxiosError } from "axios";
import { useNotify } from "ebs-design";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { NumberParam } from "use-query-params";

import api, { querykeys } from "api";
import { PageTitle, WhiteSpace } from "components";
import { DndDiagnosticCard } from "components/dnd-table";
import { useParam } from "hooks";
import { DiagnosticType } from "types";
import { notifyErrors } from "utils";

import { BrandCard } from "../components";

export const BrandDetails = () => {
  const { t } = useTranslation();

  const notify = useNotify();
  const id = useParam("id", NumberParam.decode) || 0;

  const { data, isSuccess } = useQuery(
    querykeys.brands.one(id),
    () => api.brands.getById(id),
    {
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  return (
    <>
      <PageTitle goBack>
        {t("brand.brand")} - {data?.brand_id}
      </PageTitle>

      <div className="brand-details">
        <BrandCard data={data} />
        <WhiteSpace v="24px" />
        {isSuccess && (
          <DndDiagnosticCard
            title={t("common.pricing_and_condition_definition")}
            diagnosticType={DiagnosticType.BRAND}
            brandId={data?.brand_id}
          />
        )}
      </div>
    </>
  );
};
