import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNotify } from "ebs-design";

import api, { querykeys } from "api";
import { CURRENCY_SYMBOL_FALLBACK } from "app-constants";
import { useAuth } from "hooks";
import { notifyErrors } from "utils";

import { CardWithFilterWrapper, PayoutAndPaymentChart } from ".";

export const PayoutAndPayment = () => {
  const { t } = useTranslation();
  const { organization } = useAuth();
  const notify = useNotify();

  const [periodParams, setPeriodParams] = useState({
    from_date: null,
    to_date: null,
  });

  const queryParams = {
    ...periodParams,
  };

  const currency = organization?.currency?.symbol || CURRENCY_SYMBOL_FALLBACK;

  const query = useQuery(
    querykeys.nomenclature.statistics.payoutPayment(queryParams),
    () => api.statistics.getPaymentPayout(queryParams),
    {
      onError: (error: Error) => notifyErrors(notify, error),
    },
  );

  const list = [
    {
      label: t("payout.payout"),
      value: `${currency} ${Number(query.data?.payout_total)}`,
    },
    {
      label: t("payment.payment"),
      value: `${currency} ${Number(query.data?.payment_total)}`,
    },
  ];

  return (
    <CardWithFilterWrapper
      title={t("common.payout_payments")}
      list={list}
      query={query}
      action={setPeriodParams}
    >
      <PayoutAndPaymentChart data={query.data} currency={currency} />
    </CardWithFilterWrapper>
  );
};
