import { AxiosError } from "axios";
import { Button, Card, Space, useNotify } from "ebs-design";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { ArrayParam, useQueryParams } from "use-query-params";
import { useUpdateEffect } from "react-use";

import api, { querykeys } from "api";
import { isOnlineStatus } from "app-constants";
import {
  DeleteModal,
  Flex,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import {
  ChoiceFilter,
  Filters,
  SelectQueryListFilter,
} from "components/filters";
import {
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";
import models from "models";
import {
  capitalizeString,
  CommaArrayParam,
  forwardSetState,
  notifyErrors,
} from "utils";

import { EditStaffModal } from "../components";
import { staffColumns, invitedColumns, StaffSortOptions } from "../config";

export const Staff = () => {
  const { t } = useTranslation();
  const pagination = useQueryPagination();
  const editStaffModalState = useModalState<models.User>();
  const deleteModal = useModalState<models.User>();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const labels = [t("common.active"), t("common.deleted"), t("common.invited")];
  const [activeTab, setActiveTab] = useState(0);
  const isActiveUsers = useMemo(() => activeTab === 0, [activeTab]);
  const isInvitedUsers = useMemo(() => activeTab === 2, [activeTab]);

  const [filterParams, setFilterParams] = useQueryParams({
    is_online: ArrayParam,
    profile__roles__id__in: CommaArrayParam,
  });

  const [ordering, setOrdering] = useOrderingQueryParam();
  const [search, setSearch] = React.useState("");

  useUpdateEffect(() => {
    pagination.setPage(1);
  }, [isActiveUsers, isInvitedUsers]);

  const queryParams = {
    ...pagination.queryParams,
    ...filterParams,
    search,
    ordering,
    is_staff: true,
    is_deleted: !isActiveUsers,
  };

  type QueryParams = typeof queryParams;

  const apiCall: (
    queryParams: QueryParams,
  ) => Promise<models.WithResults<models.Invite | models.User>> = useMemo(
    () => (isInvitedUsers ? api.invites.get : api.users.get),
    [isInvitedUsers],
  );

  const query = useQuery(
    querykeys.staff.many({ ...queryParams, isInvitedUsers }),
    () => apiCall(queryParams),
  );

  const deleteMutation = useMutation(
    () => api.users.delete(deleteModal?.data?.id!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.staff.many());
        deleteModal.close();
      },
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  const tableColumns = React.useMemo(
    () =>
      isInvitedUsers
        ? invitedColumns({ t })
        : staffColumns({
            activeUsers: isActiveUsers,
            onEditClick: editStaffModalState.openWith,
            onDelete: deleteModal.openWith,
            t,
          }),
    [
      isInvitedUsers,
      t,
      isActiveUsers,
      editStaffModalState.openWith,
      deleteModal.openWith,
    ],
  );

  return (
    <>
      <PageTitle
        actions={
          <Link to="/auth/internal-users/invite">
            <Button type="primary">{t("user.invite_internal_user")}</Button>
          </Link>
        }
        title={t("menu.internal_users")}
        count={query.data?.count}
      />
      <Card>
        <Card.Body>
          <QueryTable
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                  sortOptions={StaffSortOptions}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                  filters={
                    <Filters
                      queryParams={filterParams}
                      setQueryParams={setFilterParams}
                    >
                      <ChoiceFilter
                        value={filterParams.is_online}
                        setValue={forwardSetState(setFilterParams, "is_online")}
                        title={t("common.status")}
                        choice={Object.values(["true", "false"]).map(
                          (is_online) => ({
                            key: is_online,
                            value: isOnlineStatus[is_online],
                          }),
                        )}
                      />
                      <SelectQueryListFilter
                        title={t("common.roles")}
                        value={filterParams.profile__roles__id__in}
                        setValue={forwardSetState(
                          setFilterParams,
                          "profile__roles__id__in",
                        )}
                        querykey={querykeys.nomenclature.roles}
                        apiCall={api.nomenclatures.roles}
                        getKey={(u: models.Role) => String(u.id)}
                        getValue={(u: models.Role) =>
                          capitalizeString(String(u.name), "_")
                        }
                      />
                    </Filters>
                  }
                />
                <Space>
                  {labels.map((label, index) => (
                    <Button
                      key={index}
                      type={activeTab === index ? "fill" : "light"}
                      onClick={() => setActiveTab(index)}
                      value={label}
                    >
                      {label}
                    </Button>
                  ))}
                </Space>
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            className="people-table"
          />
        </Card.Body>
      </Card>
      {editStaffModalState.isOpen && (
        <EditStaffModal
          className="edit-staff-modal"
          open={editStaffModalState.isOpen}
          onClose={editStaffModalState.close}
          data={editStaffModalState.data}
          closeOnClickOutside={false}
          size="small"
        />
      )}
      {deleteModal.isOpen && (
        <DeleteModal
          open={deleteModal.isOpen}
          onClose={deleteModal.close}
          mutation={deleteMutation}
          title={t("template.delete_item", {
            item: t("user.user").toLowerCase(),
          })}
        >
          <h4 className="text-center">
            {t("template.are_you_sure_you_want_to_delete_this_item", {
              item: t("user.user").toLowerCase(),
            })}
          </h4>
        </DeleteModal>
      )}
    </>
  );
};
