import { Card } from "ebs-design";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { useUpdateEffect } from "react-use";

import api, { querykeys } from "api";
import {
  ExportInFile,
  Flex,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import {
  useAuth,
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";
import models from "models";
import { checkboxesCheckedInfo, CheckboxesCheckedInfoType } from "types";

import { EditPaymentModal } from "../components";
import { paymentsColumns, paymentsSortOptions } from "../config";

export const Invoices = () => {
  const { t } = useTranslation();
  const { organization } = useAuth();
  const history = useHistory();
  const editPaymentModal = useModalState<models.Payment>();
  const pagination = useQueryPagination();
  const [search, setSearch] = useState("");
  const [ordering, setOrdering] = useOrderingQueryParam();

  const queryParams = {
    ...pagination.queryParams,
    search,
    ordering,
  };

  const query = useQuery(querykeys.payments.many(queryParams), () =>
    api.payments.get(queryParams),
  );

  const allInvoicesIds = query.data?.results?.map((item) => String(item?.id));
  const [checkedInvoices, setCheckedInvoices] =
    useState<CheckboxesCheckedInfoType>(checkboxesCheckedInfo);

  const tableColumns = React.useMemo(
    () =>
      paymentsColumns({
        editPaymentModal: editPaymentModal.openWith,
        currency: organization?.currency,
        t,
        setCheckboxInfo: setCheckedInvoices,
        checkboxInfo: {
          ...checkedInvoices,
          checkboxesOnPage: allInvoicesIds,
        },
      }),
    [
      allInvoicesIds,
      checkedInvoices,
      editPaymentModal.openWith,
      organization?.currency,
      t,
    ],
  );

  useUpdateEffect(() => {
    pagination.setPage(1);
    setCheckedInvoices(checkboxesCheckedInfo);
  }, [search]);

  return (
    <>
      <PageTitle title={t("menu.invoices")} count={query.data?.count} />
      <Card>
        <Card.Body>
          <QueryTable
            variants={["highlight-hover"]}
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  sortOptions={paymentsSortOptions}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                  setSearch={setSearch}
                />
                <ExportInFile
                  idList={checkedInvoices.allCheckedCheckboxes}
                  queryKey="payments-exports"
                  apiFunction={api.payments.export}
                />
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            onRow={(data) => ({
              onClick: () => history.push(`/crm/invoices/payments/${data?.id}`),
            })}
            rowKey="id"
          />
        </Card.Body>
      </Card>

      {editPaymentModal.isOpen && (
        <EditPaymentModal
          open={editPaymentModal.isOpen}
          onClose={editPaymentModal.close}
          data={editPaymentModal.data}
          closeOnClickOutside={false}
        />
      )}
    </>
  );
};
