import { AxiosError } from "axios";
import { Button, Card, useNotify } from "ebs-design";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ImgViewer from "react-simple-image-viewer";

import api, { querykeys } from "api";
import { DeleteModal, PageTitle, QueryTable, TableTitle } from "components";
import {
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";
import models from "models";
import { ImageViewerState } from "types";
import { notifyErrors } from "utils";

import { FormFileModal } from "../components";
import { filesColumns, filesSortOptions } from "../config";

export const Files = () => {
  const { t } = useTranslation();
  const pagination = useQueryPagination();
  const formFileModalState = useModalState<models.Attachment>();
  const deleteModal = useModalState<models.User>();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const [ordering, setOrdering] = useOrderingQueryParam();
  const [search, setSearch] = React.useState("");
  const [imgViewerState, setImgViewerState] = React.useState<ImageViewerState>({
    isOpen: false,
    imageUrl: "",
  });

  const queryParams = {
    ...pagination.queryParams,
    search,
    ordering,
  };

  const query = useQuery(querykeys.attachments.many(queryParams), () =>
    api.attachments.organization.get(queryParams),
  );

  const deleteMutation = useMutation(
    () => api.attachments.organization.delete(deleteModal?.data?.id!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.attachments.many(queryParams));
        deleteModal.close();
      },
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  const tableColumns = React.useMemo(
    () =>
      filesColumns({
        onEdit: formFileModalState.openWith,
        onDelete: deleteModal.openWith,
        onFeatured: (imageUrl) => setImgViewerState({ isOpen: true, imageUrl }),
        t,
      }),
    [formFileModalState.openWith, deleteModal.openWith, t],
  );

  return (
    <>
      <PageTitle
        actions={
          <Button type="primary" onClick={formFileModalState.open}>
            {t("template.add_item", {
              item: t("file.new_file").toLowerCase(),
            })}
          </Button>
        }
        title={t("menu.files")}
        count={query.data?.count}
      />
      <Card>
        <Card.Body>
          <QueryTable
            pagination={pagination}
            title={() => (
              <TableTitle
                className="px-0"
                search={search}
                setSearch={setSearch}
                sortOptions={filesSortOptions}
                sortOrdering={ordering}
                onSortChange={setOrdering}
              />
            )}
            query={query}
            columns={tableColumns}
            className="people-table"
          />
        </Card.Body>
      </Card>
      {formFileModalState.isOpen && (
        <FormFileModal
          open={formFileModalState.isOpen}
          onClose={formFileModalState.close}
          data={formFileModalState.data}
          closeOnClickOutside={false}
          size="small"
        />
      )}
      {deleteModal.isOpen && (
        <DeleteModal
          open={deleteModal.isOpen}
          onClose={deleteModal.close}
          mutation={deleteMutation}
          title={t("template.delete_item", { item: t("file.file") })}
        >
          <h4 className="text-center">
            {t("template.are_you_sure_you_want_to_delete_this_item", {
              item: t("file.file").toLowerCase(),
            })}
          </h4>
        </DeleteModal>
      )}
      {imgViewerState.isOpen && (
        <ImgViewer
          src={[imgViewerState.imageUrl]}
          onClose={() => setImgViewerState({ isOpen: false, imageUrl: "" })}
          disableScroll
          closeOnClickOutside
        />
      )}
    </>
  );
};
