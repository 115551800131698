import models from "models";
import { GenericObject } from "types";
import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const clients = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/organization/clients", queryParams, {
      arrayFormat: "comma",
    });
    const { data } = await apiAxios.get<models.WithResults<models.User>>(url);
    return data;
  },
  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/clients/${id}`);
    return data;
  },
  post: async (body: models.User) => {
    const { data } = await apiAxios.post<models.User>(
      "/organization/clients",
      body,
    );
    return data;
  },
  patch: async (id: number, body: models.User) => {
    const { data } = await apiAxios.patch<models.User>(
      `/organization/clients/${id}`,
      body,
    );
    return data;
  },
};
