import models from "models";
import { GenericObject } from "types";

import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const statistics = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl(`/statistics/dashboard`, queryParams);
    const { data } = await apiAxios.get<models.Statistics>(url);
    return data;
  },
  getOrderTotals: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl(`/statistics/dashboard/order-totals`, queryParams);
    const { data } = await apiAxios.get<models.OrderTotals>(url);
    return data;
  },
  getPaymentTotals: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl(
      `/statistics/dashboard/payment-totals`,
      queryParams,
    );
    const { data } = await apiAxios.get<models.OrderTotals[]>(url);
    return data;
  },
  getPayoutTotals: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl(
      `/statistics/dashboard/payout-totals`,
      queryParams,
    );
    const { data } = await apiAxios.get<models.OrderTotals[]>(url);
    return data;
  },
  getOrderTotalByStatus: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl(
      `/statistics/dashboard/order-totals-by-status`,
      queryParams,
    );
    const { data } = await apiAxios.get<models.OrderTotalsByStatusList>(url);
    return data;
  },
  getOrderTotalByBrandModel: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl(
      `/statistics/dashboard/device-totals-by-brand-model`,
      queryParams,
    );
    const { data } = await apiAxios.get<models.DeviceTotalsByBrandModel[]>(url);
    return data;
  },
  getPaymentPayout: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl(`/statistics/dashboard/payments`, queryParams);
    const { data } = await apiAxios.get<models.PaymentPayout>(url);
    return data;
  },
  
  getCustomers: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl(`/statistics/dashboard/customers`, queryParams);
    const { data } = await apiAxios.get<models.CustomerStatisticsList>(url);
    return data
  },

  getDepartments: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl(`/statistics/dashboard/departments`, queryParams);
    const { data } = await apiAxios.get<{
      total_users: number;
      total_departments: number;
      departments: models.Department[];
    }>(url);

    return data;
  },

  getDropProcessLocations: async () => {
    const { data } = await apiAxios.get<{
      drop_off_locations: models.LocationCount;
      process_locations: models.LocationCount;
    }>("/statistics/dashboard/drop-process-locations");

    return data;
  },
};
