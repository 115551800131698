import { Button, Form, Modal, Select, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import { Flex, LoadingButton, WhiteSpace } from "components";
import { transformEnumToArray } from "utils";
import models from "models";
import api, { querykeys } from "api";
import { useMapErrors } from "hooks";

interface ChangeStatusModalProps extends ModalProps {
  status?: models.OrderStatus;
  orderId?: number;
  parent_id?: number;
  onClose: () => void;
}

const orderStatusOptions = transformEnumToArray(models.OrderStatus);

export const ChangeStatusModal = ({
  status,
  orderId,
  parent_id,
  onClose,
  ...props
}: ChangeStatusModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm<models.OrderStatusEntity>();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const mapErrors = useMapErrors();

  const { mutate, isLoading } = useMutation(
    (values: models.OrderStatusEntity) =>
      api.orders.changeStatus(orderId!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.crmOrders.one(parent_id));
        notify.success({
          title: t("notification.changes_saved_successfully"),
        });
        onClose();
      },
      onError: (errors: unknown) => mapErrors(errors, notify, form),
    },
  );

  return (
    <Modal
      title={t("template.edit_item", { item: t("order.order").toLowerCase() })}
      onClose={onClose}
      {...props}
    >
      <Form
        form={form}
        name="order-status"
        onFinish={(values: models.OrderStatusEntity) => mutate(values)}
        initialValues={{ status }}
      >
        <Modal.Content>
          <Form.Field name="status" label={t("common.change_status")}>
            <Select
              options={orderStatusOptions}
              size="large"
              placeholder={t("template.select_item", {
                item: t("common.status").toLowerCase(),
              })}
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton submit loading={isLoading} type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
