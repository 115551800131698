import { Button } from "ebs-design";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { makeBEM } from "utils";

const bem = makeBEM("location-card");

interface statisticsProp {
  title: string;
  count: number;
  className?: string;
}

interface LocationCardProps {
  title: string;
  link?: string;
  value: number;
  statistics?: statisticsProp[];
}

export const LocationCard = ({
  title,
  link,
  value,
  statistics,
}: LocationCardProps) => {
  const { t } = useTranslation();

  return (
    <div className={bem()}>
      <div className={bem("header")}>
        <h3>{title}</h3>
        <div className={bem("status")}>
          {statistics?.map((item, i) => (
            <p key={i}>
              {item.title}:{" "}
              <span className={`${item.className} fw-600`}>{item.count}</span>
            </p>
          ))}
        </div>
      </div>
      <h3 className={bem("value")}>{value}</h3>
      {link && (
        <Link to={link}>
          <Button type="light">
            {t("template.add_item", { item: title })}
          </Button>
        </Link>
      )}
    </div>
  );
};
