import cn from "classnames";

import { NO_VALUE_FALLBACK } from "app-constants";
import { Flex } from "components";
import models from "models";
import { createClassWithModifiers, makeBEM } from "utils";

interface StatusProps {
  status?: boolean;
  value?: string;
  person?: boolean;
  isBlocked?: boolean;
}

const bem = makeBEM("status");

export const Status = ({ status, value, person, isBlocked }: StatusProps) => {
  const statusColor = {
    [createClassWithModifiers("status", ["success"])]: !person && status,
    [createClassWithModifiers("status", ["danger"])]: !person && !status,
    [createClassWithModifiers("status", ["online"])]: person && status,
    [createClassWithModifiers("status", ["offline"])]: person && !status,
  };

  return (
    <Flex justify="start" align="center" className={cn(statusColor)}>
      <span className={bem("circle mr-5")} />
      {!person ? (
        <div>
          {!isBlocked ? (
            <span>
              {status ? models.StatusValue.ACTIVE : models.StatusValue.INACTIVE}
            </span>
          ) : (
            <span>
              {status ? models.StatusValue.ACTIVE : models.StatusValue.BLOCKED}
            </span>
          )}
        </div>
      ) : (
        <span>{value ? value : NO_VALUE_FALLBACK}</span>
      )}
    </Flex>
  );
};
