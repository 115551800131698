import React from "react";
import { Form, Select, Checkbox, Input } from "antd";
import { Properties } from "types";

import { formItemStyle } from "../helpers";

const Card = ({ item, lang, setFieldsValue }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "header"]}
        label="Title"
        style={formItemStyle}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "size"]}
        initialValue="medium"
        label="Size"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="small">Small</Select.Option>
          <Select.Option value="medium">Medium</Select.Option>
          <Select.Option value="large">Large</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "collapsible"]}
        valuePropName="checked"
        label="Collapsible"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  collapsible: !item.collapsible,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "collapsed"]}
        valuePropName="checked"
        label="Collapsed"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  collapsed: !item.collapsed,
                },
              },
            });
          }}
        />
      </Form.Item>
    </>
  );
};

export default Card;
