import { AxiosError } from "axios";
import { Button, Card, Loader, useNotify } from "ebs-design";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Flex, DeleteModal, LoadingButton } from "components";
import * as Icons from "components/icons";
import { useDiagnosticURL, useModalState } from "hooks";
import models from "models";
import { DiagnosticType } from "types";
import { formatPercent, notifyErrors } from "utils";

import { DndTable, CriteriaModal, ColumnEntity } from ".";

interface DndTableProps {
  title?: string;
  diagnosticType: DiagnosticType;
  brandId?: number;
}

export const DndDiagnosticCard = ({
  title,
  diagnosticType,
  brandId,
}: DndTableProps) => {
  const { t } = useTranslation();
  const queryParams = { ordering: "order" };

  const criteriaModal = useModalState<models.DiagnosticCriteria>();
  const deleteCriteriaModal = useModalState<models.DiagnosticCriteria>();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const diagnosticUrl = useDiagnosticURL(diagnosticType, brandId, queryParams);

  const criteriaQuery = useQuery(
    diagnosticUrl.criterionsKey,
    diagnosticUrl.get,
    {
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  const { mutate, isLoading } = useMutation(
    diagnosticUrl.criterionsKey,
    diagnosticUrl.resetCriteria,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(diagnosticUrl.criterionsKey);
        notify.success({
          title: t("notification.changes_saved_successfully"),
        });
      },
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  const deleteCriteria = useMutation(
    () => diagnosticUrl.delete(deleteCriteriaModal?.data?.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(diagnosticUrl.criterionsKey);
        notify.success({
          title: t("notification.changes_saved_successfully"),
        });
        deleteCriteriaModal.close();
      },
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  //Table columns
  const diagnosticCriteria: ColumnEntity[] = [
    {
      name: t("criteria.criteria_order"),
      key: "",
      render: (_, index) => <span>{index + 1}</span>,
    },
    { name: t("common.criteria"), key: "title" },
    {
      name: t("price.deduction_in_percentage"),
      key: "price_percentage",
      render: (row: models.DiagnosticCriteria) =>
        formatPercent(row?.price_percentage),
    },
    {
      name: "",
      key: "",
      render: (row: models.DiagnosticCriteria) => (
        <Flex>
          <Button
            size="small"
            type="light"
            icon={() => <Icons.Edit size="14px" />}
            className="ml-8"
            onClick={() => criteriaModal.openWith(row)}
          />
          <Button
            size="small"
            type="light"
            icon={() => <Icons.Trash size="14px" />}
            className="ml-8"
            onClick={() => deleteCriteriaModal.openWith(row)}
          />
        </Flex>
      ),
      style: {
        width: "50px",
      },
    },
  ];

  return (
    <>
      <Card className="dnd-card">
        <Card.Header>
          <Flex justify="space-between" align="center">
            <h3>{title}</h3>

            <Button type="primary" size="small" onClick={criteriaModal.open}>
              {t("common.new_diagnostic_criteria")}
            </Button>
          </Flex>
        </Card.Header>
        <Card.Body>
          <DndProvider backend={HTML5Backend}>
            <Loader loading={criteriaQuery.isLoading}>
              <DndTable
                onReorderSuccess={() => criteriaQuery.refetch()}
                data={criteriaQuery.data?.results}
                columns={diagnosticCriteria}
              />
            </Loader>
          </DndProvider>
        </Card.Body>
        <Card.Footer>
          <Flex justify="end">
            <LoadingButton
              type="light"
              size="small"
              onClick={mutate}
              loading={isLoading}
            >
              {t("common.reset_to_default")}
            </LoadingButton>
          </Flex>
        </Card.Footer>
      </Card>
      {criteriaModal.isOpen && (
        <CriteriaModal
          data={criteriaModal.data}
          onClose={criteriaModal.close}
          open={criteriaModal.isOpen}
          closeOnClickOutside={false}
          diagnosticType={diagnosticType}
          brandId={brandId}
        />
      )}
      {deleteCriteriaModal.isOpen && (
        <DeleteModal
          onClose={deleteCriteriaModal.close}
          open={deleteCriteriaModal.isOpen}
          mutation={deleteCriteria}
          closeOnClickOutside={false}
          title={t("template.delete_item", {
            item: t("criteria.criteria").toLowerCase(),
          })}
          size="small"
        >
          <h2
            className="mx-auto mt-20 mb-10 "
            style={{ maxWidth: "300px", textAlign: "center" }}
          >
            {t("template.are_you_sure_you_want_to_delete_this_item", {
              item: t("criteria.criteria").toLowerCase(),
            })}
            ?
          </h2>
          <h3
            className="grey-400 mx-auto mb-20"
            style={{ maxWidth: "300px", textAlign: "center" }}
          >
            " {deleteCriteriaModal.data?.title} "
          </h3>
        </DeleteModal>
      )}
    </>
  );
};
