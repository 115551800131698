import { AxiosError } from "axios";
import { Button, Card, Loader, useNotify } from "ebs-design";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { NumberParam } from "use-query-params";

import api, { querykeys } from "api";
import { DeviceCard, Divider, Grid, PageTitle, Table } from "components";
import { useModalState, useParam } from "hooks";
import models from "models";
import { notifyErrors } from "utils";

import { DiagnosticCriteriaTable, EditInventoryModal } from "../components";
import { inventoryDetailsColumns } from "../config";

export const InventoryDetails = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const editInventoryModal = useModalState<models.Inventory>();

  const id = useParam("id", NumberParam.decode) || 0;

  const { data, isLoading } = useQuery(
    querykeys.inventory.one(id),
    () => api.inventory.getByID(id),
    {
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );
  const tableColumns = useMemo(
    () =>
      inventoryDetailsColumns({
        t,
      }),
    [t],
  );

  if (isLoading) return <Loader loading />;

  return (
    <>
      <PageTitle
        actions={
          <Button
            type="primary"
            onClick={() => editInventoryModal.openWith(data)}
          >
            {t("common.change_status")}
          </Button>
        }
        to="/product-management/inventory"
      >
        {t("order.order")} - {data?.order?.code}
      </PageTitle>
      <Card>
        <Card.Body>
          <Table data={[data]} columns={tableColumns} variants={["one-row"]} />
          <Divider space={24} />
          <Grid cols="1fr 1fr" gap="24px">
            <DeviceCard device={data?.device} />
            <DiagnosticCriteriaTable
              diagnosticCriteria={data?.device?.diagnostic_criteria}
            />
          </Grid>
        </Card.Body>
      </Card>

      {editInventoryModal.isOpen && (
        <EditInventoryModal
          open={editInventoryModal.isOpen}
          onClose={editInventoryModal.close}
          data={editInventoryModal.data}
          closeOnClickOutside={false}
        />
      )}
    </>
  );
};
