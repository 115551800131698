import { Form } from "ebs-design";
import { LatLngLiteral } from "leaflet";
import { Alpha3ToAlpha2 } from "types";

import { AddressMapInput } from ".";

const getFullAddress = async (position: { lat: number; lng: number }) => {
  if (!position) return 0;

  const response = await fetch(
    `${process.env.REACT_APP_GEOCODE_API}&location=${position.lng},${position.lat}`,
  );
  const { address } = await response.json();
  return address;
};

export const AddressMapFormField = ({ ...props }) => {
  return (
    <>
      <Form.Field {...props} initialValue={props.value}>
        {({ value, onChange }, __, form) => {
          return (
            <AddressMapInput
              value={value}
              onChange={onChange}
              setPosition={(position: LatLngLiteral) => {
                getFullAddress(position).then(({ City, CountryCode }) => {
                  form.setFields([
                    { name: "latitude", value: position.lat },
                    { name: "longitude", value: position.lng },
                    { name: "city", value: City },
                    {
                      name: "country_code",
                      value: Alpha3ToAlpha2[CountryCode],
                    },
                  ]);
                });
              }}
            />
          );
        }}
      </Form.Field>
      <Form.Field name="latitude" />
      <Form.Field name="longitude" />
      <Form.Field name="city" />
      <Form.Field name="country_code" />
    </>
  );
};
