import { Button } from "ebs-design";
import React from "react";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { Article } from "apps/cms/lib/api/pages";
import { useAuth } from "hooks";
import { defaultLocale } from "apps/cms/lib/utils";
import * as Icons from "components/icons";

interface PageActionsProps {
  type?: string;
  article: Article;
  onRemove: (user: Article) => void;
  vizualization?: boolean;
}

/**
 * `ArticleActions` are used in `Article table`.
 * Containing 3 buttons: edit, remove and view.
 *
 * `Edit` button will redirect user to `/pages/edit/id`
 * `Delete` button will remove data and trigger refetch table content.
 * `View` button is a link to the article for preview.
 */
const ArticleActions: React.FC<PageActionsProps> = ({
  article,
  type = "pages",
  vizualization = true,
  onRemove,
}) => {
  const history = useHistory();
  const { domain } = useAuth().organization;

  return (
    <Row gutter={[10, 10]} justify="end" className="flex-no-wrap">
      {vizualization && (
        <Col>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`//${domain}/${defaultLocale}/page/${article.i18n[defaultLocale]?.slug}`}
          >
            <Button
              type="light"
              icon={() => <Icons.Eye size="small" />}
              disabled={article.state !== 1}
            />
          </a>
        </Col>
      )}
      <Col>
        <Button
          type="light"
          icon={() => <Icons.Edit size="small" />}
          onClick={() => history.push(`/cms/${type}/edit/${article.id}`)}
        />
      </Col>

      <Col>
        <Button
          type="light"
          icon={() => <Icons.Trash size="small" />}
          onClick={() => onRemove(article)}
        />
      </Col>
    </Row>
  );
};

export default ArticleActions;
