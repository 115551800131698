import models from "models";
import { GenericObject } from "types";

import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const warehouses = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/warehouses", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Warehouse>>(
      url,
    );
    return data;
  },
  getById: async (id: number) => {
    const { data } = await apiAxios.get<models.Warehouse>(`/warehouses/${id}`);
    return data;
  },
  post: async (body: models.Warehouse) => {
    const { data } = await apiAxios.post<models.Warehouse>(`/warehouses`, body);
    return data;
  },
  patch: async (id: number, body: models.Warehouse) => {
    const { data } = await apiAxios.patch<models.Warehouse>(
      `/warehouses/${id}`,
      body,
    );
    return data;
  },
  delete: async (id: number) => {
    return await apiAxios.delete(`/warehouses/${id}`);
  },
};
