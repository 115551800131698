import { Button, Card } from "ebs-design";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Flex, WhiteSpace } from "components";
import * as Icons from "components/icons";
import { makeBEM } from "utils";

const bem = makeBEM("success-card");

interface SuccessInviteRedirect {
  url: string;
  title: string;
}

interface SuccessInviteCardProps {
  redirect: SuccessInviteRedirect;
}

export const SuccessInviteCard = ({ redirect }: SuccessInviteCardProps) => {
  const { t } = useTranslation();

  return (
    <Card size="small" className="success-card">
      <Card.Body>
        <div className={bem("body")}>
          <div className={bem("icon")}>
            <Icons.Checked size="25px" />
          </div>
          <h2 className={bem("title")}>
            {t("common.invitations_were_sent_with_success")}
          </h2>
          <Flex justify="center">
            <Link to="/">
              <Button>{t("common.go_to_dashboard")}</Button>
            </Link>
            <WhiteSpace h="8px" />
            <Link to={redirect.url}>
              <Button>{redirect.title}</Button>
            </Link>
          </Flex>
        </div>
      </Card.Body>
    </Card>
  );
};
