import { createSvgIcon } from "../utils";

export const Edit = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M1 17.0001H0.25C0.25 17.4143 0.585786 17.7501 1 17.7501L1 17.0001ZM5 17.0001V17.7501C5.19891 17.7501 5.38968 17.671 5.53033 17.5304L5 17.0001ZM15.5 6.50006L16.0303 7.03039L15.5 6.50006ZM16.3284 4.50006H17.0784H16.3284ZM11.5 2.50006L10.9697 1.96973V1.96973L11.5 2.50006ZM1 13.0001L0.46967 12.4697C0.329018 12.6104 0.25 12.8011 0.25 13.0001H1ZM11.0303 2.96973C10.7374 2.67683 10.2626 2.67683 9.96967 2.96973C9.67678 3.26262 9.67678 3.73749 9.96967 4.03039L11.0303 2.96973ZM13.9697 8.03039C14.2626 8.32328 14.7374 8.32328 15.0303 8.03039C15.3232 7.73749 15.3232 7.26262 15.0303 6.96973L13.9697 8.03039ZM1 17.7501H5V16.2501H1V17.7501ZM5.53033 17.5304L16.0303 7.03039L14.9697 5.96973L4.46967 16.4697L5.53033 17.5304ZM16.0303 7.03039C16.7014 6.3593 17.0784 5.44912 17.0784 4.50006H15.5784C15.5784 5.05129 15.3595 5.57995 14.9697 5.96973L16.0303 7.03039ZM17.0784 4.50006C17.0784 3.551 16.7014 2.64081 16.0303 1.96973L14.9697 3.03039C15.3595 3.42017 15.5784 3.94882 15.5784 4.50006H17.0784ZM16.0303 1.96973C15.3592 1.29864 14.4491 0.921631 13.5 0.921631V2.42163C14.0512 2.42163 14.5799 2.64061 14.9697 3.03039L16.0303 1.96973ZM13.5 0.921631C12.5509 0.921631 11.6408 1.29864 10.9697 1.96973L12.0303 3.03039C12.4201 2.64061 12.9488 2.42163 13.5 2.42163V0.921631ZM10.9697 1.96973L0.46967 12.4697L1.53033 13.5304L12.0303 3.03039L10.9697 1.96973ZM0.25 13.0001V17.0001H1.75V13.0001H0.25ZM9.96967 4.03039L13.9697 8.03039L15.0303 6.96973L11.0303 2.96973L9.96967 4.03039Z"
      fill="currentColor"
    />
  </svg>,
);
