import cn from "classnames";

import { capitalizeString, makeBEM } from "utils";

type Props = {
  show?: boolean;
  left?: boolean;
  title?: string;
  info?: string[];
  infoWithFields?: object;
};

const bem = makeBEM("card-info");

export const CardInfo = ({
  show,
  left,
  title,
  info,
  infoWithFields,
}: Props) => {
  if (!show) return <div className={left ? "" : "text-center"}>-</div>;

  return (
    <div className={bem()}>
      <div className={bem("container", { left: left })}>
        <span className={cn(bem("title"), "nowrap")}>{title}</span>
        {info &&
          info.map((item, index) => (
            <span
              title={item}
              key={index}
              className={cn(bem("information"), "one-line")}
            >
              {item}
            </span>
          ))}
        {infoWithFields &&
          Object.keys(infoWithFields)?.map((key) => (
            <span key={key} className={cn(bem("information"), "one-line")}>
              {infoWithFields[key] && (
                <>
                  <span className={bem("information", { grey: true })}>
                    {capitalizeString(key)}:{" "}
                  </span>
                  <span
                    title={capitalizeString(String(infoWithFields[key]))}
                    className={bem("information", { blue: true })}
                  >
                    {infoWithFields[key]}
                  </span>
                </>
              )}
            </span>
          ))}
      </div>
    </div>
  );
};
