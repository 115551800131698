import { AxiosError } from "axios";
import { Form, useForm, useNotify } from "ebs-design";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";

import { MINUTE } from "app-constants";
import { Flex, LoadingButton } from "components";
import { InputFormField, InputPasswordShowField } from "components/form-fields";
import { ArrowRight } from "components/icons";
import { useAuth } from "hooks";
import { makeBEM, notifyErrors } from "utils";

const bem = makeBEM("auth-layout");

interface Props {
  needToConfirm: boolean;
  setNeedToConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}
export const LoginForm = ({ needToConfirm, setNeedToConfirm }: Props) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const auth = useAuth();
  const notify = useNotify();

  const loginMutation = useMutation(
    !needToConfirm ? auth.login : auth.confirmLogin,
    {
      onSuccess: () => setNeedToConfirm(true),
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  const [confirmationTime, setConfirmationTime] = useState(MINUTE);
  useEffect(() => {
    if (needToConfirm) {
      const interval = setInterval(() => {
        setConfirmationTime((prevState) => prevState - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [needToConfirm, confirmationTime]);

  const resendCodeMutation = useMutation(auth.resendCode, {
    onSuccess: () => {
      notify.success({
        title: t("template.item_sent_successfully", {
          item: t("common.code"),
        }),
      });
      setConfirmationTime(MINUTE);
    },
    onError: (error: AxiosError) => notifyErrors(notify, error),
  });

  return (
    <>
      <Form
        form={form}
        onFinish={(values) =>
          confirmationTime > 0
            ? loginMutation.mutate(values)
            : resendCodeMutation.mutate(values)
        }
      >
        <InputFormField
          name="username"
          placeholder={t("common.email")}
          type="email"
          hideLabel
          rules={[
            {
              required: true,
              message: t("validation.required_email"),
            },
          ]}
        />
        <InputPasswordShowField
          name="password"
          placeholder={t("common.password")}
          rules={[
            {
              required: true,
              message: t("validation.required_password"),
            },
          ]}
        />
        {needToConfirm && confirmationTime > 0 && (
          <>
            <InputFormField
              className="mb-8"
              name="code"
              placeholder={t("common.code")}
              type="text"
              hideLabel
              rules={[
                {
                  required: true,
                  message: t("validation.required_code"),
                },
              ]}
            />
            <div className="mb-8">
              {confirmationTime > 0 &&
                t("template.you_have_number_seconds_to_confirm_your_email", {
                  number: confirmationTime,
                })}
            </div>
          </>
        )}
        {!needToConfirm && (
          <h4 className="mb-15">
            <Link to="/reset-password" className={bem("link")}>
              {t("common.reset_password")}
            </Link>
          </h4>
        )}
        <LoadingButton
          size="large"
          className="full-width "
          type={confirmationTime > 0 ? "primary" : "light"}
          submit
          loading={loginMutation.isLoading || resendCodeMutation.isLoading}
        >
          <Flex justify="center" align="center">
            {confirmationTime > 0 ? (
              <>
                <span className="mr-13 fw-600">{t("common.sign_in")}</span>
                <ArrowRight size="14" />
              </>
            ) : (
              <span className="fw-600">{t("common.resend_code")}</span>
            )}
          </Flex>
        </LoadingButton>

        {needToConfirm && (
          <h4
            className="mt-15"
            onClick={() => {
              setNeedToConfirm(false);
              setConfirmationTime(60);
            }}
          >
            Go to{" "}
            <Link to="/login" className={bem("link")}>
              login
            </Link>
          </h4>
        )}
      </Form>
    </>
  );
};
