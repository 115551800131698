export const accessObjectField = (
  obj: object,
  arrOfFields: string[],
): string => {
  if (!obj) return "";
  if (!arrOfFields.length) return "";
  const [field, ...rest] = arrOfFields;
  if (!field) return "";
  if (!rest.length) return obj[field];
  return accessObjectField(obj[field], rest);
};
