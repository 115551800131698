import { TFunction } from "react-i18next";

import { TableCheckboxSelector, TableCheckbox } from "components";
import models from "models";
import { formattedDateTime, TableColumnBuilder } from "utils";
import { CheckboxesCheckedInfoType } from "types";
import { Button } from "ebs-design";

interface RecyclingCertificatesColumnsProps {
  t: TFunction;
  setCheckboxInfo: React.Dispatch<
    React.SetStateAction<CheckboxesCheckedInfoType>
  >;
  checkboxInfo?: CheckboxesCheckedInfoType;
}

export const recyclingCertificatesColumns = ({
  t,
  setCheckboxInfo,
  checkboxInfo,
}: RecyclingCertificatesColumnsProps) =>
  new TableColumnBuilder<models.RecyclingCertificates>()
    .add(
      <TableCheckboxSelector
        word="Recycling Certificates"
        checkboxInfo={checkboxInfo}
        setCheckboxInfo={setCheckboxInfo}
        wrap={false}
      />,
      "",
      (_, record) => (
        <TableCheckbox
          itemId={record?.id}
          checkboxInfo={checkboxInfo}
          setCheckboxInfo={setCheckboxInfo}
          wrap={false}
        />
      ),
    )
    .add(t("common.id"), "id")
    .add(t("common.name"), "filename")
    .add(t("common.creation_date"), "timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add(
      "",
      "",
      (_, record) => (
        <span onClick={(e) => e.stopPropagation()}>
          <Button>{t("common.download")}</Button>
        </span>
      ),
      {
        align: "right",
      },
    )
    .getColumns();

export const recyclingCertificatesSortOptions = [
  { title: "Creation date", value: "timestamp" },
];
