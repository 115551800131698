import React from "react";
import { Checkbox, Loader } from "ebs-design";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, QueryKey } from "react-query";

import { GenericObject } from "types";
import models from "models";
import {
  combineQueryPagesData,
  getNextPageParam,
  translateIfIsTranslatable,
} from "utils";
import { useIntersectionObserver, useQueryPagination } from "hooks";

import {
  AlertErrors,
  DebouncedInput,
  Grid,
  InfiniteScrollTrigger,
  WhiteSpace,
} from "..";
import { FilterCollapse } from ".";

export interface SelectQueryListFilterProps<T, Key extends string | number> {
  title: React.ReactNode;
  value?: (Key | null)[] | null | undefined;
  setValue?: React.Dispatch<
    React.SetStateAction<(Key | null)[] | null | undefined>
  >;
  querykey: (queryParams: GenericObject) => QueryKey;
  apiCall: (queryParams: GenericObject) => Promise<models.WithResults<T>>;

  getKey: (record: T) => Key | undefined;
  getValue: (record: T) => React.ReactNode;
  showSearch?: boolean;
}

export const SelectQueryListFilter = <T, Key extends string | number>({
  title,
  value,
  querykey,
  apiCall,
  getKey,
  getValue,
  setValue,
  showSearch = true,
}: SelectQueryListFilterProps<T, Key>) => {
  const { t } = useTranslation();
  const loadMoreRef = React.useRef<HTMLDivElement>(null);
  const loadMoreRootRef = React.useRef<HTMLDivElement>(null);

  const [search, setSearch] = React.useState("");
  const pagination = useQueryPagination();

  const queryParams = { search, page_size: 20 };

  const query = useInfiniteQuery(
    querykey({ ...queryParams }),
    ({ pageParam = 1 }) =>
      apiCall({
        ...queryParams,
        page: pageParam,
      }),
    { getNextPageParam },
  );

  const data = combineQueryPagesData(query.data);

  useIntersectionObserver({
    rootRef: loadMoreRootRef,
    targetRef: loadMoreRef,
    onIntersect: query.fetchNextPage,
    enabled: query.hasNextPage,
    rootMargin: "150px",
  });

  return (
    <FilterCollapse
      title={translateIfIsTranslatable(t, title as string, "common")}
      count={value?.length}
    >
      {showSearch && (
        <DebouncedInput
          placeholder={t("search.search")}
          value={search}
          onChange={setSearch}
          size="large"
        />
      )}

      <WhiteSpace v="0.5rem" />
      <AlertErrors error={query.error} />
      {query.isLoading && (
        <Loader.Inline>{t("common.loading")}...</Loader.Inline>
      )}

      {!data.length && !query.isError && !query.isLoading && (
        <h5 className="color-gray text-center m-15">
          {t("common.no_results")}
        </h5>
      )}

      <div className="list-filter-container" ref={loadMoreRootRef}>
        {data.length ? (
          <>
            <Grid gap="0.5rem" className="py-3">
              {data.map((item: any) => (
                <Checkbox
                  checked={value?.includes(getKey(item) || null) || false}
                  onChange={(c) => {
                    pagination.setPage(1);
                    c
                      ? setValue?.((prev) => [
                          ...(prev || []),
                          getKey(item) || null,
                        ])
                      : setValue?.((prev) =>
                          prev?.filter((p) => p !== getKey(item)),
                        );
                  }}
                  key={getKey(item)}
                  text={getValue(item)}
                />
              ))}
            </Grid>
            <WhiteSpace v="0.5rem" />
          </>
        ) : null}

        <InfiniteScrollTrigger ref={loadMoreRef} {...{ query, data }} />
      </div>
    </FilterCollapse>
  );
};
