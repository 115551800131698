import { createSvgIcon } from "../utils";

export const FileServices = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="26"
    viewBox="0 0 32 26"
    fill="none"
  >
    <path
      d="M23.75 20C23.75 19.5858 23.4142 19.25 23 19.25C22.5858 19.25 22.25 19.5858 22.25 20H23.75ZM23 25V25.75C23.4142 25.75 23.75 25.4142 23.75 25H23ZM1 25H0.25C0.25 25.4142 0.585786 25.75 1 25.75L1 25ZM1 11V10.25C0.585786 10.25 0.25 10.5858 0.25 11H1ZM5 11.75C5.41421 11.75 5.75 11.4142 5.75 11C5.75 10.5858 5.41421 10.25 5 10.25V11.75ZM27.75 15C27.75 14.5858 27.4142 14.25 27 14.25C26.5858 14.25 26.25 14.5858 26.25 15H27.75ZM27 20V20.75C27.4142 20.75 27.75 20.4142 27.75 20H27ZM5 20H4.25C4.25 20.4142 4.58579 20.75 5 20.75V20ZM5 6V5.25C4.58579 5.25 4.25 5.58579 4.25 6H5ZM9 6.75C9.41421 6.75 9.75 6.41421 9.75 6C9.75 5.58579 9.41421 5.25 9 5.25V6.75ZM9 1V0.25C8.58579 0.25 8.25 0.585786 8.25 1L9 1ZM31 1H31.75C31.75 0.585786 31.4142 0.25 31 0.25V1ZM31 15V15.75C31.4142 15.75 31.75 15.4142 31.75 15H31ZM9 15H8.25C8.25 15.4142 8.58579 15.75 9 15.75V15ZM22.25 20V25H23.75V20H22.25ZM23 24.25H1V25.75H23V24.25ZM1.75 25V11H0.25V25H1.75ZM1 11.75H5V10.25H1V11.75ZM26.25 15V20H27.75V15H26.25ZM27 19.25H5V20.75H27V19.25ZM5.75 20V6H4.25V20H5.75ZM5 6.75H9V5.25H5V6.75ZM9 1.75H31V0.25H9V1.75ZM30.25 1V15H31.75V1H30.25ZM31 14.25H9V15.75H31V14.25ZM9.75 15V1H8.25V15H9.75Z"
      fill="currentColor"
    />
  </svg>,
);
