import { TooltipProps } from "recharts";

import { Flex } from "components/Flex";
import { makeBEM } from "utils";


export type TooltipPayload = TooltipProps<
  string | number,
  string | number
>["payload"][number];
interface CustomTooltipProps extends TooltipPayload {
  icon?: boolean;
}

const bem = makeBEM("custom-tooltip");

export const CustomTooltip = ({
  name,
  value,
  color,
  icon,
}: CustomTooltipProps) => {
  return (
    <div className={bem()}>
      <Flex gap={6}>
        {icon && (
          <div className={bem("type")} style={{ backgroundColor: color }} />
        )}

        <div>
          <p className={bem("title")}>{name}</p>
          <p className={bem("description")}>{value}</p>
        </div>
      </Flex>
    </div>
  );
};
