import { cmsAxios } from "api/axios";
import { stringifyUrl } from "utils";
import { Properties } from "types";

export enum Ii18nType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
  NAMESPACE = "NAMESPACE",
}

export interface Ii18n {
  id?: number;
  code: string;
  i18n: any;
  type: Ii18nType;
  count?: number;
  codes: string[];
}

const i18n = {
  /**
   * Get all dictionaries
   */
  getAll: async (params?: Properties) => {
    const { data } = await cmsAxios.get(
      stringifyUrl(
        `nomenclatures/keys`,
        { page_size: params?.page_size || 100, ...params },
        { api: process.env.REACT_APP_BASE_API },
      ),
      {
        headers: {
          "Accept-Language": "no",
        },
      },
    );

    return data;
  },
  /**
   * Update an dictionary object
   */
  update: async (id: string, i18n: any, type: Ii18nType): Promise<Ii18n> => {
    const { data } = await cmsAxios.patch(
      stringifyUrl(
        `nomenclatures/keys/${id}`,
        {},
        { api: process.env.REACT_APP_BASE_API },
      ),
      {
        type,
        i18n,
      },
      {
        headers: {
          "Accept-Language": "no",
        },
      },
    );

    return data as Ii18n;
  },
  /**
   * Update an dictionary object
   */
  create: async (code: string): Promise<Ii18n> => {
    const { data } = await cmsAxios.post(
      stringifyUrl(
        `nomenclatures/keys`,
        {},
        { api: process.env.REACT_APP_BASE_API },
      ),
      {
        code,
      },
      {
        headers: {
          "Accept-Language": "no",
        },
      },
    );

    return data as Ii18n;
  },
  /**
   * Get dictionary by id
   */
  getById: async (id: string): Promise<Ii18n> => {
    const { data } = await cmsAxios.get(
      stringifyUrl(
        `nomenclatures/keys/${id}`,
        {},
        { api: process.env.REACT_APP_BASE_API },
      ),
      {
        headers: {
          "Accept-Language": "no",
        },
      },
    );

    return data as Ii18n;
  },
};

export default i18n;
