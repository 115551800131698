import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card } from "ebs-design";

import api, { querykeys } from "api";
import { useOrderingQueryParam, useQueryPagination } from "hooks";
import {
  ExportInFile,
  Flex,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import { checkboxesCheckedInfo, CheckboxesCheckedInfoType } from "types";
import {
  recyclingCertificatesColumns,
  recyclingCertificatesSortOptions,
} from "../config";

export const RecyclingCertificates = () => {
  const { t } = useTranslation();
  const pagination = useQueryPagination();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [ordering, setOrdering] = useOrderingQueryParam();
  const queryParams = {
    ...pagination.queryParams,
    search,
    ordering,
  };

  const query = useQuery(
    querykeys.recyclingCertificates.many(queryParams),
    () => api.recyclingCertificates.get(queryParams),
  );

  const allRecyclingCertificatesIds = query.data?.results?.map((item) =>
    String(item?.id),
  );

  const [checkedRecyclingCertificates, setCheckedRecyclingCertificates] =
    useState<CheckboxesCheckedInfoType>(checkboxesCheckedInfo);

  const tableColumns = useMemo(
    () =>
      recyclingCertificatesColumns({
        t,
        setCheckboxInfo: setCheckedRecyclingCertificates,
        checkboxInfo: {
          ...checkedRecyclingCertificates,
          checkboxesOnPage: allRecyclingCertificatesIds,
        },
      }),
    [allRecyclingCertificatesIds, checkedRecyclingCertificates, t],
  );

  return (
    <>
      <PageTitle
        title={t("menu.recycling_certificates")}
        count={query.data?.count}
      />

      <Card>
        <Card.Body>
          <QueryTable
            query={query}
            columns={tableColumns}
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  sortOptions={recyclingCertificatesSortOptions}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                  setSearch={setSearch}
                />
                <ExportInFile
                  idList={checkedRecyclingCertificates.allCheckedCheckboxes}
                  queryKey="recycling-certificates-exports"
                  // Wait API ----------->
                  apiFunction={() => Promise.resolve({} as any)}
                />
              </Flex>
            )}
            variants={["highlight-hover"]}
            onRow={(data) => ({
              onClick: () =>
                history.push(`/crm/recycling-certificates/${data?.id}`),
            })}
          />
        </Card.Body>
      </Card>
    </>
  );
};
