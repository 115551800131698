import React from "react";
import { Form } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

interface LabelFieldProps extends FormFieldProps {
  label?: React.ReactNode;
  name?: string | number;
}

export const LabelField = ({
  name,
  label,
  children,
  ...restProps
}: LabelFieldProps) => (
  <Form.Field name={name} {...restProps}>
    {(control, meta, form) => {
      const childNode =
        typeof children === "function"
          ? children(control, meta, form)
          : React.cloneElement(children as React.ReactElement, {
              ...control,
            });

      return (
        <div style={{ position: "relative" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {childNode}
          </div>
        </div>
      );
    }}
  </Form.Field>
);
