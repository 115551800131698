import { cmsAxios } from "api/axios";
import { Languages } from "apps/cms/lib/utils";
import { stringifyUrl } from "utils";

export enum ArticleTags {
  PAGE = 1,
  HOME = 2,
  NEWS = 3,
  HELP = 4,
  LEGAL = 5,
  PRIVACY = 6,
}

export enum ArticleTagsCode {
  PAGE = "page",
  HOME = "home",
  NEWS = "news",
  HELP = "help",
  LEGAL = "legalTerms",
  PRIVACY = "privacyPolicy",
}

export interface Tag {
  id: ArticleTags;
  code: ArticleTagsCode;
  i18n: Record<Languages, string>;
  pid?: ArticleTags;
}

export const defaultTags: Tag[] = [
  {
    id: ArticleTags.PAGE,
    code: ArticleTagsCode.PAGE,
    i18n: {
      en: "Static page",
      de: "Statische Seite",
      ro: "Pagina statică",
      ru: "Статическая страница",
    },
    pid: ArticleTags.PAGE,
  },
  {
    id: ArticleTags.HOME,
    code: ArticleTagsCode.HOME,
    i18n: {
      de: "Homepage",
      en: "Home page",
      ro: "Pagina principală",
      ru: "Домашняя страница",
    },
    pid: ArticleTags.HOME,
  },
  {
    id: ArticleTags.HELP,
    code: ArticleTagsCode.HELP,
    i18n: {
      de: "Hilfe & Support",
      en: "Help & Support",
      ro: "Ajutor & Asistență",
      ru: "Помощь & Поддержка",
    },
    pid: ArticleTags.HELP,
  },
  {
    id: ArticleTags.LEGAL,
    code: ArticleTagsCode.LEGAL,
    i18n: {
      en: "Terms & Conditions",
      de: "Allgemeine Geschäftsbedingungen",
      ro: "Termeni Și Condiții",
      ru: "Правила и Условия",
    },
    pid: ArticleTags.LEGAL,
  },
  {
    id: ArticleTags.PRIVACY,
    code: ArticleTagsCode.PRIVACY,
    i18n: {
      en: "Privacy policy",
      de: "Datenschutzerklärung",
      ro: "Politica de confidențialitate",
      ru: "Политика конфиденциальности",
    },
    pid: ArticleTags.PRIVACY,
  },
  {
    id: ArticleTags.NEWS,
    code: ArticleTagsCode.NEWS,
    i18n: {
      en: "Blog",
      de: "Blog",
      ro: "Blog",
      ru: "Блог",
    },
    pid: ArticleTags.NEWS,
  },
];

const tags = {
  all: async (params: any = {}) => {
    const query = new URLSearchParams({
      page_size: "100",
      ordering: "id",
      ...params,
    });

    [...query.entries()].forEach(([name, value]) => {
      if (!value || value === "undefined") {
        query.delete(name);
      }
    });

    const { data } = await cmsAxios.get(
      stringifyUrl(`articleTag/?${query.toString()}`, query),
    );

    return data;
  },
  /**
   * Set default names to all tags
   */
  resetAll: async () => {
    for (const item of defaultTags) {
      const { id, pid, ...tag } = item;

      await cmsAxios.post(stringifyUrl(`articleTag/`), tag);
    }
  },
};

export default tags;
