import models from "models";
import { GenericObject } from "types";

import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const nomenclatures = {
  department: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/departments", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Department>>(
      url,
    );
    return data;
  },
  warehouses: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/warehouses", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Warehouse>>(
      url,
    );
    return data;
  },
  roles: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/organization/roles", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Role>>(url);
    return data;
  },
  applicationList: async () => {
    const { data } = await apiAxios.get<{ code: string }[]>(
      "/commons/applications",
    );
    return data;
  },
  countries: {
    get: async (queryParams: GenericObject = {}) => {
      const url = stringifyUrl("/nomenclatures/countries", queryParams);
      const { data } = await apiAxios.get<models.WithResults<models.Country>>(
        url,
      );
      return data;
    },
    getById: async (id: string) => {
      const { data } = await apiAxios.get<models.Country>(
        `/nomenclatures/countries/${id}`,
      );
      return data;
    },
  },
  cities: {
    get: async (queryParams: GenericObject = {}) => {
      const url = stringifyUrl("/nomenclatures/cities", queryParams);
      const { data } = await apiAxios.get<models.WithResults<models.City>>(url);
      return data;
    },
    getById: async (id: string) => {
      const { data } = await apiAxios.get<models.City>(
        `/nomenclatures/cities/${id}`,
      );
      return data;
    },
  },
  currencies: {
    get: async () => {
      const { data } = await apiAxios.get<models.WithResults<models.Currency>>(
        "/nomenclatures/currencies",
      );

      return data;
    },
    getById: async (id: string) => {
      const { data } = await apiAxios.get<models.Currency>(
        `/nomenclatures/currencies/${id}`,
      );

      return data;
    },
  },
  fees: {
    get: async () => {
      const { data } = await apiAxios.get<models.WithResults<models.Fees>>(
        "/nomenclatures/fees",
      );

      return data;
    },
    getById: async (id: string) => {
      const { data } = await apiAxios.get<models.Fees>(
        `/nomenclatures/fees/${id}`,
      );

      return data;
    },
  },
  brands: {
    get: async (queryParams: GenericObject = {}) => {
      const url = stringifyUrl("/nomenclatures/brands", queryParams);
      const { data } = await apiAxios.get<
        models.WithResults<models.NomenclatureBrand>
      >(url);

      return data;
    },
  },
  models: {
    get: async (queryParams: GenericObject = {}) => {
      const url = stringifyUrl("/nomenclatures/brand-models", queryParams, {
        arrayFormat: "comma",
      });
      const { data } = await apiAxios.get<
        models.WithResults<models.NomenclatureBrandModel>
      >(url);

      return data;
    },
  },
};
