import React from "react";
import { useTranslation } from "react-i18next";

import { Form, Select, useForm } from "ebs-design";

import { Grid, WhiteSpace } from "components";
import { useInvitationOptions } from "hooks";
import { ADMIN_ROLES, WAREHOUSE_ROLES } from "app-constants";
import models from "models";
import { servicesConfigs, translateIfIsTranslatable } from "utils";
import { InviteContext } from "../context";

import { ServiceRow } from "./ServiceRow";

interface PermissionsFormProps {
  roles_id?: number;
  department_id?: number;
  warehouse_id?: number;
  applications?: models.Apps[];
}

const initialWHFields = {
  warehouse_id: undefined,
  department_id: undefined,
};

export const PermissionsForm = () => {
  const { t } = useTranslation();
  const { setInvite, userRoleType, setUserRoleType } =
    React.useContext(InviteContext);
  const [form] = useForm();

  const { roles, applications, warehouses, departments } =
    useInvitationOptions();

  const resetPermissions = (roles_id = undefined) => {
    const initialAppFields = Object.assign(
      {},
      ...applications?.map((app) => ({
        [app.code]: undefined,
      })),
    );
    form.setFieldsValue({
      ...initialWHFields,
      ...initialAppFields,
    });

    setInvite((prevInvite) => ({
      ...prevInvite,
      emailsRoles: prevInvite.emailsRoles.map((item) => ({
        ...item,
        ...initialWHFields,
        roles_id,
        applications: undefined,
      })),
    }));
  };

  const handleFormChange = (
    _,
    { roles_id, ...values }: PermissionsFormProps,
  ) => {
    if (!!!roles_id) {
      resetPermissions();
      setUserRoleType(null);
      return;
    }

    const roleText = roles?.find(({ value }) => value === roles_id).text;
    const currentUserRoleType = Object.values(ADMIN_ROLES).includes(roleText)
      ? models.RoleType.ADMIN
      : Object.values(WAREHOUSE_ROLES).includes(roleText)
      ? models.RoleType.WAREHOUSE
      : null;

    if (userRoleType !== currentUserRoleType) {
      resetPermissions([roles_id]);
      setUserRoleType(currentUserRoleType);
      return;
    }

    const userValues = (values) =>
      currentUserRoleType === models.RoleType.ADMIN
        ? {
            applications: Object.entries(values)
              .map((v) => !!v[1] && v[0])
              .filter((m) => m) as models.Apps[],
          }
        : values;

    setInvite((prevInvite) => ({
      ...prevInvite,
      emailsRoles: prevInvite.emailsRoles.map((item) => ({
        ...item,
        ...userValues(values),
        roles_id: [roles_id],
      })),
    }));

    setUserRoleType(currentUserRoleType);
  };

  return (
    <Form form={form} onValuesChange={handleFormChange}>
      <Grid
        cols={`1fr ${userRoleType === "admin" ? "2fr" : "1fr 1fr"}`}
        gap="16px"
        className="permision-row"
      >
        <Form.Field
          name="roles_id"
          label={t("template.select_item", {
            item: t("common.role").toLowerCase(),
          })}
        >
          <Select options={roles} size="large" mode="single" />
        </Form.Field>

        {userRoleType === models.RoleType.WAREHOUSE && (
          <>
            <Form.Field
              name="department_id"
              label={t("template.select_item", {
                item: t("department.department").toLowerCase(),
              })}
            >
              <Select options={departments} size="large" />
            </Form.Field>
            <Form.Field
              name="warehouse_id"
              label={t("template.select_item", {
                item: t("process_locations.process_locations").toLowerCase(),
              })}
            >
              <Select options={warehouses} size="large" />
            </Form.Field>
          </>
        )}

        {userRoleType === models.RoleType.ADMIN && (
          <div>
            <label className="d-block mb-4">
              {t("template.select_item", {
                item: t("menu.applications").toLowerCase(),
              })}
            </label>
            <>
              {applications.map((v, i) => {
                const index = v.code as keyof typeof servicesConfigs;
                return (
                  <React.Fragment key={i}>
                    <ServiceRow
                      icon={servicesConfigs[index].icon}
                      iconBg={servicesConfigs[index].iconBg}
                      title={translateIfIsTranslatable(
                        t,
                        servicesConfigs[index].title,
                        "application",
                      )}
                      name={servicesConfigs[index].name}
                    />
                    <WhiteSpace h="16px" />
                  </React.Fragment>
                );
              })}
            </>
          </div>
        )}
      </Grid>
    </Form>
  );
};
