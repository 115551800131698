import { Card, Form, Loader, useForm, useNotify } from "ebs-design";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import api, { querykeys } from "api";
import { ColorPicker, Flex, LoadingButton, UploadImage } from "components";
import { contrastColor } from "components/color-picker";
import models from "models";
import { notifyErrors } from "utils";

import { BrandHeader } from "./BrandHeader";

export const BrandCustomizationCard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = useForm();
  const notify = useNotify();

  const [logo, setLogo] = React.useState<models.File>();
  const [color, setColor] = React.useState("#000000");
  const [certusLogo, setCertusLogo] = React.useState<models.File>();

  const textColor = contrastColor(color);

  const query = useQuery(
    querykeys.settings.organization,
    api.settings.getOrganizationData,
  );

  const mutation = useMutation(api.settings.updateOrganizationData, {
    onSuccess: () => {
      notify.success({
        title: t("template.item_updated_successfully", {
          item: t("common.theme_settings"),
        }),
      });
      history.push("/settings");
    },
    onError: (error) => notifyErrors(notify, error),
  });

  React.useEffect(() => {
    setLogo(query?.data?.logo);
    setColor(query?.data?.accent_color || "#000000");
    setCertusLogo(query?.data?.settings.logo);

    form.setFieldsValue({
      is_logo_active: query?.data?.is_logo_active,
      is_accent_color_active: query.data?.is_accent_color_active,
      settings: {
        logo_is_active: query?.data?.settings?.logo_is_active,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.data?.is_accent_color_active]);

  return (
    <Card className="brand-card">
      <Loader loading={query.isLoading}>
        <Form
          form={form}
          onFinish={(values) =>
            mutation.mutate({
              ...values,
              logo_id: logo?.id,
              accent_color: color,
              accent_color_content: textColor,
              settings: {
                ...values.settings,
                logo_id: certusLogo?.id,
              },
            })
          }
        >
          <Card.Body>
            <BrandHeader
              title={t("brand.brand_logo")}
              name="is_logo_active"
              initialValue={query.data?.is_logo_active}
            />
            <UploadImage file={logo} setFile={setLogo} showImage publicFile />

            <BrandHeader
              title={t("brand.brand_color")}
              name="is_accent_color_active"
            />
            {query.data && <ColorPicker color={color} onChange={setColor} />}
            <p className="grey-400 mt-16 mb-16">
              {t(
                "brand.turning_off_the_brand_color_will_set_the_default_app_color_or_all_color_sensitive_elements_from_all_the_pages",
              )}
            </p>
            <BrandHeader
              title={t("common.certus_logo")}
              name={["settings", "logo_is_active"]}
              initialValue={query.data?.settings?.logo_is_active}
            />
            <UploadImage
              file={certusLogo}
              setFile={setCertusLogo}
              showImage
              publicFile
            />
          </Card.Body>
          <Card.Footer>
            <Flex justify="flex-end">
              <LoadingButton loading={mutation.isLoading} submit type="primary">
                {t("common.save")}
              </LoadingButton>
            </Flex>
          </Card.Footer>
        </Form>
      </Loader>
    </Card>
  );
};
