import cn from "classnames";

import { Flex, SwitchFormField, WhiteSpace } from "components";
import { makeBEM } from "utils";

const bem = makeBEM("service-row");
interface ServiceRowProps {
  iconBg: string;
  icon: React.ReactNode;
  title: string;
  name: string;
  small?: boolean;
}
export const ServiceRow = ({
  iconBg,
  icon,
  title,
  name,
  small,
}: ServiceRowProps) => {
  return (
    <Flex
      className={cn("service-row", { "service-row--small": small })}
      justify="space-between"
      align="center"
    >
      <Flex align="center">
        <div
          style={{ backgroundColor: iconBg }}
          className={cn("full-height", bem("icon"))}
        >
          {icon}
        </div>
        <WhiteSpace h="16px" />
        <h3>{title}</h3>
      </Flex>
      <SwitchFormField name={name} />
    </Flex>
  );
};
