import cn from "classnames";
import { Form, FormInstance } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { DAYS_OF_THE_WEEK } from "app-constants";
import models from "models";
import { makeBEM } from "utils";

import { Schedule } from "./Schedule";

export interface WeekScheduleFormFieldProps extends FormFieldProps {
  form?: FormInstance;
  label?: string;
  className?: string;
}

export const bem = makeBEM("schedule-form");

export const WeekScheduleFormField = ({
  label,
  className,
  ...restProps
}: WeekScheduleFormFieldProps) => {
  return (
    <Form.Field {...restProps}>
      {({ value, onChange }) => {
        value = value?.sort((a, b) => a.index - b.index);

        return (
          <div className={cn(bem(), className)}>
            <span className={bem("title")}>{label}</span>
            {DAYS_OF_THE_WEEK.map((item, idx) => {
              let workHours: models.WorkingHours[] = value?.filter(
                (item: models.WorkingHours) => item.index === idx,
              );
              return (
                <Schedule
                  key={idx}
                  index={idx}
                  day={item.name}
                  dayWorkingHours={workHours?.length ? workHours[0] : undefined}
                  schedule={value as models.WorkingHours[]}
                  onChange={onChange}
                />
              );
            })}
          </div>
        );
      }}
    </Form.Field>
  );
};
