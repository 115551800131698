import React from "react";
import { Form, Select } from "antd";
import { Properties } from "types";

import { formItemStyle } from "../helpers";

const Responsive = ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "from"]}
        initialValue={!item?.until ? "small-desktop" : undefined}
        label="From"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="mobile">Mobile</Select.Option>
          <Select.Option value="small-tablet">Small tablet</Select.Option>
          <Select.Option value="tablet">Tablet</Select.Option>
          <Select.Option value="small-desktop">Small desktop</Select.Option>
          <Select.Option value="desktop">Desktop</Select.Option>
          <Select.Option value="wide">Wide</Select.Option>
          <Select.Option value="extra-wide">Extra wide</Select.Option>
          <Select.Option value="extra-extra-wide">Expansive</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "until"]}
        label="Until"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="mobile">Mobile</Select.Option>
          <Select.Option value="small-tablet">Small tablet</Select.Option>
          <Select.Option value="tablet">Tablet</Select.Option>
          <Select.Option value="small-desktop">Small desktop</Select.Option>
          <Select.Option value="desktop">Desktop</Select.Option>
          <Select.Option value="wide">Wide</Select.Option>
          <Select.Option value="extra-wide">Extra wide</Select.Option>
          <Select.Option value="extra-extra-wide">Expansive</Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};

export default Responsive;
