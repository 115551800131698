import { Button, Card, useNotify } from "ebs-design";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import * as queryString from "query-string";

import api, { querykeys } from "api";
import { Flex, PageTitle, QueryTable, TableTitle } from "components";
import { useQueryPagination } from "hooks";
import { notifyErrors } from "utils";

import { nomenclatureBrandsColumns } from "../config";

export const AddBrands = () => {
  const { t } = useTranslation();
  const pagination = useQueryPagination();
  const notify = useNotify();
  const history = useHistory();

  const [search, setSearch] = React.useState("");
  const [checkedBrands, setCheckedBrands] = React.useState<string[]>([]);

  const queryParams = {
    ...pagination.queryParams,
    search,
  };

  const query = useQuery(querykeys.nomenclature.brands.many(queryParams), () =>
    api.nomenclatures.brands.get(queryParams),
  );

  const allBrandsId = query.data?.results
    ?.map((item) => String(item?.id))
    .filter((item) => item);

  const { mutate } = useMutation(api.brands.connectBrands, {
    onSuccess: () => {
      const url = queryString.stringifyUrl(
        {
          url: "/product-management/brands/add-brands/success-connected",
          query:
            checkedBrands.length === 1
              ? { brand__id: checkedBrands[0] }
              : { brand__id__in: checkedBrands },
        },
        {
          arrayFormat: "comma",
        },
      );
      history.push(url);
    },
    onError: (errors: unknown) => notifyErrors(notify, errors),
  });

  const tableColumns = React.useMemo(
    () =>
      nomenclatureBrandsColumns({
        setCheckedBrands,
        checkedBrands,
        allBrandsId,
        t,
      }),
    [setCheckedBrands, checkedBrands, allBrandsId, t],
  );

  return (
    <>
      <PageTitle>
        {t("template.add_item", { item: t("brand.brands").toLowerCase() })}
      </PageTitle>
      <Card className="add-brands-table">
        <Card.Body>
          <QueryTable
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                />
                <Button
                  type="primary"
                  onClick={() =>
                    mutate({ brands_id: checkedBrands.map((v) => Number(v)) })
                  }
                >
                  {t("common.save")}
                </Button>
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            className="people-table"
            pagination={pagination}
          />
        </Card.Body>
      </Card>
    </>
  );
};
