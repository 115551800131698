import models from "models";

export interface ShippingProvider {
  id?: number;
  i18n?: models.I18N;
  timestamp?: string;
  edited_timestamp?: string;
  code?: string;
  title?: string;
  price?: string;
  price_translation?: string;
  is_active?: boolean;
  type?: ShippingProviderType;
  logo?: models.File;
}

export enum ShippingProviderType {
  DHL = "DHL",
  DROP_OFF_LOCATION = "DROP_OFF_LOCATION",
}

export interface DropOffLocation {
  id?: number;
  phone?: string;
  working_hours?: WorkingHours[];
  timestamp?: string;
  edited_timestamp?: string;
  is_active?: boolean;
  title?: string;
  address?: string;
}

export interface WorkingHours {
  index: number;
  time_from?: string;
  time_to?: string;
}

export interface DropOffLocation {
  id?: number;
  phone?: string;
  working_hours?: WorkingHours[];
  timestamp?: string;
  edited_timestamp?: string;
  is_active?: boolean;
  title?: string;
  address?: string;
}
