import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import * as Icon from "components/icons";
import { useAuth } from "hooks";
import { makeBEM } from "utils";
import { Restricted } from ".";

const bem = makeBEM("top-bar");

export const TopBarTooltip = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <div className={bem("tooltip")}>
      <Link to="/profile-settings" className={bem("tooltip__item")}>
        <Icon.Settings />
        {t("common.profile_settings")}
      </Link>
      <Restricted>
        <Link to="/language-settings" className={bem("tooltip__item")}>
          <Icon.Language />
          {t("common.languages")}
        </Link>
      </Restricted>

      <Link to="/" className={bem("tooltip__item")} onClick={auth.logout}>
        <Icon.Logout /> {t("common.logout")}
      </Link>
    </div>
  );
};
