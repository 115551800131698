import { createContext, Dispatch, SetStateAction } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';

export type ActiveType = { index: number; type?: 'preview' | 'description' };

export interface GalleryFile extends UploadFile {
  caption?: string;
}

interface GalleryContextProps {
  handleCancel: () => void;
  fileList: GalleryFile[];
  handleRemove: (url: string) => void;
  active: ActiveType;
  setActive: React.Dispatch<React.SetStateAction<ActiveType>>;
  setFileList: Dispatch<SetStateAction<GalleryFile[]>>;
}

const GalleryContext = createContext<GalleryContextProps>({
  fileList: [],
  active: { index: -1 },
  setActive: () => null,
  setFileList: () => null,
  handleRemove: () => null,
  handleCancel: () => null,
});

export default GalleryContext;
