import { AxiosError } from "axios";
import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useContext, useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { querykeys } from "api";
import api, { Banner } from "apps/cms/lib/api/banners";
import { LanguagesContext } from "apps/cms/context/Languages";
import { Flex, InputFormField, LanguageTabs, WhiteSpace } from "components";
import { notifyErrors } from "utils";
import UploadImage from "../Backgrounds/UploadImage";

interface BannerModalProps extends ModalProps {
  data?: Banner;
  onClose: () => void;
}

export const BannerModal = ({ data, onClose, ...props }: BannerModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const {
    languages: { data: languagesCtx },
  } = useContext(LanguagesContext);

  const languages = languagesCtx.map((lang) => lang.code);
  const [translationFields, setTranslationFields] = useState(
    data
      ? data?.i18n
      : Object.fromEntries(
          languages.map((lang) => [
            lang,
            {
              title: data?.i18n?.[lang]?.title
                ? data?.i18n?.[lang]?.title
                : `${t("common.title")} [${lang}]`,
              content: data?.i18n?.[lang]?.content
                ? data?.i18n?.[lang]?.content
                : `${t("common.content")} [${lang}]`,
            },
          ]),
        ),
  );

  const queryClient = useQueryClient();
  const { mutate, isLoading: isLoadingMutation } = useMutation(
    (banner: Banner) =>
      data ? api.update(data.id, banner) : api.create(banner),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.cms.banners.many());
        notify.success({
          title: t(
            data
              ? "template.item_updated_successfully"
              : "template.item_created_successfully",
            {
              item: t("common.banner"),
            },
          ),
        });
        onClose();
      },
      onError: (errors: AxiosError) => notifyErrors(notify, errors),
    },
  );

  return (
    <Modal
      size="small"
      title={`${data ? t("common.edit") : t("common.add")} ${t(
        "common.banner",
      ).toLowerCase()}`}
      onClose={onClose}
      {...props}
    >
      <Modal.Content>
        <Form
          form={form}
          initialValues={{
            ...data,
            i18n: {
              ...data?.i18n,
              ...translationFields,
            },
          }}
          onValuesChange={(values) =>
            setTranslationFields((prevState) => ({
              ...prevState,
              ...values.i18n,
            }))
          }
          onFinish={(values) =>
            mutate({
              ...values,
              i18n: {
                ...translationFields,
                ...values.i18n,
              },
            })
          }
        >
          <LanguageTabs>
            {(language) => (
              <>
                <InputFormField
                  name={["i18n", language, "title"]}
                  label={t("common.title")}
                  placeholder={`${t("common.title")} [${language}]`}
                />
                <InputFormField
                  name={["i18n", language, "content"]}
                  label={t("common.content")}
                  placeholder={`${t("common.content")} [${language}]`}
                />
              </>
            )}
          </LanguageTabs>
          <InputFormField
            name={["link"]}
            defaultValue={data?.link}
            label={t("common.link")}
          />
          <InputFormField
            name={["image"]}
            defaultValue={data?.image}
            label={t("common.image_url")}
          />
          <UploadImage
            onChange={(image) => form.setFieldsValue({ image: image?.[0] })}
            value={form.getFieldValue("image")}
          />
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Flex justify="end">
          <Button type="light" onClick={onClose}>
            {t("common.cancel")}
          </Button>
          <WhiteSpace h="10px" />
          <Button
            loading={isLoadingMutation}
            type="primary"
            onClick={form.submit}
            form="BannerForm"
          >
            {t("common.save")}
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
