import models from "models";
import { stringifyUrl } from "api/utils";
import * as Icons from "components/icons";

export const transformFileExtension = (extension?: string) =>
  extension?.split(".")[1]?.toUpperCase();

export const isFileImage = (format: string) => {
  return ["jpg", "png", "jpeg", "gif"].includes(format);
};

export const getFileFormat = (url?: string) => {
  return url?.split(/[#?]/)[0].split(".").pop()?.trim();
};

export const getFilePreview = (format: string) => {
  switch (true) {
    case format === "pdf":
      return <Icons.FilePdf size="40px" />;
    case ["xls", "xlsx"].includes(format):
      return <Icons.FileXls size="40px" />;
    case ["doc", "docx"].includes(format):
      return <Icons.FileDocument size="40px" />;
    default:
      return <Icons.FileDownload size="40px" />;
  }
};

export const fileToken = (file: models.Attachment) => {
  const token = localStorage.getItem("access_token");

  const url = file?.public ? file?.url : stringifyUrl(file?.url!, { token });

  return url;
};

export const convertAndAddFileSizeMeasure = (size: number) => {
  if (size < 1e3) return `${size} B`;
  if (size < 1e6) return `${String(size).slice(0, -3)} KB`;
  if (size < 1e9) return `${String(size).slice(0, -6)} MB`;
  return size;
};

export const downloadFile = (
  data: Blob,
  fileName = "Document.csv",
  type = "application/csv",
) => {
  const blob = new window.Blob([data], { type });
  const link = document.createElement("a");

  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();

  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 5000);
};
