import { useState } from "react";
import { Form, Upload, Input, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import api from "apps/cms/lib/api/pages";
import { getBase64 } from "utils";
import { Properties } from "types";

import { maxImageSizeInMb, formItemStyle } from "../helpers";

const Image = ({ item, lang, setFieldsValue }: Properties) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (info: Properties) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, () => {
        setLoading(false);
      });
    }
  };

  const handleUpload = async (file: any): Promise<void> => {
    setLoading(true);

    try {
      const data = await api.uploadFile(file);
      const { id: uid, upload, uploaded } = data;

      const newFile: UploadFile<any> = {
        uid,
        size: 1,
        name: upload,
        status: uploaded ? "done" : "error",
        url: `${process.env.REACT_APP_STATIC_API}/v1${upload}`,
        type: "image",
      };

      setFieldsValue({
        [item.id]: {
          [lang]: {
            background: { fileList: [newFile], url: newFile.url },
          },
        },
      });
    } catch (e) {
      message.error("Unsuccessfull image upload!");
    }
    setLoading(false);
  };

  const handleBeforeUpload = (file: any): boolean => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Puteți încărca doar imagini în formatul JPG/PNG!");
      return false;
    }

    const isMbLt = file.size / 1024 / 1024 <= maxImageSizeInMb;
    if (!isMbLt) {
      message.error(
        `Imaginea nu trebuie să fie mai mare decât ${maxImageSizeInMb} MB!`,
      );
      return false;
    }
    handleUpload(file);

    return false;
  };

  return (
    <>
      <Form.Item
        wrapperCol={{ span: 24 }}
        style={{ ...formItemStyle, width: "calc(100% + 8px)" }}
      >
        <Upload
          listType="picture-card"
          className="background-uploader"
          showUploadList={false}
          multiple={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleChange}
        >
          {item?.background?.url ? (
            <img
              src={item.background.url}
              alt="background"
              style={{ width: "100%" }}
            />
          ) : (
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">Upload</div>
            </div>
          )}
        </Upload>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "maxWidth"]}
        label="Max width"
        style={formItemStyle}
      >
        <Input type="number" min={0} />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "maxHeight"]}
        label="Max height"
        style={formItemStyle}
      >
        <Input type="number" min={0} />
      </Form.Item>
    </>
  );
};

export default Image;
