import { Button, Modal } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { Flex, LoadingButton, WhiteSpace } from "components";
import { useTranslation } from "react-i18next";

interface ConfirmationModalProps extends ModalProps {
  title: string;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmText?: string;
}
export const ConfirmationModal = ({
  title,
  children,
  isLoading,
  onClose,
  onConfirm,
  confirmText = "Confirm",
  ...props
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal title={title} size="small" onClose={onClose} {...props}>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Footer>
        <Flex justify="flex-end" align="center">
          <Button type="light" onClick={onClose}>
            {t("common.cancel")}
          </Button>
          <WhiteSpace h="8px" />
          <LoadingButton
            onClick={() => {
              onConfirm();
              onClose();
            }}
            loading={isLoading}
            type="primary"
          >
            {confirmText}
          </LoadingButton>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
