import { Button, Card, Form, useForm, useNotify } from "ebs-design";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { NumberParam } from "use-query-params";

import api, { querykeys } from "api";
import {
  AddressMapFormField,
  Flex,
  InputFormField,
  LoadingButton,
  PageTitle,
  PhoneFormField,
  SwitchFormField,
  WeekScheduleFormField,
  WhiteSpace,
} from "components";
import { useParam } from "hooks";
import models from "models";
import { makeBEM, notifyErrors } from "utils";

import "leaflet/dist/leaflet.css";

const bem = makeBEM("map-form");

interface Props {
  edit: boolean;
}

export const DropOffLocationForm = ({ edit }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const dropOffLocationId = useParam("id", NumberParam.decode) || 0;

  const { data } = useQuery(
    querykeys.dropOffLocations.one(dropOffLocationId),
    () => api.dropOffLocations.getById(dropOffLocationId),
    {
      enabled: dropOffLocationId !== 0,
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const { mutate, isLoading } = useMutation(
    (body: models.DropOffLocation) =>
      edit
        ? api.dropOffLocations.patch(dropOffLocationId, body)
        : api.dropOffLocations.post(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.dropOffLocations.many());
        notify.success({
          title: t(
            edit
              ? "template.item_updated_successfully"
              : "template.item_created_successfully",
            { item: t("common.drop_off_location") },
          ),
        });
        history.goBack();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  useEffect(() => {
    form.setFieldsValue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <PageTitle goBack>
        {t(edit ? "template.edit_item" : "template.add_item", {
          item: t("common.drop_off_location"),
        })}
      </PageTitle>
      <Card style={{ maxWidth: 1200, margin: "auto" }}>
        <Form
          form={form}
          name="drop-off-location"
          className="service-form"
          initialValues={data}
          onFinish={mutate}
        >
          <Card.Body>
            <h2 className="fw-600 ">{`${
              edit ? t("common.edit") : t("common.add")
            } ${t("common.drop_off_location")}`}</h2>

            <div className={bem()}>
              <AddressMapFormField
                className="map"
                name="address"
                label={t("common.address")}
              />
              <div>
                <InputFormField
                  name="title"
                  label={t("common.location_name")}
                  placeholder={t("common.location_name")}
                />
                <PhoneFormField
                  name="phone"
                  label={t("common.phone_number")}
                  placeholder={t("common.phone_number")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required_phone"),
                    },
                  ]}
                />
                <WeekScheduleFormField
                  name="working_hours"
                  label={t("common.working_hours")}
                />
              </div>
              <SwitchFormField
                className={bem("last-block")}
                labelOptions={{ col: 1 }}
                controlOptions={{
                  col: 1,
                  className: "d-flex",
                  justify: "end",
                  align: "center",
                }}
                name="is_active"
                label={t("common.active")}
              />
            </div>
          </Card.Body>
          <Card.Footer>
            <Flex justify="flex-end" align="center">
              <Button type="ghost" onClick={history.goBack}>
                {t("common.cancel")}
              </Button>
              <WhiteSpace h="8px" />
              <LoadingButton loading={isLoading} submit type="primary">
                {t("common.save")}
              </LoadingButton>
            </Flex>
          </Card.Footer>
        </Form>
      </Card>
    </>
  );
};
