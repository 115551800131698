import { Card, Col, Row } from "ebs-design";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import api, { querykeys } from "api";
import { CURRENCY_CODE_FALLBACK, CURRENCY_SYMBOL } from "app-constants";
import { Flex, SettingsCard, Status } from "components";
import { CardMoney, LotsOfMoney, Money } from "components/icons";
import models from "models";
import { formatPrice } from "utils";

export interface PaymentsCardProps {
  organizationData?: models.OrganizationSettings;
}
export const PaymentsCard = ({ organizationData }: PaymentsCardProps) => {
  const { t } = useTranslation();

  const organizationFees = useQuery(querykeys.settings.fees.many(), () =>
    api.settings.fees(),
  );

  const { data } = useQuery(querykeys.settings.defaultSettings, () =>
    api.settings.defaultSettings(),
  );
  const currency = organizationData?.currency?.code || CURRENCY_CODE_FALLBACK;
  const paymentMethod = data?.data.PAYMENT_PAYOUT_METHOD;

  return (
    <Card className="full-height">
      <Card.Header>
        <Flex align="center" justify="space-between">
          <h3>{t("fee.currency_payments_fees")}</h3>
          <Link className="fw-500" to="/settings/payments">
            {t("common.edit")}
          </Link>
        </Flex>
      </Card.Header>
      <Card.Body>
        <Row g={3} className="mb-24">
          <Col size={12} sm={6} lg={12} xxl={6}>
            <SettingsCard
              title={t("common.currency")}
              description={
                <span className="grey-400">
                  {`${CURRENCY_SYMBOL[currency]} ${currency}`}
                </span>
              }
              icon={<LotsOfMoney />}
            />
          </Col>
          {paymentMethod && (
            <Col size={12} sm={6} lg={12} xxl={6}>
              <SettingsCard
                title={t("common.payment_and_payout_method")}
                description={
                  <span className="grey-400">
                    {models.PaymentPayoutMethod[paymentMethod]}
                  </span>
                }
                icon={<CardMoney />}
              />
            </Col>
          )}
        </Row>

        <span className="grey-400 font-size-12 mb-8">
          {t("fee.return_fees")}
        </span>
        <Row g={3}>
          {organizationFees.data?.results?.map((item: models.Fees) => (
            <Col key={item?.id} size={12} sm={6} lg={12} xxl={6}>
              <SettingsCard
                title={item?.title}
                description={formatPrice(item?.price, currency)}
                icon={<Money />}
                children={<Status status={item?.is_active} />}
              />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};
