import cn from "classnames";
import { QueryParamConfigMap, SetQuery } from "use-query-params";

import { TrendUp } from "components/icons";
import { numberWithSpaces, makeBEM } from "utils";
import { DynamicCssProperties } from "types";
import { Flex, SelectPeriod } from "components";

interface Props {
  title: string;
  value?: number;
  trendDirection?: number;
  color?: string;
  currency?: string;
  action?: SetQuery<QueryParamConfigMap>;
}

const bem = makeBEM("statistics-card");

export const StatisticsCardOld = ({
  title,
  value,
  trendDirection,
  color = "#01689a",
  currency,
  action,
}: Props) => {
  return (
    <div className="statistics-card">
      <Flex justify="space-between" align="center" wrap>
        <h5
          className={cn(bem("title"), "mr-16")}
          style={
            {
              "--background-color": color,
            } as DynamicCssProperties
          }
        >
          {title}
        </h5>
        <SelectPeriod action={action} />
      </Flex>
      <Flex justify="space-between" align="baseline">
        <span
          className={bem("value")}
          style={
            {
              "--color": color,
            } as DynamicCssProperties
          }
        >
          {currency} {numberWithSpaces(value)}
        </span>
        {trendDirection && (
          <div
            className={bem("trend-direction", [
              trendDirection > 0 ? "up" : "down",
            ])}
          >
            {`${Math.abs(trendDirection)}%`} <TrendUp />
          </div>
        )}
      </Flex>
    </div>
  );
};
