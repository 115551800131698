import cn from "classnames";
import { Space } from "ebs-design";
import { useEffect, useState } from "react";

import { Flex } from "components";
import * as Icons from "components/icons";

interface SorterProps {
  reset?: boolean;
  onChange?: (value: "desc" | "asc" | null) => void;
}

export const Sorter: React.FC<SorterProps> = ({
  children,
  reset,
  onChange,
}) => {
  const [isDesc, setIsDesc] = useState<boolean>(null);
  useEffect(() => (reset ? setIsDesc(null) : () => {}), [reset]);

  return (
    <Space
      style={{ width: "min-content" }}
      onClick={() => {
        setIsDesc(isDesc === null ? true : isDesc ? false : null);
        onChange(isDesc === null ? "desc" : isDesc ? "asc" : null);
      }}
      size={6}
      className="cursor-pointer no-user-select pr-5"
    >
      {children}
      <Flex
        className={cn("font-size-5 grey-100 wait-rotation", {
          "trigger-rotation": isDesc === false,
        })}
        direction="column"
      >
        <Icons.AngleDownSmall className="rotate-180" />
        <Icons.AngleDownSmall className={cn({ black: isDesc !== null })} />
      </Flex>
    </Space>
  );
};
