import React, { useCallback, useEffect, useRef } from "react";

interface PageContainerProps {
  size: string | number;
  breakpoint?: string | number;
  children: React.ReactChild;
}

export const PageContainer = ({
  size = 960,
  breakpoint,
  children,
}: PageContainerProps) => {
  const divElement = useRef<HTMLDivElement>(null);

  const resizeFunction = useCallback(
    ({ target }) => {
      if (target.innerWidth <= (breakpoint || size)) {
        divElement.current.style.width = "100%";
      } else {
        divElement.current.style.width = size + "px";
      }
    },
    [size, breakpoint],
  );

  useEffect(() => {
    resizeFunction({
      target: window,
    });
    window.addEventListener("resize", resizeFunction);
    return () => window.removeEventListener("resize", resizeFunction);
  });

  return (
    <div ref={divElement} style={{ margin: "auto" }}>
      {children}
    </div>
  );
};
