import { List, Card, Row, Col } from "antd";
import { AxiosError } from "axios";
import { Button, Space, useNotify } from "ebs-design";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";

import api, { Banner } from "apps/cms/lib/api/banners";
import { BannerModal } from "apps/cms/components/Banners/BannerModal";
import { ConfirmationModal, Flex, PageTitle } from "components";
import * as Icons from "components/icons";

import { querykeys } from "api";
import { useModalState } from "hooks";
import { notifyErrors, onErrorImage } from "utils";

const Banners: FC = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const bannerModal = useModalState<Banner>();
  const deleteBannerModal = useModalState<Banner>();

  const { data, isLoading } = useQuery(querykeys.cms.banners.many(), () =>
    api.get(),
  );
  const queryClient = useQueryClient();
  const { mutate: deleteMutation, isLoading: isDeleteLoading } = useMutation(
    (id: number) => api.remove(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.cms.banners.many());
        notify.success({
          title: t("template.item_deleted_successfully", {
            item: t("common.banner"),
          }),
        });
        deleteBannerModal.close();
      },
      onError: (errors: AxiosError) => notifyErrors(notify, errors),
    },
  );

  return (
    <>
      <PageTitle
        actions={
          <Button type="primary" onClick={bannerModal.open}>
            {t("template.add_item", {
              item: t("common.banner").toLowerCase(),
            })}
          </Button>
        }
        title={t("menu.banners")}
        count={data?.count}
      />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 2,
              xxl: 3,
            }}
            dataSource={data?.results}
            loading={isLoading}
            renderItem={(item) => {
              const i18n = item.i18n.en;

              return (
                <List.Item key={item.id}>
                  <Card
                    hoverable
                    title={
                      <Flex justify="space-between">
                        <span title={i18n.title} className="one-line max-w-200">
                          {i18n.title}
                        </span>
                        <Space size={6}>
                          <Button
                            onClick={() => bannerModal.openWith(item)}
                            type="light"
                            size="small"
                            icon={() => <Icons.Edit size="small" />}
                          />
                          <Button
                            onClick={() => deleteBannerModal.openWith(item)}
                            type="light"
                            size="small"
                            icon={() => <Icons.Trash size="small" />}
                          />
                        </Space>
                      </Flex>
                    }
                    cover={
                      item.image && (
                        <div
                          style={{
                            overflow: "hidden",
                            height: "200px",
                            width: "100%",
                          }}
                        >
                          <img
                            alt={i18n.title}
                            src={item.image}
                            style={{ height: "100%", width: "100%" }}
                            onError={onErrorImage}
                          />
                        </div>
                      )
                    }
                  >
                    {i18n.content ? i18n.content : "Card content"}
                  </Card>
                </List.Item>
              );
            }}
          />
        </Col>
      </Row>

      {bannerModal.isOpen && (
        <BannerModal
          open={bannerModal.isOpen}
          onClose={bannerModal.close}
          data={bannerModal.data}
        />
      )}
      {deleteBannerModal.isOpen && (
        <ConfirmationModal
          open={deleteBannerModal.isOpen}
          onClose={deleteBannerModal.close}
          onConfirm={() =>
            deleteMutation(Number(deleteBannerModal.data.id) || 0)
          }
          isLoading={isDeleteLoading}
          title={t("template.delete_item", {
            item: t("common.banner").toLowerCase(),
          })}
          confirmText={t("common.delete")}
        >
          <p>
            {`${t("template.are_you_sure_you_want_to_delete_this_item", {
              item: t("common.banner").toLowerCase(),
            }).replace("?", "")} - ${deleteBannerModal.data.i18n.en.title}?`}
          </p>
        </ConfirmationModal>
      )}
    </>
  );
};

export default Banners;
