import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import {
  Flex,
  InputFormField,
  LoadingButton,
  SwitchFormField,
  WhiteSpace,
} from "components";
import models from "models";
import { useMapErrors } from "hooks";

interface ClientFormModalProps extends ModalProps {
  data?: models.User;
  onClose: () => void;
}

export const ClientFormModal = ({
  data,
  onClose,
  ...props
}: ClientFormModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm<models.User>();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const mapErrors = useMapErrors();

  const { mutate, isLoading } = useMutation(
    (values: models.User) => api.clients.patch(data?.id!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.clients.many());
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("user.user_settings"),
          }),
        });
        onClose();
      },
      onError: (errors: any) => mapErrors(errors, notify, form),
    },
  );

  return (
    <Modal
      title={
        data
          ? `${t("common.edit")} - ${data?.name}`
          : t("template.create_item", {
              item: t("common.client").toLowerCase(),
            })
      }
      onClose={onClose}
      {...props}
    >
      <Form
        form={form}
        name="service"
        onFinish={mutate}
        initialValues={{
          first_name: data?.first_name,
          last_name: data?.last_name,
          email: data?.email,
          is_active: data?.is_active,
        }}
      >
        <Modal.Content>
          <InputFormField name="first_name" label={t("common.first_name")} />
          <InputFormField name="last_name" label={t("common.last_name")} />
          <InputFormField name="email" label={t("common.email")} type="email" />

          <SwitchFormField
            label={t("common.active")}
            name="is_active"
            labelOptions={{
              col: {
                size: 10,
              },
              className: "d-flex",
              align: "center",
            }}
            controlOptions={{
              col: { size: 2 },
              className: "d-flex",
              justify: "end",
              align: "center",
            }}
          />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton submit loading={isLoading} type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
