import { createSvgIcon } from "../utils";

export const TrendDown = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.86383 1.13666C1.57093 0.843769 1.09606 0.843769 0.803166 1.13666C0.510273 1.42956 0.510273 1.90443 0.803166 2.19732L1.86383 1.13666ZM5.3335 5.66699L4.80317 6.19732C5.09606 6.49021 5.57093 6.49021 5.86383 6.19732L5.3335 5.66699ZM8.00016 3.00033L8.53049 2.47C8.2376 2.1771 7.76273 2.1771 7.46983 2.47L8.00016 3.00033ZM13.3335 8.33366V9.08366C13.7477 9.08366 14.0835 8.74787 14.0835 8.33366H13.3335ZM14.0835 3.66699C14.0835 3.25278 13.7477 2.91699 13.3335 2.91699C12.9193 2.91699 12.5835 3.25278 12.5835 3.66699H14.0835ZM8.66683 7.58366C8.25262 7.58366 7.91683 7.91944 7.91683 8.33366C7.91683 8.74787 8.25262 9.08366 8.66683 9.08366V7.58366ZM0.803166 2.19732L4.80317 6.19732L5.86383 5.13666L1.86383 1.13666L0.803166 2.19732ZM5.86383 6.19732L8.53049 3.53066L7.46983 2.47L4.80317 5.13666L5.86383 6.19732ZM7.46983 3.53066L12.8032 8.86399L13.8638 7.80333L8.53049 2.47L7.46983 3.53066ZM12.5835 3.66699V8.33366H14.0835V3.66699H12.5835ZM13.3335 7.58366H8.66683V9.08366H13.3335V7.58366Z"
      fill="currentColor"
    />
  </svg>,
);
