import deepmerge from "deepmerge";

export const merge = <T>(
  x: Partial<T>,
  y: Partial<T>,
  options?: deepmerge.Options | undefined,
) => {
  return deepmerge(x, y, {
    customMerge: (key) => {
      if (["currency", "tabs", "items"].includes(key)) {
        return (prev, curr) =>
          prev.map((item, i) => merge(curr[i] || {}, item));
      }
    },
    ...options,
  });
};
