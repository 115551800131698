import api, { querykeys } from "api";
import models from "models";
import { MutationFunction, QueryKey } from "react-query";
import { DiagnosticType, GenericObject, UpdateCriterion } from "types";

interface DiagnosticURL {
  get: () => Promise<models.WithResults<models.DiagnosticCriteria>>;
  delete: (id: number) => Promise<void>;
  switch: (
    value: boolean,
  ) => Promise<models.WithResults<models.DiagnosticCriteria>>;
  updateCriterion: (
    values: UpdateCriterion,
  ) => Promise<models.DiagnosticCriteria>;
  createCriterion: (
    values: UpdateCriterion,
  ) => Promise<models.DiagnosticCriteria>;
  criterionsKey: QueryKey;
  switchKey: (id?: number) => QueryKey;
  resetCriteria: MutationFunction<unknown, void>;
}

export const useDiagnosticURL = (
  diagnosticType: DiagnosticType,
  brandId?: number,
  queryParams: GenericObject = {},
): DiagnosticURL => {
  const urls = {
    [DiagnosticType.ORGANIZATION]: {
      get: () => api.settings.getDefaultCriteria(queryParams),
      delete: (id: number) => api.settings.deleteCriteria(id),
      switch: (value: boolean) =>
        api.settings.updateDefaultSwitch({ is_active: value }),
      updateCriterion: (values: UpdateCriterion) =>
        api.settings.updateCriteria(values),
      createCriterion: (values: UpdateCriterion) =>
        api.settings.createCriteria(values),
      criterionsKey: querykeys.settings.diagnostic.many(queryParams),
      switchKey: () => querykeys.settings.defaultSettings,
      resetCriteria: () => api.settings.resetCriteria(),
    },
    [DiagnosticType.BRAND]: {
      get: () => api.brands.getDefaultCriteria(brandId, queryParams),
      delete: (id: number) => api.brands.deleteCriteria(id, brandId),
      switch: (value: boolean) =>
        api.brands.updateDefaultSwitch(brandId, { is_active: value }),
      updateCriterion: (values: UpdateCriterion) =>
        api.brands.updateCriteria(brandId, values),
      createCriterion: (values: UpdateCriterion) =>
        api.brands.createCriteria(brandId, values),
      criterionsKey: querykeys.brands.diagnostic.many(queryParams),
      switchKey: (id: number) => querykeys.brands.one(id),
      resetCriteria: () => api.brands.resetCriteria(brandId),
    },
    [DiagnosticType.BRAND_MODEL]: {
      get: () => api.brandsModels.getDefaultCriteria(brandId, queryParams),
      delete: (id: number) => api.brandsModels.deleteCriteria(id, brandId),
      switch: (value: boolean) =>
        api.brandsModels.updateDefaultSwitch(brandId, { is_active: value }),
      updateCriterion: (values: UpdateCriterion) =>
        api.brandsModels.updateCriteria(brandId, values),
      createCriterion: (values: UpdateCriterion) =>
        api.brandsModels.createCriteria(brandId, values),
      criterionsKey: querykeys.brandsModels.diagnostic.many(queryParams),
      switchKey: (id: number) => querykeys.brandsModels.one(id),
      resetCriteria: () => api.brandsModels.resetCriteria(brandId),
    },
  };

  return urls[diagnosticType];
};
