import { createSvgIcon } from "../utils";

export const FilePdf = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="40"
    viewBox="0 0 30 40"
    fill="none"
  >
    <path
      d="M0.5 36V4C0.5 2.067 2.067 0.5 4 0.5H17.9C18.7987 0.5 19.663 0.845712 20.3138 1.46552L28.4138 9.1798C29.1074 9.8404 29.5 10.7564 29.5 11.7143V36C29.5 37.933 27.933 39.5 26 39.5H4C2.067 39.5 0.5 37.933 0.5 36Z"
      fill="white"
      stroke="#E5E9EB"
    />
    <path
      d="M5.77148 23.9993H7.5293V21.7408H8.72248C10.388 21.7408 11.4284 20.7536 11.4284 19.2443C11.4284 17.7493 10.4093 16.7266 8.77575 16.7266H5.77148V23.9993ZM7.5293 20.3558V18.1435H8.39577C9.18413 18.1435 9.60671 18.5696 9.60671 19.2443C9.60671 19.9155 9.18413 20.3558 8.39577 20.3558H7.5293Z"
      fill="#F76659"
    />
    <path
      d="M14.9231 23.9993C17.139 23.9993 18.5133 22.6321 18.5133 20.3594C18.5133 18.0902 17.139 16.7266 14.9196 16.7266H12.2349V23.9993H14.9231ZM13.9927 22.5007V18.2251H14.8308C16.0808 18.2251 16.752 18.7933 16.752 20.3594C16.752 21.9325 16.0808 22.5007 14.8557 22.5007H13.9927Z"
      fill="#F76659"
    />
    <path
      d="M19.4796 23.9993H21.2374V21.0767H24.128V19.6456H21.2374V18.1541H24.4441V16.7266H19.4796V23.9993Z"
      fill="#F76659"
    />
  </svg>,
);
