import { AxiosError } from "axios";
import { useNotify, Button } from "ebs-design";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation } from "react-query";

import api, { querykeys } from "api";
import models from "models";
import { notifyErrors } from "utils";

export const PriceImport = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const priceMutate = useMutation(
    (file: models.Attachment) => api.brandsModels.import(file),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.brandsModels.many());
        notify.success({ title: t("price.prices_imported_successfully") });
      },
      onError: (errors: AxiosError) => notifyErrors(notify, errors),
    },
  );

  return (
    <Button type="light" loading={priceMutate.isLoading}>
      <input
        title={t("common.accepted_format_xlsx")}
        onChange={(e) => {
          const formData = new FormData();
          formData.append("file", e.target.files[0]);
          e.target.files[0] && priceMutate.mutate(formData);
          e.target.files[0] = null;
        }}
        type="file"
        accept=".xlsx"
        className="stretch-and-hide"
      />
      {t("price.import_price")}
    </Button>
  );
};
