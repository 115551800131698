import { Redirect, Switch, Route } from "react-router-dom";
import { Loader } from "ebs-design";

import { authRoutes } from "routes";
import { useAuth } from "hooks";

import { AuthLayout } from "components/AuthLayout";
import { LayoutRoute } from "components/LayoutRoute";

import { DashboardRouter } from "layouts";
import i18n from "translation/i18n";

export const AppRouter = () => {
  const { isLoading, user } = useAuth();
  i18n.changeLanguage(user ? user?.language : "en");

  if (isLoading) {
    return <Loader loading />;
  }

  return (
    <Switch>
      {user &&
        authRoutes.map((route, i) => (
          <Redirect
            key={i}
            from={route.path instanceof Array ? route.path[0] : route.path}
            to="/"
          />
        ))}

      {authRoutes.map((route, i) => (
        <LayoutRoute key={i} {...route} layout={AuthLayout} />
      ))}

      <Route>
        <DashboardRouter />
      </Route>
    </Switch>
  );
};
