import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import {
  Flex,
  InputFormField,
  LanguageTabs,
  LoadingButton,
  WhiteSpace,
} from "components";
import { useMapErrors } from "hooks";
import models from "models";

interface TranslateServiceModalProps extends ModalProps {
  data?: models.ExtraService;
  onClose: () => void;
}
export const TranslateServiceModal = ({
  data,
  onClose,
  ...props
}: TranslateServiceModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const mapErrors = useMapErrors();

  const [translationFields, setTranslationFields] = useState(data.i18n);

  const { mutate, isLoading } = useMutation(
    (values: models.ExtraService) =>
      api.settings.updateService(data?.id!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.settings.service.many());
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("common.translations"),
          }),
        });
        onClose();
      },
      onError: (errors: any) => mapErrors(errors, notify, form),
    },
  );

  return (
    <Modal
      header={<h3>{t("common.translate_service")}</h3>}
      size="small"
      onClose={onClose}
      className="edit-service-modal"
      {...props}
    >
      <Form
        form={form}
        name="translate_service"
        initialValues={data.i18n}
        onValuesChange={(values) =>
          setTranslationFields((prevState) => ({ ...prevState, ...values }))
        }
        onFinish={(values) =>
          mutate({
            ...data,
            i18n: {
              ...translationFields,
              ...values,
            },
          })
        }
        className="service-form"
      >
        <Modal.Content>
          <LanguageTabs>
            {(language) => (
              <>
                <InputFormField
                  name={[language, "title"]}
                  label={t("common.title")}
                />
                <InputFormField
                  name={[language, "description"]}
                  label={t("common.description")}
                />
                <InputFormField
                  name={[language, "popup_description"]}
                  label={t("common.popup_description")}
                />
                <InputFormField
                  name={[language, "price_translation"]}
                  label={t("price.price_translation")}
                />
              </>
            )}
          </LanguageTabs>
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton submit loading={isLoading} type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
