import { makeBEM, numberWithSpaces } from "utils";

const bem = makeBEM("statistic-card");

interface StatisticCardProps {
  icon: JSX.Element;
  title: string;
  value: number;
  bgColor: string;
  color: string;
}

export const StatisticCard = ({
  icon,
  title,
  value,
  bgColor,
  color,
}: StatisticCardProps) => {
  return (
    <div
      className={bem()}
      style={{
        backgroundColor: bgColor,
        color: color,
      }}
    >
      {icon}
      <h3 className={bem("title")}>{title}</h3>
      <div className={bem("value")}>{numberWithSpaces(value)}</div>
    </div>
  );
};
