import { AxiosError } from "axios";
import { Card, useNotify } from "ebs-design";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ArrayParam, useQueryParams } from "use-query-params";

import api, { querykeys } from "api";
import { isActiveStatus } from "app-constants";
import {
  DeleteModal,
  Flex,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import {
  ChoiceFilter,
  Filters,
  SelectQueryListFilter,
} from "components/filters";
import {
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";
import models from "models";
import { CommaArrayParam, forwardSetState, notifyErrors } from "utils";

import { ClientFormModal } from "../components";
import { clientsColumns, clientsSortOptions } from "../config";

export const Clients = () => {
  const { t } = useTranslation();
  const pagination = useQueryPagination();
  const deleteModal = useModalState<models.User>();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const clientModalState = useModalState<models.User>();
  const [filterParams, setFilterParams] = useQueryParams({
    profile__country__id__in: CommaArrayParam,
    is_active: ArrayParam,
  });

  const [ordering, setOrdering] = useOrderingQueryParam();
  const [search, setSearch] = React.useState("");

  const queryParams = {
    ...pagination.queryParams,
    search,
    ...filterParams,
    ordering,
  };

  const query = useQuery(querykeys.clients.many(queryParams), () =>
    api.clients.get(queryParams),
  );

  const deleteMutation = useMutation(
    () => api.clients.delete(deleteModal?.data?.id!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.clients.many());
        deleteModal.close();
      },
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  const tableColumns = React.useMemo(
    () =>
      clientsColumns({
        onEdit: clientModalState.openWith,
        onDelete: deleteModal.openWith,
        t,
      }),
    [clientModalState.openWith, deleteModal.openWith, t],
  );

  return (
    <>
      <PageTitle title={t("menu.customers")} count={query.data?.count} />
      <Card>
        <Card.Body>
          <QueryTable
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                  sortOptions={clientsSortOptions}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                  filters={
                    <Filters
                      queryParams={filterParams}
                      setQueryParams={setFilterParams}
                    >
                      <ChoiceFilter
                        value={filterParams.is_active}
                        setValue={forwardSetState(setFilterParams, "is_active")}
                        title={t("common.status")}
                        choice={Object.values(["true", "false"]).map(
                          (is_active) => ({
                            key: is_active,
                            value: isActiveStatus[is_active],
                          }),
                        )}
                      />

                      <SelectQueryListFilter
                        title={t("common.country")}
                        value={filterParams.profile__country__id__in}
                        setValue={forwardSetState(
                          setFilterParams,
                          "profile__country__id__in",
                        )}
                        querykey={querykeys.nomenclature.countries}
                        apiCall={api.nomenclatures.countries.get}
                        getKey={(u: models.Country) => String(u.id)}
                        getValue={(u) => u.name}
                      />
                    </Filters>
                  }
                />
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            className="people-table"
          />
        </Card.Body>
      </Card>
      {clientModalState.isOpen && (
        <ClientFormModal
          className="edit-staff-modal"
          open={clientModalState.isOpen}
          onClose={clientModalState.close}
          data={clientModalState.data}
          closeOnClickOutside={false}
          size="small"
        />
      )}
      {deleteModal.isOpen && (
        <DeleteModal
          open={deleteModal.isOpen}
          onClose={deleteModal.close}
          mutation={deleteMutation}
          title={t("template.delete_item", {
            item: t("user.user").toLowerCase(),
          })}
          size="small"
        >
          <h4 className="text-center">
            {t("template.are_you_sure_you_want_to_delete_this_item", {
              item: t("user.user").toLowerCase(),
            })}
          </h4>
        </DeleteModal>
      )}
    </>
  );
};
