import { DAYS_OF_THE_WEEK } from "app-constants";
import { TFunction } from "react-i18next";
import { getLocaleMonth, getWeekOfMonth } from "utils";

interface SetFormatedDataProps<T = any> {
  data: T[];
  xAxisKey: string;
  clusterKey: string[];
  t: TFunction;
  setChartData: React.Dispatch<React.SetStateAction<T[]>>;
}

export const daysInNumbers = {
  week: 7,
  month: 30,
  shortMonth: 28,
  longMonth: 31,
  shortYear: 365,
  longYear: 366,
};

export const checkFormatDate = (data: unknown[], xAxisKey: string) => {
  const isOneWeek = data.every(
    (value) =>
      getWeekOfMonth(value[xAxisKey]) === getWeekOfMonth(data[0][xAxisKey]) &&
      new Date(value[xAxisKey]).getMonth() ===
        new Date(data[0][xAxisKey]).getMonth(),
  );

  const isOneMonth =
    data.every(
      (value) =>
        new Date(value[xAxisKey]).getMonth() ===
          new Date(data[0][xAxisKey]).getMonth() &&
        new Date(value[xAxisKey]).getFullYear() ===
          new Date(data[0][xAxisKey]).getFullYear(),
    ) && !isOneWeek;

  const isOneYear =
    data.every(
      (value) =>
        new Date(value[xAxisKey]).getFullYear() ===
        new Date(data[0][xAxisKey]).getFullYear(),
    ) &&
    !isOneMonth &&
    !isOneWeek;

  const isMoreOneMonths =
    data.length >= daysInNumbers.shortMonth && !isOneMonth && !isOneYear;

  return { isOneWeek, isOneMonth, isOneYear, isMoreOneMonths };
};

export const monthTickFormatter =
  (data: unknown[], xAxisKey: string, t: TFunction) => (tick: string) => {
    if (!data.length) return "";
    const date = new Date(tick);
    const { isOneWeek, isOneMonth, isOneYear, isMoreOneMonths } =
      checkFormatDate(data, xAxisKey);

    if (isMoreOneMonths) {
      return `${getLocaleMonth(tick)} ${new Date(tick).getFullYear()}`;
    } else if (isOneYear) {
      return getLocaleMonth(tick);
    } else if (isOneMonth) {
      return `${t("time.week")} ${tick}`;
    } else if (isOneWeek) {
      const day =
        new Date(tick).getDay() - 1 < 0 ? 6 : new Date(tick).getDay() - 1;
      return t(`time.${DAYS_OF_THE_WEEK[day]?.name.toLocaleLowerCase()}` as any);
    } else {
      return String(date.toISOString().split("T")[0]);
    }
  };

export const setFormatedData = ({
  data,
  xAxisKey,
  clusterKey,
  setChartData,
  t,
}: SetFormatedDataProps) => {
  const { isOneMonth, isOneYear, isMoreOneMonths } = checkFormatDate(
    data,
    xAxisKey,
  );

  if (isOneMonth) {
    const res = data.reduce((prev, next) => {
      const index = prev.findIndex(
        (v) => v[xAxisKey] === getWeekOfMonth(next[xAxisKey]),
      );

      index !== -1
        ? Object.assign(
            prev[index],
            ...clusterKey.map((value) => ({
              [value]: prev[index][value] + next[value],
            })),
          )
        : prev.push({ ...next, [xAxisKey]: getWeekOfMonth(next[xAxisKey]) });
      return prev;
    }, []);
    setChartData(
      res.sort(
        (a, b) =>
          (new Date(a[xAxisKey]) as any) - (new Date(b[xAxisKey]) as any),
      ),
    );
  } else if (isOneYear || isMoreOneMonths) {
    const res = data.reduce((prev, next) => {
      const index = prev.findIndex(
        (v) =>
          new Date(v[xAxisKey]).getMonth() ===
          new Date(next[xAxisKey]).getMonth(),
      );
      index !== -1
        ? Object.assign(
            prev[index],
            ...clusterKey.map((value) => ({
              [value]: prev[index][value] + next[value],
            })),
          )
        : prev.push(next);
      return prev;
    }, []);
    setChartData(
      res.sort(
        (a, b) =>
          (new Date(a[xAxisKey]) as any) - (new Date(b[xAxisKey]) as any),
      ),
    );
  } else {
    setChartData(
      data.sort(
        (a, b) =>
          (new Date(a[xAxisKey]) as any) - (new Date(b[xAxisKey]) as any),
      ),
    );
  }
};
