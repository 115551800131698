import { useTranslation } from "react-i18next";
import { NumberParam } from "use-query-params";
import { useQuery } from "react-query";
import { Alert, Button, Loader, useNotify } from "ebs-design";
import { AxiosError } from "axios";

import { notifyErrors } from "utils";
import { ACCESS_TOKEN_KEY } from "app-constants";
import { PageTitle, PDFViewer } from "components";
import { useParam } from "hooks";
import api, { querykeys } from "api";

export const RecyclingCertificateDetails = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const id = useParam("id", NumberParam.decode) || 0;

  const { data, isLoading } = useQuery(
    querykeys.recyclingCertificates.one(id),
    () => api.recyclingCertificates.getByID(id),
    {
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  const pdfDocument = `${data}?token=${localStorage.getItem(ACCESS_TOKEN_KEY)}`;

  return (
    <>
      <PageTitle
        goBack
        actions={
          <a href={pdfDocument} download target="_blank" rel="noreferrer">
            <Button type="primary"> {t("common.download")}</Button>
          </a>
        }
      >
        {t("menu.recycling_certificates")} - {id}
      </PageTitle>
      <Loader loading={isLoading}>
        {/* Wait API ----------------> */}
        <Alert type="error">This page is in development</Alert>
        {data && <PDFViewer file={pdfDocument} options={{}} />}
      </Loader>
    </>
  );
};
