import { Form, Tooltip } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import { nanoid } from "nanoid";

import { Question } from "components/icons";
import { makeBEM } from "utils";

export interface CheckBoxInfoFieldProps extends FormFieldProps {
  info?: string;
  name?: string;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

const bem = makeBEM("radio-box");

export const RadioBoxInfo = ({
  info,
  name,
  checked,
  onChange,
  ...restProps
}: CheckBoxInfoFieldProps) => {
  const radioId = nanoid();

  return (
    <div className={bem()}>
      <Form.Field valuePropName="checked" name={name} {...restProps}>
        <>
          <input
            id={radioId}
            type="radio"
            name={name}
            checked={checked}
            onChange={onChange}
          />
          <label htmlFor={radioId} className={bem("label")} />
        </>
      </Form.Field>

      <Tooltip className={bem()} placement="top" tooltip={info}>
        <Question />
      </Tooltip>
    </div>
  );
};
