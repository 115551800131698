import { LegendProps } from "recharts";
import { Payload } from "recharts/types/component/DefaultLegendContent";

import { Flex } from "components";

interface CustomLegendProps extends LegendProps {
  showZeroValue?: boolean;
}

export const CustomLegend = ({
  showZeroValue,
  payload,
  align,
  layout,
}: CustomLegendProps) => {
  return (
    <ul style={{ listStyle: "none", padding: 0, margin: 0, textAlign: align }}>
      {payload.map(({ value, color, payload }, i) =>
        (payload as unknown as Payload).value || showZeroValue ? (
          <li
            key={i}
            style={{
              display: layout === "horizontal" ? "inline-block" : "block",
              marginRight: 10,
            }}
          >
            <Flex align="center" gap={4}>
              <div
                style={{
                  width: 6,
                  height: 6,
                  borderRadius: "100%",
                  backgroundColor: color,
                }}
              />
              <span className="grey-400">{value}</span>
            </Flex>
          </li>
        ) : null,
      )}
    </ul>
  );
};
