import models from "models";

export interface UpdateCriterion {
  id?: number;
  body?: models.DiagnosticCriteria;
  brandId?: number;
}

export enum DiagnosticType {
  ORGANIZATION = "organization",
  BRAND = "brand",
  BRAND_MODEL = "model",
}
