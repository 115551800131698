import {
  CompleteInvitation,
  CompleteInvitationConfirm,
  ConfirmResetPassword,
  Login,
  ResetPassword,
} from "features/auth/pages";
import { RouteBuilder } from "utils";

export const authRoutes = new RouteBuilder()
  .add("/login", Login)
  .add("/reset-password", ResetPassword)
  .add("/confirm-reset-password", ConfirmResetPassword)
  .add("/complete-invitation", CompleteInvitation)
  .add("/complete-invitation-confirm", CompleteInvitationConfirm)
  .getRoutes();
