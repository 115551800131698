import { Card } from "ebs-design";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { PageTitle, QueryTable } from "components";
import models from "models";
import { useAuth, useModalState } from "hooks";
import api, { querykeys } from "api";

import { CURRENCY_CODE_FALLBACK } from "app-constants";
import { CurrencySymbolType } from "types";

import { EditServiceModal, TranslateServiceModal } from "../components";
import { serviceManagementColumns } from "../config";

export const ServiceManagement = () => {
  const { t } = useTranslation();
  const serviceModal = useModalState<models.ExtraService>();
  const translateServiceModal = useModalState<models.ExtraService>();

  const query = useQuery(querykeys.settings.service.many(), () =>
    api.settings.services(),
  );

  const { organization } = useAuth();
  const currency =
    organization?.currency?.code ||
    (CURRENCY_CODE_FALLBACK as CurrencySymbolType);

  const tableColumns = serviceManagementColumns(
    serviceModal.openWith,
    translateServiceModal.openWith,
    currency,
    t,
  );

  return (
    <>
      <PageTitle to="/settings">
        {t("common.extra_service_management")}
      </PageTitle>
      <Card>
        <Card.Body>
          <QueryTable
            query={query}
            columns={tableColumns}
            className="people-table"
          />
        </Card.Body>
      </Card>
      {serviceModal.isOpen && (
        <EditServiceModal
          currency={currency}
          open={serviceModal.isOpen}
          onClose={serviceModal.close}
          data={serviceModal.data}
          closeOnClickOutside={false}
        />
      )}
      {translateServiceModal.isOpen && (
        <TranslateServiceModal
          open={translateServiceModal.isOpen}
          onClose={translateServiceModal.close}
          data={translateServiceModal.data}
          closeOnClickOutside={false}
        />
      )}
    </>
  );
};
