import { useNotify } from "ebs-design";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { NumberParam } from "use-query-params";

import api, { querykeys } from "api";
import { Checkbox } from "components";
import { useParam } from "hooks";
import models from "models";
import { notifyErrors } from "utils";

interface Props {
  checked?: boolean;
  diagnosticCriteria?: models.DiagnosticCriteria;
  disabled?: boolean;
  areNoneCorrect?: boolean;
}
export const CriteriaUpdateCheckbox = ({
  checked,
  diagnosticCriteria,
  disabled,
  areNoneCorrect,
}: Props) => {
  const deviceId = useParam("deviceId", NumberParam.decode) || 0;
  const orderId = useParam("orderId", NumberParam.decode) || 0;

  const notify = useNotify();
  const queryClient = useQueryClient();
  const [isChecked, setIsChecked] = useState(checked);

  const { mutate } = useMutation(
    (body: models.DiagnosticCriteria) =>
      api.devices.diagnosticCriteria.putIsCorrect(
        deviceId,
        diagnosticCriteria?.id,
        body,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          querykeys.devices.diagnosticCriteria.one(deviceId),
        );
        queryClient.invalidateQueries(querykeys.orders.one(orderId));
        setIsChecked(!isChecked);
      },
      onError: (error) => {
        notifyErrors(notify, error);
      },
    },
  );

  return (
    <Checkbox
      disabled={disabled}
      checked={
        (!diagnosticCriteria.is_default && isChecked) ||
        (diagnosticCriteria.is_default && (checked || areNoneCorrect))
      }
      onChange={() =>
        diagnosticCriteria?.id &&
        mutate({
          id: diagnosticCriteria?.id,
          is_correct: !isChecked,
          title: diagnosticCriteria?.title,
        })
      }
    />
  );
};
