import { AxiosError } from "axios";
import { useNotify } from "ebs-design";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";

import api, { querykeys } from "api";
import { Divider, Grid, RadioBoxInfo } from "components";
import models from "models";
import { notifyErrors } from "utils";

export const PaymentsSettings = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const [paymentMethod, setPaymentMethod] = useState<
    models.PaymentPayoutMethodType | undefined
  >();

  const query = useQuery(querykeys.settings.defaultSettings, () =>
    api.settings.defaultSettings(),
  );
  const { mutate } = useMutation(
    (body: Pick<models.DefaultSettingsData, "PAYMENT_PAYOUT_METHOD">) =>
      api.settings.updateDefaultSettings(body),
    {
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  useEffect(() => {
    setPaymentMethod(query.data?.data.PAYMENT_PAYOUT_METHOD);
  }, [query.data?.data.PAYMENT_PAYOUT_METHOD]);

  return (
    <>
      <h2 className="font-size-18">{t("common.payment_and_payout_method")}</h2>
      <Divider space="8px" />

      <Grid
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        }}
        gap="20px"
        className="mt-16"
      >
        {(
          Object.keys(
            models.PaymentPayoutMethod,
          ) as Array<models.PaymentPayoutMethodType>
        ).map((key) => {
          return (
            <RadioBoxInfo
              name="PAYMENT_PAYOUT_METHOD"
              key={key}
              info={models.PaymentPayoutMethod[key]}
              label={models.PaymentPayoutMethod[key]}
              checked={String(key) === paymentMethod}
              onChange={() => {
                setPaymentMethod(key);
                mutate({
                  PAYMENT_PAYOUT_METHOD: key,
                });
              }}
            />
          );
        })}
      </Grid>
    </>
  );
};
