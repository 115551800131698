import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import {
  Divider,
  Flex,
  Grid,
  InputFormField,
  LoadingButton,
  SwitchFormField,
  UploadImage,
  WhiteSpace,
} from "components";
import models from "models";
import { formatNumber, notifyErrors } from "utils";

interface ShippingProviderFormModalProps extends ModalProps {
  data?: models.ShippingProvider;
  onClose: () => void;
}

export const ShippingProviderFormModal = ({
  data,
  onClose,
  ...props
}: ShippingProviderFormModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [image, setImage] = useState<models.File>();

  const { mutate, isLoading } = useMutation(
    (body: models.ShippingProvider) =>
      data && data.id
        ? api.shippingProviders.patch(data.id, body)
        : api.shippingProviders.post(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.shippingProviders.many());
        notify.success({
          title: t(
            data
              ? "template.item_updated_successfully"
              : "template.item_created_successfully",
            { item: t("shipping.shipping_provider") },
          ),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  useEffect(() => {
    setImage(data?.logo);
  }, [data?.logo]);

  return (
    <Modal
      title={`${data ? t("common.edit") : t("common.add")} ${t(
        "shipping.shipping_provider",
      )}`}
      size="small"
      onClose={onClose}
      className="edit-service-modal"
      {...props}
    >
      <Form
        form={form}
        name="shipping"
        onFinish={(values) => mutate({ ...values, logo_id: image?.id })}
        className="service-form"
        initialValues={{ ...data, ...{ price: formatNumber(data?.price) } }}
      >
        <Modal.Content>
          <InputFormField name="title" label={t("common.provider_name")} />
          <UploadImage file={image} setFile={setImage} showImage publicFile />
          <Grid cols="160px 1fr" gap="16px">
            <InputFormField
              name="price"
              label={t("price.price")}
              prefix={<span className="fw-600">$</span>} //FIXME add correct currency
            />
            <InputFormField
              name="price_translation"
              label={t("price.price_translation")}
            />
          </Grid>
          <Divider style={{ marginBottom: 20 }} />
          <SwitchFormField
            labelOptions={{ col: 1 }}
            controlOptions={{
              col: 1,
              justify: "end",
              className: "d-flex",
            }}
            name="is_active"
            label={t("common.active")}
          />
        </Modal.Content>

        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton loading={isLoading} submit type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
