import React from "react";
import { DragSource } from "react-dnd";
import { Tooltip } from "antd";
import preview from "assets/preview";
import { Properties } from "types";

const spec = {
  beginDrag(props) {
    return { ...props.value };
  },
};
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

export interface ItemProps {
  type: string;
  connectDragSource: any;
  isDragging: boolean;
  canDrag: boolean;
  value: Properties;
  disabled?: boolean;
  onMouseDown: () => void;
  onMouseOut: () => void;
}

const Item: React.FC<ItemProps> = ({
  type,
  connectDragSource,
  isDragging,
  canDrag,
  value: { title },
  disabled,
  ...props
}) => {
  return canDrag && !disabled ? (
    connectDragSource(
      <div
        className="float-left"
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: isDragging ? "move" : "",
        }}
        onMouseDown={props.onMouseDown}
        onMouseOut={props.onMouseOut}
      >
        {preview[type] ? (
          <Tooltip placement="leftBottom" title={title}>
            <img src={preview[type]} alt={type} className="full-width" />
          </Tooltip>
        ) : (
          title
        )}
      </div>,
    )
  ) : (
    <div className={`float-left${disabled ? " disabled" : ""}`}>
      {preview[type] ? (
        <Tooltip placement="right" title={title}>
          <img src={preview[type]} className="full-width" alt="" />
        </Tooltip>
      ) : (
        title
      )}
    </div>
  );
};

export default DragSource("components", spec, collect)(Item);
