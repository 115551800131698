import { useTranslation } from "react-i18next";

import { Flex, PageTitle } from "components";

import { SuccessBrandCard } from "../components";

export const ModelsSuccessConnected = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>
        {t("template.add_item", { item: t("model.models").toLowerCase() })}
      </PageTitle>
      <Flex align="center" className="success-brand-card-wrapper">
        <SuccessBrandCard
          type="models"
          message={t("template.you_have_successfully_added_a_new_item", {
            item: t("model.models").toLowerCase(),
          })}
          redirect={{
            url: "/product-management/models/add-models",
            title: t("template.add_item", {
              item: t("model.models").toLowerCase(),
            }),
          }}
        />
      </Flex>
    </>
  );
};
