import { StringParam, useQueryParams } from "use-query-params";

import { useAuth } from "hooks";
import { hideEmail } from "utils";
import { t } from "i18next";

interface ConfirmHeaderProps {
  title: string;
}
export const ConfirmHeader = ({ title }: ConfirmHeaderProps) => {
  const auth = useAuth();
  const [query] = useQueryParams({
    email: StringParam,
  });

  //loginEmail
  const hiddenEmail = hideEmail(auth?.userEmail);

  //reset password email
  const resetEmail = hideEmail(query.email as string);

  return (
    <div>
      <h3 className="mb-24">{title}</h3>
      <p
        className="grey-400"
        dangerouslySetInnerHTML={{
          __html: t(
            "template.we_have_sent_a_confirmation_code_to_the_specified_email_address_item",
            { item: hiddenEmail || resetEmail },
          ),
        }}
      />
    </div>
  );
};
