import { createSvgIcon } from "../utils";

export const Key = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="7" cy="17" r="6" />
    <line x1="10.85" y1="12" x2="21" y2="2" />
    <line x1="20" y1="5" x2="22" y2="7" />
    <line x1="15" y1="8" x2="17" y2="10" />
  </svg>,
);
