import models from "models";

export const orderStatus: Record<models.OrderStatus, string> = {
  [models.OrderStatus.CANCELLED]: "#f76659",
  [models.OrderStatus.COMPLETED]: "#8ceee6",
  [models.OrderStatus.DEVICE_AT_PICK_UP_LOCATION]: "#1563e2",
  [models.OrderStatus.DEVICE_DELIVERED]: "#4b8fed",
  [models.OrderStatus.DEVICE_RECEIVED_BACK]: "#bbfbd0",
  [models.OrderStatus.DEVICE_SENT_BACK]: "#350080",
  [models.OrderStatus.DEVICE_SHIPPED]: "#38d9b9",
  [models.OrderStatus.PAYMENT_COMPLETED]: "#91f3ae",
  [models.OrderStatus.PENDING_COMPLETION]: "#a0cefc",
  [models.OrderStatus.PENDING_DROP_OFF]: "#c479f3",
  [models.OrderStatus.PENDING_RECYCLING]: "#fff699",
  [models.OrderStatus.PENDING_REJECTION]: "#f5d2fe",
  [models.OrderStatus.PENDING_RETURN]: "#fdaea1",
  [models.OrderStatus.PENDING_RETURN_FEE]: "#dbbc00",
  [models.OrderStatus.PENDING_SHIPMENT]: "#f8dd4e",
  [models.OrderStatus.PRICE_UPDATED]: "#7e10e5",
  [models.OrderStatus.PROCESSING]: "#47d16c",
  [models.OrderStatus.RECYCLED]: "#0d9676",
  [models.OrderStatus.REJECTED]: "#8d0104",
};
