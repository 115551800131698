import React from "react";
import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { NumberParam } from "use-query-params";

import api, { querykeys } from "api";
import {
  Flex,
  InputFormField,
  LoadingButton,
  UploadImage,
  WhiteSpace,
} from "components";
import { SwitchFormField } from "components/form-fields";
import { useMapErrors, useParam } from "hooks";
import models from "models";

interface BrandModalProps extends ModalProps {
  data?: models.OrganizationBrand;
  onClose: () => void;
}
export const BrandModal = ({ data, onClose, ...props }: BrandModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const mapErrors = useMapErrors();
  const [disabled, setDisabled] = React.useState<boolean>(!data?.is_active);

  const brandId = useParam("id", NumberParam.decode) || 0;

  const [brandImage, setBrandImage] = React.useState<models.File>();

  const { mutate, isLoading } = useMutation(
    (values: models.OrganizationBrand) =>
      api.brands.upgrade(data?.brand_id, values),
    {
      onSuccess: () => {
        brandId
          ? queryClient.invalidateQueries(querykeys.brands.one(brandId))
          : queryClient.invalidateQueries(querykeys.brands.many());

        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("brand.brand"),
          }),
        });
        onClose();
      },
      onError: (errors: unknown) => mapErrors(errors, notify, form),
    },
  );

  React.useEffect(() => {
    data?.logo && setBrandImage(data?.logo);
  }, [data?.logo]);

  return (
    <Modal
      title={`${t("template.edit_item", {
        item: t("brand.brand").toLowerCase(),
      })} - ${data?.id}`}
      size="small"
      onClose={onClose}
      {...props}
    >
      <Form
        form={form}
        onFinish={(values) => mutate({ logo_id: brandImage?.id, ...values })}
        initialValues={{
          name: data?.title,
          is_displayed: data?.is_displayed,
          is_active: data?.is_active,
          title: data?.title,
        }}
        onValuesChange={(_, allValues) => {
          const { is_active } = allValues;
          if (!is_active) {
            form.setFieldsValue({ is_displayed: false });
            setDisabled(true);
          } else setDisabled(false);
        }}
      >
        <Modal.Content>
          <UploadImage
            file={brandImage}
            setFile={setBrandImage}
            showImage
            publicFile
          />
          <InputFormField name="title" label={t("common.name")} />
          <SwitchFormField
            disabled={disabled}
            label={t("common.show_on_main_page")}
            name="is_displayed"
            labelOptions={{
              col: {
                size: 10,
              },
            }}
            controlOptions={{
              col: { size: 2 },
              className: "d-flex",
              justify: "end",
              align: "center",
            }}
          />
          <div className="horizontal-line" />
          <SwitchFormField
            label={t("common.active")}
            name="is_active"
            labelOptions={{
              col: {
                size: 10,
              },
              className: "d-flex",
              align: "center",
            }}
            controlOptions={{
              col: { size: 2 },
              className: "d-flex",
              justify: "end",
              align: "center",
            }}
          />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton submit loading={isLoading} type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
