import { Button, Form, Modal, Space, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { NumberParam } from "use-query-params";

import api, { querykeys } from "api";
import { InputFormField, LoadingButton } from "components";
import { useParam } from "hooks";
import { notifyErrors } from "utils";
import models from "models";

interface UpdatePriceModalProps extends ModalProps {
  data?: string;
  onClose: () => void;
}
export const UpdatePriceModal = ({
  data,
  onClose,
  ...props
}: UpdatePriceModalProps) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const deviceId = useParam("deviceId", NumberParam.decode) || 0;
  const orderId = useParam("orderId", NumberParam.decode) || 0;

  const { mutate, isLoading } = useMutation(
    (body: models.DeviceReason) => api.devices.updatePrice(deviceId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.orders.one(orderId));

        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("price.price"),
          }),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Modal
      title={t("price.update_price")}
      size="small"
      onClose={onClose}
      className="edit-service-modal"
      {...props}
    >
      <Form
        initialValues={{ reason: data }}
        name="device"
        onFinish={mutate}
        className="approve-form"
      >
        <Modal.Content>
          <p>
            {t("device.are_you_sure_you_want_to_update_price_for_this_device")}
          </p>
          <InputFormField name="reason" label={t("common.reason")} textarea />
        </Modal.Content>

        <Modal.Footer>
          <Space justify="end">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <LoadingButton loading={isLoading} submit type="primary">
              {t("price.update_price")}
            </LoadingButton>
          </Space>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
