import models from "models";
import { GenericObject } from "types";
import { stringifyUrl } from "./utils";
import { apiAxios } from "./axios";

export const recyclingCertificates = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl(
      "/organization/recycling-certificates",
      queryParams,
    );
    const { data } = await apiAxios.get<
      models.WithResults<models.RecyclingCertificates>
    >(url);

    return data;
  },

  getByID: async (id: number) => {
    const { data } = await apiAxios.get<models.RecyclingCertificates>(
      `/organization/recycling-certificates/${id}`,
    );

    return data;
  },
};
