import { Button } from "ebs-design";
import { TFunction } from "react-i18next";

import { Flex, Status, WhiteSpace } from "components";
import * as Icons from "components/icons";
import models from "models";
import { formattedDateTime, TableColumnBuilder } from "utils";

interface ClientsColumnsProps {
  onEdit: React.Dispatch<models.User>;
  onDelete: React.Dispatch<models.User>;
  t: TFunction;
}

export const clientsColumns = ({ onEdit, onDelete, t }: ClientsColumnsProps) =>
  new TableColumnBuilder<models.User>()
    .add(t("common.name"), "name")
    .add(t("common.email"), "email")
    .add(t("common.phone_number"), "phone")
    .add(t("common.country"), ["profile", "country", "name"])
    .add(t("common.last_activity"), "", (record: models.User) =>
      record?.is_online ? "online" : formattedDateTime(record?.last_login),
    )
    .add(t("common.creation_date"), "timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add(t("common.status"), "is_active", (value: boolean) => (
      <Status status={value} isBlocked />
    ))
    .add("", "", (record: models.User) => (
      <Flex justify="flex-end">
        <Button
          type="light"
          size="small"
          icon={() => <Icons.Edit size="small" />}
          onClick={() => onEdit(record)}
        />
        <WhiteSpace h="8px" />
        <Button
          type="light"
          size="small"
          icon={() => <Icons.Trash size="small" />}
          onClick={() => onDelete(record)}
        />
      </Flex>
    ))
    .getColumns();

export const clientsSortOptions = [
  { title: "Name", value: "name" },
  { title: "Creation date", value: "timestamp" },
  { title: "Last activity", value: "last_login" },
];
