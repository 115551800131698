import { Form } from "antd";
import { Properties } from "types";

import { Link as ILink } from "../Custom";
import { formItemStyle } from "../helpers";

const Link = ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "action"]}
        initialValue={item?.action}
        label="Action"
        style={formItemStyle}
      >
        <ILink lang={lang} />
      </Form.Item>
    </>
  );
};

export default Link;
