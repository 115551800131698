import { TFunction } from "react-i18next";

import { FormInstance } from "rc-field-form";
import {
  FieldData,
  NamePath,
  InternalNamePath,
} from "rc-field-form/es/interface";

import { tryToNumber } from "utils";
import models from "models";
import { UseNotifyResult } from "types/ebs-design";

export function extractErrorMessages(error: any) {
  let data = error?.response?.data;
  if (data?.detail instanceof Array) return [...data?.detail] as string[];
  if (data?.detail) return [data.detail as string];

  const messages: string[] = [];

  function extract(e?: models.ErrorResponse) {
    if (e instanceof Array) {
      if (typeof e[0] === "string") return messages.push(...(e as string[]));
      e.forEach((item) => extract(item as models.ErrorResponse));
    } else if (e instanceof Object) {
      Object.entries(e).forEach((e) => extract(e[1]));
    } else if (typeof e === "string") {
      messages.push(e);
    }
  }
  extract(data);

  return messages;
}

export const setFieldsErrors = (
  data: any,
  form: FormInstance,
  notify: UseNotifyResult,
  t: TFunction,
  pathPrefix?: NamePath,
) => {
  let fields: FieldData[] = [];

  function extract(e: models.ErrorResponse, name: InternalNamePath = []) {
    if (e instanceof Array) {
      if (typeof e[0] === "string")
        return fields.push({ name, errors: e as string[] });

      e.forEach((item, i) =>
        extract(item as models.ErrorResponse, [...name, i]),
      );
    } else if (e instanceof Object) {
      Object.entries(e).forEach((e) => extract(e[1], [...name, e[0]]));
    }
  }

  extract(
    data,
    pathPrefix
      ? pathPrefix instanceof Array
        ? pathPrefix
        : [pathPrefix]
      : undefined,
  );

  fields = fields.map((f) => ({
    ...f,
    name:
      f.name instanceof Array ? f.name.map(tryToNumber) : tryToNumber(f.name),
  }));

  form.setFields(fields);
  notify.error({
    title: t("notification.invalid_data"),
  });
};
