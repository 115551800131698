import { AxiosError } from "axios";
import { Button, Card, Space, useNotify } from "ebs-design";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateEffect } from "react-use";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { ArrayParam, useQueryParams } from "use-query-params";

import api, { querykeys } from "api";
import { isDisplayedStatus } from "app-constants";
import {
  DeleteModal,
  ExportInFile,
  Flex,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import {
  ChoiceFilter,
  Filters,
  SelectQueryListFilter,
} from "components/filters";
import {
  useAuth,
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
  useFilterQueryChecked,
} from "hooks";
import models from "models";
import { CommaArrayParam, forwardSetState, notifyErrors } from "utils";
import { checkboxesCheckedInfo, CheckboxesCheckedInfoType } from "types";
import * as Icons from "components/icons";
import { ModelModal, PriceImport, UpdatePriceModal } from "../components";
import { brandsModelsColumns, brandsModelsSortOptions } from "../config";

export const Models = () => {
  const { t } = useTranslation();
  const { organization } = useAuth();
  const pagination = useQueryPagination();
  const brandModelModal =
    useModalState<models.ExtendedOrganizationBrandModel>();
  const updatePriceModal = useModalState<string[]>();
  const deleteModel = useModalState<models.ExtendedOrganizationBrandModel>();
  const bulkDeleteModal = useModalState();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const history = useHistory();
  const [filterParams, setFilterParams] = useQueryParams({
    is_active: ArrayParam,
    brands: CommaArrayParam,
  });

  const [ordering, setOrdering] = useOrderingQueryParam();
  const [search, setSearch] = useState("");

  const [checkedModels, setCheckedModels] = useState<CheckboxesCheckedInfoType>(
    checkboxesCheckedInfo,
  );

  const queryParams = {
    ...pagination.queryParams,
    search,
    ordering,
    brand__id__in: filterParams.brands,
    is_active: filterParams.is_active,
  };

  const query = useQuery(querykeys.brandsModels.many(queryParams), () =>
    api.brandsModels.get(queryParams),
  );

  const allModelsIdOnPage = query.data?.results
    ?.map((item) => String(item?.brand_model_id))
    .filter((item) => item);

  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const deleteMutation = useMutation(
    () => api.brandsModels.deleteByID(deleteModel.data?.brand_model_id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.brandsModels.many());
        notify.success({
          title: t("template.item_deleted_successfully", {
            item: t("model.model"),
          }),
        });
        deleteModel.close();
      },
      onError: (errors: AxiosError) => notifyErrors(notify, errors),
    },
  );

  const bulkDeleteMutation = useMutation(
    () =>
      api.brandsModels.bulkDelete({
        brand_models_id: checkedModels.allCheckedCheckboxes,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.brandsModels.many());
        notify.success({
          title: t("template.item_deleted_successfully", {
            item: t("model.models"),
          }),
        });
        bulkDeleteModal.close();
        setCheckedModels(checkboxesCheckedInfo);
      },
      onError: (errors: AxiosError) => notifyErrors(notify, errors),
    },
  );

  useUpdateEffect(() => {
    pagination.setPage(1);
    setCheckedModels(checkboxesCheckedInfo);
  }, [search, filterParams]);

  const tableColumns = useMemo(
    () =>
      brandsModelsColumns({
        onEdit: brandModelModal.openWith,
        onDelete: deleteModel.openWith,
        setCheckboxInfo: setCheckedModels,
        checkboxInfo: {
          ...checkedModels,
          checkboxesOnPage: allModelsIdOnPage,
        },
        currency: organization?.currency,
        setSelectedColumns,
        selectedColumns,
        t,
      }),
    [
      brandModelModal.openWith,
      deleteModel.openWith,
      checkedModels,
      allModelsIdOnPage,
      organization?.currency,
      selectedColumns,
      t,
    ],
  );

  const selectedModels =
    useFilterQueryChecked<models.ExtendedOrganizationBrandModel>({
      querykeys: querykeys.brandsModels.many(queryParams)[0],
      checked: checkedModels,
      key: "brand_model_id",
    });

  const selectedTitle = selectedModels.map(({ title }) => title);
  const selectedModelsId = useMemo(
    () => selectedModels.map(({ id }) => String(id)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedModels.length],
  );

  return (
    <>
      <PageTitle
        actions={
          <Link to="/product-management/models/add-models">
            <Button type="primary">
              {t("template.add_item", {
                item: t("model.models").toLowerCase(),
              })}
            </Button>
          </Link>
        }
        title={t("model.models")}
        count={query.data?.count}
      />
      <Card>
        <Card.Body>
          <QueryTable
            variants={["highlight-hover"]}
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                  sortOptions={brandsModelsSortOptions}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                  filters={
                    <Filters
                      queryParams={filterParams}
                      setQueryParams={setFilterParams}
                    >
                      <SelectQueryListFilter
                        title={t("brand.brands")}
                        value={filterParams.brands}
                        setValue={forwardSetState(setFilterParams, "brands")}
                        querykey={querykeys.brands.many}
                        apiCall={api.brands.get}
                        getKey={(u) => String(u.brand_id)}
                        getValue={(u) => u.title}
                      />
                      <ChoiceFilter
                        value={filterParams.is_active}
                        setValue={forwardSetState(setFilterParams, "is_active")}
                        title={t("brand.brand_status")}
                        choice={Object.values(["true", "false"]).map(
                          (is_active) => ({
                            key: is_active,
                            value: isDisplayedStatus[is_active],
                          }),
                        )}
                      />
                    </Filters>
                  }
                />
                <Space>
                  {!!checkedModels.allCheckedCheckboxes.length && (
                    <Button
                      type="primary"
                      prefix={<Icons.Trash size="1rem" />}
                      onClick={bulkDeleteModal.open}
                    >
                      {t("common.delete")}
                    </Button>
                  )}
                  <ExportInFile
                    columns={selectedColumns}
                    idList={selectedModelsId}
                    queryKey="models"
                    apiFunction={api.brandsModels.export}
                  />
                  {checkedModels.allCheckedCheckboxes.length ? (
                    <Button
                      type="dark"
                      onClick={() =>
                        updatePriceModal.openWith(
                          checkedModels.allCheckedCheckboxes,
                        )
                      }
                    >
                      {t("price.update_price")}
                    </Button>
                  ) : (
                    <PriceImport />
                  )}
                </Space>
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            onRow={(record) => ({
              onClick: () =>
                history.push(`/product-management/models/${record?.id}`),
            })}
          />
        </Card.Body>
      </Card>
      {updatePriceModal.isOpen && (
        <UpdatePriceModal
          open={updatePriceModal.isOpen}
          onClose={updatePriceModal.close}
          data={updatePriceModal.data}
          closeOnClickOutside={false}
        />
      )}
      {brandModelModal.isOpen && (
        <ModelModal
          open={brandModelModal.isOpen}
          onClose={brandModelModal.close}
          data={brandModelModal.data}
          closeOnClickOutside={false}
        />
      )}
      {deleteModel.isOpen && (
        <DeleteModal
          open={deleteModel.isOpen}
          onClose={deleteModel.close}
          size="small"
          mutation={deleteMutation}
          title={t("template.delete_item", {
            item: t("model.model").toLowerCase(),
          })}
        >
          <div className="py-30">
            <h3 className="text-center">
              {`${t("template.are_you_sure_you_want_to_delete_this_item", {
                item: t("model.model").toLowerCase(),
              })} - `}
              <span className="primary-color">{deleteModel.data?.id}</span>?
            </h3>
          </div>
        </DeleteModal>
      )}
      {bulkDeleteModal.isOpen && (
        <DeleteModal
          open={bulkDeleteModal.isOpen}
          onClose={bulkDeleteModal.close}
          size="small"
          mutation={bulkDeleteMutation}
          title={t("template.delete_item", {
            item: t("model.models").toLowerCase(),
          })}
        >
          <div className="py-30">
            <h3 className="text-center">
              {`${t("template.are_you_sure_you_want_to_delete_these_items", {
                items: t("model.models").toLowerCase(),
              })} - `}
              <Flex inline gap="0.5rem">
                {selectedTitle.map((title, i) => (
                  <span key={i} className="primary-color">
                    {title}
                    {i !== selectedTitle.length - 1 && ","}
                  </span>
                ))}
              </Flex>
              ?
            </h3>
          </div>
        </DeleteModal>
      )}
    </>
  );
};
