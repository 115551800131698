import { Button } from "ebs-design";
import { RenderExpandIconProps } from "rc-table/lib/interface";

import * as Icons from "components/icons";
import models from "models";

export const CustomExpandIcon = ({
  onExpand,
  record,
}: RenderExpandIconProps<models.Order>) => {
  return (
    <span onClick={(e) => onExpand(record, e)}>
      <Button
        icon={() => <Icons.AngleDown size="small" />}
        type="text"
        className="mr-10"
      />
    </span>
  );
};
