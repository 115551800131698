import { LanguagesContext } from "apps/cms/context/Languages";
import { Tabs } from "ebs-design";
import { FC, useContext, useState } from "react";

interface LanguageTabsProps {
  languages?: string[];
}

export const LanguageTabs: FC<LanguageTabsProps> = ({
  languages,
  children,
}) => {
  const {
    languages: { data: languagesCtx },
  } = useContext(LanguagesContext);
  const langs = languages
    ? languages
    : languagesCtx
        .map((lang) => lang.code)
        .reduce((acc, lang) => {
          return lang === "en" ? [lang, ...acc] : [...acc, lang];
        }, []);

  const [activeTab, setActiveTab] = useState(langs[0]);

  return (
    <div className="custom-tabs">
      <Tabs activeTab={activeTab} className="mb-10">
        {langs.map((language) => (
          <Tabs.Tab
            key={language}
            label={<span>{language.toLocaleUpperCase()}</span>}
            tabKey={language}
            onClick={() => setActiveTab(language)}
          />
        ))}

        {langs.map((language) => (
          <Tabs.Panel key={language} tabKey={language}>
            {typeof children === "function" ? children(language) : children}
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
};
