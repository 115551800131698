import { useCallback, useMemo } from "react";
import CKEditor from "ckeditor4-react";
import { getXAPIKey } from "api/axios/utils";
import { ACCESS_TOKEN_KEY } from "app-constants";

const fileUploadUrl = `${process.env.REACT_APP_STATIC_API}/v1/upload-file/?upload`;

interface ArticleEditorProps {
  features?: boolean;
  height?: number;
  value?: string;
  onChange?: (content: string) => void;
}

/**
 * This is the integration of CKEditor4 for editing article content.
 *
 * It has a default value, and will automatically update the form state
 */
const ArticleEditor: React.FC<ArticleEditorProps> = ({
  height = 500,
  value,
  onChange = () => null,
  features = true,
}) => {
  const onBeforeLoad = useCallback((CKEDITOR: any) => {
    CKEDITOR.disableAutoInline = true;
  }, []);

  const config = useMemo(
    () => ({
      removeButtons: "",
      height,
      language: "en",
      allowedContent: true,
      extraPlugins:
        features &&
        "image2,justify,uploadimage,uploadfile,widget,widgetselection,clipboard,lineutils,maximize,font,basicstyles,colorbutton,autoembed,copyformatting",
      removePlugins: "image",
      filebrowserUploadUrl: fileUploadUrl,
      filebrowserImageUploadUrl: fileUploadUrl,
      fileTools_requestHeaders: {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
        "X-API-Key": getXAPIKey(),
      },
    }),
    [features, height],
  );

  return (
    <CKEditor
      data={value}
      onBeforeLoad={onBeforeLoad}
      config={config}
      onChange={(event: any) => onChange(event.editor.getData())}
      placeholder="Enter content of page here"
    />
  );
};

export default ArticleEditor;
