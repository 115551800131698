import { Form, useForm, useNotify } from "ebs-design";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useMutation } from "react-query";

import api from "api";
import { makeBEM, notifyErrors } from "utils";
import { Flex, LoadingButton, InputFormField } from "components";
import { ArrowRight } from "components/icons";
import { stringifyUrl } from "api/utils";
import { Link } from "react-router-dom";
import models from "models";

const bem = makeBEM("auth-layout");

export const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const history = useHistory();

  const resetPasswordLink = `${window.location.origin}/confirm-reset-password`;

  const loginMutation = useMutation(api.authApi.resetPassword, {
    onSuccess: (values) => {
      const url = stringifyUrl(`/confirm-reset-password`, {
        email: values.email,
      });
      history.push(url);
    },
    onError: (error: AxiosError) => notifyErrors(notify, error),
  });

  return (
    <>
      <Form
        form={form}
        onFinish={(values: models.ResetPassword) =>
          loginMutation.mutate({ ...values, redirect: resetPasswordLink })
        }
      >
        <InputFormField
          name="username"
          placeholder={t("common.email")}
          type="email"
          hideLabel
          rules={[
            {
              required: true,
              message: t("validation.required_email"),
            },
          ]}
        />
        <LoadingButton
          size="large"
          className="full-width "
          type="primary"
          submit
          loading={loginMutation.isLoading}
        >
          <Flex justify="center" align="center">
            <span className="mr-13 fw-600">{t("common.send_email")}</span>
            <ArrowRight size="14" />
          </Flex>
        </LoadingButton>
        <h4 className="mt-15">
          {t("common.go_to")}{" "}
          <Link to="/login" className={bem("link")}>
            {t("common.login")}
          </Link>
        </h4>
      </Form>
    </>
  );
};
