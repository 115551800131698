import { createSvgIcon } from "../utils";

export const Posts = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M17 6H23M17 10H27M17 14H27M17 18H23M17 22H27M1 1H31V31H1V1ZM5 6H13V14H5V6ZM5 18H13V26H5V18Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
