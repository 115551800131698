import { Card } from "ebs-design";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router";

import api, { querykeys } from "api";
import {
  ExportInFile,
  Flex,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import {
  useAuth,
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";
import models from "models";
import { checkboxesCheckedInfo, CheckboxesCheckedInfoType } from "types";

import { EditPayoutModal } from "../components";
import { payoutColumns, payOutSortOptions } from "../config";

export const Payouts = () => {
  const { t } = useTranslation();
  const { organization } = useAuth();
  const history = useHistory();
  const pagination = useQueryPagination();
  const editPayoutModal = useModalState<models.Payment>();
  const [search, setSearch] = useState("");
  const [ordering, setOrdering] = useOrderingQueryParam();

  const queryParams = {
    ...pagination.queryParams,
    search,
    ordering,
  };

  const query = useQuery(querykeys.payouts.many(queryParams), () =>
    api.payouts.get(queryParams),
  );
  const allPayoutsIds = query.data?.results?.map((item) => String(item?.id));

  const [checkedPayouts, setCheckedPayouts] =
    useState<CheckboxesCheckedInfoType>(checkboxesCheckedInfo);

  const tableColumns = React.useMemo(
    () =>
      payoutColumns({
        editPayoutModal: editPayoutModal.openWith,
        currency: organization?.currency,
        t,
        setCheckboxInfo: setCheckedPayouts,
        checkboxInfo: {
          ...checkedPayouts,
          checkboxesOnPage: allPayoutsIds,
        },
      }),
    [
      allPayoutsIds,
      checkedPayouts,
      editPayoutModal.openWith,
      organization?.currency,
      t,
    ],
  );

  return (
    <>
      <PageTitle title={t("menu.payout")} count={query.data?.count} />
      <Card>
        <Card.Body>
          <QueryTable
            variants={["highlight-hover"]}
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  sortOptions={payOutSortOptions}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                  setSearch={setSearch}
                />
                <ExportInFile
                  idList={checkedPayouts.allCheckedCheckboxes}
                  queryKey="payouts-exports"
                  apiFunction={api.payouts.export}
                />
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            onRow={(data) => ({
              onClick: () => history.push(`/crm/invoices/payouts/${data?.id}`),
            })}
            rowKey="id"
          />
        </Card.Body>
      </Card>
      {editPayoutModal.isOpen && (
        <EditPayoutModal
          open={editPayoutModal.isOpen}
          onClose={editPayoutModal.close}
          data={editPayoutModal.data}
          closeOnClickOutside={false}
        />
      )}
    </>
  );
};
