import { Button } from "ebs-design";
import { TFunction } from "react-i18next";

import * as Icons from "components/icons";
import {
  DiagnosticCriteria,
  InventoryStatus,
  Flex,
  OrderStatus,
  TableCheckboxSelector,
  TableCheckbox,
} from "components";
import models from "models";
import { TableColumnBuilder } from "utils";
import { CheckboxesCheckedInfoType } from "types";

interface InventoryColumnsProps {
  editInventoryModal: React.Dispatch<models.Inventory>;
  setCheckboxInfo: React.Dispatch<
    React.SetStateAction<CheckboxesCheckedInfoType>
  >;
  checkboxInfo?: CheckboxesCheckedInfoType;
  t: TFunction;
}

export const inventoryColumns = ({
  editInventoryModal,
  setCheckboxInfo,
  checkboxInfo,
  t,
}: InventoryColumnsProps) =>
  new TableColumnBuilder<models.Inventory>()
    .add(
      <TableCheckboxSelector
        word="orders"
        checkboxInfo={checkboxInfo}
        setCheckboxInfo={setCheckboxInfo}
      />,
      "",
      (row: models.Order) => (
        <TableCheckbox
          itemId={row?.id}
          checkboxInfo={checkboxInfo}
          setCheckboxInfo={setCheckboxInfo}
        />
      ),
    )
    .add(t("order.order_id"), ["order", "code"])
    .add(t("common.seller_id"), ["client", "id"])
    .add(t("device.device_id"), ["device", "id"])
    .add(t("device.device_brand"), ["device", "brand_model", "brand", "title"])
    .add(t("device.device_model"), ["device", "brand_model", "title"])
    .add(t("common.storage"), ["device", "storage_capacity"])
    .add(t("common.color"), ["device", "color"])
    .add(t("common.imei"), ["device", "imei"])
    .add(t("common.condition"), "", (row: models.Order) => (
      <DiagnosticCriteria
        diagnosticCriteria={row?.device?.diagnostic_criteria}
      />
    ))
    .add(t("common.status"), "status", (status) => (
      <InventoryStatus status={status} />
    ))
    .add("", "", (row) => (
      <Flex justify="flex-end" onClick={(e) => e.stopPropagation()}>
        <Button
          onClick={() => {
            editInventoryModal(row);
          }}
          type="light"
          size="small"
          icon={() => <Icons.Edit size="small" />}
        />
      </Flex>
    ))
    .getColumns();

interface InventoryDetailsColumns {
  t: TFunction;
}

export const inventoryDetailsColumns = ({ t }: InventoryDetailsColumns) =>
  new TableColumnBuilder<models.Inventory | undefined>()
    .add(t("order.order_id"), ["order", "id"])
    .add(t("common.seller_id"), ["client", "id"])
    .add(t("device.device_id"), ["device", "id"])
    .add(t("brand.brand"), ["device", "brand_model", "brand", "title"])
    .add(t("model.model"), ["device", "brand_model", "title"])
    .add(t("common.storage"), ["device", "storage_capacity"])
    .add(t("common.color"), ["device", "color"])
    .add(t("common.imei"), ["device", "imei"])
    .add(
      t("common.condition"),
      ["device", "diagnostic_criteria"],
      (diagnostic_criteria) => (
        <DiagnosticCriteria diagnosticCriteria={diagnostic_criteria} />
      ),
    )
    .add(t("common.status"), "status", (status) => (
      <InventoryStatus status={status} />
    ))
    .add(t("order.order_status"), ["order", "status"], (status) => (
      <OrderStatus orderStatus={status} />
    ))
    .getColumns();

export const diagnosticCriteriaColumns = () =>
  new TableColumnBuilder<models.DiagnosticCriteria>()
    .add("Client", "is_correct", (is_correct) => (
      <>
        {is_correct ? (
          <Icons.Check color="success" />
        ) : (
          <Icons.Close color="danger" />
        )}
      </>
    ))
    .add("", "title")
    .getColumns();
