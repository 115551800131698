export const transparentSvg = `url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><path d="M8 0h8v8H8zM0 8h8v8H0z"/></svg>')`;

export const colorPickerOptions = [
  { text: "RGB", value: "rgb" },
  { text: "HEX", value: "hex" },
];

export const stringRGBToArray = (color: string) => color.split(",").map(Number);

export const contrastColor = (hex: string) => {
  let threshold = 130; // about 256/2 - lower values results into darker text on dark background
  let colorBrightness =
    (hexToRed(hex) * 299 + hexToGreen(hex) * 587 + hexToBlue(hex) * 114) / 1000;

  function cleanHex(h: string) {
    return h?.charAt(0) === "#" ? h?.substring(1, 7) : h;
  }
  function hexToRed(h: string) {
    return parseInt(cleanHex(h)?.substring(0, 2), 16);
  }
  function hexToGreen(h: string) {
    return parseInt(cleanHex(h)?.substring(2, 4), 16);
  }
  function hexToBlue(h: string) {
    return parseInt(cleanHex(h)?.substring(4, 6), 16);
  }
  return colorBrightness > threshold ? "#252c32" : "#ffffff";
};
