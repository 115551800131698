import { useTranslation } from "react-i18next";
import { makeBEM, translateIfIsTranslatable } from "utils";

const bem = makeBEM("steps__label");

interface Props {
  index: string | number;
  title: string;
  icon?: boolean;
}

export const StepsLabel = ({ index, title }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={bem("content")}>
      <div className={bem("content__circle")}>{index}</div>
      <p>{translateIfIsTranslatable(t, title, "common")}</p>
    </div>
  );
};
