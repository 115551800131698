import cn from "classnames";
import { Form, Switch } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

interface SwitchFormFieldProps extends FormFieldProps {
  disabled?: boolean;
}

export const SwitchFormField = ({
  className,
  disabled,
  ...props
}: SwitchFormFieldProps) => {
  return (
    <Form.Field {...props} className={cn(className, "custom-switch")}>
      {({ value, onChange }) => (
        <Switch disabled={disabled} checked={value} onChange={onChange} />
      )}
    </Form.Field>
  );
};
