import React from 'react';
import { Tree, Spin, Row, Col } from 'antd';
import { DragOutlined, FolderOutlined, FolderOpenOutlined } from '@ant-design/icons';
import pages from 'apps/cms/lib/api/pages';

import { loop, normalizeTree } from '../utils';
import DraggableItem from './DraggableItem';

const TreeNode = Tree.TreeNode;
class Drag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedKey: undefined,
      expandedKeys: [],
      loading: true,
      treeContent: [],
    };
  }

  componentDidMount = () => {
    try {
      pages
        .all({ name_content_icontains: '', page: 1, page_size: 1000 })
        .then(({ results: treeContent }) =>
          this.setState({ treeContent: normalizeTree(treeContent), loading: false }),
        );
    } catch (e) {
      console.error(e);
    }
  };

  onMouseDown = (dragItem) => {
    this.props.handler({ dragItem });
    this.props.onDrag();
  };

  getTreeNode = (treeContent) => {
    if (!treeContent || treeContent.length === 0) {
      return null;
    }

    const treeNode = treeContent.map((value, i) => {
      let disabled = false;

      loop(this.props.state.treeContent, value.id, () => {
        disabled = true;
      });

      return (
        <TreeNode
          key={i}
          title={
            <DraggableItem
              lang={this.props.lang}
              value={value}
              onMouseDown={() => this.onMouseDown(value)}
              onMouseOut={() => this.props.onDrop()}
              disabled={disabled}
            />
          }
          icon={
            <Row gutter={8}>
              {value?.children?.length ? (
                <Col>
                  {this.state.selectedKey?.includes(value.type) ? (
                    <FolderOpenOutlined />
                  ) : (
                    <FolderOutlined />
                  )}
                </Col>
              ) : null}
              {value.canDrag ||
                (typeof value.canDrag === 'undefined' && !value?.children?.length) ? (
                <Col>
                  <DragOutlined />
                </Col>
              ) : null}
            </Row>
          }
          showIcon={false}
          className="drag-tree__node"
          disabled={disabled}
        >
          {this.getTreeNode(value?.children || [])}
        </TreeNode>
      );
    });

    return treeNode;
  };

  render() {

    return (
      <div className="drag-tree">
        <label style={{ marginBottom: 16 }}>Static pages</label>
        <Tree
          showLine
          blockNode
          icon={<FolderOutlined />}
          showIcon
          expandedKeys={this.state.expandedKeys}
          selectedKeys={this.state.expandedKeys}
          onExpand={(expandedKeys) => this.setState({ expandedKeys })}
          onSelect={(expandedKeys) => this.setState({ expandedKeys })}
        >
          {this.getTreeNode(this.state.treeContent)}
        </Tree>
        {this.state.loading && <Spin loading={this.state.loading} className="full-width" />}
      </div>
    );
  }
}

export default Drag;
