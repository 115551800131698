import { makeBEM } from "utils";

export interface CounterBoxProps {
  value: string | number;
  title: string;
}

const bem = makeBEM("counter-box");

export const CounterBox = ({ value, title }: CounterBoxProps) => {
  return (
    <div className="counter-box">
      <h4 className={bem("value")}>{value}</h4>
      <span className={bem("title")}>{title}</span>
    </div>
  );
};
