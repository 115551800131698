import { createSvgIcon } from "../utils";

export const Warehouses = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 21C2.25 21.4142 2.58579 21.75 3 21.75C3.41421 21.75 3.75 21.4142 3.75 21H2.25ZM3 8L2.6954 7.31464C2.42455 7.43502 2.25 7.70361 2.25 8H3ZM12 4L12.3046 3.31464C12.1107 3.22845 11.8893 3.22845 11.6954 3.31464L12 4ZM21 8H21.75C21.75 7.70361 21.5754 7.43502 21.3046 7.31464L21 8ZM20.25 21C20.25 21.4142 20.5858 21.75 21 21.75C21.4142 21.75 21.75 21.4142 21.75 21H20.25ZM13 12.25C12.5858 12.25 12.25 12.5858 12.25 13C12.25 13.4142 12.5858 13.75 13 13.75V12.25ZM17 13H17.75C17.75 12.5858 17.4142 12.25 17 12.25V13ZM17 21V21.75C17.4142 21.75 17.75 21.4142 17.75 21H17ZM7 21H6.25C6.25 21.4142 6.58579 21.75 7 21.75V21ZM7 15V14.25C6.58579 14.25 6.25 14.5858 6.25 15H7ZM13 15.75C13.4142 15.75 13.75 15.4142 13.75 15C13.75 14.5858 13.4142 14.25 13 14.25V15.75ZM12.25 21C12.25 21.4142 12.5858 21.75 13 21.75C13.4142 21.75 13.75 21.4142 13.75 21H12.25ZM8.25 15C8.25 15.4142 8.58579 15.75 9 15.75C9.41421 15.75 9.75 15.4142 9.75 15H8.25ZM3.75 21V8H2.25V21H3.75ZM3.3046 8.68536L12.3046 4.68536L11.6954 3.31464L2.6954 7.31464L3.3046 8.68536ZM11.6954 4.68536L20.6954 8.68536L21.3046 7.31464L12.3046 3.31464L11.6954 4.68536ZM20.25 8V21H21.75V8H20.25ZM13 13.75H17V12.25H13V13.75ZM16.25 13V21H17.75V13H16.25ZM17 20.25H7V21.75H17V20.25ZM7.75 21V15H6.25V21H7.75ZM7 15.75H13V14.25H7V15.75ZM13.75 21V12H12.25V21H13.75ZM13.75 12C13.75 11.5359 13.5656 11.0908 13.2374 10.7626L12.1768 11.8232C12.2237 11.8701 12.25 11.9337 12.25 12H13.75ZM13.2374 10.7626C12.9092 10.4344 12.4641 10.25 12 10.25V11.75C12.0663 11.75 12.1299 11.7763 12.1768 11.8232L13.2374 10.7626ZM12 10.25H10V11.75H12V10.25ZM10 10.25C9.53587 10.25 9.09075 10.4344 8.76256 10.7626L9.82322 11.8232C9.87011 11.7763 9.9337 11.75 10 11.75V10.25ZM8.76256 10.7626C8.43437 11.0908 8.25 11.5359 8.25 12H9.75C9.75 11.9337 9.77634 11.8701 9.82322 11.8232L8.76256 10.7626ZM8.25 12V15H9.75V12H8.25Z"
      fill="currentColor"
    />
  </svg>,
);
