import models from "models";
import { GenericObject } from "types";
import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const attachments = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/attachments", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Attachment>>(
      url,
    );
    return data;
  },
  getById: async (id: number) => {
    const { data } = await apiAxios.get<models.Attachment>(
      `/attachments/${id}`,
    );
    return data;
  },
  post: async (body: models.Attachment) => {
    const { data } = await apiAxios.post<models.Attachment>(
      "/attachments",
      body,
    );
    return data;
  },
  patch: async (values: { id: number; body: models.Attachment }) => {
    const url = `/attachments/${values?.id}`;
    const { data } = await apiAxios.patch<models.Attachment>(url, values?.body);
    return data;
  },
  delete: async (id: number) => {
    await apiAxios.delete(`/attachments/${id}`);
  },

  organization: {
    get: async (queryParams: GenericObject = {}) => {
      const url = stringifyUrl("/organization/attachments", queryParams);
      const { data } = await apiAxios.get<
        models.WithResults<models.Attachment>
      >(url);
      return data;
    },
    post: async (body: models.Attachment) => {
      const { data } = await apiAxios.post<models.Attachment>(
        "/organization/attachments",
        body,
      );
      return data;
    },
    patch: async (values: { id: number; body: models.Attachment }) => {
      const url = `/organization/attachments/${values?.id}`;
      const { data } = await apiAxios.patch<models.Attachment>(
        url,
        values?.body,
      );
      return data;
    },
    delete: async (id: number) => {
      await apiAxios.delete(`/organization/attachments/${id}`);
    },
  },
};
