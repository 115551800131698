import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import {
  Divider,
  Flex,
  Grid,
  InputFormField,
  LoadingButton,
  SelectOptions,
  SwitchFormField,
  UploadImage,
  WhiteSpace,
} from "components";
import { useAuth } from "hooks";
import models from "models";
import { FeeEnum, FeeTypeSymbol } from "models/payment";
import { formatNumber, notifyErrors } from "utils";
import { t } from "i18next";

interface PaymentProviderFormModalProps extends ModalProps {
  data?: models.PaymentProvider;
  onClose: () => void;
}

const selectOption = [
  { name: "Currency", value: models.FeeEnum.CURRENCY },
  { name: "Percent", value: models.FeeEnum.PERCENT },
];

export const PaymentProviderFormModal = ({
  data,
  onClose,
  ...props
}: PaymentProviderFormModalProps) => {
  const { organization } = useAuth();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [image, setImage] = useState<models.File>();

  const { mutate, isLoading } = useMutation(
    (body: models.PaymentProvider) =>
      data && data.id
        ? api.paymentMethods.patch(data.id, body)
        : api.paymentMethods.post(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.paymentMethods.many());
        notify.success({
          title: t(
            data
              ? "template.item_updated_successfully"
              : "template.item_created_successfully",
            { item: t("payment.payment_method") },
          ),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );
  const [feeType, setFeeType] = useState(data?.fee_type);

  useEffect(() => {
    setImage(data?.logo);
  }, [data?.logo]);

  return (
    <Modal
      title={t("template.edit_item", {
        item: t("common.payment_provider").toLowerCase(),
      })}
      size="small"
      onClose={onClose}
      className="edit-service-modal"
      {...props}
    >
      <Form
        form={form}
        name="payment-provider"
        onFinish={(values) => mutate({ ...values, logo_id: image?.id })}
        className="service-form"
        initialValues={{
          ...data,
          ...{
            fee: formatNumber(data.fee, feeType === FeeEnum.PERCENT ? 0 : 2),
          },
        }}
      >
        <Modal.Content>
          <InputFormField name="title" label={t("common.provider_name")} />
          <UploadImage file={image} setFile={setImage} showImage publicFile />
          <Grid cols="1fr 1fr " gap="16px">
            <SelectOptions
              name="fee_type"
              size="medium"
              label={t("fee.fee_type")}
              selectOption={selectOption}
              onChange={(v) =>
                setFeeType(
                  selectOption.filter((item) => item.name === v)[0].value,
                )
              }
            />
            <InputFormField
              name="fee"
              label={t("fee.fee_in_amount")}
              prefix={
                <span className="fw-600">
                  {feeType === FeeEnum.PERCENT
                    ? FeeTypeSymbol.PERCENT
                    : organization.currency.symbol}
                </span>
              }
            />
          </Grid>
          <Divider style={{ marginBottom: 20 }} />
          <SwitchFormField
            labelOptions={{ col: 1 }}
            controlOptions={{
              col: 1,
              justify: "end",
              className: "d-flex",
            }}
            name="is_active"
            label={t("common.active")}
          />
        </Modal.Content>

        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton loading={isLoading} submit type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
