import React from "react";

interface Args extends Omit<IntersectionObserverInit, "root"> {
  targetRef: React.RefObject<HTMLElement | null>;
  rootRef?: React.RefObject<HTMLElement | null>;
  onIntersect: () => void;
  enabled?: boolean;
}

export function useIntersectionObserver({
  targetRef,
  rootRef,
  onIntersect,
  enabled = true,
  ...options
}: Args) {
  React.useEffect(() => {
    const refNode = targetRef.current;

    if (!enabled || !refNode) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      { ...options, root: rootRef?.current },
    );

    observer.observe(refNode);

    return () => {
      refNode && observer.unobserve(refNode);
    };
    // TODO: Check if the deps should be updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
}
