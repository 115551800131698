import { createSvgIcon } from "../utils";

export const Checked = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="20"
    viewBox="0 0 28 20"
    fill="none"
  >
    <path
      d="M1.33301 10.0001L9.66634 18.3334L26.333 1.66675"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
