import { AuthContainer } from "components";
import { ResetPasswordForm } from "../components";

export const ResetPassword = () => {
  return (
    <AuthContainer header={<h3>Reset password</h3>}>
      <ResetPasswordForm />
    </AuthContainer>
  );
};
