import models from "models";

export interface Warehouse {
  id?: number;
  users__count?: number;
  timestamp?: string;
  edited_timestamp?: string;
  title?: string;
  is_default?: boolean;
  is_active?: boolean;
}

export interface Department extends models.DefaultModel {
  address?: string;
  working_hours?: any;
}

export interface RolePermission {
  id?: number;
  name?: models.Roles;
  permissions?: Permissions[];
}

export interface Permissions {
  id?: number;
  codename?: string;
  content_type?: ContentType;
}

export interface ContentType {
  id?: number;
  app_label?: string;
  model?: string;
}

export interface NomenclatureBrand {
  id?: number;
  title?: string;
  brand?: ShortNomenclatureBrand;
  storage_capacities?: string[];
  colors?: string[];
  logo?: models.File;
}

export interface ShortNomenclatureBrand {
  id?: number;
  title?: string;
}

export interface NomenclatureBrandModel {
  id?: number;
  data?: NomenclatureBrandModelData;
  title?: string;
  slug?: string;
  brand?: models.Brand;
  type?: ModelType;
  max_price?: string;
  storage_capacities?: string[];
  colors?: string[];
  properties?: NomenclatureBrandModelProperties;
}

export interface NomenclatureBrandModelData {
  image?: string;
  url?: string;
}

export interface NomenclatureBrandModelProperties {
  battery?: string;
  camera?: string;
  cpu?: string;
  dimensions?: string;
  display?: string;
  gpu?: string;
  os?: string;
  ram?: string;
  soc?: string;
  storage?: string;
  weight?: string;
}

export enum ModelType {
  PHONE = "PHONE",
  TABLET = "TABLET",
}
