import { useState } from "react";
import { Form, Input, Select, Upload, message } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import api from "apps/cms/lib/api/pages";
import { getBase64 } from "utils";
import { Properties } from "types";

import { maxImageSizeInMb, formItemStyle } from "../helpers";
import { IconSelect } from "../";

const Avatar = ({ item, lang, setFieldsValue }: Properties) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (info: Properties) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, () => {
        setLoading(false);
      });
    }
  };

  const handleUpload = async (file: any): Promise<void> => {
    setLoading(true);

    try {
      const data = await api.uploadFile(file);
      const { id: uid, upload, uploaded } = data;

      const newFile: UploadFile<any> = {
        uid,
        size: 1,
        name: upload,
        status: uploaded ? "done" : "error",
        url: `${process.env.REACT_APP_STATIC_API}/v1${upload}`,
        type: "image",
      };

      setFieldsValue({
        [item.id]: {
          [lang]: {
            img: { fileList: [newFile], url: newFile.url },
          },
        },
      });
    } catch (e) {
      message.error("Unsuccessfull image upload!");
    }
    setLoading(false);
  };

  const handleBeforeUpload = (file: any): boolean => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Puteți încărca doar imagini în formatul JPG/PNG!");
      return false;
    }

    const isMbLt = file.size / 1024 / 1024 <= maxImageSizeInMb;
    if (!isMbLt) {
      message.error(
        `Imaginea nu trebuie să fie mai mare decât ${maxImageSizeInMb} MB!`,
      );
      return false;
    }
    handleUpload(file);

    return false;
  };

  return (
    <>
      <Form.Item
        name={[item.id, lang, "size"]}
        initialValue="small"
        label="Size"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="small">Small</Select.Option>
          <Select.Option value="big">Big</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "dataType"]}
        initialValue="regular"
        label="Type"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="regular">Regular</Select.Option>
          <Select.Option value="primary">Primary</Select.Option>
          <Select.Option value="light">Light</Select.Option>
          <Select.Option value="dynamic">Dynamic</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "icon"]}
        label="Icon"
        style={formItemStyle}
      >
        <IconSelect />
      </Form.Item>
      <Form.Item label=" ">or</Form.Item>
      <Form.Item label="Avatar" style={formItemStyle}>
        <Upload
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          multiple={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleChange}
        >
          {item?.img?.url ? (
            <img src={item.img.url} alt="img" style={{ width: "100%" }} />
          ) : (
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">Upload</div>
            </div>
          )}
        </Upload>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "alt"]}
        label="Title"
        style={formItemStyle}
      >
        <Input placeholder="Mark Zuckerberg" />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "description"]}
        label="Description"
        style={formItemStyle}
      >
        <Input placeholder="Administrator" />
      </Form.Item>
    </>
  );
};

export default Avatar;
