import { useState } from "react";
import { Marker, Popup, useMapEvents } from "react-leaflet";

import { IconMarker } from ".";

export function LocationMarker() {
  const [position, setPosition] = useState(null);
  const map = useMapEvents({
    dblclick() {
      map.locate();
    },
    locationfound(e: any) {
      setPosition(e.latlng);
      const zoom = e.target._zoom;
      map.flyTo(e.latlng, zoom < 13 ? 13 : zoom);
    },
  });

  return position === null ? null : (
    <Marker position={position} icon={IconMarker}>
      <Popup>You are here</Popup>
    </Marker>
  );
}
