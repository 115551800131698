import React from "react";
import { Form, Input } from "antd";
import { Properties } from "types";

import { formItemStyle } from "../helpers";

const DownloadBanner = ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "apple"]}
        label="App Store URL"
        style={formItemStyle}
      >
        <Input placeholder="https://apps.apple.com/us/app/buyback" />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "google"]}
        label="Google Play URL"
        style={formItemStyle}
      >
        <Input placeholder="https://play.google.com/store/apps/details?id=buyback" />
      </Form.Item>
    </>
  );
};

export default DownloadBanner;
