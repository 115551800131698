import { useAuth } from "hooks";
import models from "models";
import {
  OrderDetailsTemplate,
  SupervisorOrderDetails,
  OperatorOrderDetails,
} from "../components";

export const OrderDetails = () => {
  //FIXME: temporary solution
  const { roles } = useAuth();
  const isWarehouseSupervisor = roles.some(
    (role) => role.name === models.Roles.WAREHOUSE_SUPERVISOR,
  );

  return (
    <OrderDetailsTemplate
      OrderDetails={
        isWarehouseSupervisor ? SupervisorOrderDetails : OperatorOrderDetails
      }
    />
  );
};
