import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import { Form as IForm, Row as IRow, Col } from "antd";
import cn from "classnames";
import { Languages } from "apps/cms/lib/utils";
import { Properties } from "types";

import {
  Avatar,
  Button,
  HomeBanner,
  GenericFields,
  Order,
  InfoCard,
  TextCard,
  DownloadBanner,
  ContactUs,
  Collapse,
  Confirmation,
  Card,
  Label,
  Link,
  Responsive,
  Space,
  Text,
  Image,
  Row,
  Container,
  SectionContent,
  SectionMain,
  JoinUs,
} from "../Fields";
import { formItemLayout } from "../helpers";
import { loopChildren, loopObjects } from "../utils";
import { merge } from "./utils";

interface ItemFormProps {
  initialValue: Properties;
  state: Properties;

  item: Properties;
  lang: Languages;

  onSaved: (values: Properties) => void;
  handler: (values: Properties) => void;
}

const ItemForm: React.FC<ItemFormProps> = ({
  lang,
  item,
  initialValue,
  state,
  handler,
  onSaved,
}) => {
  const [form] = IForm.useForm();
  const [params, setParams] = useState({});

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        [item.id]: {
          [lang]: item,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, lang]);

  const [, cancel] = useDebounce(
    () => {
      if (Object.keys(params).length) {
        const props = form.getFieldsValue();
        let values = { ...initialValue };
        let data = [...state.treeContent];

        values = loopObjects(
          values,
          item.id,
          (item) => {
            if (props) {
              Object.keys(props).forEach((id) => {
                item = {
                  ...item,
                  ...merge(
                    { ...(params && params[id] && params[id][lang]) },
                    { ...(props[id] && props[id][lang]) },
                  ),
                };
              });
            }

            data = loopChildren(data, item.id, (treeItem) => {
              treeItem = { ...treeItem, ...item };

              return treeItem;
            });

            return item;
          },
          lang,
        );

        onSaved(values);
        handler({ treeContent: data });
        setParams({});
      }
    },
    350,
    [params],
  );

  const onChange = useCallback(
    (params) => {
      cancel();
      setParams(params);
    },
    [cancel],
  );

  const props = useMemo(
    () => ({
      lang,
      item,
      setFieldsValue: onChange,
      onSaved,
    }),
    [item, lang, onChange, onSaved],
  );

  const hasOptions = useMemo(
    () =>
      item &&
      [
        "moduleOrder",
        "bannerHome",
        "bannerDownload",
        "templateInfo",
        "templateText",
        "componentCollapse",
        "templateConfirmation",
      ].includes(item.type),
    [item],
  );

  return (
    <div className={cn({ "hide-options": !hasOptions })}>
      <IForm
        form={form}
        {...formItemLayout}
        colon={false}
        onValuesChange={onChange}
      >
        <GenericFields {...props} />

        <IRow gutter={[16, 16]} justify="space-between">
          <Col span={24}>
            {item &&
              (() => {
                switch (item?.type) {
                  case "moduleOrder":
                    return <Order {...props} />;
                  case "moduleContactUs":
                    return <ContactUs {...props} />;
                  case "bannerHome":
                    return <HomeBanner {...props} />;
                  case "bannerDownload":
                    return <DownloadBanner {...props} />;
                  case "templateInfo":
                    return <InfoCard {...props} />;
                  case "templateText":
                    return <TextCard {...props} />;
                  case "templateConfirmation":
                    return <Confirmation {...props} />;
                  case "componentCollapse":
                    return <Collapse {...props} />;
                  case "componentAvatar":
                    return <Avatar {...props} />;
                  case "componentButton":
                    return <Button {...props} />;
                  case "componentCard":
                    return <Card {...props} />;
                  case "componentLabel":
                    return <Label {...props} />;
                  case "componentLink":
                    return <Link {...props} />;
                  case "componentResponsive":
                    return <Responsive {...props} />;
                  case "componentSpace":
                    return <Space {...props} />;
                  case "componentText":
                    return <Text {...props} />;
                  case "componentImage":
                    return <Image {...props} />;
                  case "sectionRow":
                    return <Row {...props} />;
                  case "componentContainer":
                    return <Container {...props} />;
                  case "sectionContent":
                    return <SectionContent {...props} />;
                  case "sectionMain":
                    return <SectionMain {...props} />;
                  case "joinUs":
                    return <JoinUs {...props} />;
                  default:
                    return null;
                }
              })()}
          </Col>
        </IRow>
      </IForm>
    </div>
  );
};

export default ItemForm;
