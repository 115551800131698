import { useQuery } from "react-query";

import api, { querykeys } from "api";
import { USER_ROLES } from "app-constants";
import models from "models";

const whDataOptions = {
  select: (data) => ({
    ...data,
    results: data?.results
      .filter((item) => item.is_active)
      .map((item) => ({
        text: item.title,
        value: item.id,
      })),
  }),
};

export const useInvitationOptions = () => {
  const { data: roleList } = useQuery(
    querykeys.nomenclature.roles(),
    () => api.nomenclatures.roles(),
    {
      select: (data) => ({
        ...data,
        results: data.results
          .filter((item) => Object.values(models.Roles).includes(item.name))
          .map((item) => ({
            text: USER_ROLES[item.name],
            value: item.id,
          })),
      }),
    },
  );

  const { data: departmentList } = useQuery(
    querykeys.nomenclature.departments(),
    () => api.nomenclatures.department(),
    whDataOptions,
  );

  const { data: warehouseList } = useQuery(
    querykeys.nomenclature.warehouses(),
    () => api.nomenclatures.warehouses(),
    whDataOptions,
  );

  const { data: applications } = useQuery(
    querykeys.nomenclature.applicationList(),
    () => api.nomenclatures.applicationList(),
  );

  return {
    roles: roleList?.results,
    departments: departmentList?.results,
    warehouses: warehouseList?.results,
    applications,
  };
};
