import { PageTitle } from "components";
import { useTranslation } from "react-i18next";
import { BrandCustomizationCard } from "../components/brand-customization";

export const Customization = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle to="/settings">{t("common.customization")}</PageTitle>
      <BrandCustomizationCard />
    </>
  );
};
