import models from "models";
import { CurrencyCodeType, CurrencySymbolType } from "types";

export interface Order {
  id?: number;
  device?: models.Device;
  client?: models.ShortClientUser;
  services?: Service[];
  services_id?: number[];
  payment_method?: models.PaymentMethod;
  payment_method_id?: number;
  shipping_provider?: models.ShippingProvider;
  shipping_provider_id?: number;
  delivery_shipping_provider?: models.ShippingProvider;
  address?: models.Address;
  address_id?: number;
  delivery_address?: models.Address;
  drop_off_location?: models.DropOffLocation;
  drop_off_location_id?: number;
  devices__count?: number;
  timestamp?: string;
  edited_timestamp?: string;
  fee?: string;
  cost?: string;
  price?: string;
  payout?: string;
  code?: string;
  payment_payout_method?: models.PaymentPayoutMethod;
  payout_status?: models.PayoutStatus;
  status?: models.OrderStatus;
  hidden_status?: models.OrderStatus;
  is_recycle_certificate_issued?: boolean;
  is_data_erasure_only?: boolean;
  currency?: CurrencyCodeType;
  currency_symbol?: CurrencySymbolType;
  delivery_drop_off_location?: models.DropOffLocation;
  parent?: {
    id?: number;
    code?: string;
  };
  tags?: models.Tag[];
}

export interface ShortService {
  id?: number;
  code?: string;
}

export interface ShortInventoryOrder {
  id?: number;
  code?: string;
  status?: OrderStatus;
  services?: ShortService[];
}

export enum OrderStatus {
  PROCESSING = "PROCESSING",
  CANCELLED = "CANCELLED",
  PENDING_SHIPMENT = "PENDING_SHIPMENT",
  PENDING_DROP_OFF = "PENDING_DROP_OFF",
  DEVICE_SHIPPED = "DEVICE_SHIPPED",
  DEVICE_DELIVERED = "DEVICE_DELIVERED",
  PENDING_COMPLETION = "PENDING_COMPLETION",
  PENDING_REJECTION = "PENDING_REJECTION",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
  PRICE_UPDATED = "PRICE_UPDATED",
  PENDING_RETURN = "PENDING_RETURN",
  PENDING_RETURN_FEE = "PENDING_RETURN_FEE",
  DEVICE_SENT_BACK = "DEVICE_SENT_BACK",
  DEVICE_RECEIVED_BACK = "DEVICE_RECEIVED_BACK",
  PAYMENT_COMPLETED = "PAYMENT_COMPLETED",
  PENDING_RECYCLING = "PENDING_RECYCLING",
  RECYCLED = "RECYCLED",
  DEVICE_AT_PICK_UP_LOCATION = "DEVICE_AT_PICK_UP_LOCATION",
}

export interface OrderStatusEntity {
  status: OrderStatus;
}

export interface Service {
  id?: number;
  code?: string;
  price?: string;
  price_translation?: string;
  is_mandatory?: boolean;
  is_active?: boolean;
  title?: string;
  description?: string;
  i18n?: models.I18N;
  is_email_notification_active?: boolean;
  emails?: string[];
  image?: models.File;
  image_id?: number;
}

export enum ServiceCode {
  SECURE_BOX = "SECURE_BOX",
  CERTUS_SOFTWARE_DATA_ERASURE = "CERTUS_SOFTWARE_DATA_ERASURE",
}
