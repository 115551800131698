import { CSSProperties } from "react";
import { Flex } from "./Flex";

export interface OrDividerProps {
  space?: CSSProperties["padding"];
  style?: CSSProperties;
}

export const OrDivider = ({ space, style }: OrDividerProps) => {
  return (
    <Flex align="center" justify="center">
      <span className="horizontal-line mb-0" />
      <span className="grey-400 mx-16">Or</span>
      <span className="horizontal-line mb-0" />
    </Flex>
  );
};
