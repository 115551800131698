import models from "models";
import { GenericObject } from "types";
import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const invites = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/invites", queryParams, {
      arrayFormat: "comma",
    });
    const { data } = await apiAxios.get<models.WithResults<models.Invite>>(url);
    return data;
  },
  post: async (body: models.InvitePayload) => {
    const { data } = await apiAxios.post<models.InvitePayload>(
      "/invites",
      body,
    );
    return data;
  },
};
