import { useTranslation } from "react-i18next";

import { DndDiagnosticCard } from "components/dnd-table";
import { DiagnosticType } from "types";

export const DiagnosticCriteria = () => {
  const { t } = useTranslation();

  return (
    <DndDiagnosticCard
      title={t("common.diagnostic_criteria")}
      diagnosticType={DiagnosticType.ORGANIZATION}
    />
  );
};
