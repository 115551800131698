import { AxiosError } from "axios";
import { Form, useForm, useNotify } from "ebs-design";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

import api from "api";
import { MINUTE } from "app-constants";
import {
  AuthContainer,
  bem,
  Flex,
  InputFormField,
  LoadingButton,
} from "components";
import { ArrowRight } from "components/icons";
import { useAuth } from "hooks";
import models from "models";
import { notifyErrors } from "utils";

export const CompleteInvitationConfirm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const auth = useAuth();
  const [confirmationTime, setConfirmationTime] = useState(MINUTE);

  const [query] = useQueryParams({
    email: StringParam,
    token: StringParam,
  });

  const [form] = useForm();
  const notify = useNotify();

  const { mutate, isLoading } = useMutation(
    async (values: models.RegisterConfirmCredentials) =>
      api.authApi.confirmRegister(values),
    {
      onSuccess: () => history.push("/login"),
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  const resendCodeMutation = useMutation(auth.resendCode, {
    onSuccess: () => {
      notify.success({
        title: t("template.item_sent_successfully", {
          item: t("common.code"),
        }),
      });
      setConfirmationTime(MINUTE);
    },
    onError: (error: AxiosError) => notifyErrors(notify, error),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setConfirmationTime((prevState) => prevState - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [confirmationTime]);

  return (
    <AuthContainer header={<h3>{t("common.confirm_invitation")}</h3>}>
      <Form
        form={form}
        onFinish={(values) =>
          confirmationTime > 0
            ? mutate({
                ...values,
                username: query.email,
                token: query.token,
              })
            : resendCodeMutation.mutate({
                username: query.email,
                redirect: `${window.location.origin}/complete-invitation-confirm`,
              })
        }
      >
        <InputFormField
          name="code"
          placeholder={t("common.code")}
          type="text"
          hideLabel
          rules={[
            {
              required: confirmationTime > 0,
              message: t("validation.required_code"),
            },
          ]}
        />
        <div className="mb-8">
          {confirmationTime > 0 &&
            t("template.you_have_number_seconds_to_confirm_your_email", {
              number: confirmationTime,
            })}
        </div>
        <LoadingButton
          size="large"
          className="full-width"
          type={confirmationTime > 0 ? "primary" : "light"}
          submit
          loading={isLoading}
        >
          <Flex justify="center" align="center">
            <span className="mr-13 fw-600">
              {t(confirmationTime > 0 ? "common.submit" : "common.resend_code")}
            </span>
            <ArrowRight size="14" />
          </Flex>
        </LoadingButton>
        <h4 className="mt-15">
          {t("common.go_to")}{" "}
          <Link to="/login" className={bem("link")}>
            {t("common.login")}
          </Link>
        </h4>
      </Form>
    </AuthContainer>
  );
};
