import models from "models";
import { GenericObject } from "types";

import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const paymentMethods = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/organization/payment-methods", queryParams);
    const { data } = await apiAxios.get<models.WithResults<any>>(url);
    return data;
  },
  patch: async (id: number, body: models.PaymentProvider) => {
    const { data } = await apiAxios.patch<models.PaymentProvider>(
      `/organization/payment-methods/${id}`,
      body,
    );
    return data;
  },
  post: async (body: models.PaymentProvider) => {
    const { data } = await apiAxios.post<models.PaymentProvider>(
      `/organization/payment-methods`,
      body,
    );
    return data;
  },
  postApiKeys: async (id: number, body: models.PaymentKeys) => {
    const { data } = await apiAxios.post<models.PaymentKeys>(
      `/organization/payment-methods/${id}/api-key`,
      body,
    );
    return data;
  },
};
