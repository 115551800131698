import { CSSProperties } from "react";
import { CURRENCY_CODE, CURRENCY_SYMBOL } from "app-constants";

export type GenericObject<T = any> = { [key in string | number | symbol]: T };

export type DynamicCssProperties = CSSProperties & GenericObject;

export interface FormControl<T> {
  value: T | undefined;
  onChange: React.Dispatch<T | undefined>;
}
export interface TabsEntity {
  label: JSX.Element;
  content: JSX.Element;
  states?: string[];
  access?: boolean;
  disabled?: boolean;
}

export type CurrencySymbolType = keyof typeof CURRENCY_SYMBOL;
export type CurrencyCodeType = keyof typeof CURRENCY_CODE;

export const checkboxesCheckedInfo: { [key: string]: string[] } = {
  checkboxesCheckedOnPage: [],
  allCheckedCheckboxes: [],
  checkboxesOnPage: [],
};

export type CheckboxesCheckedInfoType = typeof checkboxesCheckedInfo;

export type Properties = { [key: string]: any };

export type VersionType = 1 | 2;

export enum EnvApi {
  API_BASE_URL = "STATIC_API",
  API_MAIN_URL = "BASE_API",
  WEB_APP_URL = "WEB_APP_URL",
}

export enum KeyMessages {
  TRANSLATIONS = "This key not have any translations.",
  FIELD = "This field does not have a translation.",
}
