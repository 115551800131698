import { Button, Space, Switch, Tooltip } from "ebs-design";
import { TFunction } from "react-i18next";

import { ColumnSorter, Flex, Status, ThumbnailImage } from "components";
import * as Icons from "components/icons";
import models from "models";
import { CurrencySymbolType } from "types";
import { formatPrice, formatPercent, TableColumnBuilder } from "utils";

interface Props {
  ordering: object;
  setOrdering: React.Dispatch<any>;
  t: TFunction;
}

export const organizationDiagnosticsColumns = ({
  ordering,
  setOrdering,
  t,
}: Props) =>
  new TableColumnBuilder<any>()
    .add(t("common.sequence"), "id")
    .add(t("common.criteria"), "title")
    .add(
      <ColumnSorter
        right
        title={t("price.deduction_in_percentage")}
        column="price_percentage"
        ordering={ordering}
        setOrdering={setOrdering}
      />,
      "price_percentage",
      (price) => formatPercent(price),
    )
    .getColumns();

export const serviceManagementColumns = (
  serviceModal: React.Dispatch<any>,
  translateServiceModal: React.Dispatch<any>,
  currency: CurrencySymbolType,
  t: TFunction,
) =>
  new TableColumnBuilder<models.ExtraService>()
    .add(t("common.service_name"), "title")
    .add(t("common.short_description"), "description")
    .add(t("common.image"), "image", (image: models.File) => (
      <ThumbnailImage file={image} background />
    ))
    .add(t("price.price"), "price", (price) => formatPrice(price, currency))
    .add(t("price.price_translation"), "price_translation")
    .add(
      t("common.email_notification"),
      "",
      (row: models.ExtraService) =>
        row.is_email_notification_active
          ? row?.emails?.join(", ")
          : t("common.no"),
      { width: 200 },
    )
    .add(t("common.mandatory"), "is_mandatory", (mandatory) =>
      mandatory ? t("common.yes") : t("common.no"),
    )
    .add(t("common.status"), "is_active", (status) => (
      <Status status={status} />
    ))
    .add("", "", (row) => (
      <Flex justify="flex-end">
        <Space>
          <Button
            type="light"
            onClick={() => serviceModal(row)}
            icon={() => <Icons.Edit size="14px" />}
          />
          <Tooltip placement="top" title={t("common.translate_service")}>
            <Button
              type="light"
              onClick={() => translateServiceModal(row)}
              icon={() => <Icons.Language size="14px" />}
            />
          </Tooltip>
        </Space>
      </Flex>
    ))
    .getColumns();

export const feesColumns = (
  feesModal: React.Dispatch<models.Fees>,
  currency: CurrencySymbolType,
  t: TFunction,
) =>
  new TableColumnBuilder<models.Fees>()
    .add(t("fee.fee_name"), "title")
    .add(t("common.description"), "description")
    .add(t("price.price"), "price", (price) => formatPrice(price, currency))
    .add(t("price.price_translation"), "price_translation")
    .add(t("common.status"), "is_active", (status) => (
      <Status status={status} />
    ))
    .add("", "", (row) => (
      <Button
        type="light"
        onClick={() => feesModal(row)}
        icon={() => <Icons.Edit size="14px" />}
      />
    ))
    .getColumns();

export const taxDetailsColumns = (
  taxDetailsModal: React.Dispatch<models.OrganizationSettings>,
  t: TFunction,
) =>
  new TableColumnBuilder<models.OrganizationSettings>()
    .add(t("common.country"), ["country", "name"])
    .add(t("common.city"), ["city", "name"])
    .add(t("finance.registration_number"), "registration_number")
    .add(t("finance.vat_number"), "vat_number")
    .add(t("finance.tax_number"), "tax_number")
    .add("", "", (row) => (
      <Button
        type="light"
        onClick={() => taxDetailsModal(row)}
        icon={() => <Icons.Edit size="14px" />}
      />
    ))
    .getColumns();

export const bankDetailsColumns = (
  bankAccountModal: React.Dispatch<models.OrganizationSettings>,
  deleteModal: React.Dispatch<models.BankDetails>,
  t: TFunction,
) =>
  new TableColumnBuilder<models.BankDetails>()
    .add(t("common.title"), "title")
    .add(t("finance.swift"), "swift")
    .add(t("finance.iban"), "iban")
    .add(t("common.currency"), ["currency", "code"])
    .add("", "", (row) => (
      <Space justify="end">
        <Button
          type="light"
          onClick={() => bankAccountModal(row)}
          icon={() => <Icons.Edit size="14px" />}
        />
        <Button
          type="light"
          onClick={() => deleteModal(row)}
          icon={() => <Icons.Trash size="14px" />}
        />
      </Space>
    ))
    .getColumns();

export const languageColumns = (
  t: TFunction,
  handleChange: (lang) => void,
  isLoading: boolean,
  search: string,
) =>
  new TableColumnBuilder<any>()
    .add(t("common.id"), "", (row) => (
      <span style={{ color: row.is_active ? "" : "var(--grey-100)" }}>
        {row.id}
      </span>
    ))
    .add(t("common.language"), "", (row) => (
      <span style={{ color: row.is_active ? "" : "var(--grey-100)" }}>
        {row.language}
      </span>
    ))
    .add(t("common.is_active"), "", (row) =>
      row.is_active ? (
        <span style={{ color: "var(--success-500)" }}>{t("common.yes")}</span>
      ) : (
        <span style={{ color: "var(--grey-100)" }}>{t("common.no")}</span>
      ),
    )
    .add(t("common.actions"), "code", (value, row) => {
      const isMutating = search?.includes(value) && isLoading;

      return (
        <>
          {isMutating ? (
            <Button disabled type="text" loading />
          ) : (
            <Switch
              disabled={row?.is_default || isLoading}
              checked={row?.is_active}
              onChange={() => {
                handleChange(row?.language);
              }}
            />
          )}
        </>
      );
    })
    .getColumns();
