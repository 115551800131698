import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import {
  Flex,
  InputFormField,
  LoadingButton,
  SwitchFormField,
  WhiteSpace,
} from "components";
import models from "models";
import { notifyErrors } from "utils";

interface EditDepartmentModalProps extends ModalProps {
  data?: models.Department;
  onClose: () => void;
}

// FIXME: Refactor this component for department form
export const DepartmentFormModal = ({
  data,
  onClose,
  ...props
}: EditDepartmentModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (body: models.Department) =>
      data && data.id
        ? api.departments.patch(data.id, body)
        : api.departments.post(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.departments.many());
        notify.success({
          title: t(
            data
              ? "template.item_updated_successfully"
              : "template.item_created_successfully",
            { item: t("department.department") },
          ),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Modal
      title={`${data ? t("common.edit") : t("common.add")} ${t(
        "department.department",
      )}`}
      size="small"
      onClose={onClose}
      className="edit-service-modal"
      {...props}
    >
      <Form
        form={form}
        name="department"
        onFinish={mutate}
        className="service-form"
        initialValues={data}
      >
        <Modal.Content>
          <InputFormField name="title" label={t("common.name")} />
          <InputFormField
            name="description"
            label={t("common.description")}
            textarea
          />
          <SwitchFormField
            labelOptions={{ col: 1 }}
            controlOptions={{
              col: 1,
              justify: "end",
              align: "center",
              className: "d-flex",
            }}
            name="is_active"
            label={t("common.active")}
          />
        </Modal.Content>

        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton loading={isLoading} submit type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
