import { AxiosError } from "axios";
import { Form, useForm, useNotify } from "ebs-design";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

import api from "api";
import { AuthContainer, Flex, LoadingButton, NoAutoComplete } from "components";
import { InputFormField, InputPasswordShowField } from "components/form-fields";
import { ArrowRight } from "components/icons";
import models from "models";
import {
  confirmPasswordValidatorRule,
  makeBEM,
  notifyErrors,
  passwordValidatorRule,
} from "utils";

const bem = makeBEM("auth-layout");

export const CompleteInvitation = () => {
  const { t } = useTranslation();
  const [query] = useQueryParams({
    email: StringParam,
    token: StringParam,
    hash: StringParam,
  });

  const [form] = useForm();
  const notify = useNotify();
  const history = useHistory();

  const { mutate, isLoading } = useMutation(
    async (values: models.RegisterCredentials) => api.authApi.register(values),
    {
      onSuccess: () => {
        history.push(`/complete-invitation-confirm${history.location.search}`);
      },
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  if (!query.token && !query.hash) history.push("/login");

  return (
    <AuthContainer
      header={
        <h3>{t("common.complete_your_invitation_in_the_buyback_account")}</h3>
      }
    >
      <Form
        form={form}
        onFinish={(values: models.RegisterCredentials) =>
          mutate({
            ...values,
            username: query.email as string,
            token: query.token as string,
            redirect_url: `${window.location.origin}/complete-invitation-confirm`,
          })
        }
      >
        <NoAutoComplete />
        <InputFormField
          name="first_name"
          placeholder={t("common.first_name")}
          type="text"
          hideLabel
          rules={[
            {
              required: true,
              message: t("validation.first_name"),
            },
          ]}
        />
        <InputFormField
          name="last_name"
          placeholder={t("common.last_name")}
          type="text"
          hideLabel
          rules={[
            {
              required: true,
              message: t("validation.last_name"),
            },
          ]}
        />
        <InputPasswordShowField
          size="large"
          placeholder={t("common.password")}
          name="password"
          validateTrigger="onSubmit"
          rules={[{ validateTrigger: "onSubmit" }, passwordValidatorRule(t)]}
        />

        <InputPasswordShowField
          size="large"
          placeholder={t("common.confirm_password")}
          name="confirm_password"
          validateTrigger="onSubmit"
          rules={[
            { validateTrigger: "onSubmit" },
            confirmPasswordValidatorRule(form, t),
          ]}
        />

        <LoadingButton
          size="large"
          className="full-width"
          type="primary"
          submit
          loading={isLoading}
        >
          <Flex justify="center" align="center">
            <span className="mr-13 fw-600">{t("common.sign_up")}</span>
            <ArrowRight size="14" />
          </Flex>
        </LoadingButton>
        <h4 className="mt-15">
          {t("common.go_to")}{" "}
          <Link to="/login" className={bem("link")}>
            {t("common.login")}
          </Link>
        </h4>
      </Form>
    </AuthContainer>
  );
};
