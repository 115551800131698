import { ConfirmationModal, Flex, Table } from "components";
import { Button, Card, useNotify } from "ebs-design";
import { useModalState } from "hooks";
import models from "models";
import { useTranslation } from "react-i18next";
import api, { querykeys } from "api";
import { useMutation, useQueryClient } from "react-query";
import { notifyErrors } from "utils";

import { bankDetailsColumns, taxDetailsColumns } from "../config";
import { TaxDetailsModal, BankAccountModal } from ".";

export interface Props {
  organizationData?: models.OrganizationSettings;
}

export const FinancialCard = ({ organizationData }: Props) => {
  const { t } = useTranslation();
  const taxDetailsModal = useModalState<models.OrganizationSettings>();
  const bankAccountModal = useModalState<models.OrganizationSettings>();
  const deleteModal = useModalState<models.BankDetails>();

  const notify = useNotify();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => api.settings.bankingDetails.deleteById(deleteModal?.data?.id!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.settings.organization);
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("finance.banking_details"),
          }),
        });
        deleteModal.close();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <>
      <Card className="mb-24" style={{ gridColumn: "1 / 3" }}>
        <Card.Header>
          <Flex align="center" justify="space-between">
            <h3>{t("finance.tax_details")}</h3>
          </Flex>
        </Card.Header>
        <Card.Body>
          <Table
            variants={["white-title"]}
            showHeader
            data={[organizationData]}
            columns={taxDetailsColumns(taxDetailsModal.openWith, t)}
          />
        </Card.Body>
      </Card>

      <Card style={{ gridColumn: "1 / 3" }}>
        <Card.Header>
          <Flex align="center" justify="space-between">
            <h3>{t("finance.banking_details")}</h3>
          </Flex>
        </Card.Header>
        <Card.Body>
          <Table
            variants={["white-title"]}
            showHeader
            data={organizationData?.banking_details}
            columns={bankDetailsColumns(
              bankAccountModal.openWith,
              deleteModal.openWith,
              t,
            )}
          />
          <Flex justify="flex-end" className="mt-16">
            <Button type="primary" onClick={bankAccountModal.open}>
              {t("template.add_item", {
                item: t("common.bank_account_details").toLowerCase(),
              })}
            </Button>
          </Flex>
        </Card.Body>
      </Card>

      {taxDetailsModal.isOpen && (
        <TaxDetailsModal
          open={taxDetailsModal.isOpen}
          onClose={taxDetailsModal.close}
          data={taxDetailsModal.data}
          closeOnClickOutside={false}
        />
      )}
      {bankAccountModal.isOpen && (
        <BankAccountModal
          open={bankAccountModal.isOpen}
          onClose={bankAccountModal.close}
          data={bankAccountModal.data}
          closeOnClickOutside={false}
        />
      )}
      {deleteModal.isOpen && (
        <ConfirmationModal
          open={deleteModal.isOpen}
          onClose={deleteModal.close}
          onConfirm={mutation.mutate}
          isLoading={mutation.isLoading}
          title={t("template.delete_item", {
            item: t("common.bank_account_details").toLowerCase(),
          })}
        >
          <h4 className="text-center">
            {t("template.are_you_sure_you_want_to_delete_this_item", {
              item: t("common.bank_account_details").toLowerCase(),
            })}
          </h4>
        </ConfirmationModal>
      )}
    </>
  );
};
