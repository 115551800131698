import { useTranslation } from "react-i18next";
import { Space } from "ebs-design";

import {
  CardInfo,
  DiagnosticCriteria,
  Divider,
  Flex,
  OrderStatus,
  UserInfo,
  Grid,
} from "components";
import models from "models";
import {
  capitalizeString,
  formatAddressInJSX,
  formatPercent,
  formatPrice,
  formattedDateTime,
} from "utils";
import { useDataErasureVisibility } from "hooks";
import React from "react";

interface OrderFieldProps {
  label: React.ReactNode;
}

interface OrderDetailsCardProps {
  order: models.Order;
}

export const OrderField: React.FC<OrderFieldProps> = ({ label, children }) => {
  return (
    <div>
      <div style={{ color: "var(--grey-400)", paddingBottom: "0.4rem" }}>
        {label}
      </div>
      <div>{children || "-"}</div>
    </div>
  );
};

export const OrderDetailsCard = ({ order }: OrderDetailsCardProps) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap="1.6rem">
      <Flex justify="space-between" gap="1rem">
        <Space wrap align="start" size={32}>
          <OrderField label={t("order.order_id")}>
            {order?.parent?.code}
          </OrderField>
          <OrderField label={t("order.suborder_id")}>{order?.code}</OrderField>
          <OrderField label={t("common.seller")}>
            <UserInfo name={order?.client?.name} email={order?.client?.email} />
          </OrderField>
          <OrderField label={t("common.arriving_date")}>
            {order.timestamp && (
              <span style={{ color: "var(--primary-400)" }}>
                {formattedDateTime(order.timestamp)}
              </span>
            )}
          </OrderField>
        </Space>

        <Space wrap align="start" justify="end" size={32}>
          <OrderField label={t("common.condition")}>
            <DiagnosticCriteria
              diagnosticCriteria={order?.device?.diagnostic_criteria || []}
            />
          </OrderField>

          <OrderField label={t("common.approval")}>
            <CardInfo
              left
              show={!!order?.device?.approval}
              infoWithFields={{
                state: capitalizeString(order?.device?.approval?.state, "_"),
                price: formatPrice(
                  order?.device?.approval?.price,
                  order?.currency,
                ),
                reason: order?.device?.approval?.reason,
                remarks: order?.device?.approval?.remark,
              }}
            />
          </OrderField>

          <OrderField label={t("order.order_status")}>
            <OrderStatus orderStatus={order.hidden_status} />
          </OrderField>

          <OrderField label={t("order.client_order_status")}>
            <OrderStatus orderStatus={order.status} />
          </OrderField>
        </Space>
      </Flex>
      <Divider />
      <Grid cols="1fr 1fr" gap="1.5rem">
        {/* Shipping column*/}
        <Grid cols="1fr 1fr" gap="1.5rem">
          {!!order?.drop_off_location && (
            <OrderField label={t("common.drop_off_location")}>
              <CardInfo
                left
                show={!!order?.drop_off_location}
                title={order?.drop_off_location?.title}
                info={[
                  order?.drop_off_location?.address,
                  order?.drop_off_location?.phone,
                ]}
              />
            </OrderField>
          )}
          {!!order?.shipping_provider && (
            <>
              <OrderField label={t("common.address")}>
                {formatAddressInJSX({
                  postalCountry: order.address?.country?.code2,
                  administrativeArea: order.address?.region?.name,
                  locality: order.address?.country?.name,
                  postalCode: order.address?.zipcode,
                  organization: order.address?.company,
                  addressLines: order.address?.address_lines,
                })}
              </OrderField>
              <OrderField label={t("shipping.shipping_provider")}>
                <CardInfo
                  left
                  show={!!order?.shipping_provider}
                  title={order?.shipping_provider?.title}
                  infoWithFields={{
                    type: order?.shipping_provider?.type,
                    price: formatPrice(
                      order?.shipping_provider?.price,
                      order?.currency,
                    ),
                  }}
                />
              </OrderField>
            </>
          )}
        </Grid>
        <Grid cols="1fr 1fr" gap="1.5rem">
          {/* Delivery column */}
          <OrderField label={t("common.delivery_address")}>
            {formatAddressInJSX({
              postalCountry: order.delivery_address?.country?.code2,
              administrativeArea: order.delivery_address?.region?.name,
              locality: order.delivery_address?.country?.name,
              postalCode: order.delivery_address?.zipcode,
              organization: order.delivery_address?.company,
              addressLines: order.delivery_address?.address_lines,
            })}
          </OrderField>
          <OrderField label={t("shipping.delivery_shipping_provider")}>
            <CardInfo
              left
              show={!!order?.delivery_shipping_provider}
              title={order?.delivery_shipping_provider?.title}
              infoWithFields={{
                type: order?.delivery_shipping_provider?.type,
                price: formatPrice(
                  order?.delivery_shipping_provider?.price,
                  order?.currency,
                ),
              }}
            />
          </OrderField>
        </Grid>
      </Grid>
    </Flex>
  );
};

export const OrderDetailsBottom = ({ order }: OrderDetailsCardProps) => {
  const { t } = useTranslation();
  const dataErasureVisibile = useDataErasureVisibility();

  const dataErasure = order?.services?.find(
    (item) => item?.code === models.ServiceCode.CERTUS_SOFTWARE_DATA_ERASURE,
  );

  const secureBox = order.services?.find(
    (item) => item.code === models.ServiceCode.SECURE_BOX,
  );

  return (
    <Flex gap={32}>
      {dataErasureVisibile && (
        <OrderField label={t("common.data_erasure")}>
          <CardInfo
            show={!!dataErasure}
            info={[formatPrice(dataErasure?.price, order?.currency)]}
          />
        </OrderField>
      )}

      <OrderField label={t("common.secure_box")}>
        <CardInfo
          left
          show={!!secureBox}
          info={[formatPrice(secureBox?.price, order?.currency)]}
        />
      </OrderField>

      <OrderField label={t("common.service_cost")}>
        <CardInfo
          show
          left
          info={[formatPrice(order?.cost, order?.currency)]}
        />
      </OrderField>

      <OrderField label={t("payment.payment_method")}>
        <CardInfo
          left
          show={!!order?.payment_method}
          title={order?.payment_method?.title}
          info={[
            order?.payment_method?.fee_type === models.FeeEnum.CURRENCY
              ? formatPrice(order?.fee, order?.currency)
              : formatPercent(order?.fee),
          ]}
        />
      </OrderField>
    </Flex>
  );
};
