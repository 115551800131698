export const hideEmail = (value?: string) => {
  if (!value) return;

  const email = value;
  let hiddenEmail = "";
  for (let i = 0; i < email.length; i++) {
    if (i > 0 && i < email.indexOf("@") - 1) {
      hiddenEmail += "*";
    } else {
      hiddenEmail += email[i];
    }
  }
  return hiddenEmail;
};
