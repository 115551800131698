import { Select } from "ebs-design";
import { DateType } from "ebs-design/dist/components/molecules/DatePicker/types";
import { OptionValue } from "ebs-design/dist/components/molecules/Select/interfaces";
import cn from "classnames";
import { Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeBEM } from "utils";
import { translateIfIsTranslatable } from "./../utils/translations";

interface selectOption {
  value: OptionValue;
  name: string;
}

interface SelectWithContentProps {
  content?: React.ReactNode;
  footer?: React.ReactNode;
  selectOption: selectOption[];
  passedInterval?: string;
  setPassedInterval?: Dispatch<string>;
  onChange?: (date: DateType) => void;
  resetDate?: () => void;
  placeholder?: string;
}

const bem = makeBEM("select-field");

export const SelectWithContent = ({
  content,
  footer,
  selectOption,
  passedInterval,
  setPassedInterval,
  onChange,
  resetDate,
  placeholder,
  ...props
}: SelectWithContentProps) => {
  const { t } = useTranslation();
  const [interval, setInterval] = useState<string>(
    selectOption[0].value as string,
  );

  if (passedInterval) {
    let lastOption: any = {
      value: passedInterval,
      name: passedInterval,
    };

    for (const [, value] of Object.entries(selectOption)) {
      if (value.value === passedInterval) lastOption = undefined;
    }
    selectOption = [...selectOption, lastOption || ""];
  }

  return (
    <Select
      mode="single"
      className={cn(bem(), "select-no-mp-right")}
      onChange={(value) => {
        if (!value) return;

        if (typeof value === "object") value = value[0];
        if (setPassedInterval) setPassedInterval(value as string);
        else setInterval(value as string);

        onChange?.(value as DateType);
      }}
      value={passedInterval || interval}
      {...props}
    >
      <Select.Options
        style={{ overflow: "auto" }}
        emptyLabel={t("common.not_found")}
      >
        {selectOption.map((item, i) => (
          <Select.Options.Item
            id={item.name?.includes("-") ? "custom-select" : ""}
            key={i}
            value={item.value}
          >
            {translateIfIsTranslatable(t, item.name as string, "all")}
          </Select.Options.Item>
        ))}
      </Select.Options>

      {content && <div className={bem("content")}>{content}</div>}
      {footer && <div className={bem("footer")}>{footer}</div>}
    </Select>
  );
};
