import { Card } from "ebs-design";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import api, { querykeys } from "api";
import { Flex, QueryTable } from "components";
// import models from "models";
import { addCorrectId, orderTableByColumn } from "utils";

import { organizationDiagnosticsColumns } from "../config";

export const OrganizationCard = () => {
  const { t } = useTranslation();
  let query = useQuery(querykeys.settings.diagnostic.many(), () =>
    api.settings.getDefaultCriteria(),
  );

  // const affectedPrice = query?.data?.results?.reduce(
  //   (total: number, obj: models.DiagnosticCriteria) =>
  //     Number(obj?.price_percentage) + total,
  //   0,
  // );

  const [ordering, setOrdering] = useState(null);
  const diagnosticTable = useMemo(() => {
    const results = addCorrectId(query?.data?.results);

    // sort by id if ordering is not set
    if (ordering && ordering[Object.keys(ordering)[0]] === null) {
      return orderTableByColumn(results, "id");
    }

    return orderTableByColumn(results, ordering);
  }, [query?.data?.results, ordering]);

  query.data = {
    ...query.data,
    results: diagnosticTable,
  };
  const tableColumns = useMemo(
    () => organizationDiagnosticsColumns({ ordering, setOrdering, t }),
    [ordering, setOrdering, t],
  );

  return (
    <Card className="full-height">
      <Card.Header>
        <Flex align="center" justify="space-between">
          <h3>{t("common.pricing_and_condition_definition")}</h3>
          <Link className="fw-500" to="/settings/diagnostic-criteria">
            {t("common.edit")}
          </Link>
        </Flex>
      </Card.Header>
      <Card.Body>
        {/* <Flex justify="space-between" className="mb-12">
          <span>
            {t("price.affected_price")}{" "}
            <span className="fw-600">({formatPercent(affectedPrice)})</span>
          </span>
          <span className="grey-400">
            {t("price.affected_price_cannot_be_higher_than_100")}
          </span>
        </Flex> */}
        <QueryTable
          variants={["white-title"]}
          showHeader
          query={query}
          columns={tableColumns}
        />
      </Card.Body>
    </Card>
  );
};
