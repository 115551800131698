import React from "react";
import { Form, Select, Checkbox } from "antd";
import { Properties } from "types";

import { formItemStyle } from "../helpers";

const Space = ({ item, lang, setFieldsValue }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "size"]}
        initialValue="medium"
        label="Size"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="none">None</Select.Option>
          <Select.Option value="extra-small">Extra small</Select.Option>
          <Select.Option value="small">Small</Select.Option>
          <Select.Option value="medium">Medium</Select.Option>
          <Select.Option value="large">Large</Select.Option>
          <Select.Option value="extra-large">Extra large</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "direction"]}
        initialValue="horizontal"
        label="Direction"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="horizontal">Horizontal</Select.Option>
          <Select.Option value="vertical">Vertical</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "align"]}
        initialValue="center"
        label="Align"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="start">Start</Select.Option>
          <Select.Option value="end">End</Select.Option>
          <Select.Option value="center">Center</Select.Option>
          <Select.Option value="baseline">Baseline</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "justify"]}
        initialValue="center"
        label="Justify"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="start">Start</Select.Option>
          <Select.Option value="end">End</Select.Option>
          <Select.Option value="center">Center</Select.Option>
          <Select.Option value="space-around">Space around</Select.Option>
          <Select.Option value="space-between">Space between</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "wrap"]}
        valuePropName="checked"
        label="Wrap"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  wrap: !item.wrap,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "inline"]}
        valuePropName="checked"
        label="Inline"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  inline: !item.inline,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "reverse"]}
        valuePropName="checked"
        label="Reverse"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  reverse: !item.reverse,
                },
              },
            });
          }}
        />
      </Form.Item>
    </>
  );
};

export default Space;
