import { Button } from "ebs-design";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Tabs, Modal, Input, Row, Col } from "antd";
import faq, { QandA } from "apps/cms/lib/api/faq";
import { ArticleEditor } from "apps/cms/components/Articles";
import { LanguagesContext } from "apps/cms/context/Languages";
import { Flex, WhiteSpace } from "components";

const { TabPane } = Tabs;

interface FrequentlyAskedQuestionModalProps {
  id?: number;
  lang?: string;
  onClose: () => void;
  onSuccess: () => void;
}

const FrequentlyAskedQuestionModal: React.FC<
  FrequentlyAskedQuestionModalProps
> = ({ onClose, onSuccess, id, lang }) => {
  const { t } = useTranslation();
  const {
    languages: { data: languages },
  } = useContext(LanguagesContext);
  const [form] = Form.useForm();
  const [data, setData] = useState<QandA>();
  const [activeTabKey, setActiveTabKey] = useState("en");

  useEffect(() => {
    if (data) {
      Object.keys(data.i18n).forEach((lang) => {
        if (!Array.isArray(data.i18n[lang])) {
          form.setFieldsValue({
            i18n: {
              [lang]: data.i18n[lang],
            },
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (lang) {
      setActiveTabKey(lang);
    }
  }, [lang]);

  //getFieldDecorator('id', { initialValue: id });

  const fetch = useCallback(async () => {
    try {
      if (typeof id === "number") {
        setData(await faq.byId(id));
      }
    } catch (e) {
      console.error(e);
    }
  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const onChange = (key: string): void => setActiveTabKey(key);

  const handleSubmit = async (values: any): Promise<void> => {
    try {
      await faq.upsert({ id, ...values });

      onSuccess();
      onClose();

      Modal.success({ title: "Successfully applied changes." });
    } catch (e) {
      Modal.error({
        title: `${values.id ? "Edit" : "Create"} FAQ failed.`,
        content: e.message,
      });
    }
    // } else {
    //   let errorLang = '';
    //   Object.keys(err?.i18n as any).forEach((lang) => {
    //     Object.keys(err?.i18n[lang]).forEach((el) => {
    //       if (err?.i18n[lang][el]?.errors.length) {
    //         errorLang = lang;
    //       }
    //     });
    //   });
    //   if (errorLang) {
    //     onChange(errorLang);
    //   }
    // }
  };

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      footer={
        <Flex justify="end">
          <Button onClick={onClose}>{t("common.cancel")}</Button>
          <WhiteSpace h="10px" />
          <Button type="primary" submit form="vacancy">
            {t("common.save")}
          </Button>
        </Flex>
      }
      destroyOnClose
      width="60vw"
      bodyStyle={{ padding: 0 }}
    >
      <Form form={form} id="vacancy" onFinish={handleSubmit}>
        <Tabs
          className="custom-tabs"
          animated={false}
          activeKey={activeTabKey}
          onChange={onChange}
        >
          {languages.map(({ code }) => {
            const source = data?.i18n && data.i18n[code];
            const { question, answer } = source || {};

            return (
              <TabPane
                forceRender
                tab={code.toUpperCase()}
                key={code}
                style={{ padding: "0 16px" }}
              >
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item
                      name={["i18n", code, "question"]}
                      initialValue={question}
                      label="Question"
                    >
                      <Input placeholder={`Enter your question [${code}]`} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name={["i18n", code, "answer"]}
                      initialValue={answer}
                      label="Answer"
                    >
                      <ArticleEditor features={false} />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
            );
          })}
        </Tabs>
      </Form>
    </Modal>
  );
};

export default FrequentlyAskedQuestionModal;
