import { createSvgIcon } from "../utils";

export const ArrowLeft = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
  >
    <path d="M1 5L15 5L1 5Z" fill="currentColor" />
    <path
      d="M5 9L1 5M1 5L5 1M1 5L15 5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
