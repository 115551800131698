import { useState } from "react";
import { Bar, TooltipProps } from "recharts";
import { useTranslation } from "react-i18next";

import { BarChart, CustomTooltip } from "components";
import models from "models";
interface PayoutAndPaymentChartProps {
  data: models.PaymentPayout;
  currency: string;
}

type TooltipState = "payout" | "payment";
interface CustomTooltipComponentProps
  extends TooltipProps<string | number, string | number> {
  tooltip: TooltipState;
  currency: string;
}

const CustomTooltipComponent = ({
  active,
  payload,
  tooltip,
  currency,
}: CustomTooltipComponentProps) => {
  if (!active || !tooltip) return null;

  for (const { value, ...data } of payload)
    if (data.dataKey === tooltip)
      return <CustomTooltip icon value={currency + value} {...data} />;
  return null;
};

export const PayoutAndPaymentChart = ({
  data,
  currency,
}: PayoutAndPaymentChartProps) => {
  const { t } = useTranslation();

  const [tooltip, setTooltip] = useState<TooltipState>();

  return (
    <BarChart
      data={data.statistics.map((v) => ({
        ...v,
        payment: Number(v.payment),
        payout: Number(v.payout),
      }))}
      suffix={currency}
      legend
      tooltip={<CustomTooltipComponent currency={currency} tooltip={tooltip} />}
      xAxisKey="date"
      clusterKey={["payout", "payment"]}
    >
      <Bar
        name={t("payout.payout")}
        dataKey="payout"
        fill="#62E4D3"
        onPointerEnter={() => setTooltip("payout")}
        onPointerLeave={() => setTooltip(undefined)}
      />
      <Bar
        name={t("payment.payment")}
        dataKey="payment"
        fill="#4B8FED"
        onPointerEnter={() => setTooltip("payment")}
        onPointerLeave={() => setTooltip(undefined)}
      />
    </BarChart>
  );
};
