import React from "react";
import { Form } from "antd";
import { Properties } from "types";

import { RowSelect } from "../Custom";
import { formItemStyle } from "../helpers";

const Generic = ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "id"]}
        className="hide"
        style={formItemStyle}
      >
        <input type="hidden" />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "type"]}
        className="hide"
        style={formItemStyle}
      >
        <input type="hidden" />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "order"]}
        className="hide"
        style={formItemStyle}
      >
        <input type="hidden" />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "col"]}
        initialValue={12}
        className="drop-tree__node-edit__col"
      >
        <RowSelect />
      </Form.Item>
    </>
  );
};

export default Generic;
