import { Button } from "ebs-design";
import { TFunction } from "react-i18next";

import { Flex, Status } from "components";
import * as Icons from "components/icons";
import models from "models";
import { formatPrice, formattedDateTime, TableColumnBuilder } from "utils";

interface ShippingsColumnsProps {
  EditShippingProviderModal: React.Dispatch<models.ShippingProvider>;
  currency: models.Currency;
  t: TFunction;
}
export const shippingsColumns = ({
  EditShippingProviderModal,
  currency,
  t,
}: ShippingsColumnsProps) =>
  new TableColumnBuilder<models.ShippingProvider>()
    .add(t("common.id"), "id")
    .add(t("shipping.shipping_provider_name"), "title")
    .add(t("common.logo"), "", (record) => (
      <div className="table-img">
        {record?.logo && <img src={record?.logo?.url} alt={record.title} />}
      </div>
    ))
    .add(t("price.price"), "", (row: models.ShippingProvider) => (
      <span className="nowrap">
        {Number(row?.price) === 0
          ? row.price_translation
          : formatPrice(row?.price, currency?.code)}
      </span>
    ))
    .add(t("common.creation_date"), "timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add(t("common.modified_date"), "edited_timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add(t("common.status"), "is_active", (status) => (
      <Status
        status={status}
        value={status ? models.StatusValue.ACTIVE : models.StatusValue.INACTIVE}
      />
    ))
    .add("", "", (row) => (
      <Flex justify="flex-end">
        <Button
          onClick={() => EditShippingProviderModal(row)}
          type="light"
          size="small"
          icon={() => <Icons.Edit size="small" />}
        />
      </Flex>
    ))
    .getColumns();

export const shippingProviderSortOptions = [
  { title: "Provider name", value: "title" },
];
