import { NO_AVAILABLE_ERRORS_MESSAGE } from "app-constants";
import { UseNotifyResult } from "types";

import { extractErrorMessages } from ".";

export function notifyErrors(notify: UseNotifyResult, error: any) {
  const errorMessages = extractErrorMessages(error);
  if (errorMessages.length)
    errorMessages.forEach((m) =>
      notify.error({
        title: m,
      }),
    );
  else if (error.message)
    notify.error({ title: "", description: error.message });
  else notify.error({ title: "", description: NO_AVAILABLE_ERRORS_MESSAGE });
}
