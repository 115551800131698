import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NO_VALUE_FALLBACK } from "app-constants";
import models from "models";
import { formatPrice, makeBEM, onErrorImage } from "utils";
import { CurrencyCodeType } from "types";

interface Props {
  price?: string;
  device?: models.Device;
  currency?: CurrencyCodeType;
}

const bem = makeBEM("device-card");

export const DeviceCard = ({ device, price, currency }: Props) => {
  const { t } = useTranslation();

  const updatedPrice = useMemo(() => {
    let percent = 0;
    device?.diagnostic_criteria.forEach((criteria) => {
      percent += criteria.is_correct
        ? parseFloat(criteria?.price_percentage)
        : 0;
    });

    return String((parseFloat(price) * (percent || 1)) / 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price]);

  return (
    <div className={bem()}>
      <div className={bem("image")}>
        <img
          src={device?.brand_model?.logo?.url || ""}
          alt="Device logo"
          onError={onErrorImage}
        />
      </div>
      <ul className={bem("grid")}>
        <li>
          <p className={bem("title")}>{t("common.imei")}</p>
          <p className={bem("value")}>{device?.imei || NO_VALUE_FALLBACK}</p>
        </li>
        <li></li>
        <li>
          <p className={bem("title")}>{t("brand.brand")}</p>
          <p className={bem("value")}>
            {device?.brand_model?.brand?.title || NO_VALUE_FALLBACK}
          </p>
        </li>
        <li>
          <p className={bem("title")}>{t("model.model")}</p>
          <p className={bem("value")}>
            {device?.brand_model?.title || NO_VALUE_FALLBACK}
          </p>
        </li>
        <li>
          <p className={bem("title")}>{t("common.storage")}</p>
          <p className={bem("value")}>
            {device?.storage_capacity || NO_VALUE_FALLBACK}
          </p>
        </li>
        <li>
          <p className={bem("title")}>{t("common.color")}</p>
          <p className={bem("value")}>{device?.color || NO_VALUE_FALLBACK}</p>
        </li>
        <li>
          <p className={bem("title")}>{t("price.max_price")}</p>
          <p className={bem("value")}>
            {formatPrice(price, currency) || NO_VALUE_FALLBACK}
          </p>
        </li>
        <li>
          <p className={bem("title")}>{t("price.updated_price")}</p>
          <p className={bem("value")}>
            {formatPrice(updatedPrice, currency) || NO_VALUE_FALLBACK}
          </p>
        </li>
      </ul>
    </div>
  );
};
