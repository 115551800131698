import { Button, useNotify } from "ebs-design";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import api from "api";
import { MAX_DOCUMENT_UPLOAD_SIZE_50 } from "app-constants";
import models from "models";
import { makeBEM, convertAndAddFileSizeMeasure, notifyErrors } from "utils";

interface ConfirmUploaderProps {
  fieldName: string;
  accept?: string;
  onConfirm: (f: any) => void;
  isLoading: boolean;
  buttonText?: string;
  maxDocSize?: number;
}

const bem = makeBEM("uploader");

export const ConfirmUploader = ({
  fieldName,
  accept,
  onConfirm,
  isLoading,
  buttonText = "Upload",
  maxDocSize = MAX_DOCUMENT_UPLOAD_SIZE_50,
}: ConfirmUploaderProps) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const maxDocSizeMB = `${(maxDocSize / 1024 / 1024).toFixed(2)} MB`;
  const [file, setFile] = useState<File>();
  const [fileExtension, setFileExtension] = useState<string>();

  let formData = useMemo(() => new FormData(), []);

  const mutation = useMutation(
    (body: models.Attachment) => api.attachments.organization.post(body),
    {
      onError: (error) => notifyErrors(notify, error),
    },
  );

  useEffect(() => {
    if (!!file) {
      setFileExtension(`.${file?.name?.split(".").pop()}`);
    }
  }, [file]);

  const handleInputChange = (v) => {
    if (!accept.split(",").includes(v.target.files[0].type)) {
      return notify.error({
        title: t("error.is_not_required_format"),
      });
    }
    if (v.target.files[0].size > maxDocSize) {
      return notify.error({
        title: t("error.max_size_limit") + maxDocSizeMB,
      });
    }

    setFile(v.target.files[0]);
  };

  const handleSubmit = async () => {
    const formDataFile = new FormData();
    formDataFile.append("file", file);
    const data = await mutation.mutateAsync(formDataFile);

    formData.delete(`${fieldName}_id`);
    formData.append(`${fieldName}_id`, String(data?.id));

    onConfirm(formData);
  };

  return (
    <div className={bem()}>
      <label className={bem("block")} htmlFor="uploader">
        <button className={bem("title", { black: !!file })}>
          {file ? (
            <>
              <span className={bem("file-name")}>
                {file.name.replace(fileExtension, "")}
              </span>
              <span
                className={bem("file-extension")}
              >{`${fileExtension} (${convertAndAddFileSizeMeasure(
                file.size,
              )})`}</span>
            </>
          ) : (
            t("file.no_file_chosen")
          )}
        </button>
        <div
          className={bem("button")}
          onClick={() => {
            setTimeout(() => {
              if (file) setFile(undefined);
            }, 0);
          }}
        >
          {file ? t("common.remove") : t("file.choose_file")}
        </div>
      </label>
      <input
        accept={accept}
        disabled={!!file}
        id="uploader"
        type="file"
        onChange={handleInputChange}
      />
      <Button
        className="one-line"
        type="primary"
        disabled={!file}
        loading={isLoading || mutation.isLoading}
        onClick={handleSubmit}
      >
        {buttonText}
      </Button>
    </div>
  );
};
