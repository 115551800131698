import * as dateFNS from "date-fns";
import { DATE_FORMAT, NO_VALUE_FALLBACK } from "app-constants";
import { DateType } from "ebs-design/dist/components/molecules/DatePicker/types";
import { getCurrentLocale } from "translation/utils";
import { capitalizeString } from "./capitalizeString";

export const formattedDate = (date?: string) => {
  return date
    ? new Date(date).toLocaleDateString(getCurrentLocale())
    : NO_VALUE_FALLBACK;
};

export const formattedTime = (date?: string) => {
  return date
    ? new Date(date).toLocaleTimeString(getCurrentLocale())
    : NO_VALUE_FALLBACK;
};

export const formattedDateTime = (date?: string) => {
  return date
    ? new Date(date).toLocaleString(getCurrentLocale())
    : NO_VALUE_FALLBACK;
};

export function dateToInputFormat(date?: Date | string) {
  date = new Date(date as Date);
  if (isNaN(date as unknown as number)) return "";

  return [
    ("0000" + date.getFullYear()).slice(-4),
    ("00" + (date.getMonth() + 1)).slice(-2),
    ("00" + date.getDate()).slice(-2),
  ].join("-");
}

export function getDateFormat(locale = "ro-RO") {
  const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());

  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case "day":
          return "dd";
        case "month":
          return "MM";
        case "year":
          return "yyyy";
        default:
          return obj.value;
      }
    })
    .join("");
}

export const roDateFormat = getDateFormat("ro-RO");

export const minDate = (d1: Date, d2: Date) =>
  new Date(d1) < new Date(d2) ? d1 : d2;
export const maxDate = (d1: Date, d2: Date) =>
  new Date(d1) > new Date(d2) ? d1 : d2;

export const checkIfDateIsLessThen = (date: string, periodInAge: number) => {
  const initialDate = new Date(date);

  const todayMinusPeriodInAge = new Date(
    new Date(new Date().setFullYear(new Date().getFullYear() - periodInAge)),
  );

  return initialDate < todayMinusPeriodInAge;
};

export const daysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};

export const firstDayOfThe = (
  period: "week" | "month" | "quarter" | "year",
  locale = getCurrentLocale(),
) => {
  const today: Date = new Date();

  switch (period) {
    case "week":
      return new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay() - 6,
      ).toLocaleDateString(locale);

    case "month":
      return new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1,
      ).toLocaleDateString(locale);
    case "quarter":
      return new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1,
      ).toLocaleDateString(locale);
    case "year":
      return new Date(today.getFullYear() - 1, 0, 1).toLocaleDateString(locale);
  }
};

export const lastDayOfThe = (
  period: "week" | "month" | "quarter" | "year",
  locale = getCurrentLocale(),
) => {
  const today: Date = new Date();

  switch (period) {
    case "week":
      return new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay(),
      ).toLocaleDateString(locale);

    case "month":
      return new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        daysInMonth(today.getMonth(), today.getFullYear()),
      ).toLocaleDateString(locale);
    case "quarter":
      return new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        daysInMonth(today.getMonth(), today.getFullYear()),
      ).toLocaleDateString(locale);
    case "year":
      return new Date(today.getFullYear() - 1, 11, 31).toLocaleDateString(
        locale,
      );
  }
};

export const parseDate = (
  value: DateType | Date,
  date_format: string = DATE_FORMAT[getCurrentLocale()],
  date: Date = new Date(),
) => {
  return dateFNS.parse(value as string, date_format, date);
};

export const getWeekOfMonth = (value: string) =>
  dateFNS.getWeekOfMonth(new Date(value), { weekStartsOn: 1 });

export const getLocaleMonth = (value: string, capitalize = true) => {
  const month = new Date(value).toLocaleString(getCurrentLocale(), {
    month: "long",
  });
  return capitalize ? capitalizeString(month) : month;
};

export const getSettingDate = (days = 0, locale = getCurrentLocale()) => {
  const date = new Date();
  date.setDate(date.getDate() - days);

  return date.toLocaleDateString(locale);
};