import { AxiosError } from "axios";
import { Button, Card, useNotify } from "ebs-design";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { NumberParam } from "use-query-params";

import models from "models";
import { formattedDateTime, notifyErrors } from "utils";
import { useModalState, useParam } from "hooks";
import {
  DeleteModal,
  Flex,
  Status,
  ThumbnailImage,
  WhiteSpace,
} from "components";
import * as Icons from "components/icons";
import api from "api";

import { ShowVisibility } from "./ShowVisibility";
import { BrandModal } from "./BrandModal";

export interface BrandCardProps {
  data: models.OrganizationBrand;
}

export const BrandCard = ({ data }: BrandCardProps) => {
  const { t } = useTranslation();
  const brandModal = useModalState<models.OrganizationBrand>();
  const deleteBrand = useModalState<models.OrganizationBrand>();
  const notify = useNotify();
  const history = useHistory();
  const brandId =
    useParam("id", () => NumberParam.decode(`${data?.brand_id}`)) || 0;

  const deleteMutation = useMutation(() => api.brands.deleteByID(brandId), {
    onSuccess: () => {
      notify.success({
        title: t("template.item_deleted_successfully", {
          item: t("brand.brand"),
        }),
      });
      history.push("/product-management/brands");
    },
    onError: (errors: AxiosError) => notifyErrors(notify, errors),
  });

  return (
    <>
      <Card>
        <Card.Body>
          <table className="brand-table">
            <thead>
              <tr>
                <th>{t("brand.brand_id")}</th>
                <th>{t("brand.brand_name")}</th>
                <th>{t("brand.brand_logo")}</th>
                <th>{t("common.status")}</th>
                <th>{t("common.main_page")}</th>
                <th>{t("common.creation_date")}</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data?.brand_id}</td>
                <td>{data?.title}</td>
                <td>
                  <ThumbnailImage file={data?.logo} background />
                </td>
                <td>
                  <Status status={data?.is_active} />
                </td>
                <td>
                  <ShowVisibility isVisible={data?.is_displayed} />
                </td>
                <td>{formattedDateTime(data?.timestamp)}</td>
                <td>
                  <Flex align="center">
                    <Button
                      size="small"
                      icon={() => <Icons.Edit size="small" />}
                      type="light"
                      onClick={() => brandModal.openWith(data)}
                    />
                    <WhiteSpace h="16px" />
                    <Button
                      size="small"
                      icon={() => <Icons.Trash size="small" />}
                      type="light"
                      onClick={deleteBrand.open}
                    />
                  </Flex>
                </td>
              </tr>
            </tbody>
          </table>
        </Card.Body>
      </Card>
      {brandModal.isOpen && (
        <BrandModal
          open={brandModal.isOpen}
          onClose={brandModal.close}
          data={brandModal.data}
          closeOnClickOutside={false}
        />
      )}
      {deleteBrand.isOpen && (
        <DeleteModal
          open={deleteBrand.isOpen}
          onClose={deleteBrand.close}
          size="small"
          mutation={deleteMutation}
          title={t("template.delete_item", {
            item: t("brand.brand").toLowerCase(),
          })}
        >
          <div className="py-30">
            <h3 className="text-center">
              {`${t("template.are_you_sure_you_want_to_delete_this_item", {
                item: t("brand.brand").toLowerCase(),
              })} `}
              <span className="primary-color">{deleteBrand.data?.id}</span>?
            </h3>
          </div>
        </DeleteModal>
      )}
    </>
  );
};
