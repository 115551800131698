import React, { CSSProperties } from "react";
import { ColorMapKey, getColor } from "./utils";

export interface SvgIconProps extends React.SVGProps<SVGSVGElement> {
  color?: ColorMapKey;
  size?: CSSProperties["fontSize"];
  children: React.ReactElement<React.SVGProps<SVGSVGElement>>;
}

export const SvgIcon: React.FC<SvgIconProps> = ({
  style,
  color,
  children,
  size,
  ...restProps
}) => {
  return React.cloneElement(children, {
    style: { color: getColor(color), fontSize: size, ...style },
    ...restProps,
  });
};

SvgIcon.defaultProps = {
  size: "var(--icon-size)",
  width: "1em",
  height: "1em",
};
