import models from "models";
import { GenericObject } from "types";

import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const shippingProviders = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("organization/shipping-providers", queryParams);
    const { data } = await apiAxios.get<
      models.WithResults<models.ShippingProvider>
    >(url);
    return data;
  },
  patch: async (id: number, body: models.ShippingProvider) => {
    const { data } = await apiAxios.patch<models.ShippingProvider>(
      `organization/shipping-providers/${id}`,
      body,
    );
    return data;
  },
  post: async (body: models.ShippingProvider) => {
    const { data } = await apiAxios.post<models.ShippingProvider>(
      `organization/shipping-providers`,
      body,
    );
    return data;
  },
};
