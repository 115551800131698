import { useEffect, useState } from "react";
import { Button, ButtonGroup as ButtonGroupEBS } from "ebs-design";
import { ButtonProps } from "ebs-design/dist/components/atoms/Button/Button";

import { makeBEM } from "utils";

interface List<T> extends Omit<ButtonProps, "value"> {
  value: T;
  label: React.ReactNode;
}

export interface ButtonGroupProps<T = string | number> {
  list: List<T>[];
  onChange?: React.Dispatch<React.SetStateAction<T>>;
  disabledOnSelected?: boolean;
  initValue?: T;
}

const bem = makeBEM("button-group");

export const ButtonGroup = ({
  list,
  onChange,
  disabledOnSelected = true,
  initValue,
}: ButtonGroupProps) => {
  const [selected, setSelected] = useState<string | number>();

  useEffect(() => {
    setSelected(initValue || list[0].value);
  }, [initValue, list]);

  return (
    <ButtonGroupEBS className={bem()}>
      {list.map(({ label, value, ...props }, i) => (
        <Button
          className={bem("button", {
            disabled: value === selected && disabledOnSelected,
          })}
          type="text"
          disabled={value === selected && disabledOnSelected}
          key={i}
          {...props}
          onClick={() => {
            setSelected(value);
            onChange?.(value);
          }}
        >
          {label}
        </Button>
      ))}
    </ButtonGroupEBS>
  );
};
