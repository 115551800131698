import { Button, Card } from "ebs-design";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Flex, WhiteSpace } from "components";
import * as Icons from "components/icons";
import { makeBEM } from "utils";

const bem = makeBEM("success-card");

interface SuccessBrandRedirect {
  url: string;
  title: string;
}
interface SuccessBrandCardProps {
  type: "brands" | "models";
  message: string;
  redirect: SuccessBrandRedirect;
}
export const SuccessBrandCard = ({
  type,
  message,
  redirect,
}: SuccessBrandCardProps) => {
  const { t } = useTranslation();

  return (
    <Card size="small" className="success-card">
      <Card.Body>
        <div className={bem("body")}>
          <div className={bem("icon")}>
            <Icons.Checked size="25px" />
          </div>
          <h2 className={bem("title")}>{message}</h2>
          <Flex justify="center">
            <Link to={`/product-management/${type}`}>
              <Button type="light">{t("common.close")}</Button>
            </Link>
            <WhiteSpace h="8px" />
            <Link to={redirect.url}>
              <Button type="primary">{redirect.title}</Button>
            </Link>
          </Flex>
        </div>
      </Card.Body>
    </Card>
  );
};
