import cn from "classnames";

import { WAREHOUSE_STATE_OPTIONS } from "app-constants";
import { Flex } from "components";
import { createClassWithModifiers, makeBEM } from "utils";

interface InventoryStatusProps {
  status?: string;
}

const bem = makeBEM("status");

const inventoryStatusColors = {
  [WAREHOUSE_STATE_OPTIONS[0].value]: "success",
  [WAREHOUSE_STATE_OPTIONS[1].value]: "danger",
  [WAREHOUSE_STATE_OPTIONS[2].value]: "success",
  [WAREHOUSE_STATE_OPTIONS[3].value]: "danger",
};

export const InventoryStatus = ({ status }: InventoryStatusProps) => {
  const statusColor = {
    [createClassWithModifiers(bem(), [inventoryStatusColors[status]])]:
      !!status,
  };

  return (
    <Flex justify="start" align="center" className={cn(statusColor)}>
      <span className={bem("circle mr-5")} />
      <span className="one-line">
        {
          WAREHOUSE_STATE_OPTIONS.filter((item) => item.key === status)?.[0]
            .value
        }
      </span>
    </Flex>
  );
};
