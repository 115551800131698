import { AxiosError } from "axios";
import { Button, Modal } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "react-query";

import { Flex, LoadingButton, WhiteSpace } from "components";

interface DeleteModalProps extends ModalProps {
  onClose: () => void;
  mutation: UseMutationResult<any, AxiosError<any, any>, void, unknown>;
}

export const DeleteModal = ({
  onClose,
  mutation,
  children,
  ...props
}: DeleteModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} {...props}>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Footer>
        <Flex justify="flex-end" align="center">
          <Button type="light" onClick={onClose}>
            {t("common.cancel")}
          </Button>
          <WhiteSpace h="8px" />
          <LoadingButton
            onClick={mutation.mutate}
            loading={mutation.isLoading}
            type="primary"
          >
            {t("common.delete")}
          </LoadingButton>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
