import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import {
  Divider,
  Flex,
  InputFormField,
  LoadingButton,
  SwitchFormField,
  UploadImage,
  WhiteSpace,
} from "components";
import api, { querykeys } from "api";
import models from "models";
import { GenericObject } from "types";
import { useMapErrors } from "hooks";

interface FormFileModalProps extends ModalProps {
  data?: models.Attachment;
  onClose: () => void;
}

export const FormFileModal = ({
  data,
  onClose,
  ...props
}: FormFileModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm<models.Attachment>();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const mapErrors = useMapErrors();

  const requestFunction = (id: number, values: models.Attachment) =>
    data
      ? api.attachments.organization.patch({ id: id, body: values })
      : api.attachments.organization.post(values);

  const { mutate, isLoading } = useMutation(
    (values: models.Attachment) => requestFunction(data?.id!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.attachments.many());
        notify.success({
          title: t("notification.changes_saved_successfully"),
        });
        onClose();
      },
      onError: (errors: any) => mapErrors(errors, notify, form),
    },
  );

  const handleFinish = (fieldsValue: GenericObject) => {
    const { tags, ...values } = fieldsValue;
    const formData = new FormData();

    let normalizedTags: { [key: string]: string } = {};

    if (tags && Array.isArray(tags)) {
      tags.forEach(
        (tag: string, index: number) =>
          (normalizedTags[`tags[${index}]code`] = tag),
      );
    }

    Object.entries({
      ...values,
      ...normalizedTags,
    }).forEach(([key, value]) => formData.append(key, value as any));

    mutate(formData);
  };

  return (
    <Modal
      title={
        data
          ? t("template.edit_item", { item: t("file.file").toLowerCase() })
          : t("file.upload_file")
      }
      onClose={onClose}
      {...props}
    >
      <Form
        form={form}
        name="file-service"
        onFinish={handleFinish}
        initialValues={{
          title: data?.title,
          tags: data?.tags?.map((v) => v.code),
          public: data?.public || false,
        }}
      >
        <Modal.Content>
          {!data && (
            <Form.Field name="file">
              <UploadImage formats={[]} />
            </Form.Field>
          )}
          <InputFormField name="title" label={t("file.file_name")} />
          <InputFormField
            name="tags"
            label={t("common.tags")}
            normalize={(value) => value.split(",")}
          />
          <Divider style={{ marginBottom: 10 }} />
          <SwitchFormField
            name="public"
            label={t("common.public")}
            labelOptions={{
              col: {
                size: 10,
              },
              className: "d-flex",
              align: "center",
            }}
            controlOptions={{
              col: { size: 2 },
              className: "d-flex",
              justify: "end",
              align: "center",
            }}
          />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton submit loading={isLoading} type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
