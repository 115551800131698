import { useState } from "react";
import { QueryParamConfigMap, SetQuery } from "use-query-params";
import { Button, DatePicker, Space } from "ebs-design";
import { DateType } from "ebs-design/dist/components/molecules/DatePicker/types";
import { OptionValue } from "ebs-design/dist/components/molecules/Select/interfaces";
import { useTranslation } from "react-i18next";
import { registerLocale } from "react-datepicker";
import * as locale from "date-fns/locale";
import { nanoid } from "nanoid";

import { CURRENT_LOCALE, DATE_FORMAT } from "app-constants";
import {
  firstDayOfThe,
  getSettingDate,
  lastDayOfThe,
  makeBEM,
  normalizeDataParams,
  parseDate,
} from "utils";
import { getCurrentLocale } from "translation/utils";
import { SelectWithContent } from "./SelectWithContent";

registerLocale(CURRENT_LOCALE.en, locale.enGB);
registerLocale(CURRENT_LOCALE.de, locale.de);
registerLocale(CURRENT_LOCALE.it, locale.it);

const past: Date = new Date("01/01/2020");
const today: Date = new Date();

interface FooterProps {
  resetDate: () => void;
  applyFilter: () => void;
}

interface SelectPeriodProps {
  action: SetQuery<QueryParamConfigMap>;
}

const Footer = ({ resetDate, applyFilter }: FooterProps) => {
  const { t } = useTranslation();

  return (
    <Space>
      <Button type="light" onClick={resetDate}>
        {t("common.clear")}
      </Button>
      <Button onClick={applyFilter} type="primary">
        {t("common.apply_filter")}
      </Button>
    </Space>
  );
};

const id = nanoid();
const bem = makeBEM("select-calendar");

export const SelectPeriod = ({ action }: SelectPeriodProps) => {
  const { t } = useTranslation();

  const [interval, setInterval] = useState<OptionValue | undefined>();
  const [startDate, setStartDate] = useState<Date | undefined>(today);
  const [endDate, setEndDate] = useState<Date | undefined>(today);

  let locale = getCurrentLocale();
  let dateFormat = DATE_FORMAT[locale];

  const daysInCurrentMonthUntilNow = new Date().getDate() - 1;
  const getStartDateOfTheYear = new Date(
    `${today.getFullYear()}`,
  ).toLocaleDateString(locale);

  const selectOption = [
    {
      value: `${past.toLocaleDateString(locale)}-${today.toLocaleDateString(
        locale,
      )}`,
      name: t("common.all"),
    },
    {
      value: `${getSettingDate()}-${getSettingDate()}`,
      name: t("time.today"),
    },
    {
      value: `${getSettingDate(1)}-${getSettingDate(1)}`,
      name: t("time.yesterday"),
    },
    {
      value: `${getSettingDate(7)}-${getSettingDate()}`,
      name: t("time.last_days_value", { value: 7 }),
    },
    {
      value: `${getSettingDate(30)}-${getSettingDate()}`,
      name: t("time.last_days_value", { value: 30 }),
    },
    {
      value: `${getSettingDate(
        daysInCurrentMonthUntilNow,
      )}-${getSettingDate()}`,
      name: t("time.this_month"),
    },
    {
      value: `${firstDayOfThe("month")}-${lastDayOfThe("month")}`,
      name: t("time.last_month"),
    },
    {
      value: `${getStartDateOfTheYear}-${getSettingDate()}`,
      name: t("time.this_year"),
    },
    {
      value: `${firstDayOfThe("year")}-${lastDayOfThe("year")}`,
      name: t("time.last_year"),
    },
  ];

  const resetDate = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setInterval(undefined);
  };
  const applyFilter = () => {
    document.getElementById("custom-select")!.click();
    normalizeDataParams(action, startDate, endDate);
  };

  const updateDatesFromInterval = (date: DateType) => {
    const [startDateFromInterval, endDateFromInterval] =
      String(date).split("-");

    if (startDateFromInterval)
      setStartDate(
        parseDate(parseDate(startDateFromInterval).toLocaleDateString(locale)),
      );

    if (endDateFromInterval)
      setEndDate(
        parseDate(parseDate(endDateFromInterval).toLocaleDateString(locale)),
      );

    normalizeDataParams(
      action,
      parseDate(parseDate(startDateFromInterval).toLocaleDateString(locale)),
      parseDate(parseDate(endDateFromInterval).toLocaleDateString(locale)),
    );
  };

  return (
    <SelectWithContent
      key={id}
      onChange={updateDatesFromInterval}
      selectOption={selectOption}
      passedInterval={interval as string}
      setPassedInterval={setInterval}
      resetDate={resetDate}
      content={
        <DatePicker
          open
          locale={locale}
          calendarStartDay={1}
          wrapperClassName={bem()}
          popperClassName={bem("popper")}
          dateFormat={dateFormat}
          startDate={startDate}
          endDate={endDate}
          onChange={(date) => {
            if (!date) return;

            if (!startDate && !endDate) {
              setStartDate(parseDate(date));
              setEndDate(parseDate(date));
            }

            const isInTheMiddle =
              startDate < parseDate(date) && parseDate(date) < endDate;

            if (isInTheMiddle) {
              setStartDate(parseDate(date));
              setEndDate(parseDate(date));
              const todayLocaleFormat =
                parseDate(date).toLocaleDateString(locale);
              setInterval(
                `${todayLocaleFormat}-${todayLocaleFormat}` as string,
              );
              return;
            }
            const isStartDate = parseDate(date) < startDate;

            isStartDate
              ? setStartDate(parseDate(date))
              : setEndDate(parseDate(date));

            setInterval((prevState) => {
              let arr: string[];

              if (String(prevState).includes("-")) {
                arr = String(prevState).split("-");
                return isStartDate ? `${date}-${arr[1]}` : `${arr[0]}-${date}`;
              } else {
                return isStartDate
                  ? `${date}-${today.toLocaleDateString(locale)}`
                  : `${today.toLocaleDateString(locale)}-${date}`;
              }
            });
          }}
        />
      }
      footer={<Footer resetDate={resetDate} applyFilter={applyFilter} />}
    />
  );
};
