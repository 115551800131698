import { PageTitle } from "components";
import { useTranslation } from "react-i18next";
import { PaymentsSettingsCard } from "../components";

export const Payments = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle to="/settings">{t("fee.currency_payments_fees")}</PageTitle>
      <PaymentsSettingsCard />
    </>
  );
};
