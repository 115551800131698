import { Sidebar } from "ebs-design";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { matchPath, useLocation } from "react-router-dom";

import { LanguagesStore } from "apps/cms/context/Languages";
import { ContentProvider } from "apps/cms/context/Content";

import { Restricted } from "components";
import * as Icons from "components/icons";
import models from "models";
import { useAuth, useDataErasureVisibility } from "hooks";

import { LayoutTemplate } from "./LayoutTemplate";

const adminRoles = [
  models.Roles.MAIN_ADMINISTRATOR,
  models.Roles.ADMINISTRATOR,
];

export const DashboardLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { roles } = useAuth();
  const isActive = (url: string | string[]): boolean =>
    !!matchPath(location.pathname, { path: url, exact: true });

  const dataErasureVisible = useDataErasureVisibility();

  const submenuCMS = (
    <Sidebar.Item
      prefix={<Icons.Document />}
      text={t("application.content_management")}
      active={isActive("/cms*")}
    >
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.menu")}
        onClick={() => history.push("/cms/menu")}
        active={isActive(["/cms/menu*"])}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.pages")}
        onClick={() => history.push("/cms/pages")}
        active={isActive(["/cms/pages*"])}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.banners")}
        onClick={() => history.push("/cms/banners")}
        active={isActive(["/cms/banners*"])}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.help_and_support")}
        onClick={() => history.push("/cms/faq")}
        active={isActive(["/cms/faq*"])}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.translations")}
        onClick={() => history.push("/cms/i18n")}
        active={isActive(["/cms/i18n*"])}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.files")}
        onClick={() => history.push("/cms/files")}
        active={isActive("/cms/files*")}
      />
    </Sidebar.Item>
  );

  const submenuAuth = (
    <Sidebar.Item
      prefix={<Icons.User />}
      text={t("application.user_management")}
      active={isActive("/auth*")}
    >
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.departments")}
        onClick={() => history.push("/auth/departments")}
        active={isActive("/auth/departments*")}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.internal_users")}
        onClick={() => history.push("/auth/internal-users")}
        active={isActive("/auth/internal-users*")}
      />
    </Sidebar.Item>
  );

  const submenuPM = (
    <Sidebar.Item
      prefix={<Icons.Devices />}
      text={t("menu.product_management")}
      active={isActive("/product-management*")}
    >
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.brands")}
        onClick={() => history.push("/product-management/brands")}
        active={isActive("/product-management/brands*")}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.models")}
        onClick={() => history.push("/product-management/models")}
        active={isActive("/product-management/models*")}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.inventory")}
        onClick={() => history.push("/product-management/inventory")}
        active={isActive("/product-management/inventory*")}
      />
    </Sidebar.Item>
  );

  const submenuCRM = (
    <Sidebar.Item prefix={<Icons.CRM />} text="CRM" active={isActive("/crm*")}>
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.buyback_orders")}
        onClick={() => history.push("/crm/orders/buyback-orders")}
        active={isActive("/crm/orders/buyback-orders*")}
      />
      {dataErasureVisible && (
        <Sidebar.Item
          className="sub-menu-item"
          text={t("menu.erasure_orders")}
          onClick={() => history.push("/crm/orders/erasure-orders")}
          active={isActive("/crm/orders/erasure-orders*")}
        />
      )}
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.pending_orders")}
        onClick={() => history.push("/crm/orders/warehouse-orders")}
        active={isActive("/crm/orders/warehouse-orders*")}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.invoices")}
        onClick={() => history.push("/crm/invoices/payments")}
        active={isActive("/crm/invoices/payments*")}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.payout")}
        onClick={() => history.push("/crm/invoices/payouts")}
        active={isActive("/crm/invoices/payouts*")}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.payment")}
        onClick={() => history.push("/crm/payment-providers")}
        active={isActive("/crm/payment-providers*")}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.customers")}
        onClick={() => history.push("/crm/clients")}
        active={isActive("/crm/clients*")}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.recycling_certificates")}
        onClick={() => history.push("/crm/recycling-certificates")}
        active={isActive("/crm/recycling-certificates*")}
      />
    </Sidebar.Item>
  );

  const submenuWarehouse = (
    <Sidebar.Item
      prefix={<Icons.Warehouses />}
      text={t("application.warehouse_service")}
      active={isActive("/warehouse*")}
    >
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.drop_off_locations")}
        onClick={() => history.push("/warehouse/drop-off-locations")}
        active={isActive("/warehouse/drop-off-locations*")}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.shipping")}
        onClick={() => history.push("/warehouse/shipping-providers")}
        active={isActive("/warehouse/shipping-providers*")}
      />
      <Sidebar.Item
        className="sub-menu-item"
        text={t("menu.process_locations")}
        onClick={() => history.push("/warehouse/warehouses")}
        active={isActive("/warehouse/warehouses*")}
      />
    </Sidebar.Item>
  );

  const sidebarMenu = (
    <Sidebar.TopMenu showToggle={false}>
      <Restricted toRoles={[models.Roles.ADMINISTRATOR]}>
        <Sidebar.Item
          prefix={<Icons.Dashboard />}
          text={t("menu.dashboard")}
          onClick={() => history.push("/")}
          active={isActive(["/", "/dashboard*"])}
        />
      </Restricted>

      <Restricted toApp={models.Apps.CRM_SERVICE}>{submenuCRM}</Restricted>

      <Restricted toApp={models.Apps.PM_SERVICE}>{submenuPM}</Restricted>

      <Restricted toApp={models.Apps.CMS_SERVICE}>{submenuCMS}</Restricted>

      <Restricted toApp={models.Apps.AUTH_SERVICE}>{submenuAuth}</Restricted>

      <Restricted toApp={models.Apps.WAREHOUSE_SERVICE}>
        {submenuWarehouse}
      </Restricted>

      {roles.every((role) => !adminRoles.includes(role.name)) && (
        <>
          <Restricted
            toApp={models.Apps.CRM_SERVICE}
            toRoles={[
              models.Roles.WAREHOUSE_SUPERVISOR,
              models.Roles.WAREHOUSE_OPERATOR,
            ]}
          >
            <Sidebar.Item
              prefix={<Icons.Orders />}
              text={t("menu.orders")}
              onClick={() => history.push("/crm/orders/warehouse-orders")}
              active={isActive("/crm/orders/warehouse-orders*")}
            />
          </Restricted>
        </>
      )}

      <Restricted>
        <Sidebar.Item
          prefix={<Icons.Settings />}
          text={t("menu.settings")}
          onClick={() => history.push("/settings")}
          active={isActive("/settings*")}
        />
      </Restricted>
    </Sidebar.TopMenu>
  );

  return (
    <LanguagesStore>
      <ContentProvider>
        <LayoutTemplate className="cms" menu={sidebarMenu}>
          {children}
        </LayoutTemplate>
      </ContentProvider>
    </LanguagesStore>
  );
};
