import sectionMain from "./sectionMain.png";
import sectionContent from "./sectionContent.png";
import componentContainer from "./componentContainer.png";
import componentSpace from "./componentSpace.png";
import moduleOrder from "./moduleOrder.png";
import moduleContactUs from "./moduleContactUs.png";
import moduleSearchModel from "./moduleSearchModel.png";
import moduleMap from "./moduleMap.png";
import moduleBlog from "./moduleBlog.png";
import bannerHome from "./bannerHome.png";
import cardHowItWorks from "./cardHowItWorks.png";
import bannerDownload from "./bannerDownload.png";
import bannerTrustUs from "./bannerTrustUs.png";
import bannerHelpSupport from "./bannerHelpSupport.png";
import templateConfirmation from "./templateConfirmation.png";
import templateInfo from "./templateInfo.png";
import templateText from "./templateText.png";
import cardBrands from "./cardBrands.png";
import joinUs from "./joinUs.png";

const preview = {
  sectionMain,
  sectionContent,
  componentContainer,
  componentSpace,
  moduleOrder,
  moduleContactUs,
  moduleSearchModel,
  moduleMap,
  moduleBlog,
  bannerHome,
  cardHowItWorks,
  bannerDownload,
  bannerTrustUs,
  bannerHelpSupport,
  templateConfirmation,
  templateInfo,
  templateText,
  cardBrands,
  joinUs
};

export default preview;
