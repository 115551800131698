import { Button } from "ebs-design";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSetState } from "react-use";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Row, Col, Tooltip } from "antd";
import {
  CopyOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MergeCellsOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
} from "@ant-design/icons";
import { Languages } from "apps/cms/lib/utils";
import { Properties } from "types";

import {
  copy,
  Drag,
  Drop,
  loopAll,
  loopObjects,
  normalizeTreeContent,
  read,
  titles,
} from "./components";

export interface BuilderType {
  activeTabKey?: Languages;
  value?: Properties[];
  loading?: boolean;
  onChange?: (values: Properties[]) => void;
}

export type StateType = {
  dragType?: string;
  expandedKeys: string[];
  selectedKey: string[];
  treeContent: Properties[];
  showPreview: boolean;
  isFullScreen: boolean;
  isDrag: boolean;
  mounted: boolean;
};

const Builder: React.FC<BuilderType> = (props) => {
  const { t } = useTranslation();

  const [state, setState] = useSetState<StateType>({
    dragType: undefined,
    expandedKeys: [],
    selectedKey: Object.keys(props.value || []).map((_, i) => i.toString()),
    treeContent: Object.keys(props.value || [])
      .reduce((obj: Properties[], id) => {
        let params;
        const activeValue = props.value[id][props.activeTabKey];

        loopObjects(
          props.value,
          id,
          (item) => (params = item),
          props.activeTabKey,
        );

        return [
          ...obj,
          {
            id,
            type: activeValue?.type,
            title: titles[activeValue?.type],
            ...params,
          },
        ];
      }, [])
      .sort(({ id: prevId }, { id: currentId }) =>
        props.value[prevId][props.activeTabKey]?.order >
        props.value[currentId][props.activeTabKey]?.order
          ? 1
          : props.value[currentId][props.activeTabKey]?.order >
            props.value[prevId][props.activeTabKey]?.order
          ? -1
          : 0,
      ),
    showPreview: false,
    isFullScreen: false,
    isDrag: false,
    mounted: false,
  });

  const escFunction = (event) => {
    if (event.key === "Escape") {
      setState({ isFullScreen: false });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  const onExpandAll = useCallback(() => {
    const expandedKeys: string[] = [];

    loopAll(state.treeContent, ({ id }) => expandedKeys.push(id));

    setState({ expandedKeys });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.treeContent]);

  useEffect(() => {
    if (props.value && props.activeTabKey) {
      setState({
        treeContent: Object.keys(props.value)
          .reduce((obj: Properties[], id) => {
            let params;
            const activeValue = props.value[id][props.activeTabKey];

            loopObjects(
              props.value,
              id,
              (item) => (params = item),
              props.activeTabKey,
            );

            return [
              ...obj,
              {
                id,
                type: activeValue?.type,
                title: titles[activeValue?.type],
                ...params,
              },
            ];
          }, [])
          .sort(({ id: prevId }, { id: currentId }) =>
            props.value[prevId][props.activeTabKey]?.order >
            props.value[currentId][props.activeTabKey]?.order
              ? 1
              : props.value[currentId][props.activeTabKey]?.order >
                props.value[prevId][props.activeTabKey]?.order
              ? -1
              : 0,
          ),
        mounted: true,
      });
      //onExpandAll();
    }

    return () => {
      setState({ mounted: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTabKey, props.value]);

  const handler = (state) => {
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const onSubmit = (values) => {
    props.onChange(values);
  };

  let data;

  if (props.value) {
    try {
      data = new URLSearchParams(
        Object.keys(props.value).reduce(
          (obj, id) => ({
            ...obj,
            [id]: JSON.stringify(props.value[id]),
          }),
          {},
        ),
      );
    } catch {
      //
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Row
        gutter={[16, 16]}
        className={`full-width${state.isFullScreen ? " full-screen" : ""}`}
        style={{ width: "calc(100% + 16px)" }}
      >
        <Col className="drag-tree__components" xs={24} md={5}>
          <Drag
            name="components"
            state={state}
            handler={handler}
            onDrag={() => setState({ isDrag: true })}
            onDrop={() => setState({ dragType: undefined, isDrag: false })}
          />
        </Col>
        <Col xs={24} md={19}>
          <Row
            className="drag-tree__bar"
            gutter={[8, 8]}
            justify="end"
            style={{ paddingBottom: 8 }}
          >
            <Col>
              <Row gutter={[4, 4]} align="middle">
                <Col>
                  <Button
                    type="light"
                    onClick={() =>
                      copy(
                        JSON.stringify({
                          state: state,
                          initialValue: props.value,
                        }),
                      )
                    }
                  >
                    <CopyOutlined className="pr-10" />
                    {t("common.copy")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="light"
                    onClick={() =>
                      read().then((val) => {
                        try {
                          const { state, initialValue } = JSON.parse(
                            val as string,
                          );
                          if (state && initialValue) {
                            setState(state);

                            onSubmit(
                              normalizeTreeContent(
                                state.treeContent,
                                initialValue,
                              ),
                            );
                          }
                        } catch (err) {}
                      })
                    }
                  >
                    <MergeCellsOutlined className="pr-10" /> {t("common.paste")}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[4, 4]} align="middle">
                <Col>
                  <Tooltip
                    placement="topLeft"
                    title={t("common.hide_internal_components")}
                  >
                    <Button
                      type="light"
                      onClick={() => setState({ expandedKeys: [] })}
                    >
                      <NodeCollapseOutlined className="pr-10" />{" "}
                      {t("common.collapse")}
                    </Button>
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip
                    placement="topLeft"
                    title={t("common.show_internal_components")}
                  >
                    <Button type="light" onClick={onExpandAll}>
                      <NodeExpandOutlined className="pr-10" />
                      {t("common.expand")}
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
            {/* <Col>
                <Button
                  type={state.isFullScreen ? 'showPreview' : 'default'}
                  onClick={() => setState({ showPreview: !state.showPreview })}
                >
                  <RocketOutlined /> {state.showPreview ? 'Hide' : 'Preview'}
                </Button>
              </Col> */}
            <Col>
              <Button
                type={state.isFullScreen ? "fill" : "light"}
                onClick={() => setState({ isFullScreen: !state.isFullScreen })}
              >
                {state.isFullScreen ? (
                  <FullscreenExitOutlined />
                ) : (
                  <FullscreenOutlined />
                )}
              </Button>
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ height: "100%" }}>
            <Col span={state.showPreview ? 12 : 24}>
              <Drop
                lang={props.activeTabKey}
                initialValue={props.value}
                loading={props.loading}
                state={state}
                isDrag={state.isDrag}
                handler={handler}
                onDrop={() => setState({ dragType: undefined, isDrag: false })}
                onSubmit={onSubmit}
              />
            </Col>

            {state.showPreview && data && (
              <Col span={12}>
                <iframe
                  src={`${
                    window.location.origin.includes("localhost")
                      ? `http://localhost:3000`
                      : process.env.REACT_APP_WEB_APP_URL
                  }/en/page/preview?${data.toString()}`}
                  title={t("common.preview")}
                  style={{
                    border: 0,
                    transform: "scale(0.5, 0.5) translate3d(-50%, -50%, 0)",
                    width: "200%",
                    height: "200%",
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </DndProvider>
  );
};

export default Builder;
