import { defaultTags } from "apps/cms/lib/api/tags";

import ArticlePage from "./ArticlePage";

export const filters = defaultTags.map(({ i18n, code }) => ({
  text: i18n.en,
  value: code,
}));

export default ArticlePage;
