import { formatAddress, Address } from "libs/localized-address-format";

export const formatAddressInJSX = (address: Address) => {
  if (formatAddress(address).length === 0) return "-";

  return (
    <address>
      {formatAddress(address).map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </address>
  );
};
