import { createSvgIcon } from "../utils";

export const FileImage = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="40"
    viewBox="0 0 30 40"
    fill="none"
  >
    <path
      d="M0.5 36V4C0.5 2.067 2.067 0.5 4 0.5H17.9C18.7987 0.5 19.663 0.845712 20.3138 1.46552L28.4138 9.1798C29.1074 9.8404 29.5 10.7564 29.5 11.7143V36C29.5 37.933 27.933 39.5 26 39.5H4C2.067 39.5 0.5 37.933 0.5 36Z"
      fill="white"
      stroke="#E5E9EB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 13C8.46957 13 7.96086 13.2107 7.58579 13.5858C7.21071 13.9609 7 14.4696 7 15V25C7 25.5304 7.21071 26.0391 7.58579 26.4142C7.96086 26.7893 8.46957 27 9 27H21C21.5304 27 22.0391 26.7893 22.4142 26.4142C22.7893 26.0391 23 25.5304 23 25V15C23 14.4696 22.7893 13.9609 22.4142 13.5858C22.0391 13.2107 21.5304 13 21 13H9ZM21 25H9L13 17L16 23L18 19L21 25Z"
      fill="#F8DD4E"
    />
  </svg>,
);
