import { CheckboxesCheckedInfoType } from "types";
import { Checkbox } from "./Checkbox";

interface Props {
  checkboxInfo: CheckboxesCheckedInfoType;
  setCheckboxInfo: React.Dispatch<
    React.SetStateAction<CheckboxesCheckedInfoType>
  >;
  itemId: string | number;
  wrap?: boolean;
}

export function TableCheckbox({
  checkboxInfo,
  setCheckboxInfo,
  itemId,
  wrap = true,
}: Props) {
  return (
    <div className="min-w-10">
      <Checkbox
        wrap={wrap}
        checked={checkboxInfo?.allCheckedCheckboxes?.includes(String(itemId))}
        onChange={() =>
          setCheckboxInfo((prevState): CheckboxesCheckedInfoType => {
            if (itemId === undefined) return prevState;
            const stringifiedId = String(itemId);

            if (prevState.allCheckedCheckboxes.includes(stringifiedId)) {
              return {
                ...prevState,
                checkboxesCheckedOnPage:
                  prevState.checkboxesCheckedOnPage.filter(
                    (item) => item !== stringifiedId,
                  ),
                allCheckedCheckboxes: prevState.allCheckedCheckboxes.filter(
                  (item) => item !== stringifiedId,
                ),
              };
            }

            return {
              ...prevState,
              checkboxesCheckedOnPage: [
                ...prevState.checkboxesCheckedOnPage,
                stringifiedId,
              ],
              allCheckedCheckboxes: [
                ...prevState.allCheckedCheckboxes,
                stringifiedId,
              ],
            };
          })
        }
      />
    </div>
  );
}
