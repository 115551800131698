import models from "models";
import { apiAxios } from "./axios";

export const profile = {
  get: async () => {
    const { data } = await apiAxios.get<models.User>(
      "/organization/user/profile"
    );

    return data;
  },
  patch: async (body: models.User) => {
    const { data } = await apiAxios.patch<models.User>(
      `/organization/user/profile`,
      body
    );
    return data;
  },
};
