import { Button, Form, Modal, Select, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import {
  Flex,
  Grid,
  InputFormField,
  LoadingButton,
  SwitchFormField,
  WhiteSpace,
} from "components";
import { useInvitationOptions, useMapErrors } from "hooks";
import models from "models";
import { ADMIN_ROLES, WAREHOUSE_ROLES } from "app-constants";
import {
  getUserApplications,
  servicesConfigs,
  translateIfIsTranslatable,
} from "utils";
import { ServiceRow } from "./ServiceRow";
interface EditStaffModalProps extends ModalProps {
  data?: models.User;
  onClose: () => void;
}

interface EditStaffFormProps {
  department_id?: number;
  email?: string;
  name?: string;
  roles_id?: number;
  warehouse_id?: number;
  applications?: models.Apps[];
}

const initialWHFields = {
  warehouse_id: null,
  department_id: null,
};

const getAppsFromFields = (fields: any) =>
  Object.entries(fields)
    .map(
      (v) =>
        Object.values(models.Apps).includes(v[0] as models.Apps) &&
        !!v[1] &&
        v[0],
    )
    .filter((m) => m) as models.Apps[];

export const EditStaffModal = ({
  data,
  onClose,
  ...props
}: EditStaffModalProps) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const mapErrors = useMapErrors();
  const queryClient = useQueryClient();
  const [form] = useForm<models.User>();

  const apps = getUserApplications(data?.profile?.applications);
  const { roles, applications, warehouses, departments } =
    useInvitationOptions();

  const initialRoleName = data?.profile?.roles[0]?.name;
  const initialRoleType = Object.values(models.AdminRoles).includes(
    initialRoleName,
  )
    ? models.RoleType.ADMIN
    : Object.values(models.WarehouseRoles).includes(initialRoleName)
    ? models.RoleType.WAREHOUSE
    : null;

  const [userRoleType, setUserRoleType] =
    React.useState<models.RoleType | null>(initialRoleType);
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);

  const { mutate, isLoading } = useMutation(
    (values: models.User) => api.users.update(data?.id!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.staff.many());
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("user.user_settings"),
          }),
        });
        onClose();
      },
      onError: (errors: any) => mapErrors(errors, notify, form),
    },
  );

  const resetPermissions = () =>
    form.setFieldsValue({
      ...initialWHFields,
      ...Object.assign(
        {},
        ...applications?.map((app) => ({
          [app.code]: null,
        })),
      ),
    });

  const handleFormChange = (
    _,
    { roles_id, department_id, warehouse_id, ...values }: EditStaffFormProps,
  ) => {
    if (!!!roles_id) {
      resetPermissions();
      setUserRoleType(null);
      return;
    }

    const roleText = roles?.find(({ value }) => value === roles_id).text;
    const currentUserRoleType = Object.values(ADMIN_ROLES).includes(roleText)
      ? models.RoleType.ADMIN
      : Object.values(WAREHOUSE_ROLES).includes(roleText)
      ? models.RoleType.WAREHOUSE
      : null;

    if (userRoleType !== currentUserRoleType) {
      resetPermissions();
    }

    setIsSubmitDisabled(
      !currentUserRoleType ||
        (currentUserRoleType === models.RoleType.ADMIN
          ? !getAppsFromFields(values)?.length
          : !(department_id && warehouse_id)),
    );

    setUserRoleType(currentUserRoleType);
  };

  return (
    <Modal
      title={`${t("common.edit")} - ${data?.name}`}
      onClose={onClose}
      {...props}
    >
      <Form
        form={form}
        name="service"
        onValuesChange={handleFormChange}
        onFinish={(values) =>
          mutate({
            ...values,
            applications: getAppsFromFields(values),
            roles_id: [values?.roles_id],
            ...(userRoleType === models.RoleType.ADMIN && initialWHFields),
          })
        }
        initialValues={{
          first_name: data?.first_name,
          last_name: data?.last_name,
          email: data?.email,
          department_id: data?.department?.id,
          roles_id: data?.profile?.roles
            ? data?.profile.roles[0].id
            : undefined,
          warehouse_id: data?.warehouse?.id,
          is_active: data?.is_active,
          ...apps,
        }}
      >
        <Modal.Content>
          <Grid cols="1fr 1fr" gap="0 16px">
            <InputFormField
              name="first_name"
              label={t("common.first_name")}
              rules={[{ required: true }]}
            />
            <InputFormField
              name="last_name"
              label={t("common.last_name")}
              rules={[{ required: true }]}
            />
          </Grid>
          <Grid cols="1fr 1fr" gap="0 16px">
            <InputFormField
              name="email"
              label={t("common.email")}
              type="email"
              rules={[{ required: true }]}
            />
            <Form.Field
              name="roles_id"
              label={t("common.role")}
              rules={[{ required: true }]}
            >
              <Select
                options={roles}
                size="large"
                mode="single"
                placeholder={t("template.select_item", {
                  item: t("common.role").toLowerCase(),
                })}
                onSelect={(value) =>
                  setUserRoleType(
                    Object.values(ADMIN_ROLES).includes(value[0].text as string)
                      ? models.RoleType.ADMIN
                      : Object.values(WAREHOUSE_ROLES).includes(
                          value[0].text as string,
                        )
                      ? models.RoleType.WAREHOUSE
                      : null,
                  )
                }
              />
            </Form.Field>
          </Grid>

          {userRoleType === models.RoleType.WAREHOUSE && (
            <>
              <Form.Field
                name="department_id"
                label={t("department.department")}
                rules={[{ required: true }]}
              >
                <Select
                  options={departments}
                  size="large"
                  placeholder={t("template.select_item", {
                    item: t("department.department").toLowerCase(),
                  })}
                />
              </Form.Field>
              <Form.Field
                name="warehouse_id"
                label={t("process_locations.process_locations")}
                rules={[{ required: true }]}
              >
                <Select
                  options={warehouses}
                  size="large"
                  placeholder={t("template.select_item", {
                    item: t(
                      "process_locations.process_locations",
                    ).toLowerCase(),
                  })}
                />
              </Form.Field>
            </>
          )}

          {userRoleType === models.RoleType.ADMIN && (
            <div>
              <label>{t("common.permission")}</label>
              {applications?.map((v, index) => {
                const serviceCode = v.code as keyof typeof servicesConfigs;
                return (
                  <React.Fragment key={index}>
                    <ServiceRow
                      icon={servicesConfigs[serviceCode].icon}
                      iconBg={servicesConfigs[serviceCode].iconBg}
                      title={translateIfIsTranslatable(
                        t,
                        servicesConfigs[serviceCode]?.title,
                        "application",
                      )}
                      name={servicesConfigs[serviceCode].name}
                      small
                    />
                    {applications?.length
                      ? index < applications?.length - 1 && (
                          <div className="horizontal-line mb-0" />
                        )
                      : null}
                  </React.Fragment>
                );
              })}
            </div>
          )}

          <SwitchFormField
            label={t("common.active")}
            name="is_active"
            labelOptions={{
              col: {
                size: 10,
              },
              className: "d-flex",
              align: "center",
            }}
            controlOptions={{
              col: { size: 2 },
              className: "d-flex",
              justify: "end",
              align: "center",
            }}
          />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton
              submit
              loading={isLoading}
              type="primary"
              disabled={isSubmitDisabled}
            >
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
