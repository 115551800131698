import { TimePicker, TimePickerProps } from "antd";
import moment from "moment";
import cn from "classnames";
import { makeBEM } from "utils";

export interface InputTimeProps
  extends Omit<TimePickerProps, "value" | "onChange"> {
  value?: string;
  onChange?: (val: string) => void;
}

const bem = makeBEM("input-time");

export const InputTime = ({
  onChange,
  value,
  className,
  ...props
}: InputTimeProps) => {
  const format = "HH:mm";

  return (
    <span className={bem()}>
      <TimePicker
        showSecond={false}
        showNow={false}
        getPopupContainer={(trigger) => trigger.parentElement}
        size="small"
        placeholder={null}
        suffixIcon
        clearIcon
        className={cn(bem("input"), className)}
        defaultValue={moment(value, format)}
        format={format}
        onChange={(_, val) => {
          onChange?.(val);
        }}
        {...props}
      />
    </span>
  );
};
