import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import models from "models";
import { notifyErrors } from "utils";
import { Flex, LoadingButton } from "components";
import { InputFormField, SelectOptions } from "components/form-fields";

interface BankAccountModalProps extends ModalProps {
  data?: models.OrganizationSettings;
  onClose: () => void;
}

export const BankAccountModal = ({
  data,
  onClose,
  ...props
}: BankAccountModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const isEdit = !!data;

  const { data: currencyOptions } = useQuery(
    querykeys.nomenclature.currency.many(),
    () => api.nomenclatures.currencies.get(),
    {
      select: (data) =>
        data.results.map((currency) => ({
          value: String(currency.id),
          name: currency.code,
        })),
    },
  );

  const { mutate, isLoading: isMutationLoading } = useMutation(
    (body: models.BankDetails) =>
      (isEdit
        ? api.settings.bankingDetails.updateById(data?.id, body)
        : api.settings.bankingDetails.create(body)) as any,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.settings.organization);
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("finance.banking_details"),
          }),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Modal
      size="small"
      title={t("common.bank_account_details")}
      onClose={onClose}
      {...props}
    >
      <Form
        form={form}
        onFinish={(v) =>
          mutate({
            ...v,
            currency_id:
              typeof v.currency === "object" ? v.currency.value : v.currency,
          })
        }
        className="service-form"
        initialValues={{
          ...data,
          currency: data?.currency.code,
        }}
      >
        <Modal.Content>
          <InputFormField
            name="title"
            label={t("common.title")}
            placeholder={t("common.title")}
            rules={[{ required: true }]}
          />
          <InputFormField
            name="iban"
            label={t("finance.iban")}
            placeholder={t("finance.iban")}
            rules={[{ required: true }]}
          />
          <InputFormField
            name="swift"
            label={t("finance.swift")}
            placeholder={t("finance.swift")}
            rules={[{ required: true }]}
          />
          <SelectOptions
            name="currency"
            label={t("common.currency")}
            selectOption={currencyOptions || []}
            rules={[{ required: true }]}
          />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button className="mr-8" type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <LoadingButton loading={isMutationLoading} submit type="primary">
              {isEdit ? t("common.save") : t("common.create")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
