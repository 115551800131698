import cn from "classnames";
import { CSSProperties } from "react";
import { Button } from "ebs-design";
import { Flex } from "components";
import { createClassWithModifiers, makeBEM } from "utils";
import { Edit } from "./icons";

interface SettingsCardProps {
  children?: React.ReactNode;
  title?: string;
  description?: React.ReactNode;
  icon?: React.ReactNode;
  imageUrl?: string;
  className?: string;
  thumbBgColor?: string;
  alignContent?: CSSProperties["alignItems"];
  type?: "bordered" | "ghost";
  action?: VoidFunction;
}

const bem = makeBEM("settings-card");

export const SettingsCard = ({
  imageUrl,
  title,
  description,
  children,
  className,
  icon,
  thumbBgColor,
  alignContent = "center",
  type = "bordered",
  action,
}: SettingsCardProps) => {
  const imageProps = {
    imageUrl,
    icon,
    thumbBgColor,
  };

  const isSettingsCardImageVisible = imageUrl || icon || thumbBgColor;

  return (
    <Flex
      className={cn(
        createClassWithModifiers("settings-card", [type]),
        className,
      )}
    >
      <Flex align="center" className="full-width">
        {isSettingsCardImageVisible && <SettingsCardImage {...imageProps} />}
        <Flex
          justify="space-between"
          align={alignContent}
          className="full-width"
        >
          <div className="full-width">
            {action ? (
              <Flex justify="space-between" align="center">
                <h4 className={bem("title")}>{title}</h4>
                <Button
                  size="small"
                  type="light"
                  onClick={action}
                  icon={() => <Edit size="14px" />}
                />
              </Flex>
            ) : (
              <h4 className={bem("title")}>{title}</h4>
            )}
            {description && (
              <span className={bem("description")}>{description}</span>
            )}
          </div>
          {children && <div>{children}</div>}
        </Flex>
      </Flex>
    </Flex>
  );
};

export interface SettingsCardImageProps {
  imageUrl?: string;
  icon?: React.ReactNode;
  thumbBgColor?: string;
}

export const SettingsCardImage = ({
  imageUrl,
  icon,
  thumbBgColor,
}: SettingsCardProps) => {
  return (
    <div
      className={cn(bem("thumb mr-10"), { [bem("thumb--icon")]: icon })}
      style={{ backgroundColor: thumbBgColor }}
    >
      {imageUrl && (
        <div
          style={{
            backgroundImage: `url(${imageUrl})`,
            height: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      )}
      {icon && (
        <Flex align="center" justify="center" className={bem("icon")}>
          {icon}
        </Flex>
      )}
    </div>
  );
};
