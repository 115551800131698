export interface Notification {
  id?: number;
  title?: string;
  description?: string;
  email?: string;
  phone?: string;
  timestamp?: string;
  edited_timestamp?: string;
  is_read?: boolean;
  type?: string;
  metadata?: Metadata;
  priority?: NotificationPriority;
}

export interface Metadata {
  title?: string;
  description?: string;
}

export enum NotificationPriority {
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ERROR = "ERROR",
}