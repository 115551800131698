import { Flex } from "components";
import * as Icons from "components/icons";

interface ShowVisibilityProps {
  isVisible?: boolean;
}

export const ShowVisibility = ({ isVisible }: ShowVisibilityProps) => {
  return (
    <Flex align="center">
      {isVisible ? (
        <>
          <Icons.Eye size="small" className="primary-color" />
          <span className="ml-5">Show</span>
        </>
      ) : (
        <>
          <Icons.ClosedEye size="small" className="grey-100" />
          <span className="ml-5">Hide</span>
        </>
      )}
    </Flex>
  );
};
