import { createSvgIcon } from "../utils";

export const Danger = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M12.2728 5.72727L9.00007 9M9.00007 9L5.72735 12.2727M9.00007 9L5.72735 5.72727M9.00007 9L12.2728 12.2727M3 1H15C16.1046 1 17 1.89543 17 3V15C17 16.1046 16.1046 17 15 17H3C1.89543 17 1 16.1046 1 15V3C1 1.89543 1.89543 1 3 1Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
