import { MutableRefObject } from "react";
import { ReactMultiEmail } from "react-multi-email";

export const inputAutoResize = (input: MutableRefObject<ReactMultiEmail>) => {
    if (input?.current?.emailInputRef.current) {
      const inputElement = input.current?.emailInputRef.current;
      inputElement.style.setProperty("max-width", '100%', 'important');
      inputElement.addEventListener("input", e => {
        inputElement.style.setProperty("width", 'auto', 'important');
        inputElement.style.setProperty("width", inputElement.scrollWidth+'px', 'important');
      });
    }
}