import React from "react";

import { useIsRestrictedTo } from "hooks";
import models from "models";

export interface RestrictedProps {
  /**
   * Render if logged user is admin and has this application in profile
   */
  toApp?: models.Apps;

  /**
   * Render if logged user has one of these roles
   */
  toRoles?: models.Roles[];

  toMainAdmin?: boolean;
}

export const Restricted: React.FC<RestrictedProps> = ({
  toApp: app,
  toRoles: roles,
  toMainAdmin: mainAdmin = true,
  children,
}) => {
  const isRestricted = useIsRestrictedTo()({
    app,
    roles,
    mainAdmin,
  });

  if (isRestricted) return <>{children}</>;

  return null;
};
