import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

import { AuthContainer } from "components";
import { ConfirmHeader, ConfirmResetPasswordForm } from "../components";

export const ConfirmResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [query] = useQueryParams({
    email: StringParam,
    hash: StringParam,
  });

  if (!query.email) history.push("/reset-password");

  return (
    <AuthContainer
      header={<ConfirmHeader title={t("common.set_new_password")} />}
    >
      <ConfirmResetPasswordForm />
    </AuthContainer>
  );
};
