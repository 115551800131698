import models from "models";
import { Languages } from "apps/cms/lib/utils";

export interface User {
  id?: number;
  first_name?: string;
  last_name?: string;
  username?: string;
  name?: string;
  last_login?: string;
  language?: Languages;
  profile?: Profile;
  country_id?: number;
  is_active?: boolean;
  is_online?: boolean;
  phone?: string;
  email?: string;
  notifications?: {
    EMAIL_NOTIFICATIONS: boolean;
    PUSH_NOTIFICATIONS: boolean;
  };
  timestamp?: string;
  functions?: string[];
  edited_timestamp?: string;
  organization?: Organization;
  roles_id?: number[];
  picture_id?: number;
  warehouse_id?: number;
  warehouse?: ShortWarehouse;
  department_id?: number;
  department?: ShortDepartment;
  age_of_consent?: boolean;
  terms_of_agreement?: boolean;
  applications?: models.Apps[];
}

export interface ShortDepartment {
  id?: number;
  title?: string;
  description?: string;
  edited_timestamp?: string;
  i18n?: models.I18N;
  is_active?: boolean;
  timestamp?: string;
}
export interface ShortWarehouse {
  id?: number;
  title?: string;
}
export interface Organization {
  id?: number;
  title?: string;
  domain?: string;
  hash?: string;
  logo?: File;
}
export interface File {
  url?: string;
  id?: number;
  size?: number;
  extension?: string;
}

export interface Profile {
  id?: number;
  roles?: Role[];
  country?: Country;
  picture?: Picture;
  picture_id?: number;
  country_id?: number;
  roles_id?: number[];
  roles_name?: models.Roles[];
  applications?: models.Apps[];
  functions?: string[];
}

export interface DeletedUser {
  id?: number;
  name?: string;
  email?: string;
  user?: Pick<User, "id" | "name" | "email">;
  timestamp?: string;
  reference_timestamp?: string;
  reference?: number;
}
export interface Role {
  id?: number;
  name?: Roles;
}
export interface Country {
  id?: number;
  name?: string;
  code?: string;
}
export interface Picture {
  id?: number;
  url?: string;
  size?: number;
  extension?: string;
}

export enum Apps {
  AUTH_SERVICE = "AUTH_SERVICE",
  CMS_SERVICE = "CMS_SERVICE",
  CRM_SERVICE = "CRM_SERVICE",
  PM_SERVICE = "PM_SERVICE",
  WAREHOUSE_SERVICE = "WAREHOUSE_SERVICE",
}

export enum Roles {
  MAIN_ADMINISTRATOR = "MAIN_ADMINISTRATOR",
  ADMINISTRATOR = "ADMINISTRATOR",
  WAREHOUSE_OPERATOR = "WAREHOUSE_OPERATOR",
  WAREHOUSE_SUPERVISOR = "WAREHOUSE_SUPERVISOR",
}

export enum AdminRoles {
  MAIN_ADMINISTRATOR = Roles.MAIN_ADMINISTRATOR,
  ADMINISTRATOR = Roles.ADMINISTRATOR,
}

export enum WarehouseRoles {
  WAREHOUSE_OPERATOR = Roles.WAREHOUSE_OPERATOR,
  WAREHOUSE_SUPERVISOR = Roles.WAREHOUSE_SUPERVISOR,
}

export enum RoleType {
  ADMIN = "admin",
  WAREHOUSE = "warehouse",
}

export interface ShortUser {
  id?: number;
  email?: string;
  first_name?: string;
  last_login?: string;
  last_name?: string;
  name?: string;
}
