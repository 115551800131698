import { createSvgIcon } from "../utils";

export const ShippingProvider = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 13H0.25C0.25 13.4142 0.585786 13.75 1 13.75L1 13ZM2 1V1.75V1ZM11 1H11.75C11.75 0.585786 11.4142 0.25 11 0.25V1ZM10.25 13C10.25 13.4142 10.5858 13.75 11 13.75C11.4142 13.75 11.75 13.4142 11.75 13H10.25ZM19 13V13.75C19.4142 13.75 19.75 13.4142 19.75 13H19ZM19 7H19.75C19.75 6.86407 19.7131 6.73069 19.6431 6.61413L19 7ZM11 6.25C10.5858 6.25 10.25 6.58579 10.25 7C10.25 7.41421 10.5858 7.75 11 7.75V6.25ZM11 1.25C10.5858 1.25 10.25 1.58579 10.25 2C10.25 2.41421 10.5858 2.75 11 2.75V1.25ZM16 2L16.6431 1.61413C16.5076 1.38822 16.2634 1.25 16 1.25V2ZM6.25 13C6.25 13.6904 5.69036 14.25 5 14.25V15.75C6.51878 15.75 7.75 14.5188 7.75 13H6.25ZM5 14.25C4.30964 14.25 3.75 13.6904 3.75 13H2.25C2.25 14.5188 3.48122 15.75 5 15.75V14.25ZM3.75 13C3.75 12.3096 4.30964 11.75 5 11.75V10.25C3.48122 10.25 2.25 11.4812 2.25 13H3.75ZM5 11.75C5.69036 11.75 6.25 12.3096 6.25 13H7.75C7.75 11.4812 6.51878 10.25 5 10.25V11.75ZM16.25 13C16.25 13.6904 15.6904 14.25 15 14.25V15.75C16.5188 15.75 17.75 14.5188 17.75 13H16.25ZM15 14.25C14.3096 14.25 13.75 13.6904 13.75 13H12.25C12.25 14.5188 13.4812 15.75 15 15.75V14.25ZM13.75 13C13.75 12.3096 14.3096 11.75 15 11.75V10.25C13.4812 10.25 12.25 11.4812 12.25 13H13.75ZM15 11.75C15.6904 11.75 16.25 12.3096 16.25 13H17.75C17.75 11.4812 16.5188 10.25 15 10.25V11.75ZM3 12.25H1V13.75H3V12.25ZM1.75 13V2H0.25V13H1.75ZM1.75 2C1.75 1.9337 1.77634 1.87011 1.82322 1.82322L0.762563 0.762563C0.434375 1.09075 0.25 1.53587 0.25 2H1.75ZM1.82322 1.82322C1.87011 1.77634 1.9337 1.75 2 1.75V0.25C1.53587 0.25 1.09075 0.434375 0.762563 0.762563L1.82322 1.82322ZM2 1.75H11V0.25H2V1.75ZM10.25 1V13H11.75V1H10.25ZM7 13.75H13V12.25H7V13.75ZM17 13.75H19V12.25H17V13.75ZM19.75 13V7H18.25V13H19.75ZM19 6.25H11V7.75H19V6.25ZM11 2.75H16V1.25H11V2.75ZM15.3569 2.38587L18.3569 7.38587L19.6431 6.61413L16.6431 1.61413L15.3569 2.38587Z"
      fill="currentColor"
    />
  </svg>,
);
