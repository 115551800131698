import { RouteProps } from "react-router-dom";

export class RouteBuilder {
  private _routes: RouteProps[];

  constructor() {
    this._routes = [];
  }

  add(
    path: string | string[],
    component: RouteProps["component"],
    exact = false,
  ) {
    this._routes.push({ path, exact, component });
    return this;
  }
  // addIf(expr: boolean, ...args: Parameters<typeof this.add>) {
  //   if (expr) this.add(...args);
  //   return this;
  // }

  getRoutes() {
    return [...this._routes];
  }
}
