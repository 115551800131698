import { Card } from "ebs-design";
import { WhiteSpace } from "components";
import { CurrencySettings, FeesSettings, PaymentsSettings } from ".";

export const PaymentsSettingsCard = () => {
  return (
    <Card>
      <Card.Body>
        <CurrencySettings />
        <WhiteSpace v="32px" />

        <PaymentsSettings />
        <WhiteSpace v="32px" />

        <FeesSettings />
      </Card.Body>
    </Card>
  );
};
