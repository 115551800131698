import { Card } from "ebs-design";
import cn from "classnames";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { CounterBox, Flex, Grid, SettingsCard } from "components";
import api, { querykeys } from "api";
import { LanguagesContext } from "apps/cms/context/Languages";
import models from "models";

export const LanguagesCard = ({ select }: { select?: boolean }) => {
  const { t } = useTranslation();

  const {
    languages: { data: languages },
  } = useContext(LanguagesContext);

  const { data: selectedLanguages } = useQuery(
    querykeys.settings.defaultSettings,
    api.settings.defaultSettings,
    { select: (data) => data?.data?.LANGUAGES },
  );

  return (
    <Card className={`${cn({ "no-events": !select })} full-height`}>
      <Card.Header>
        <Flex align="center" justify="space-between">
          <h3>{t("common.languages")}</h3>
        </Flex>
      </Card.Header>
      <Card.Body>
        <Grid
          style={{
            gap: "1rem",
            gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
          }}
        >
          <CounterBox
            value={languages ? languages.length : 0}
            title={t("common.languages")}
          />
          {languages
            ?.filter((language) =>
              selectedLanguages?.includes(language.code as models.LanguageCode),
            )
            ?.map((language) => (
              <div key={language.id}>
                <SettingsCard
                  key={language.id}
                  title={models.Language[language.code]}
                  type="ghost"
                />
              </div>
            ))}
        </Grid>
      </Card.Body>
    </Card>
  );
};
