import React from "react";
import { useDrag, useDrop } from "react-dnd";
import type { XYCoord, Identifier } from "dnd-core";

import { NO_VALUE_FALLBACK } from "app-constants";
import { makeBEM } from "utils";
import * as Icons from "components/icons";

import { ColumnEntity, RowRecords } from ".";

export interface RowProps extends RowRecords {
  index: number;
  row: RowRecords;
  columns: ColumnEntity[];
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  onDragEnd?: () => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const ItemTypes = {
  ROW: "row",
};

const bem = makeBEM("dnd-table");

export const DndRow = ({
  id,
  row,
  columns,
  index,
  moveRow,
  onDragEnd,
}: RowProps) => {
  const dragRef = React.useRef<HTMLDivElement>(null);
  const previewRef = React.useRef<HTMLTableRowElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.ROW,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.ROW,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      onDragEnd?.();
    },
  });

  const opacity = isDragging ? 0 : 1;

  React.useEffect(() => {
    dragRef && drag(dragRef);
    previewRef && drop(preview(previewRef));

    // eslint-disable-next-line
  }, []);

  return (
    <tr
      ref={previewRef}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={bem("body-row")}
    >
      <td>
        <span className="dnd-table__move-btn" ref={dragRef}>
          <Icons.Move size="small" />
        </span>
      </td>
      {columns.map((column, idx) => {
        return (
          <td className={bem("body-column")} key={idx}>
            {column.render
              ? column?.render?.(row, index)
              : row[column.key as keyof typeof row] || NO_VALUE_FALLBACK}
          </td>
        );
      })}
    </tr>
  );
};
