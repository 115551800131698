import { cmsAxios } from "api/axios";
import models from "models";
import { stringifyUrl } from "utils";

export interface Banner {
  id: number;
  link: string;
  image: string;
  i18n: { [key: string]: BannerProps };
  type: string;
}

export interface BannerProps {
  title?: string;
  content?: string;
}

const banners = {
  get: async (params: any = {}) => {
    const { data } = await cmsAxios.get<models.WithResults<Banner>>(
      stringifyUrl(`banners/`, params, { version: 2 }),
    );

    return data;
  },
  update: async (id: number, body: Banner) => {
    const { data } = await cmsAxios.patch<Banner>(
      stringifyUrl(`banners/${id}/`, {}, { version: 2 }),
      body,
    );

    return data;
  },
  create: async (body: Banner) => {
    const { data } = await cmsAxios.post<Banner>(
      stringifyUrl(`banners/`, {}, { version: 2 }),
      body,
    );

    return data;
  },
  remove: async (id: number) => {
    await cmsAxios.delete(stringifyUrl(`banners/${id}/`, {}, { version: 2 }));
  },
};

export default banners;
