// import { stringifyUrl } from "utils";
import { iconList } from "../iconList";

const icons = {
  getList: async () => {
    try {
      // const data = await fetch(
      //   stringifyUrl(
      //     `public/icons/index.json`,
      //     {},
      //     { api: process.env.REACT_APP_WEB_APP_URL },
      //   ),
      // ).then((response) => response.json());

      return iconList.map((name: string) => ({
        name,
        url: `${process.env.REACT_APP_WEB_APP_URL}/public/icons/${name}.svg`,
      }));
    } catch (err) {
      console.error(err);
    }

    return [];
  },
};

export default icons;
