/**
 *
 * @param setter The setState function for object (const [, setState] = useSetState({}), const [, setQueryParams] = useQueryParams({...}))
 * @param key The key of object to set the value
 * @returns Returns a function that will take just a value (not an object) and will set the state with just the specified key
 *
 * @example
 * const [, setState] = useSetState({ counter: 0, ...otherProps });
 * const setCounter = forwardSetState(setState, "counter");
 *
 * setCounter(prev => prev + 1);
 * // The above line is equivalent with
 * setState(prev => ({ counter: prev.counter + 1 }))
 */
export const forwardSetState =
  <Obj extends Object, Key extends keyof Obj>(
    setter: React.Dispatch<Pick<Obj, Key> | ((obj: Obj) => Pick<Obj, Key>)>,
    key: Key,
  ) =>
  (valOrFn: React.SetStateAction<Obj[Key]>) =>
    valOrFn instanceof Function
      ? setter((prev) => ({ [key]: valOrFn(prev[key]) } as Pick<Obj, Key>))
      : setter({ [key]: valOrFn } as Pick<Obj, Key>);
