import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { Properties } from "types";

import { IconSelect } from "../components";

const Generic = ({ showIcon, data }: Properties) => {
  const { t } = useTranslation();

  return (
    <>
      {showIcon && (
        <Form.Item name="icon" className="drop-tree__node-edit__col">
          <IconSelect size="small" />
        </Form.Item>
      )}
      <Form.Item
        name="position"
        className="drop-tree__node-edit__align"
        initialValue={data.position || "center"}
      >
        <Select
          suffixIcon={
            !data.position || data.position === "center" ? (
              <AlignCenterOutlined />
            ) : data.position === "left" ? (
              <AlignLeftOutlined />
            ) : (
              <AlignRightOutlined />
            )
          }
          options={[
            { value: "left", label: t("common.left") },
            { value: "center", label: t("common.center") },
            { value: "right", label: t("common.right") },
          ]}
        />
      </Form.Item>
    </>
  );
};

export default Generic;
