import { Button, Space } from "ebs-design";
import { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { stringifyUrl } from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import menu, { MenuType } from "apps/cms/lib/api/menu";
import { Properties } from "types";
import { Table, Modal, Row, Badge, Col, Input } from "antd";
import { ColumnProps } from "antd/lib/table";
import { PageTitle } from "components";
import { MenuActions } from "apps/cms/components/Menu";
import { capitalizeString, getMissingTranslationsKeys } from "utils";
import models from "models";

const MenuPage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState<models.WithResults<Properties>>();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const search = params.search || undefined;
  const tags = params.tags;

  const fetchArticles = useCallback(async () => {
    setLoading(true);
    try {
      setData(
        await menu.getMenu({
          parent_id__isnull: true,
          search,
          page,
          tags,
          type: activeTab === 0 ? "" : Object.values(MenuType)[activeTab - 1],
        }),
      );
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, [search, page, tags, activeTab]);

  const onRemove = async ({ id }: Properties): Promise<void> => {
    Modal.confirm({
      title: t("template.are_you_sure_you_want_to_delete_this_item", {
        item: t("common.menu").toLowerCase(),
      }),
      onOk: async () => {
        try {
          await menu.deleteMenu(id);
          fetchArticles();
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  const onSearchHandler = (): void => {
    history.push(
      stringifyUrl({
        url: "/cms/menu",
        query: {
          ...(searchValue && { search: searchValue }),
          ...(tags && { tags }),
        },
      }),
    );
  };

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const columns: ColumnProps<Properties>[] = [
    {
      title: t("common.id"),
      dataIndex: "id",
    },
    {
      title: t("common.title"),
      render: ({ title }) => title || "- - -",
    },
    {
      title: t("common.type"),
      render: ({ type }) => type || "header",
    },
    {
      title: t("common.translations"),
      width: 50,
      className: "no-wrap",
      render: ({ children, id }) =>
        children.map((child: Properties, i: number) => {
          if (i + 1 !== children.length) {
            return null;
          }

          const keys = child?.article?.i18n
            ? getMissingTranslationsKeys(child.article.i18n)
            : {};

          return (
            <Row gutter={[8, 8]} justify="end" className="flex-no-wrap">
              {Object.keys(keys).map((key: any, i) => (
                <Col style={{ ...(!!i && { marginLeft: 10 }) }}>
                  <Badge
                    count={keys[key]}
                    size="small"
                    status={keys[key] ? "error" : "success"}
                  >
                    <Button
                      size="small"
                      type="ghost"
                      onClick={() =>
                        history.push(
                          stringifyUrl({
                            url: `/cms/menu/edit/${id}`,
                            query: { lang: key },
                          }),
                        )
                      }
                    >
                      {key.toUpperCase()}
                    </Button>
                  </Badge>
                </Col>
              ))}
            </Row>
          );
        }),
    },
    {
      title: t("common.actions"),
      align: "right",
      className: "no-wrap",
      width: 50,
      render: (menu) => {
        return <MenuActions menu={menu} onRemove={onRemove} />;
      },
    },
  ];

  return (
    <>
      <PageTitle
        actions={
          <Button
            type="primary"
            onClick={() => history.push("/cms/menu/create")}
          >
            {t("template.add_item", {
              item: t("menu.menu").toLowerCase(),
            })}
          </Button>
        }
        title={t("menu.menu")}
        count={data?.count}
      />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row
            gutter={[16, 16]}
            justify="space-between"
            align="middle"
            className="flex-no-wrap"
          >
            <Col>
              <Input.Search
                enterButton={t("search.search")}
                placeholder={t("search.search_menu_by_title")}
                size="large"
                value={searchValue}
                style={{ width: "auto" }}
                onSearch={onSearchHandler}
                onChange={({ target: { value } }) => setSearchValue(value)}
              />
            </Col>
            <Col>
              <Space>
                {["All", ...Object.values(MenuType)].map((type, index) => (
                  <Button
                    key={index}
                    type={activeTab === index ? "fill" : "light"}
                    onClick={() => setActiveTab(index)}
                    value={type}
                  >
                    {capitalizeString(type)}
                  </Button>
                ))}
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            loading={loading}
            dataSource={data ? data.results : []}
            rowKey="id"
            childrenColumnName=""
            pagination={{
              hideOnSinglePage: true,
              current: page,
              pageSize: 10,
              total: data ? data.count : 0,
            }}
            onChange={({ current }, filters: any) => {
              history.push(
                stringifyUrl(
                  {
                    url: "/cms/menu",
                    query: {
                      ...params,
                      ...filters,
                      page: current,
                    },
                  },
                  { arrayFormat: "comma" },
                ),
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default MenuPage;
