import update from "immutability-helper";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { useNotify } from "ebs-design";

import api from "api";
import models from "models";
import { arrayEquals, formatPercent, makeBEM, notifyErrors } from "utils";

import { DndRow } from "./DndRow";
import { ColumnEntity, RowRecords } from ".";

export interface DndTableProps {
  data?: models.DiagnosticCriteria[];
  onReorderSuccess?: () => void;
  columns: ColumnEntity[];
}

const bem = makeBEM("dnd-table");
const HUNDRED = 100;

export const DndTable = ({
  data,
  columns,
  onReorderSuccess,
}: DndTableProps) => {
  const { t } = useTranslation();
  const notify = useNotify();

  const [rows, setRows] = useState<models.DiagnosticCriteria[]>([]);

  const affectedPrice = rows?.reduce(
    (total, obj) => Number(obj?.price_percentage) + total,
    0,
  );

  useEffect(() => {
    data && setRows(data);
  }, [data]);

  const moveRow = useCallback((dragIndex: number, hoverIndex: number) => {
    setRows((prevCards: RowRecords[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as RowRecords],
        ],
      }),
    );
  }, []);

  const reorderMutation = useMutation(api.settings.reorderCriteria, {
    onSuccess: () => {
      onReorderSuccess?.();
      notify.success({
        title: t("notification.changes_saved_successfully"),
      });
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const onDragEnd = () => {
    if (
      !arrayEquals(
        rows.map((item) => item.id),
        data.map((item) => item.id),
      )
    )
      reorderMutation.mutate(
        rows.map((item, idx) => ({
          id: item.id,
          order: idx + 1,
        })),
      );
  };

  return (
    <table className="dnd-table">
      <thead className={bem("header")}>
        <tr>
          <td />
          {columns?.map((column, idx) => {
            return (
              <th
                key={idx}
                style={column.style}
                className={bem("header-column")}
              >
                {column.name}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={bem("body")}>
        {rows?.map((row, i) => (
          <DndRow
            key={row?.id}
            index={i}
            id={row?.id}
            row={row}
            columns={columns}
            moveRow={moveRow}
            onDragEnd={onDragEnd}
          />
        ))}
        <>
          <tr className="dnd-table__primary-row">
            <td className="dnd-table__body-column" />
            <td className="dnd-table__body-column" />
            <td className="dnd-table__body-column">
              *{t("price.affected_price")}
            </td>
            <td className="dnd-table__body-column">
              {formatPercent(affectedPrice)}
            </td>
            <td className="dnd-table__body-column" />
          </tr>
          <tr className="dnd-table__secondary-row">
            <td className="dnd-table__body-column">{t("common.last")}</td>
            <td className="dnd-table__body-column" />
            <td className="dnd-table__body-column">
              ({t("common.none_of_the_above")})
            </td>
            <td className="dnd-table__body-column">
              {formatPercent(HUNDRED - affectedPrice)}
            </td>
            <td className="dnd-table__body-column" />
          </tr>
        </>
      </tbody>
    </table>
  );
};
