import { createSvgIcon } from "../utils";

export const ChevronRight = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L5 5L1 9"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
