import { createSvgIcon } from "../utils";

export const Dots = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="4"
    viewBox="0 0 18 4"
    fill="none"
  >
    <path
      d="M2.00001 3C2.55229 3 3.00001 2.55228 3.00001 2C3.00001 1.44772 2.55229 1 2.00001 1C1.44772 1 1.00001 1.44772 1.00001 2C1.00001 2.55228 1.44772 3 2.00001 3Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99999 3C9.55228 3 9.99999 2.55228 9.99999 2C9.99999 1.44772 9.55228 1 8.99999 1C8.44771 1 7.99999 1.44772 7.99999 2C7.99999 2.55228 8.44771 3 8.99999 3Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 3C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
