import models from "models";
import { GenericObject } from "types";

import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const payments = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/payments", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Payment>>(
      url,
    );
    return data;
  },
  getByID: async (id: number) => {
    const { data } = await apiAxios.get<models.Payment>(`/payments/${id}`);
    return data;
  },
  patch: async (id: number, body: models.Payment) => {
    const { data } = await apiAxios.patch<models.Payment>(
      `/payments/${id}`,
      body,
    );
    return data;
  },
  export: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/payments/export", queryParams, {
      arrayFormat: "comma",
    });

    const { data } = await apiAxios.get<models.Attachment>(url);
    return data;
  },
};
