import { createSvgIcon } from "../utils";

export const LotsOfMoney = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="1em"
    viewBox="0 0 32 24"
    fill="none"
  >
    <path
      d="M9 1V0.25C8.58579 0.25 8.25 0.585786 8.25 1L9 1ZM31 1H31.75C31.75 0.585786 31.4142 0.25 31 0.25V1ZM31 14.995V15.745C31.4142 15.745 31.75 15.4092 31.75 14.995H31ZM9 14.995H8.25C8.25 15.4092 8.58579 15.745 9 15.745V14.995ZM28 19.75C28.4142 19.75 28.75 19.4142 28.75 19C28.75 18.5858 28.4142 18.25 28 18.25V19.75ZM5 19H4.25C4.25 19.4142 4.58579 19.75 5 19.75V19ZM5.75 2C5.75 1.58579 5.41421 1.25 5 1.25C4.58579 1.25 4.25 1.58579 4.25 2H5.75ZM24 23.75C24.4142 23.75 24.75 23.4142 24.75 23C24.75 22.5858 24.4142 22.25 24 22.25V23.75ZM1 23H0.25C0.25 23.4142 0.585786 23.75 1 23.75L1 23ZM1.75 6C1.75 5.58579 1.41421 5.25 1 5.25C0.585786 5.25 0.25 5.58579 0.25 6H1.75ZM13 4.25C12.5858 4.25 12.25 4.58579 12.25 5C12.25 5.41421 12.5858 5.75 13 5.75V4.25ZM14 5.75C14.4142 5.75 14.75 5.41421 14.75 5C14.75 4.58579 14.4142 4.25 14 4.25V5.75ZM26 10.25C25.5858 10.25 25.25 10.5858 25.25 11C25.25 11.4142 25.5858 11.75 26 11.75V10.25ZM27 11.75C27.4142 11.75 27.75 11.4142 27.75 11C27.75 10.5858 27.4142 10.25 27 10.25V11.75ZM9 1.75H31V0.25H9V1.75ZM30.25 1V14.995H31.75V1H30.25ZM31 14.245H9V15.745H31V14.245ZM9.75 14.995V1H8.25V14.995H9.75ZM22.25 8C22.25 9.24264 21.2426 10.25 20 10.25V11.75C22.0711 11.75 23.75 10.0711 23.75 8H22.25ZM20 10.25C18.7574 10.25 17.75 9.24264 17.75 8H16.25C16.25 10.0711 17.9289 11.75 20 11.75V10.25ZM17.75 8C17.75 6.75736 18.7574 5.75 20 5.75V4.25C17.9289 4.25 16.25 5.92893 16.25 8H17.75ZM20 5.75C21.2426 5.75 22.25 6.75736 22.25 8H23.75C23.75 5.92893 22.0711 4.25 20 4.25V5.75ZM28 18.25H5V19.75H28V18.25ZM5.75 19V2H4.25V19H5.75ZM24 22.25H1V23.75H24V22.25ZM1.75 23V6H0.25V23H1.75ZM13 5.75H14V4.25H13V5.75ZM26 11.75H27V10.25H26V11.75Z"
      fill="currentColor"
    />
  </svg>,
);
