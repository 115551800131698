import { createSvgIcon } from "../utils";

export const Devices = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.25 8C17.25 8.41421 17.5858 8.75 18 8.75C18.4142 8.75 18.75 8.41421 18.75 8H17.25ZM17 4V3.25V4ZM3 5H2.25H3ZM3 17H2.25H3ZM13 18.75C13.4142 18.75 13.75 18.4142 13.75 18C13.75 17.5858 13.4142 17.25 13 17.25V18.75ZM16 8.25C15.5858 8.25 15.25 8.58579 15.25 9C15.25 9.41421 15.5858 9.75 16 9.75V8.25ZM18 9.75C18.4142 9.75 18.75 9.41421 18.75 9C18.75 8.58579 18.4142 8.25 18 8.25V9.75ZM14 8.75H20V7.25H14V8.75ZM20 8.75C20.1381 8.75 20.25 8.86193 20.25 9H21.75C21.75 8.0335 20.9665 7.25 20 7.25V8.75ZM20.25 9V19H21.75V9H20.25ZM20.25 19C20.25 19.1381 20.1381 19.25 20 19.25V20.75C20.9665 20.75 21.75 19.9665 21.75 19H20.25ZM20 19.25H14V20.75H20V19.25ZM14 19.25C13.8619 19.25 13.75 19.1381 13.75 19H12.25C12.25 19.9665 13.0335 20.75 14 20.75V19.25ZM13.75 19V9H12.25V19H13.75ZM13.75 9C13.75 8.86193 13.8619 8.75 14 8.75V7.25C13.0335 7.25 12.25 8.0335 12.25 9H13.75ZM18.75 8V5H17.25V8H18.75ZM18.75 5C18.75 4.53587 18.5656 4.09075 18.2374 3.76256L17.1768 4.82322C17.2237 4.87011 17.25 4.93369 17.25 5H18.75ZM18.2374 3.76256C17.9092 3.43437 17.4641 3.25 17 3.25V4.75C17.0663 4.75 17.1299 4.77634 17.1768 4.82322L18.2374 3.76256ZM17 3.25H4V4.75H17V3.25ZM4 3.25C3.53587 3.25 3.09075 3.43437 2.76256 3.76256L3.82322 4.82322C3.87011 4.77634 3.9337 4.75 4 4.75V3.25ZM2.76256 3.76256C2.43437 4.09075 2.25 4.53587 2.25 5H3.75C3.75 4.9337 3.77634 4.87011 3.82322 4.82322L2.76256 3.76256ZM2.25 5V17H3.75V5H2.25ZM2.25 17C2.25 17.4641 2.43437 17.9093 2.76256 18.2374L3.82322 17.1768C3.77634 17.1299 3.75 17.0663 3.75 17H2.25ZM2.76256 18.2374C3.09075 18.5656 3.53587 18.75 4 18.75V17.25C3.93369 17.25 3.87011 17.2237 3.82322 17.1768L2.76256 18.2374ZM4 18.75H13V17.25H4V18.75ZM16 9.75H18V8.25H16V9.75Z"
      fill="currentColor"
    />
  </svg>,
);
