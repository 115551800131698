import { ButtonProps } from "ebs-design/dist/components/atoms/Button/Button";
import { Button } from "ebs-design";

export interface LoadingButtonProps extends ButtonProps {}

export const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading,
  prefix,
  disabled,
  ...props
}) => {
  return (
    <Button
      {...props}
      loading={loading}
      disabled={loading || disabled}
      prefix={loading ? " " : prefix}
    />
  );
};
