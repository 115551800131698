import { createSvgIcon } from "../utils";

export const Orders = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0.25C4.58579 0.25 4.25 0.585786 4.25 1C4.25 1.41421 4.58579 1.75 5 1.75V0.25ZM16 1.75C16.4142 1.75 16.75 1.41421 16.75 1C16.75 0.585786 16.4142 0.25 16 0.25V1.75ZM5 6.25C4.58579 6.25 4.25 6.58579 4.25 7C4.25 7.41421 4.58579 7.75 5 7.75V6.25ZM16 7.75C16.4142 7.75 16.75 7.41421 16.75 7C16.75 6.58579 16.4142 6.25 16 6.25V7.75ZM5 12.25C4.58579 12.25 4.25 12.5858 4.25 13C4.25 13.4142 4.58579 13.75 5 13.75V12.25ZM16 13.75C16.4142 13.75 16.75 13.4142 16.75 13C16.75 12.5858 16.4142 12.25 16 12.25V13.75ZM1.75 1C1.75 0.585786 1.41421 0.25 1 0.25C0.585786 0.25 0.25 0.585786 0.25 1H1.75ZM0.25 1.01C0.25 1.42421 0.585786 1.76 1 1.76C1.41421 1.76 1.75 1.42421 1.75 1.01H0.25ZM1.75 7C1.75 6.58579 1.41421 6.25 1 6.25C0.585786 6.25 0.25 6.58579 0.25 7H1.75ZM0.25 7.01C0.25 7.42421 0.585786 7.76 1 7.76C1.41421 7.76 1.75 7.42421 1.75 7.01H0.25ZM1.75 13C1.75 12.5858 1.41421 12.25 1 12.25C0.585786 12.25 0.25 12.5858 0.25 13H1.75ZM0.25 13.01C0.25 13.4242 0.585786 13.76 1 13.76C1.41421 13.76 1.75 13.4242 1.75 13.01H0.25ZM5 1.75H16V0.25H5V1.75ZM5 7.75H16V6.25H5V7.75ZM5 13.75H16V12.25H5V13.75ZM0.25 1V1.01H1.75V1H0.25ZM0.25 7V7.01H1.75V7H0.25ZM0.25 13V13.01H1.75V13H0.25Z"
      fill="currentColor"
    />
  </svg>,
);
