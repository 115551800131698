import { createSvgIcon } from "../utils";

export const CRM = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 19.25C6.58579 19.25 6.25 19.5858 6.25 20C6.25 20.4142 6.58579 20.75 7 20.75V19.25ZM17 20.75C17.4142 20.75 17.75 20.4142 17.75 20C17.75 19.5858 17.4142 19.25 17 19.25V20.75ZM9.75 16C9.75 15.5858 9.41421 15.25 9 15.25C8.58579 15.25 8.25 15.5858 8.25 16H9.75ZM8.25 20C8.25 20.4142 8.58579 20.75 9 20.75C9.41421 20.75 9.75 20.4142 9.75 20H8.25ZM15.75 16C15.75 15.5858 15.4142 15.25 15 15.25C14.5858 15.25 14.25 15.5858 14.25 16H15.75ZM14.25 20C14.25 20.4142 14.5858 20.75 15 20.75C15.4142 20.75 15.75 20.4142 15.75 20H14.25ZM7.46967 11.4697C7.17678 11.7626 7.17678 12.2374 7.46967 12.5303C7.76256 12.8232 8.23744 12.8232 8.53033 12.5303L7.46967 11.4697ZM11 9L11.5303 8.46967C11.2374 8.17678 10.7626 8.17678 10.4697 8.46967L11 9ZM13 11L12.4697 11.5303C12.7626 11.8232 13.2374 11.8232 13.5303 11.5303L13 11ZM16.5303 8.53033C16.8232 8.23744 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L16.5303 8.53033ZM4 4.75H20V3.25H4V4.75ZM20 4.75C20.1381 4.75 20.25 4.86193 20.25 5H21.75C21.75 4.0335 20.9665 3.25 20 3.25V4.75ZM20.25 5V15H21.75V5H20.25ZM20.25 15C20.25 15.1381 20.1381 15.25 20 15.25V16.75C20.9665 16.75 21.75 15.9665 21.75 15H20.25ZM20 15.25H4V16.75H20V15.25ZM4 15.25C3.86193 15.25 3.75 15.1381 3.75 15H2.25C2.25 15.9665 3.0335 16.75 4 16.75V15.25ZM3.75 15V5H2.25V15H3.75ZM3.75 5C3.75 4.86193 3.86193 4.75 4 4.75V3.25C3.0335 3.25 2.25 4.0335 2.25 5H3.75ZM7 20.75H17V19.25H7V20.75ZM8.25 16V20H9.75V16H8.25ZM14.25 16V20H15.75V16H14.25ZM8.53033 12.5303L11.5303 9.53033L10.4697 8.46967L7.46967 11.4697L8.53033 12.5303ZM10.4697 9.53033L12.4697 11.5303L13.5303 10.4697L11.5303 8.46967L10.4697 9.53033ZM13.5303 11.5303L16.5303 8.53033L15.4697 7.46967L12.4697 10.4697L13.5303 11.5303Z"
      fill="currentColor"
    />
  </svg>,
);
