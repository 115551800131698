import { createSvgIcon } from "../utils";

export const Departments = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 4.25C2.58579 4.25 2.25 4.58579 2.25 5C2.25 5.41421 2.58579 5.75 3 5.75V4.25ZM9 5.75C9.41422 5.75 9.75 5.41421 9.75 5C9.75 4.58579 9.41422 4.25 9 4.25V5.75ZM1 0.25C0.585786 0.25 0.25 0.585786 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75V0.25ZM5 1.75C5.41421 1.75 5.75 1.41421 5.75 1C5.75 0.585786 5.41421 0.25 5 0.25V1.75ZM3.75 1C3.75 0.585786 3.41421 0.25 3 0.25C2.58579 0.25 2.25 0.585786 2.25 1H3.75ZM9 13.75C9.41422 13.75 9.75 13.4142 9.75 13C9.75 12.5858 9.41422 12.25 9 12.25V13.75ZM3 5.75H9V4.25H3V5.75ZM1 1.75H5V0.25H1V1.75ZM2.25 1V12H3.75V1H2.25ZM2.25 12C2.25 12.4641 2.43438 12.9092 2.76256 13.2374L3.82322 12.1768C3.77634 12.1299 3.75 12.0663 3.75 12H2.25ZM2.76256 13.2374C3.09075 13.5656 3.53587 13.75 4 13.75V12.25C3.93369 12.25 3.87011 12.2237 3.82322 12.1768L2.76256 13.2374ZM4 13.75H9V12.25H4V13.75ZM10 3.75H16V2.25H10V3.75ZM16 3.75C16.1381 3.75 16.25 3.86193 16.25 4H17.75C17.75 3.0335 16.9665 2.25 16 2.25V3.75ZM16.25 4V6H17.75V4H16.25ZM16.25 6C16.25 6.13807 16.1381 6.25 16 6.25V7.75C16.9665 7.75 17.75 6.9665 17.75 6H16.25ZM16 6.25H10V7.75H16V6.25ZM10 6.25C9.86193 6.25 9.75 6.13807 9.75 6H8.25C8.25 6.9665 9.0335 7.75 10 7.75V6.25ZM9.75 6V4H8.25V6H9.75ZM9.75 4C9.75 3.86193 9.86193 3.75 10 3.75V2.25C9.0335 2.25 8.25 3.0335 8.25 4H9.75ZM10 11.75H16V10.25H10V11.75ZM16 11.75C16.1381 11.75 16.25 11.8619 16.25 12H17.75C17.75 11.0335 16.9665 10.25 16 10.25V11.75ZM16.25 12V14H17.75V12H16.25ZM16.25 14C16.25 14.1381 16.1381 14.25 16 14.25V15.75C16.9665 15.75 17.75 14.9665 17.75 14H16.25ZM16 14.25H10V15.75H16V14.25ZM10 14.25C9.86193 14.25 9.75 14.1381 9.75 14H8.25C8.25 14.9665 9.0335 15.75 10 15.75V14.25ZM9.75 14V12H8.25V14H9.75ZM9.75 12C9.75 11.8619 9.86193 11.75 10 11.75V10.25C9.0335 10.25 8.25 11.0335 8.25 12H9.75Z"
      fill="currentColor"
    />
  </svg>,
);
