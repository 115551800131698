import { Button } from "ebs-design";
import { useTranslation } from "react-i18next";

import { Flex } from "components";
import models from "models";
import { OrderDetailsProps } from ".";

export const OperatorOrderDetails = ({
  data,
  orderId,
  changeDeviceStatusModal,
}: OrderDetailsProps) => {
  const { t } = useTranslation();

  return (
    !data?.device?.approval &&
    data?.hidden_status === models.OrderStatus.DEVICE_DELIVERED && (
      <Flex align="center" justify="flex-end" gap="1rem">
        <Button
          type="light"
          onClick={() =>
            changeDeviceStatusModal.openWith({
              status: models.OrderStatus.PENDING_REJECTION,
              ids: [String(orderId)],
              reason: data?.device?.approval?.reason,
            })
          }
        >
          {t("common.reject")}
        </Button>

        <Button
          onClick={() =>
            changeDeviceStatusModal.openWith({
              status: models.OrderStatus.PENDING_COMPLETION,
              ids: [String(orderId)],
              reason: data?.device?.approval?.reason,
            })
          }
          type="primary"
        >
          {t("common.approve")}
        </Button>
      </Flex>
    )
  );
};
