import React from "react";
import { Tooltip } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
// import { Icon } from "components/icons";

interface Props extends FormFieldProps {
  info?: React.ReactNode;
  extra?: React.ReactNode;
}

export const FieldWithInfo = ({ extra, info }: Props) => {
  return (
    <>
      {extra}
      {info ? (
        <div className="label-info__tooltip">
          <Tooltip
            bodyClass="form-field__tooltip-info"
            placement="top"
            tooltip={<div dangerouslySetInnerHTML={{ __html: String(info) }} />}
            trigger="hover"
            offset={[0, 14]}
            delayHide={100}
          >
            {/* <Icon size={18} type="information" stroke="#d5dadd" /> */}
          </Tooltip>
        </div>
      ) : null}
    </>
  );
};
