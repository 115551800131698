import { Button, Card, Form, useForm, useNotify } from "ebs-design";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { NumberParam } from "use-query-params";

import api, { querykeys } from "api";
import {
  AddressMapFormField,
  Flex,
  InputFormField,
  LoadingButton,
  PageTitle,
  SwitchFormField,
  WeekScheduleFormField,
  WhiteSpace,
} from "components";
import { useParam } from "hooks";
import models from "models";
import { makeBEM, notifyErrors } from "utils";

import "leaflet/dist/leaflet.css";

const bem = makeBEM("map-form");

interface Props {
  edit: boolean;
}

export const WarehouseForm = ({ edit }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const warehouseId = useParam("id", NumberParam.decode) || 0;

  const { data } = useQuery(
    querykeys.warehouses.one(warehouseId),
    () => api.warehouses.getById(warehouseId),
    {
      enabled: warehouseId !== 0,
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const { mutate, isLoading } = useMutation(
    (body: models.Warehouse) =>
      edit && warehouseId
        ? api.warehouses.patch(warehouseId, body)
        : api.warehouses.post(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.warehouses.many());
        notify.success({
          title: t(
            edit
              ? "template.item_updated_successfully"
              : "template.item_created_successfully",
            { item: t("process_locations.process_locations") },
          ),
        });
        history.goBack();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  useEffect(() => {
    form.setFieldsValue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <PageTitle goBack>
        {edit
          ? t("template.edit_item", {
              item: t("process_locations.process_locations"),
            })
          : t("template.add_item", {
              item: t("process_locations.process_locations"),
            })}
      </PageTitle>
      <Card style={{ maxWidth: 1200, margin: "auto" }}>
        <Form
          form={form}
          name="warehouse"
          className="service-form"
          initialValues={data}
          onFinish={mutate}
        >
          <Card.Body>
            <h2 className="fw-600 ">{`${
              edit ? t("common.edit") : t("common.add")
            } ${t("process_locations.process_locations")}`}</h2>
            <div className={bem()}>
              <AddressMapFormField
                className="map"
                name="address"
                label={t("common.address")}
              />
              <div>
                <InputFormField
                  name="title"
                  label={t("common.location_name")}
                  placeholder={t("common.location_name")}
                />
                <InputFormField
                  textarea
                  name="description"
                  label={t("common.description")}
                  placeholder={t("common.description")}
                />
                <WeekScheduleFormField
                  name="working_hours"
                  label={t("common.working_hours")}
                />
              </div>

              <div className={bem("last-block")}>
                <SwitchFormField
                  labelOptions={{ col: 1 }}
                  controlOptions={{
                    col: 1,
                    className: "d-flex",
                    justify: "end",
                    align: "center",
                  }}
                  name="is_active"
                  label={t("common.active")}
                />
                <SwitchFormField
                  labelOptions={{ col: 1 }}
                  controlOptions={{
                    col: 1,
                    className: "d-flex",
                    justify: "end",
                    align: "center",
                  }}
                  name="is_default"
                  label={t("common.default")}
                />
              </div>
            </div>
          </Card.Body>

          <Card.Footer>
            <Flex justify="flex-end" align="center">
              <Button type="light" onClick={history.goBack}>
                {t("common.cancel")}
              </Button>
              <WhiteSpace h="8px" />
              <LoadingButton loading={isLoading} submit type="primary">
                {t("common.save")}
              </LoadingButton>
            </Flex>
          </Card.Footer>
        </Form>
      </Card>
    </>
  );
};
