import { Button, Card, Space, useNotify } from "ebs-design";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { NumberParam, useQueryParams } from "use-query-params";

import api, { querykeys } from "api";
import { Flex, PageTitle, QueryTable, TableTitle } from "components";
import { FilterBrands } from "components/filters";
import { useAuth, useQueryPagination } from "hooks";
import { CommaArrayParam, notifyErrors } from "utils";

import { nomenclatureModelsColumns } from "../config";

export const AddModels = () => {
  const { t } = useTranslation();
  const pagination = useQueryPagination();
  const notify = useNotify();
  const history = useHistory();
  const { organization } = useAuth();

  const [search, setSearch] = React.useState("");
  const [filterParams, setFilterParams] = useQueryParams({
    brand__id: NumberParam,
    brand__id__in: CommaArrayParam,
  });

  const [checkedModels, setCheckedModels] = React.useState<string[]>([]);

  const queryParams = {
    ...pagination.queryParams,
    search,
  };

  const query = useQuery(
    querykeys.nomenclature.models.many({
      ...queryParams,
      ...filterParams,
    }),
    () =>
      api.nomenclatures.models.get({
        ...queryParams,
        ...filterParams,
      }),
  );

  const allModelsId = query.data?.results
    ?.map((item) => String(item?.id))
    .filter((item) => item);

  const { mutate } = useMutation(api.brandsModels.connectModels, {
    onSuccess: () =>
      history.push("/product-management/models/add-models/success-connected"),
    onError: (errors: unknown) => notifyErrors(notify, errors),
  });

  const tableColumns = React.useMemo(
    () =>
      nomenclatureModelsColumns({
        setCheckedModels,
        checkedModels,
        allModelsId,
        currency: organization?.currency,
        t,
      }),
    [setCheckedModels, checkedModels, allModelsId, organization?.currency, t],
  );

  return (
    <>
      <PageTitle>
        {t("template.add_item", { item: t("model.models").toLowerCase() })}
      </PageTitle>
      <Card className="add-models-table">
        <Card.Body>
          <QueryTable
            title={() => (
              <Flex justify="space-between" align="center">
                <Space size={5}>
                  <TableTitle
                    className="px-0"
                    search={search}
                    setSearch={setSearch}
                  />
                  <FilterBrands
                    value={filterParams.brand__id}
                    onChange={(value) => {
                      pagination.setPage(1);
                      setFilterParams({ brand__id: value as number });
                    }}
                    placeholder="Filter by brand"
                    size="large"
                  />
                </Space>
                <Button
                  type="primary"
                  onClick={() =>
                    mutate({
                      brand_models_id: checkedModels.map((v) => Number(v)),
                    })
                  }
                >
                  {t("common.save")}
                </Button>
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            className="people-table"
            pagination={pagination}
          />
        </Card.Body>
      </Card>
    </>
  );
};
