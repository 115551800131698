import { useTranslation } from "react-i18next";

import { Grid, PageTitle } from "components";

import { useScreenBreakPoint } from "hooks";
import {
  Customers,
  DropOffAndProcessLocation,
  OrderStatistics,
  OrderTotalsByStatus,
  PayoutAndPayment,
  UsersAndDepartments,
} from "../components";

export const Dashboard = () => {
  const { t } = useTranslation();
  const breakPoint = useScreenBreakPoint("mobileLarge");

  const cols = !breakPoint ? "repeat( auto-fit, minmax(420px, 1fr) )" : "1fr";

  return (
    <>
      <PageTitle>{t("menu.dashboard")}</PageTitle>
      <Grid
        cols={cols}
        gap="24px"
        className="mb-24"
        style={{ alignItems: "flex-start" }}
      >
        <Grid gap="24px">
          <OrderStatistics />
          <PayoutAndPayment />
          <UsersAndDepartments />
        </Grid>
        <Grid gap="24px">
          <Customers />
          <DropOffAndProcessLocation />
          <OrderTotalsByStatus />
        </Grid>
      </Grid>
    </>
  );
};
