import { Button, Card, useNotify } from "ebs-design";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import api, { querykeys } from "api";
import {
  ConfirmationModal,
  Flex,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import { useModalState, useQueryPagination } from "hooks";
import { notifyErrors } from "utils";

import { warehouseColumns } from "../config";

export const Warehouses = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const deleteWarehouseModal = useModalState<string | number>();
  const [search, setSearch] = React.useState("");
  const pagination = useQueryPagination();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const queryParams = {
    ...pagination.queryParams,
    search,
  };

  const query = useQuery(querykeys.warehouses.many(queryParams), () =>
    api.warehouses.get(queryParams),
  );

  const mutation = useMutation((id: number) => api.warehouses.delete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(querykeys.warehouses.many());
      notify.success({
        title: t("template.item_deleted_successfully", {
          item: t("process_locations.process_locations"),
        }),
      });
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const tableColumns = warehouseColumns({
    deleteWarehouseModal: deleteWarehouseModal.openWith,
    t,
  });

  return (
    <>
      <PageTitle
        actions={
          <Button
            type="primary"
            onClick={() => history.push("/warehouse/warehouses/create")}
          >
            {t("template.add_item", {
              item: t("process_locations.process_locations"),
            })}
          </Button>
        }
        title={t("menu.process_locations")}
        count={query.data?.count}
      />
      <Card>
        <Card.Body>
          <QueryTable
            variants={["highlight-hover"]}
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                />
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            onRow={(data) => ({
              onClick: () =>
                history.push(`/warehouse/warehouses/edit/${data?.id}`),
            })}
            rowKey="id"
          />
        </Card.Body>
      </Card>

      {deleteWarehouseModal.isOpen && (
        <ConfirmationModal
          open={deleteWarehouseModal.isOpen}
          onClose={deleteWarehouseModal.close}
          onConfirm={() =>
            mutation.mutate(Number(deleteWarehouseModal.data) || 0)
          }
          isLoading={mutation.isLoading}
          title={t("template.delete_item", {
            item: t("process_locations.process_locations").toLowerCase(),
          })}
          confirmText={t("common.delete")}
        >
          <p>
            {`${t("template.are_you_sure_you_want_to_delete_this_item", {
              item: t("process_locations.process_locations").toLowerCase(),
            })} - ${deleteWarehouseModal.data}?`}
          </p>
        </ConfirmationModal>
      )}
    </>
  );
};
