import { LatLngLiteral } from "leaflet";
import { useEffect, useState } from "react";
import { Popup, useMap, useMapEvents } from "react-leaflet";

import { MarkerWithOpenPopUp } from ".";
import { IconMarker } from ".";

const GEOCODE_URL =
  "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location=";

const reverseGeoCoding = async (coordinates: LatLngLiteral) => {
  const data = await (
    await fetch(GEOCODE_URL + `${coordinates.lng},${coordinates.lat}`)
  ).json();

  return data.address.Match_addr;
};

interface Props {
  onChange: React.Dispatch<React.SetStateAction<any>>;
  setPosition: React.Dispatch<React.SetStateAction<LatLngLiteral>>;
}

export const ShowLocationOnClick = ({
  onChange,
  setPosition: setPositionToFields,
}: Props) => {
  const [position, setPosition] = useState<LatLngLiteral>();
  const [address, setAddress] = useState();
  const map = useMap();

  useEffect(() => {
    const updateAddress = async () => {
      if (!position) return;

      const tempAddress = await reverseGeoCoding(position);
      setAddress(tempAddress);
      onChange(tempAddress);
    };

    updateAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  useMapEvents({
    click(event) {
      setPosition(event.latlng);
      setPositionToFields({ lat: event.latlng.lat, lng: event.latlng.lng });
      if (event.target._zoom < 13) map.flyTo(event.latlng, 13);
    },
  });

  if (!position) return null;
  return (
    <MarkerWithOpenPopUp position={position} icon={IconMarker}>
      <Popup>{address}</Popup>
    </MarkerWithOpenPopUp>
  );
};
