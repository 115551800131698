import { Col, Row } from "ebs-design";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { PageTitle } from "components";
import api, { querykeys } from "api";
import {
  CustomizationCard,
  FinancialCard,
  LanguagesCard,
  OrganizationCard,
  PaymentsCard,
  ReminderCard,
  ServiceManagementCard,
} from "../components";

export const Settings = () => {
  const { t } = useTranslation();

  const defaultSettings = useQuery(
    querykeys.settings.defaultSettings,
    api.settings.defaultSettings,
  );

  const organization = useQuery(
    querykeys.settings.organization,
    api.settings.getOrganizationData,
  );

  return (
    <>
      <PageTitle>{t("menu.settings")}</PageTitle>
      <Row g={4} className="mb-24">
        <Col size={12} lg={6}>
          <CustomizationCard organizationData={organization.data} />
        </Col>
        <Col size={12} lg={6}>
          <ServiceManagementCard organizationData={organization.data} />
        </Col>
      </Row>
      <Row g={4} className="mb-24">
        <Col size={12} lg={6}>
          <PaymentsCard organizationData={organization.data} />
        </Col>
        <Col size={12} lg={6}>
          <OrganizationCard />
        </Col>
      </Row>
      <Row g={4} className="mb-24">
        <Col size={12} lg={6}>
          <ReminderCard defaultSettings={defaultSettings.data} />
        </Col>
        <Col size={12} lg={6}>
          <LanguagesCard />
        </Col>
      </Row>
      <Row g={4}>
        <Col size={12}>
          <FinancialCard organizationData={organization.data} />
        </Col>
      </Row>
    </>
  );
};
