import { Form } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import { InputSize } from "ebs-design/dist/components/atoms/Input/Input";
import cn from "classnames";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import { useTranslation } from "react-i18next";

import { makeBEM } from "utils";

interface PhoneFormFieldProps extends PhoneInputProps, FormFieldProps {
  className?: string;
  placeholder?: string;
  size?: InputSize;
  disabled?: boolean;
}
const bem = makeBEM("custom-phone-input");

export const PhoneFormField: React.FC<PhoneFormFieldProps> = ({
  country = "de",
  size = "large",
  placeholder = "",
  disabled = false,
  className,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Form.Field {...props} className={cn(className, bem())}>
      {({ value, onChange }, __, form) => {
        const hasErrors = form.getFieldError(props.name).length > 0;

        return (
          <PhoneInput
            enableSearch
            searchPlaceholder={t("search.search")}
            searchNotFound={t("common.no_results")}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            inputClass={bem("input", {
              large: size === "large",
              disabled,
              hasErrors,
            })}
            buttonClass={cn(bem("button"), "slim-scrollbar")}
            dropdownClass={bem("dropdown")}
            searchClass={bem("search")}
            {...props}
          />
        );
      }}
    </Form.Field>
  );
};
