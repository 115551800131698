import { Button } from "ebs-design";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { Form, Row, Col, Tabs, Input, Select } from "antd";
import api, { MenuType } from "apps/cms/lib/api/menu";
import { LanguagesContext } from "apps/cms/context/Languages";
import { Properties } from "types";

import Builder from "./Builder";
import { normalizeTree } from "./Builder/utils";

//const fields = ['title', 'content', 'preview'];

const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
};

const formItemStyle = {
  marginBottom: 0,
  lineHeight: "initial",
};

export const saveMenu = (
  data: Properties[],
  menuId: number,
  cache: Properties = {},
) => {
  data.forEach((item, i) => {
    cache[item.article_id] = api
      .addMenu({
        article_id: item.article_id,
        parent_id: menuId,
        data: { order: i, position: item.data.position },
      })
      .then(({ id }) => {
        if (item.children) {
          saveMenu(item.children, id, cache[item.article_id]);
        }
      });
  });
};

interface MenuFormProps {
  /**
   * Article to edit, this is used only on first render.
   */
  initialValue?: Properties;
  loading?: boolean;

  /**
   * Called after succesful save.
   */
  onSaved: () => void;
}

const MenuForm: React.FC<MenuFormProps> = ({
  initialValue,
  onSaved,
  loading,
}) => {
  const {
    languages: { data: languages },
  } = React.useContext(LanguagesContext);
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [activeTabKey = "en", setActiveTabKey] = useQueryParam(
    "lang",
    StringParam,
  );
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (initialValue) {
      form.setFieldsValue({
        title: initialValue?.title,
        type: initialValue?.type || MenuType.HEADER,
        children: initialValue?.children || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onSubmit = async ({
    children,
    ...values
  }: Properties): Promise<void> => {
    setIsLoading(true);

    let menuId: number;
    if (!id) {
      menuId = await api.addMenu({ ...values }).then((menu) => menu.id);
    } else {
      menuId = parseInt(id);

      api.editMenu({ ...values }, id);

      (initialValue?.children || []).forEach((item: Properties) =>
        api.deleteMenu(item.id),
      );
    }

    children = normalizeTree(children, (item, parent_id, i) => {
      item = {
        data: { order: i, position: item.data.position },
        article_id: item.id,
        ...(parent_id && { parent_id }),
        ...(item.children && { children: item.children }),
      };

      return item;
    });

    saveMenu(children, menuId);

    setIsLoading(false);
    onSaved();
  };

  return (
    <Form form={form} {...formItemLayout} colon={false} onFinish={onSubmit}>
      <Row gutter={[16, 16]} justify="end">
        <Col span={24}>
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col>
              <Form.Item name="title" style={formItemStyle}>
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row gutter={[16, 16]} align="middle" justify="end">
                <Col span={8}>
                  <Form.Item name="type" style={formItemStyle}>
                    <Select placeholder="Type">
                      <Select.Option value={MenuType.HEADER}>
                        Header
                      </Select.Option>
                      <Select.Option value={MenuType.FOOTER}>
                        Footer
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Link to="/menu">
                    <Button>{t("common.cancel")}</Button>
                  </Link>
                </Col>
                <Col>
                  <Button type="primary" submit loading={isLoading}>
                    {t("common.save")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className="background-white"
          style={{ marginTop: 10, paddingBottom: 15 }}
        >
          <Row>
            <Col span={24}>
              <Tabs
                className="custom-tabs custom-tabs--hide-content"
                activeKey={activeTabKey}
                animated={false}
                onChange={setActiveTabKey}
              >
                {languages.map(({ code }) => {
                  return <TabPane tab={code.toUpperCase()} key={code} />;
                })}
              </Tabs>
            </Col>
            <Col span={24}>
              <Form.Item
                name="children"
                initialValue={initialValue?.children}
                style={formItemStyle}
              >
                <Builder
                  isEdit={!!id}
                  activeTabKey={activeTabKey}
                  loading={loading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default MenuForm;
