import React from "react";

export const useScreenResize = (target: HTMLElement, size: number) => {
  const [on, setOn] = React.useState(false);

  React.useEffect(() => {
    const resize = () => {
      const width = target?.offsetWidth;
      if (width <= size) {
        setOn(true);
      } else {
        setOn(false);
      }
    };
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [target, size]);

  return on;
};
