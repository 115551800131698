import { PropsWithChildren, ReactNode } from "react";
import { UseMutationResult, UseQueryResult } from "react-query";

import { AxiosError } from "axios";

import { Loader } from "ebs-design";

import { AlertErrors } from ".";

export interface QueryContentProps {
  query:
    | UseQueryResult<unknown, unknown>
    | UseMutationResult<unknown, unknown, unknown, unknown>;
  showOnIdle?: boolean;
  showOn404?: boolean;
  getLoader?: () => ReactNode;
  getError?: () => ReactNode;
  getIdleContent?: () => ReactNode;
  children?: ReactNode | ((data: unknown) => ReactNode);
}

export const QueryContent = ({
  query,
  showOnIdle,
  showOn404,
  getLoader = () => <Loader loading />,
  getError = () => <AlertErrors error={query.error} />,
  children,
  getIdleContent = () => children,
}: PropsWithChildren<QueryContentProps>) => {
  let result = null;

  const error = query.error as AxiosError | undefined;

  const renderChildren = () =>
    children instanceof Function ? children(query.data) : children;

  if (query.isLoading) result = getLoader();
  else if (showOn404 && error?.response?.status === 404)
    result = renderChildren();
  else if (query.isError) result = getError();
  else if (query.isSuccess) result = renderChildren();
  else if (showOnIdle && query.isIdle) result = getIdleContent();

  return <>{result}</>;
};
