import { LatLngExpression, LatLngLiteral } from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import AbstractProvider, {
  SearchResult,
} from "leaflet-geosearch/dist/providers/provider";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";

import { MAP_CONTAINER_DEFAULT_CONFIG, TILE_LAYER_CONFIG } from "app-constants";

import { LocationMarker } from ".";
import { IconMarker } from ".";
import { Search } from ".";
import { ShowLocationOnClick } from ".";

import "leaflet-geosearch/assets/css/leaflet.css";
import "leaflet/dist/leaflet.css";

const getInitialLocation = async (prov: AbstractProvider, input: string) => {
  if (!input) return 0;
  return await prov.search({ query: input });
};

type ValueChangeType = {
  value: string;
  onChange: () => void;
  setPosition: React.Dispatch<React.SetStateAction<LatLngLiteral>>;
};

const ChangeMapView = ({ coords }: { coords: LatLngExpression }) => {
  const map = useMap();

  useEffect(() => {
    map.setView(coords, 13);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export const AddressMapInput = ({
  value,
  onChange,
  setPosition,
}: ValueChangeType) => {
  const { t } = useTranslation();
  const prov = new OpenStreetMapProvider();
  const [location, setLocation] = useState<SearchResult<any>>();

  useEffect(() => {
    const getLocation = async () => {
      const data = await getInitialLocation(prov, value);
      setLocation(data[0]);
      setPosition({ lat: data[0]?.y, lng: data[0]?.x });
    };

    getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <MapContainer
      style={{ height: "100%" }}
      center={
        location ? [location.y, location.x] : MAP_CONTAINER_DEFAULT_CONFIG
      }
      zoom={4}
    >
      <TileLayer {...TILE_LAYER_CONFIG} />
      <Search
        // eslint-disable-next-line react/style-prop-object
        style="bar"
        inputValue={value}
        setInputValue={onChange}
        marker={{ icon: IconMarker }}
        provider={prov}
        showMarker={true}
        showPopup={false}
        popupFormat={({ query, result }) => result.label}
        maxMarkers={1}
        retainZoomLevel={false}
        animateZoom={true}
        autoClose={false}
        keepResult={false}
        maxSuggestions={8}
        searchLabel={value || t("common.enter_address")}
        notFoundMessage={t("common.not_found")}
        setPosition={setPosition}
      />
      <LocationMarker />
      <ShowLocationOnClick onChange={onChange} setPosition={setPosition} />
      {location && (
        <>
          <Marker position={[location.y, location.x]} icon={IconMarker}>
            <Popup>{value}</Popup>
          </Marker>
          <ChangeMapView coords={[location.y, location.x]} />
        </>
      )}
    </MapContainer>
  );
};
