import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import api, { querykeys } from "api";
import { QueryTable } from "components";
import { useDataErasureVisibility } from "hooks";
import models from "models";
import { buybackOrdersChildColumns } from "../config";

interface ParentId {
  parent_id: number;
}
interface OrderWithParentId extends models.Order, ParentId {}

interface RowTableProps {
  orderQueryParams: {
    parent__id: number;
    device__brand_model__brand__id__in: string[];
    device__brand_model__id__in: string[];
  };
  cancelOrder: React.Dispatch<{ id: number; parent_id: number }>;
  editOrder: React.Dispatch<OrderWithParentId>;
}

export const RowTable = ({
  orderQueryParams,
  cancelOrder,
  editOrder,
}: RowTableProps) => {
  const { t } = useTranslation();
  const dataErasureVisible = useDataErasureVisibility();

  const queryById = useQuery(
    querykeys.crmOrders.one(orderQueryParams.parent__id!),
    () => api.orders.get(orderQueryParams),
    {
      enabled: !!orderQueryParams.parent__id,
    },
  );

  const tableColumns = React.useMemo(
    () =>
      buybackOrdersChildColumns({
        t,
        dataErasureVisible,
        cancelOrder,
        editOrder,
        parent_id: orderQueryParams.parent__id,
      }),
    [
      t,
      cancelOrder,
      editOrder,
      dataErasureVisible,
      orderQueryParams.parent__id,
    ],
  );

  return (
    <QueryTable columns={tableColumns} query={queryById} scroll={{ x: true }} />
  );
};
