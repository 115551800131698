import React from "react";
import { useCounter } from "react-use";
import { inDevelompent } from "utils";

export function useLayoutContainer() {
  const [rerendered, { inc: rerender }] = useCounter();

  const $container = document.querySelector(".ebs-layout__content");

  React.useEffect(() => rerender(), [rerender]);

  if (!rerendered) return null;
  if (!$container) {
    if (inDevelompent()) console.warn("Layout container not found");
    return null;
  }
  return $container;
}
