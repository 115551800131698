import { Button } from "ebs-design";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import moment from "moment";
import { Modal, Row, Table, Badge, Col } from "antd";
import { PageTitle } from "components";
import { MenuOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/lib/table";
import { defaultLocale } from "apps/cms/lib/utils";
import faq, { QandA } from "apps/cms/lib/api/faq";
import FrequentlyAskedQuestionModal from "apps/cms/components/FAQ/FrequentlyAskedQuestionModal";
import { getMissingTranslationsKeys } from "utils";
import models from "models";
import * as Icons from "components/icons";

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const FrequentlyAskedQuestion: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [selectedId, setSelectedId] = useState<number>();
  const [lang, setLang] = useState<string>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<models.WithResults<QandA>>();
  const [sorted, setSorted] = useState(false);
  const [loading, setLoading] = useState(false);

  const paramsEntries = new URLSearchParams(window.location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;

  useEffect(() => {
    if (data?.results && sorted) {
      const updateOrder = async (id: number, order: number) => {
        await faq.upsert({ id, order } as QandA);
      };

      data?.results.forEach(({ id }, i) => {
        updateOrder(id, i + 1);
      });
    }
  }, [data, sorted]);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      setData(await faq.getAll({ page, ordering: "order" }));
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, [page]);

  const onRemove = async ({ id }: QandA): Promise<void> => {
    Modal.confirm({
      title: "Want to delete this item?",
      onOk: async () => {
        try {
          await faq.remove(id);
          fetch();
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  const handleOnSuccess = (): void => {
    fetch();
    setOpenModal(false);
    setSelectedId(undefined);
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleOpenModal = (): void => setOpenModal(true);

  const columns: ColumnProps<QandA>[] = [
    {
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: t("common.id"),
      dataIndex: "id",
    },
    {
      title: t("common.question"),
      render: ({ i18n }) => {
        const { question = "- - -" } = i18n[defaultLocale];

        return question;
      },
    },

    // {
    //   title: t("common.positive_votes"),
    //   dataIndex: "upvote",
    // },
    // {
    //   title: t("common.negative_votes"),
    //   dataIndex: "downvote",
    // },

    {
      width: 175,
      title: t("common.publication_date"),
      render: ({ timestamp }) =>
        `${moment(timestamp).format("DD/MM/YYYY, HH:mm")}`,
    },
    {
      width: 175,
      title: t("common.last_edit"),
      render: ({ edited_timestamp }) =>
        `${moment(edited_timestamp).format("DD/MM/YYYY, HH:mm")}`,
    },
    {
      title: t("common.translations"),
      width: 50,
      className: "no-wrap",
      render: ({ id, i18n }) => {
        const keys = getMissingTranslationsKeys(i18n);

        return (
          <Row gutter={[8, 8]} justify="end" className="flex-no-wrap">
            {Object.keys(keys).map((key: any, i) => (
              <Col style={{ ...(!!i && { marginLeft: 10 }) }}>
                <Badge
                  count={keys[key]}
                  size="small"
                  status={keys[key] ? "error" : "success"}
                >
                  <Button
                    size="small"
                    type="ghost"
                    onClick={() => {
                      setSelectedId(id);
                      setLang(key);
                      setOpenModal(true);
                    }}
                  >
                    {key.toUpperCase()}
                  </Button>
                </Badge>
              </Col>
            ))}
          </Row>
        );
      },
    },
    {
      title: t("common.actions"),
      width: 50,
      align: "right",
      className: "no-wrap",
      render: (vacancy) => {
        return (
          <Row gutter={[10, 10]} justify="end" className="flex-no-wrap">
            <Col>
              <Button
                icon={() => <Icons.Edit size="small" />}
                type="light"
                onClick={() => {
                  setSelectedId(vacancy.id);
                  setOpenModal(true);
                }}
              />
            </Col>
            <Col>
              <Button
                type="light"
                icon={() => <Icons.Trash size="small" />}
                onClick={() => onRemove(vacancy)}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const results =
        data?.results &&
        arrayMoveImmutable(
          [].concat(data.results as []),
          oldIndex,
          newIndex,
        ).filter((el) => !!el);

      setData(
        (prevState) =>
          ({ ...prevState, results: results || prevState?.results } as any),
      );
      setSorted(true);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data?.results.findIndex(
      ({ id }) => id === restProps["data-row-key"],
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <>
      <PageTitle
        actions={
          <Button type="primary" onClick={handleOpenModal}>
            {t("template.add_item", {
              item: t("common.question").toLowerCase(),
            })}
          </Button>
        }
        title={t("menu.help_and_support")}
        count={data?.count}
      />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table
            columns={columns}
            loading={loading}
            dataSource={data ? data.results : []}
            rowKey="id"
            pagination={{
              hideOnSinglePage: true,
              current: page,
              pageSize: 10,
              total: data ? data.count : 0,
            }}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            onChange={({ current }, filters: any) => {
              history.push(
                `?${new URLSearchParams({
                  ...params,
                  ...filters,
                  page: String(current),
                }).toString()}`,
              );
            }}
          />
        </Col>

        {openModal && (
          <FrequentlyAskedQuestionModal
            id={selectedId}
            lang={lang}
            onSuccess={handleOnSuccess}
            onClose={() => {
              setOpenModal(false);
              setLang(undefined);
            }}
          />
        )}
      </Row>
    </>
  );
};

export default FrequentlyAskedQuestion;
