import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useMutation } from "react-query";
import { t } from "i18next";

import api from "api";
import { Flex, InputFormField, LoadingButton, WhiteSpace } from "components";
import models from "models";
import { notifyErrors } from "utils";

interface PaymentKeysFormModalProps extends ModalProps {
  data?: models.PaymentKeys;
  onClose: () => void;
}

export const PaymentKeysFormModal = ({
  data,
  onClose,
  ...props
}: PaymentKeysFormModalProps) => {
  const [form] = useForm();
  const notify = useNotify();

  const { mutate, isLoading } = useMutation(
    (body: models.PaymentKeys) => api.paymentMethods.postApiKeys(data.id, body),
    {
      onSuccess: () => {
        notify.success({
          title: t("template.item_created_successfully", {
            item: t("payment.payment_keys"),
          }),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Modal
      title={t("template.add_item", {
        item: t("payment.payment_keys"),
      })}
      size="small"
      onClose={onClose}
      className="add-service-modal"
      {...props}
    >
      <Form
        form={form}
        name="payment-api-keys-form"
        onFinish={(values) => mutate(values)}
        className="service-form"
        initialValues={{
          api_key: data.api_key || "",
          public_api_key: data.public_api_key || "",
        }}
      >
        <Modal.Content>
          <InputFormField
            name="public_api_key"
            label={t("payment.payment_public_api_key")}
          />
          <InputFormField name="api_key" label={t("payment.payment_api_key")} />
        </Modal.Content>

        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton loading={isLoading} submit type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
