import { Button } from "ebs-design";
import { FC, useEffect, useState, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { stringifyUrl } from "query-string";
import pages, {
  Article as IArticle,
  StateT,
  StateTColors,
} from "apps/cms/lib/api/pages";
import { ArticleActions } from "apps/cms/components/Articles";
import { Table, Modal, Row, Col, Badge, Tag, Input } from "antd";
import { ColumnProps } from "antd/lib/table";
import { PageTitle } from "components";
import moment from "moment";
import { defaultLocale } from "apps/cms/lib/utils";
import { Tag as TagType } from "apps/cms/lib/api/tags";
import { ContentContext } from "apps/cms/context/Content";
import { getMissingTranslationsKeys } from "utils";
import models from "models";

import { filters } from ".";

// Items per page
const PAGE_SIZE = 10;

const ArticlePage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { getInitialContent } = useContext(ContentContext);

  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState<models.WithResults<IArticle>>();
  const [loading, setLoading] = useState(false);

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const search = params.search || undefined;
  const tags = params.tags;

  const fetchArticles = useCallback(async () => {
    setLoading(true);
    try {
      setData(
        await pages
          .all({
            name_content_icontains: search,
            page,
            tags,
            page_size: PAGE_SIZE,
          })
          .then((response) => {
            if (!response.count) {
              getInitialContent();
            }

            return response;
          }),
      );
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, tags]);

  const onRemove = async ({ id }: IArticle): Promise<void> => {
    Modal.confirm({
      title: "Want to delete this page?",
      onOk: async () => {
        try {
          await pages.remove(id);
          fetchArticles();
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  const onSearchHandler = (): void => {
    history.push(
      stringifyUrl({
        url: "/cms/pages",
        query: {
          ...(searchValue && { search: searchValue }),
          ...(tags && { tags }),
        },
      }),
    );
  };

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const columns: ColumnProps<IArticle>[] = [
    {
      title: t("common.id"),
      render: (_, __, idx) => PAGE_SIZE * (page - 1) + ++idx,
    },
    {
      title: t("common.title"),
      render: ({ i18n }) => {
        const { title = "- - -" } = i18n[defaultLocale] || {};

        return title;
      },
    },
    {
      width: 175,
      title: t("common.publication_date"),
      render: ({ publish_date }) =>
        `${moment(publish_date).format("DD/MM/YYYY, HH:mm")}`,
    },
    {
      title: t("common.images"),
      render: ({ images }) => images?.length || "- - -",
    },
    {
      title: t("common.status"),
      dataIndex: "state",
      render: (record) => (
        <Tag color={StateTColors[record as keyof typeof StateTColors]}>
          {StateT[record as keyof typeof StateT]}
        </Tag>
      ),
    },
    {
      width: "10%",
      title: t("common.tags"),
      dataIndex: "tags",
      className: "no-wrap",
      render: (tags) =>
        Array.isArray(tags) &&
        tags.map((tag: TagType) => tag.i18n[defaultLocale]).join(", "),
      filters,
      defaultFilteredValue: tags ? tags.split(",") : [],
    },
    {
      title: t("common.translations"),
      width: 50,
      className: "no-wrap",
      render: ({ id, i18n }) => {
        const keys = getMissingTranslationsKeys(i18n);

        return (
          <Row gutter={[8, 8]} justify="end" className="flex-no-wrap">
            {Object.keys(keys).map((key: any, i) => (
              <Col style={{ ...(!!i && { marginLeft: 10 }) }}>
                <Badge
                  count={keys[key]}
                  size="small"
                  status={keys[key] ? "error" : "success"}
                >
                  <Button
                    size="small"
                    type="ghost"
                    onClick={() =>
                      history.push(
                        stringifyUrl({
                          url: `/cms/pages/edit/${id}`,
                          query: { lang: key },
                        }),
                      )
                    }
                  >
                    {key.toUpperCase()}
                  </Button>
                </Badge>
              </Col>
            ))}
          </Row>
        );
      },
    },
    {
      title: t("common.actions"),
      width: 50,
      align: "right",
      className: "no-wrap",
      render: (article) => {
        return <ArticleActions article={article} onRemove={onRemove} />;
      },
    },
  ];

  return (
    <>
      <PageTitle
        actions={
          <Button
            type="primary"
            onClick={() => history.push("/cms/pages/create")}
          >
            {t("template.add_item", {
              item: t("common.page").toLowerCase(),
            })}
          </Button>
        }
        title={t("menu.pages")}
        count={data?.count}
      />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row
            gutter={[16, 16]}
            justify="space-between"
            align="middle"
            className="flex-no-wrap"
          >
            <Col>
              <Input.Search
                enterButton={t("search.search")}
                placeholder={t("search.search_page_by_title")}
                size="large"
                value={searchValue}
                style={{ width: "auto" }}
                onSearch={onSearchHandler}
                onChange={({ target: { value } }) => setSearchValue(value)}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            loading={loading}
            dataSource={data ? data.results : []}
            rowKey="id"
            childrenColumnName=""
            pagination={{
              hideOnSinglePage: true,
              current: page,
              pageSize: PAGE_SIZE,
              total: data ? data.count : 0,
            }}
            onChange={({ current }, filters: any) => {
              history.push(
                stringifyUrl(
                  {
                    url: "/cms/pages",
                    query: {
                      ...params,
                      ...filters,
                      page: current,
                    },
                  },
                  { arrayFormat: "comma" },
                ),
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ArticlePage;
