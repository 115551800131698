import { createSvgIcon } from "../utils";

export const DeletedUser = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M0.25 19C0.25 19.4142 0.585786 19.75 1 19.75C1.41421 19.75 1.75 19.4142 1.75 19H0.25ZM5 13V12.25V13ZM9 13V12.25V13ZM13 17H13.75H13ZM12.25 19C12.25 19.4142 12.5858 19.75 13 19.75C13.4142 19.75 13.75 19.4142 13.75 19H12.25ZM15.5303 6.46967C15.2374 6.17678 14.7626 6.17678 14.4697 6.46967C14.1768 6.76256 14.1768 7.23744 14.4697 7.53033L15.5303 6.46967ZM18.4697 11.5303C18.7626 11.8232 19.2374 11.8232 19.5303 11.5303C19.8232 11.2374 19.8232 10.7626 19.5303 10.4697L18.4697 11.5303ZM19.5303 7.53033C19.8232 7.23744 19.8232 6.76256 19.5303 6.46967C19.2374 6.17678 18.7626 6.17678 18.4697 6.46967L19.5303 7.53033ZM14.4697 10.4697C14.1768 10.7626 14.1768 11.2374 14.4697 11.5303C14.7626 11.8232 15.2374 11.8232 15.5303 11.5303L14.4697 10.4697ZM10.25 5C10.25 6.79493 8.79493 8.25 7 8.25V9.75C9.62335 9.75 11.75 7.62335 11.75 5H10.25ZM7 8.25C5.20507 8.25 3.75 6.79493 3.75 5H2.25C2.25 7.62335 4.37665 9.75 7 9.75V8.25ZM3.75 5C3.75 3.20507 5.20507 1.75 7 1.75V0.25C4.37665 0.25 2.25 2.37665 2.25 5H3.75ZM7 1.75C8.79493 1.75 10.25 3.20507 10.25 5H11.75C11.75 2.37665 9.62335 0.25 7 0.25V1.75ZM1.75 19V17H0.25V19H1.75ZM1.75 17C1.75 16.138 2.09241 15.3114 2.7019 14.7019L1.64124 13.6412C0.750445 14.532 0.25 15.7402 0.25 17H1.75ZM2.7019 14.7019C3.3114 14.0924 4.13805 13.75 5 13.75V12.25C3.74022 12.25 2.53204 12.7504 1.64124 13.6412L2.7019 14.7019ZM5 13.75H9V12.25H5V13.75ZM9 13.75C9.86195 13.75 10.6886 14.0924 11.2981 14.7019L12.3588 13.6412C11.468 12.7504 10.2598 12.25 9 12.25V13.75ZM11.2981 14.7019C11.9076 15.3114 12.25 16.138 12.25 17H13.75C13.75 15.7402 13.2496 14.532 12.3588 13.6412L11.2981 14.7019ZM12.25 17V19H13.75V17H12.25ZM14.4697 7.53033L18.4697 11.5303L19.5303 10.4697L15.5303 6.46967L14.4697 7.53033ZM18.4697 6.46967L14.4697 10.4697L15.5303 11.5303L19.5303 7.53033L18.4697 6.46967Z"
      fill="currentColor"
    />
  </svg>,
);
