import { useEffect, useState } from "react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { notification, Spin } from "antd";
import { MenuForm } from "apps/cms/components/Menu";
import api from "apps/cms/lib/api/menu";
import { Properties } from "types";

enum Operation {
  CREATE = "create",
  EDIT = "edit",
}

const EditPage: React.FC = () => {
  const history = useHistory();
  const { id, tab } = useParams<{ id: string; tab: Operation }>();
  const [menu, setMenu] = useState<Properties>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (id && tab === Operation.EDIT) {
      try {
        api.getMenuById(id).then((data) => {
          setMenu(data);
          setLoading(false);
        });
      } catch (e) {
        history.push("/cms/menu");

        return notification.error({ message: "Nonexistent Menu" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tab]);

  switch (tab) {
    case Operation.CREATE:
      return <MenuForm onSaved={() => history.push("/cms/menu")} />;
    case Operation.EDIT:
      return (
        <Spin spinning={!menu} delay={300}>
          <MenuForm
            initialValue={menu}
            loading={loading}
            onSaved={() => history.push("/cms/menu")}
          />
        </Spin>
      );

    default:
      return <Redirect to="/cms/menu" />;
  }
};

export default EditPage;
