import { Button, Form, Modal, Select, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import models from "models";
import { notifyErrors, transformEnumToArray } from "utils";
import api, { querykeys } from "api";
import { Flex, LoadingButton, WhiteSpace } from "components";

interface EditPaymentModalProps extends ModalProps {
  data?: models.Payment;
  onClose: () => void;
}

const paymentStatusOptions = transformEnumToArray(models.PaymentInvoiceStatus);

export const EditPaymentModal = ({
  data,
  onClose,
  ...props
}: EditPaymentModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (body: models.Payment) => api.payments.patch(data?.id || 0, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.payments.many());
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("payment.payment"),
          }),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Modal
      title={`${t("template.edit_item", {
        item: t("invoice.invoice").toLowerCase(),
      })} - ${data?.invoice?.code}`}
      size="small"
      onClose={onClose}
      {...props}
    >
      <Form
        form={form}
        name="invoice"
        initialValues={{ invoice_status: data?.invoice?.status }}
        onFinish={mutate}
      >
        <Modal.Content>
          <Form.Field name="invoice_status">
            <Select options={paymentStatusOptions} />
          </Form.Field>
        </Modal.Content>

        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton loading={isLoading} submit type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
