import React from "react";
import { Button } from "ebs-design";
import { UseInfiniteQueryResult } from "react-query";
import { useTranslation } from "react-i18next";

export interface InfiniteScrollTriggerProps {
  data: unknown[];
  query: UseInfiniteQueryResult;
}

export const InfiniteScrollTrigger = React.forwardRef<
  HTMLDivElement,
  InfiniteScrollTriggerProps
>(({ query, data }, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} className="text-center m-15">
      {data?.length
        ? query.hasNextPage && (
            <Button
              onClick={() => query.fetchNextPage()}
              disabled={!query.hasNextPage || query.isFetchingNextPage}
            >
              {query.isFetchingNextPage
                ? t("common.loading") + "..."
                : query.hasNextPage
                ? t("common.load_more")
                : null}
            </Button>
          )
        : null}
    </div>
  );
});
