import models from "models";
import { GenericObject, UpdateCriterion } from "types";
import { stringifyUrl } from "./utils";
import { apiAxios } from "./axios";

export const brands = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/organization/brands", queryParams);
    const { data } = await apiAxios.get<
      models.WithResults<models.OrganizationBrand>
    >(url);
    return data;
  },
  getById: async (id: number) => {
    const { data } = await apiAxios.get<models.OrganizationBrand>(
      `organization/brands/${id}`,
    );
    return data;
  },
  upgrade: async (id: number, body: models.Brand) => {
    const { data } = await apiAxios.patch<models.Brand>(
      `/brand/${id}/upgrade`,
      body,
    );
    return data;
  },
  deleteByID: async (id: number) => {
    const { data } = await apiAxios.delete(`brand/${id}/disconnect`);
    return data;
  },
  connectBrands: async (body: models.BrandsConnect) => {
    const { data } = await apiAxios.post("/brands/connect", body);
    return data;
  },
  getDefaultCriteria: async (
    brandId: number,
    queryParams: GenericObject = {},
  ) => {
    const url = stringifyUrl(
      `/organization/brand/${brandId}/diagnostic-criteria`,
      queryParams,
    );
    const { data } = await apiAxios.get<
      models.WithResults<models.DiagnosticCriteria>
    >(url);
    return data;
  },

  updateDefaultSwitch: async (
    brandId: number,
    queryParams: GenericObject = {},
  ) => {
    const url = stringifyUrl(
      `/organization/brand/${brandId}/diagnostic-criteria/default`,
      queryParams,
    );
    const { data } = await apiAxios.get<
      models.WithResults<models.DiagnosticCriteria>
    >(url);
    return data;
  },
  updateCriteria: async (brandId: number, values: UpdateCriterion) => {
    const url = `/organization/brand/${brandId}/diagnostic-criteria/${values?.id}`;
    const { data } = await apiAxios.patch<models.DiagnosticCriteria>(
      url,
      values?.body,
    );
    return data;
  },
  createCriteria: async (brandId: number, values: UpdateCriterion) => {
    const url = `/organization/brand/${brandId}/diagnostic-criteria`;
    const { data } = await apiAxios.post<models.DiagnosticCriteria>(
      url,
      values?.body,
    );
    return data;
  },
  deleteCriteria: async (id: number, brandId: number) => {
    const url = `/organization/brand/${brandId}/diagnostic-criteria/${id}`;
    await apiAxios.delete(url);
  },
  resetCriteria: async (brandId: number) => {
    const url = `/organization/brand/${brandId}/diagnostic-criteria/reset`;
    await apiAxios.post(url);
  },
  bulkDelete: async (body: models.BrandsDisconnect) => {
    const { data } = await apiAxios.delete(`/brands/disconnect`, {
      data: body,
    });
    return data;
  },
};
