import { FormInstance } from "ebs-design/dist/components/organisms/Form";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

type Rule = Required<FormFieldProps>["rules"][number];

const passwordStrength = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{8,})",
);

export const passwordValidatorRule = (t: (str: string) => string): Rule => ({
  validateTrigger: "onSubmit",
  validator: (_, value) => {
    return value && value.match(passwordStrength)
      ? Promise.resolve()
      : Promise.reject(t("validation.password"));
  },
});

export const confirmPasswordValidatorRule = (
  form: FormInstance,
  t: (str: string) => string,
): Rule => ({
  validateTrigger: "onSubmit",
  validator: (_, value) => {
    return value === form.getFieldValue("password")
      ? Promise.resolve()
      : Promise.reject(t("validation.confirmation_password"));
  },
});
