import models from "models";
import { GenericObject } from "types";

import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const departments = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/departments", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Department>>(
      url,
    );
    return data;
  },
  patch: async (id: string | number, body: models.Department) => {
    const { data } = await apiAxios.patch<models.Department>(
      `/departments/${id}`,
      body,
    );
    return data;
  },
  post: async (body: models.Department) => {
    const { data } = await apiAxios.post<models.Department>(
      `/departments`,
      body,
    );
    return data;
  },
  delete: async (id: string | number) => {
    return await apiAxios.delete<models.Department>(`/departments/${id}`);
  },
};
