import { Button } from "ebs-design";
import { TFunction } from "react-i18next";
import { Link } from "react-router-dom";

import { NO_VALUE_FALLBACK } from "app-constants";
import {
  Checkbox,
  Flex,
  TableCheckboxSelector,
  TableCheckbox,
  Status,
  ThumbnailImage,
  TooltipButton,
  WhiteSpace,
  ColumnCheckbox,
} from "components";
import * as Icons from "components/icons";
import models from "models";
import { CheckboxesCheckedInfoType } from "types";
import { formatPrice, formattedDateTime, TableColumnBuilder } from "utils";

import { ShowVisibility } from "./components";

interface BrandsColumnsProps {
  onEdit: React.Dispatch<models.OrganizationBrand>;
  onDelete: React.Dispatch<models.OrganizationBrand>;
  t: (str: string, arg?: object) => string;
  setCheckboxInfo: React.Dispatch<
    React.SetStateAction<CheckboxesCheckedInfoType>
  >;
  checkboxInfo?: CheckboxesCheckedInfoType;
}

export const brandsColumns = ({
  onEdit,
  onDelete,
  t,
  setCheckboxInfo,
  checkboxInfo,
}: BrandsColumnsProps) =>
  new TableColumnBuilder<models.OrganizationBrand>()
    .add(
      <TableCheckboxSelector
        word="models"
        checkboxInfo={checkboxInfo}
        setCheckboxInfo={setCheckboxInfo}
      />,
      "",
      (row: models.OrganizationBrand) => (
        <TableCheckbox
          itemId={row?.brand_id}
          checkboxInfo={checkboxInfo}
          setCheckboxInfo={setCheckboxInfo}
        />
      ),
    )
    .add(t("brand.brand_id"), "brand_id")
    .add(t("brand.brand_name"), "title")
    .add(t("brand.brand_logo"), "logo", (logo: models.File) => (
      <ThumbnailImage file={logo} background />
    ))
    .add(t("common.status"), "is_active", (status: boolean) => (
      <Status status={status} />
    ))
    .add(t("common.main_page"), "is_displayed", (isDisplayed: boolean) => (
      <ShowVisibility isVisible={isDisplayed} />
    ))
    .add(t("common.creation_date"), "timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add("", "", (record: models.OrganizationBrand) => (
      <Flex justify="flex-end">
        <span onClick={(e) => e.stopPropagation()}>
          <Link
            to={`/product-management/models/add-models?brand__id=${record.brand_id}`}
          >
            <TooltipButton
              size="small"
              icon={() => <Icons.Grid size="small" />}
              type="light"
              tooltip={{
                placement: "top",
                tooltip: t("template.add_item", {
                  item: t("model.models").toLowerCase(),
                }),
              }}
            />
          </Link>
        </span>
        <WhiteSpace h="8px" />
        <span
          onClick={(e) => {
            e.stopPropagation();
            onEdit(record);
          }}
        >
          <Button
            size="small"
            icon={() => <Icons.Edit size="small" />}
            type="light"
          />
        </span>
        <WhiteSpace h="8px" />
        <span
          onClick={(e) => {
            e.stopPropagation();
            onDelete(record);
          }}
        >
          <Button
            size="small"
            icon={() => <Icons.Trash size="small" />}
            type="light"
          />
        </span>
      </Flex>
    ))
    .getColumns();

interface NomenclatureBrandsColumnsProps {
  setCheckedBrands: React.Dispatch<React.SetStateAction<string[]>>;
  checkedBrands?: string[];
  allBrandsId?: string[];
  t: TFunction;
}
export const nomenclatureBrandsColumns = ({
  setCheckedBrands,
  checkedBrands,
  allBrandsId,
  t,
}: NomenclatureBrandsColumnsProps) =>
  new TableColumnBuilder<models.Brand>()
    .add(
      <Checkbox
        wrap
        onChange={(v) => setCheckedBrands(v ? allBrandsId || [] : [])}
        indeterminate={
          checkedBrands?.length !== allBrandsId?.length &&
          checkedBrands?.length !== 0
        }
      />,
      "",
      (row: models.OrganizationBrandModel) => (
        <div className="min-w-10">
          <Checkbox
            wrap
            checked={checkedBrands?.includes(String(row?.id))}
            onChange={() =>
              setCheckedBrands((prevState) => {
                const brandId = String(row?.id);
                if (brandId === "undefined") return prevState || [];

                if (prevState.includes(brandId)) {
                  return prevState.filter((item) => item !== brandId);
                }
                return [...prevState, brandId];
              })
            }
          />
        </div>
      ),
    )
    .add(t("brand.brand_id"), "id")
    .add(t("brand.brand_name"), "title")
    .add(t("common.status"), "is_active", (status: boolean) => (
      <Status status={status} />
    ))
    .getColumns();

interface BrandModelsColumnsProps {
  onEdit: React.Dispatch<models.OrganizationBrandModel>;
  onDelete: React.Dispatch<models.OrganizationBrandModel>;
  setCheckboxInfo: React.Dispatch<
    React.SetStateAction<CheckboxesCheckedInfoType>
  >;
  checkboxInfo?: CheckboxesCheckedInfoType;
  currency?: models.Currency;
  setSelectedColumns?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedColumns?: string[];
  t: TFunction;
}

export const brandsModelsColumns = ({
  onEdit,
  onDelete,
  setCheckboxInfo,
  checkboxInfo,
  currency,
  setSelectedColumns,
  selectedColumns,
  t,
}: BrandModelsColumnsProps) =>
  new TableColumnBuilder<models.ExtendedOrganizationBrandModel>()
    .add(
      <TableCheckboxSelector
        word="models"
        checkboxInfo={checkboxInfo}
        setCheckboxInfo={setCheckboxInfo}
      />,
      "",
      (row: models.ExtendedOrganizationBrandModel) => (
        <TableCheckbox
          itemId={row?.brand_model_id}
          checkboxInfo={checkboxInfo}
          setCheckboxInfo={setCheckboxInfo}
        />
      ),
    )
    .add(
      <ColumnCheckbox
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        column="id"
        title={t("model.model_id")}
      />,
      "id",
    )
    .add(
      <ColumnCheckbox
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        column="title"
        title={t("model.model_name")}
      />,
      "title",
    )
    .add(
      <ColumnCheckbox
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        column="brand.title"
        title={t("brand.brand_name")}
      />,
      ["brand", "title"],
    )
    .add(
      <ColumnCheckbox
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        column="storage_capacities"
        title={t("common.storage")}
      />,
      "storage_capacities",
      (capacities: string[]) => capacities?.join(", "),
    )
    .add(
      <ColumnCheckbox
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        column="colors"
        title={t("common.colors")}
      />,
      "colors",
      (colors: string[]) => colors?.join(", ") || NO_VALUE_FALLBACK,
    )
    .add(
      <ColumnCheckbox
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        column="type"
        title={t("device.device_type")}
      />,
      "type",
      (type: string) =>
        type === models.ModelType.PHONE ? (
          <Icons.PhoneClassic />
        ) : (
          <Icons.Tablet />
        ),
    )
    .add(
      <ColumnCheckbox
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        column="logo"
        title={t("common.image")}
      />,
      "logo",
      (logo: models.File) => <ThumbnailImage file={logo} background />,
    )
    .add(
      <ColumnCheckbox
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        column="max_price"
        title={t("price.max_price")}
      />,
      "",
      (row: models.OrganizationBrandModel) =>
        formatPrice(row.max_price, currency?.code),
    )
    .add(
      <ColumnCheckbox
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        column="timestamp"
        title={t("common.creation_date")}
      />,
      "timestamp",
      (timestamp) => formattedDateTime(timestamp),
    )
    .add(
      <ColumnCheckbox
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        column="is_active"
        title={t("common.status")}
      />,
      "is_active",
      (status: boolean) => <Status status={status} />,
    )
    .add("", "", (record: models.OrganizationBrand) => (
      <Flex justify="flex-end">
        <span
          onClick={(e) => {
            e.stopPropagation();
            onEdit(record);
          }}
        >
          <Button
            size="small"
            icon={() => <Icons.Edit size="small" />}
            type="light"
          />
        </span>
        <WhiteSpace h="8px" />
        <span
          onClick={(e) => {
            e.stopPropagation();
            onDelete(record);
          }}
        >
          <Button
            size="small"
            icon={() => <Icons.Trash size="small" />}
            type="light"
          />
        </span>
      </Flex>
    ))
    .getColumns();

interface NomenclatureModelsColumnsProps {
  setCheckedModels: React.Dispatch<React.SetStateAction<string[]>>;
  checkedModels?: string[];
  allModelsId?: string[];
  currency?: models.Currency;
  t: TFunction;
}
export const nomenclatureModelsColumns = ({
  setCheckedModels,
  checkedModels,
  allModelsId,
  currency,
  t,
}: NomenclatureModelsColumnsProps) =>
  new TableColumnBuilder<models.NomenclatureBrandModel>()
    .add(
      <Checkbox
        wrap
        onChange={(v) => setCheckedModels(v ? allModelsId || [] : [])}
        indeterminate={
          checkedModels?.length !== allModelsId?.length &&
          checkedModels?.length !== 0
        }
      />,
      "",
      (row: models.OrganizationBrandModel) => (
        <div className="min-w-10">
          <Checkbox
            wrap
            checked={checkedModels?.includes(String(row?.id))}
            onChange={() =>
              setCheckedModels((prevState) => {
                const modelsId = String(row?.id);
                if (modelsId === "undefined") return prevState || [];

                if (prevState.includes(modelsId)) {
                  return prevState.filter((item) => item !== modelsId);
                }
                return [...prevState, modelsId];
              })
            }
          />
        </div>
      ),
    )
    .add(t("model.model_id"), "id")
    .add(t("brand.brand_name"), ["brand", "title"])
    .add(t("model.model_name"), "title")
    .add(t("model.model_type"), "type", (type: string) =>
      type === models.ModelType.PHONE ? (
        <Icons.PhoneClassic />
      ) : (
        <Icons.Tablet />
      ),
    )
    .add(t("common.storage"), "storage_capacities", (capacities: string[]) =>
      capacities?.join(", "),
    )
    .add(t("common.colors"), "colors", (colors: string[]) => colors?.join(", "))
    .add(t("price.max_price"), "", (row: models.OrganizationBrandModel) =>
      formatPrice(row.max_price, currency?.code),
    )
    .add(
      t("common.image"),
      "data",
      (data: models.NomenclatureBrandModelData) => (
        <ThumbnailImage file={{ url: data?.image }} background />
      ),
    )
    .getColumns();

export const brandsSortOptions = [
  { title: "Creation date", value: "timestamp" },
];

export const brandsModelsSortOptions = [
  { title: "Creation date", value: "timestamp" },
];
