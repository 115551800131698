import models from "models";
import { GenericObject } from "types";

import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const inventory = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/inventory", queryParams, {
      arrayFormat: "comma",
    });
    const { data } = await apiAxios.get<models.WithResults<models.Inventory>>(
      url,
    );
    return data;
  },
  getByID: async (id: number) => {
    const { data } = await apiAxios.get<models.Inventory>(`/inventory/${id}`);
    return data;
  },
  patch: async (id: number, body: models.Inventory) => {
    const { data } = await apiAxios.patch<models.Inventory>(
      `/inventory/${id}`,
      body,
    );
    return data;
  },
  delete: async (id: number) => {
    return await apiAxios.delete(`/inventory/${id}`);
  },
  export: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/inventory/export", queryParams, {
      arrayFormat: "comma",
    });

    const { data } = await apiAxios.get<models.Attachment>(url);
    return data;
  },
  patchStatusBulk: async (
    inventories_id: number[],
    status: models.Inventory["status"],
  ) => {
    const { data } = await apiAxios.patch<models.Inventory[]>(
      "/inventory/status/bulk",
      { inventories_id, status },
    );
    return data;
  },
};
