import { TrendDown, TrendUp } from "components/icons";
import { useRef } from "react";
import { LabelProps } from "recharts";
import { PolarViewBox } from "recharts/types/util/types";

interface CustomLabelProps extends LabelProps {
  viewBox?: PolarViewBox;
  trendDirection?: number;
  value?: number | string;
}

export const CustomLabel = ({
  viewBox,
  trendDirection,
  value,
}: CustomLabelProps) => {
  const { cx, cy } = viewBox;
  const iconMargin = 6; // margin between text and icon
  const lineHeight = 40;

  const ref = useRef<SVGTextElement>(null);
  const xIconPosition = ref.current?.getComputedTextLength() / 2 + iconMargin;

  return (
    <>
      <text x={cx} y={!trendDirection ? cy + lineHeight / 4 : cy}>
        <tspan
          style={{
            fontWeight: 700,
            fontSize: "2rem",
            fill: "#252C32",
          }}
          textAnchor="middle"
        >
          {value}
        </tspan>
      </text>
      {!!trendDirection && (
        <>
          <text ref={ref} x={cx} y={cy + lineHeight / 2}>
            <tspan
              style={{
                fontSize: "1rem",
                fill: "#6E7C87",
              }}
              textAnchor="middle"
            >
              {Math.abs(trendDirection)}%
            </tspan>
          </text>
          {trendDirection > 0 ? (
            <TrendUp x={cx + xIconPosition} y={cy + 5} color="success" />
          ) : (
            <TrendDown x={cx + xIconPosition} y={cy + 5} color="danger" />
          )}
        </>
      )}
    </>
  );
};
