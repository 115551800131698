import { Form, Select } from "ebs-design";
import { InputSize } from "ebs-design/dist/components/atoms/Input/Input";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import { useTranslation } from "react-i18next";

import { DebouncedInput } from "components/DebouncedInput";
import { useEffect, useState } from "react";

interface SelectOption {
  value: string | number;
  name: string;
}

interface SelectOptionProps extends FormFieldProps {
  selectOption: SelectOption[];
  mode?: "single" | "multiple";
  size?: InputSize;
  value?: string | number;
  onChange?: (arg: string) => void;
  showSearch?: boolean;
}

export const SelectOptions = ({
  selectOption,
  value: selectedValue,
  onChange: onValueChange,
  mode = "single",
  size = "large",
  showSearch,
  ...props
}: SelectOptionProps) => {
  return (
    <Form.Field {...props}>
      {({ value, onChange }) => {
        const v =
          selectedValue || selectedValue === null ? selectedValue : value;

        return (
          <SelectOptionsItems
            selectOption={selectOption}
            mode={mode}
            size={size}
            value={v}
            onChange={onChange}
            onValueChange={onValueChange}
            showSearch={showSearch}
          />
        );
      }}
    </Form.Field>
  );
};

const SelectOptionsItems = ({
  selectOption,
  mode,
  size,
  value,
  onChange,
  onValueChange,
  showSearch,
}: SelectOptionProps & {
  value: any;
  onValueChange: (arg: string) => void;
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch("");
  }, [value]);

  return (
    <Select size={size} mode={mode} value={value}>
      {showSearch && (
        <DebouncedInput
          placeholder={t("search.search")}
          value={search}
          onChange={setSearch}
          className="mb-10"
          size="large"
        />
      )}
      <Select.Options
        className="ebs-select__options-height--300"
        emptyLabel={t("common.not_found")}
        onChange={(v) => {
          onChange(String(v));
          onValueChange?.(
            selectOption.filter((item) => item.value === v)[0].name,
          );
        }}
      >
        {selectOption
          .filter((item) =>
            item.name.toLowerCase().includes(search.toLowerCase()),
          )
          .map((item) => (
            <Select.Options.Item key={item.name} value={item.value}>
              {item.name}
            </Select.Options.Item>
          ))}
      </Select.Options>
    </Select>
  );
};
