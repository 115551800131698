import { Flex } from "components";
import { SwitchFormField } from "components/form-fields";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

interface BrandHeaderProps extends FormFieldProps {
  title: string;
}

export const BrandHeader = ({ title, ...props }: BrandHeaderProps) => {
  return (
    <Flex justify="space-between" align="center" className="brand-header">
      <h3>{title}</h3>
      <SwitchFormField {...props} />
    </Flex>
  );
};
