import { Button, Space } from "ebs-design";
import { SearchOutlined } from "@ant-design/icons";
import { Divider, Input, Modal, notification, Table } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";

import { LanguagesContext } from "apps/cms/context/Languages";
import api, { Ii18n, Ii18nType } from "apps/cms/lib/api/i18n";
import { Locale } from "apps/cms/lib/api/pages";
import { defaultLocale } from "apps/cms/lib/utils";
import { Flex, WhiteSpace } from "components";
import * as Icons from "components/icons";

import TranslationModal from "./TranslationModal";

interface TranslationsProps {
  namespace?: string;
  loading: boolean;
  lang?: string;
  type: Ii18nType;
}

interface FilterDropdownProps {
  selectedKeys: string[];
  confirm: () => void;
  clearFilters: () => void;
  setSelectedKeys: (value: string[]) => void;
}

interface EditableElement {
  key: string;
  text: string;
  language: string;
}

const Translations: React.FC<TranslationsProps> = ({
  loading,
  lang,
  type,
  namespace,
}) => {
  const {
    languages: { data: languages },
  } = useContext(LanguagesContext);
  const [i18n, setTranslations] = useState<any>();
  const [isLoading, setLoading] = useState(true);

  const [editable, setEditable] = useState<EditableElement>();
  const [searchText, setSearchText] = useState("");

  const handleSearch = (selectedKeys: string[], confirm: () => void): void => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: () => void): void => {
    clearFilters();
    setSearchText("");
  };

  const filterDropdown = useCallback(
    ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          itemRef="any"
          placeholder="Search by text"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 200, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
            >
            Reset
          </Button>
        </Space>
      </div>
    ),
    [],
  );

  const getColumnSearchProps = (dataIndex: any): any => ({
    filterDropdown: filterDropdown,

    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#00529C" : undefined }} />
    ),

    onFilter: (value: { toLowerCase: () => void }, record: any) =>
      i18n[dataIndex][record]
        ?.toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    render: (_: any, record: any) => (
      <Flex align="center">
        <Highlighter
          textToHighlight={i18n[dataIndex][record]}
          searchWords={searchText ? [searchText] : [""]}
          highlightStyle={{ backgroundColor: "#FFDF00", padding: 0 }}
          autoEscape
        />
        {i18n[dataIndex][record] && <WhiteSpace h="10px" />}
        <Button
          icon={() => <Icons.Edit size="small" />}
          type="light"
          onClick={() =>
            setEditable({
              language: dataIndex,
              key: record,
              text: i18n[dataIndex][record],
            })
          }
        />
      </Flex>
    ),
  });

  const parseTranslations = useCallback(
    (data: any) => {
      languages.forEach(({ code }) => {
        data[code] = data[code] || {};
      });

      setTranslations(data);
    },
    [languages],
  );

  const fetchTranslations = useCallback(async () => {
    await setLoading(true);

    const { i18n } = await api.getById(namespace);

    try {
      parseTranslations(i18n);
    } catch (e) {
      setTranslations({ ro: {}, ru: {}, en: {} });
      // populate with empty values if namespace is invalid
      Modal.error({ title: "Namespace broken" });
    }

    await setLoading(false);
  }, [parseTranslations, namespace]);

  useEffect(() => {
    if (namespace) {
      fetchTranslations();
    }
  }, [namespace, fetchTranslations]);

  const columns: any = [
    {
      title: "Keyword",
      dataIndex: "name",
      key: "name",
      className: "no-wrap",
      render: (text: any, record: string) => {
        return <i>{record}</i>;
      },
      width: 50,
    },

    ...languages
      .filter(({ code }) => !lang || code === Locale.EN || lang === code)
      .map(({ code }) => ({
        title: code,
        dataIndex: code,
        className: "no-wrap",
        key: code,
        ...getColumnSearchProps(code),
      })),
  ];

  const handleEdit = async (newValue: string): Promise<void> => {
    if (editable) {
      try {
        await setLoading(true);

        const { key, language } = editable;
        setEditable(undefined);

        const data = i18n;

        data[language][key] = newValue;

        const res = await api.update(namespace!, data, type);

        parseTranslations(res.i18n);
        notification.success({ message: `Translation has been changed.` });
      } catch (e) {
        Modal.error({ title: "Something went wrong.", content: e.message });
      }

      await setLoading(false);
    }
  };

  /**
   * DEVELOPEMENT ONLY FUNCTION
   */
  const handleAddKey = async (key: string): Promise<void> => {
    try {
      await setLoading(true);

      const data = i18n;

      languages.forEach(
        ({ code }) => (data[code][key] = `{{ ${code} placeholder }}`),
      );

      const res: Ii18n = await api.update(namespace!, data, type);

      parseTranslations(res.i18n);
      Modal.success({ title: "Translation has been successfully modified" });
    } catch (e) {
      Modal.error({ title: "Something went wrong", content: e.message });
    }
    await setLoading(false);
  };

  return (
    <>
      <Table
        loading={isLoading || loading}
        columns={columns}
        dataSource={i18n ? (Object.keys(i18n[defaultLocale]) as any) : []}
        pagination={false}
        rowKey="id"
      />
      <TranslationModal
        onSave={handleEdit}
        onCancel={() => setEditable(undefined)}
        text={editable ? editable.text : ""}
        visible={Boolean(editable)}
      />

      {/* Enabling textarea hack via devtools */}
      <div style={{ display: "none" }}>
        <Divider />

        <Input
          placeholder="Add new keyword"
          onPressEnter={({ target }: any) => {
            const { value } = target;
            handleAddKey(value);
            target.value = "";
          }}
        />

        <Input.TextArea
          style={{ marginTop: 20, height: 500 }}
          value={i18n}
          onChange={({ target: { value } }) => {
            try {
              const data = value;
              setTranslations(data);
            } catch (e) {
              Modal.info({ title: "incorrect" });
            }
          }}
        />
      </div>
    </>
  );
};

export default Translations;
