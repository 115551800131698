import models from "models";
import { ServiceCode } from "./order";

export interface ExtraService {
  id?: number;
  code?: ServiceCode;
  price?: string;
  price_translation?: string;
  is_mandatory?: boolean;
  is_active?: boolean;
  title?: string;
  description?: string;
  i18n?: models.I18N;
  is_email_notification_active?: boolean;
  emails?: string[];
  image?: models.File;
  image_id?: number;
  popup_description?: string;
}
export interface OrganizationSettings extends models.DefaultModel {
  users__count?: number;
  clients__count?: number;
  accent_color?: string;
  is_logo_active?: boolean;
  accent_color_content?: string;
  domain?: string;
  subdomain?: string;
  owner?: models.Owner;
  owner_username?: string;
  logo?: models.File;
  logo_id?: number;
  currency?: models.Currency;
  currency_id?: number;
  hash?: string;
  is_accent_color_active?: boolean;
  countries?: number[];
  country?: models.Country;
  city?: models.City;
  registration_number?: string;
  vat_number?: string;
  tax_number?: string;
  banking_details?: BankDetails[];
  settings?: DefaultSettings;
  is_data_erasure_active?: boolean;
}

export interface BankDetails {
  id: number;
  swift?: string;
  iban?: string;
  currency?: models.Currency;
  currency_id?: number;
  timestamp?: string;
  edited_timestamp?: string;
  title?: string;
}

export interface DiagnosticCriteria {
  id?: number;
  title?: string;
  order?: number;
  is_active?: boolean;
  is_default?: boolean;
  is_system?: boolean;
  price_percentage?: string;
  i18n?: models.I18N;
  client_is_correct?: boolean;
  is_correct?: boolean;
}

export interface DiagnosticCriteriaReorderPayload {
  id: number;
  /**
   * The index
   */
  order: number;
}

export interface Fees extends models.DefaultModel {
  code: string;
  price: string;
  price_translation: string;
}

export interface DefaultSettings {
  id: number;
  code: string;
  value: string;
  data: DefaultSettingsData;
  organization: OrganizationSettings;
  logo: models.File;
  logo_is_active: boolean;
  logo_id: number;
}
export interface DefaultSettingsData {
  PAYMENT_PAYOUT_METHOD?: PaymentPayoutMethodType;
  USING_DEFAULT_CRITERIA?: boolean;
  STATIC_API_APPLICATION_HASH?: string;
  PRICE_UPDATE_APPROVAL?: number;
  REJECTION_STATUS_APPROVAL?: number;
  REJECTION_STATUS_APPROVAL_PERIOD?: models.Period;
  PRICE_UPDATE_APPROVAL_PERIOD?: models.Period;
  PRICE_UPDATE_APPROVAL_EMAILS?: string[];
  REJECTION_STATUS_APPROVAL_EMAILS?: string[];
  LANGUAGES?: models.LanguageCode[];
}

export enum PaymentPayoutMethod {
  PAYMENT_SEPARATELY_FROM_PAYOUT = "Seller will pay all the fees în the selling process",
  PAYOUT_AMOUNT_EQUALS_DEVICE_PRICE_MINUS_FEES = "Payment will be deducted from the Final Price",
  PAYOUT_ONLY_NO_PAYMENT_NEEDED = "No Payment needed (Only PayOut available)",
}

export type PaymentPayoutMethodType = keyof typeof PaymentPayoutMethod;

export interface RecyclingCertificates
  extends Omit<models.Attachment, "tags"> {}
