import React from 'react';
import { Select, Input, Row, Col, Switch, Upload, Button, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import api from 'apps/cms/lib/api/pages';
import { getBase64 } from 'utils';

class Items extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || {},
      pages: [],
      loading: false,
      isIntern: !this.props.value?.url,
    };
  }

  componentDidMount() {
    this.setState((prevState) => ({ ...prevState, loading: true }));
    try {
      api
        .all({ name_content_icontains: '', page: 1, page_size: 1000 })
        .then(({ results: pages }) => {
          this.setState((prevState) => ({ ...prevState, pages }));
          this.setState((prevState) => ({ ...prevState, loading: false }));
        });
    } catch (e) {
      console.error(e);
    }
  }

  componentDidUpdate() {
    if (this.props.value && JSON.stringify(this.props.value) !== JSON.stringify(this.state.value)) {
      this.setState((prevState) => ({ ...prevState, value: this.props.value }));
    }
  }

  onChange = (value) => {
    this.setState((prevState) => ({ ...prevState, value: { ...this.state.value, ...value } }));

    this.props.onChange({ ...this.state.value, ...value });
  };

  handleChange = (info) => {
    switch (info.file.status) {
      case 'uploading':
        this.setState((prevState) => ({ ...prevState, loading: true }));
        break;
      case 'done':
        getBase64(info.file.originFileObj, () => {
          this.setState((prevState) => ({ ...prevState, loading: false }));
        });
        break;
      default:
        break;
    }
  };

  handleUpload = async (file) => {
    this.setState((prevState) => ({ ...prevState, loading: true }));

    try {
      const { id: uid, upload, uploaded } = await api.uploadFile(file);

      this.onChange({
        uid,
        name: upload,
        status: uploaded ? 'done' : 'error',
        url: `${process.env.REACT_APP_STATIC_API}/v1${upload}`,
      });
    } catch (e) {
      message.error('Unsuccessfull image upload!');
    }
    this.setState((prevState) => ({ ...prevState, loading: false }));
  };

  handleBeforeUpload = (file) => {
    this.handleUpload(file);

    return false;
  };

  render() {

    return (
      <Row wrap gutter={[16, 16]}>
        <Col span={24}>
          <Switch
            checkedChildren="Internal"
            unCheckedChildren="External"
            value={this.state.isIntern}
            defaultChecked={this.state.isIntern}
            onChange={(isIntern) => this.setState((prevState) => ({ ...prevState, isIntern }))}
          />
        </Col>
        <Col span={24}>
          {this.state.isIntern ? (
            <Select
              value={this.state.value?.link}
              loading={this.state.loading}
              placeholder="Link"
              onChange={(link) => this.onChange({ link })}
            >
              {this.state.pages.map((page) => (
                <Select.Option value={page.id}>{page.i18n[this.props.lang].title}</Select.Option>
              ))}
            </Select>
          ) : (
            <Input
              value={this.state.value?.url}
              placeholder="URL"
              onChange={(e) => this.onChange({ url: e.target.value })}
            />
          )}
        </Col>
        <Col span={24}>
          <Input
            value={this.state.value?.text}
            placeholder="Text"
            onChange={(e) => this.onChange({ text: e.target.value })}
          />
        </Col>
        {!this.state.isIntern && (
          <Col span={24}>
            <Upload
              multiple={false}
              fileList={this.state.value?.url ? [this.state.value] : []}
              beforeUpload={this.handleBeforeUpload}
              onChange={this.handleChange}
            >
              <Button icon={this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}>
                Click to upload
              </Button>
            </Upload>
          </Col>
        )}
      </Row>
    );
  }
}

export default Items;
