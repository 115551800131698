import { getCurrentLocale } from "translation/utils";
import { CURRENCY_CODE_FALLBACK, CURRENCY_CODE_TYPE } from "app-constants";

export const tryToNumber = (any: any) =>
  isNaN(Number(any)) ? any : Number(any);

// transform 12345678 => 12 345 678
export const numberWithSpaces = (nr?: number): string => {
  if (!nr) return "0";

  let string_nr: string = "";
  while (nr > 1000) {
    string_nr = " " + nr.toString().slice(nr.toString().length - 3) + string_nr;
    nr = Math.round(nr / 1000);
  }

  return nr.toString() + string_nr;
};

export const formatPrice = (
  price: string,
  currency: keyof CURRENCY_CODE_TYPE = CURRENCY_CODE_FALLBACK,
): string => {
  if (typeof price !== "string") return price;
  const locale = getCurrentLocale();

  return Number(price).toLocaleString(locale, {
    style: "currency",
    ...(currency && { currency }),
  });
};

export const formatPercent = (percent: number | string): string => {
  const locale = getCurrentLocale();

  return (Number(percent) / 100).toLocaleString(locale, {
    style: "percent",
  });
};

export const formatNumber = (
  price: number | string,
  minimumFractionDigits?: number,
): string => {
  const locale = getCurrentLocale();
  if (minimumFractionDigits === undefined) minimumFractionDigits = 2;

  return Number(price).toLocaleString(locale, {
    minimumFractionDigits,
  });
};
