import { Select } from "ebs-design";
import { SelectProps } from "ebs-design/dist/components/molecules/Select/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";

import api, { querykeys } from "api";
import { DebouncedInput, LoadingButton, WhiteSpace } from "components";
import { combineQueryPagesData, hasNextPage } from "utils";

export const FilterBrands = (props: SelectProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = React.useState("");
  const queryParams = { search, limit: 10 };

  const query = useInfiniteQuery(
    querykeys.brands.many(queryParams),
    ({ pageParam = 1 }) =>
      api.brands.get({
        ...queryParams,
        page: pageParam,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) =>
        hasNextPage(lastPage) ? (lastPage.current_page || 0) + 1 : undefined,
    },
  );

  const data = combineQueryPagesData(query.data);

  return (
    <Select loading={query.isLoading} {...props} style={{ width: "200px" }}>
      <DebouncedInput
        placeholder={t("search.search")}
        value={search}
        onChange={setSearch}
      />
      <WhiteSpace v="0.5rem" />

      <Select.Options emptyLabel={t("common.no_results")}>
        {data.map((item) => (
          <Select.Options.Item key={item.id} value={item.brand_id}>
            {item?.title}
          </Select.Options.Item>
        ))}
      </Select.Options>

      <WhiteSpace v="0.5rem" />
      {query.hasNextPage && (
        <LoadingButton
          loading={query.isFetching}
          onClick={() => query.fetchNextPage()}
          size="small"
          type="light"
          block
        >
          {t("common.load_more")}
        </LoadingButton>
      )}
    </Select>
  );
};
