import { Form, Input, Textarea } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import { FieldWithInfo } from "./FieldWithInfo";
import { relativeClassName } from "./utils";

export interface InputFormFieldProps extends FormFieldProps {
  type?: string;
  required?: boolean;
  textarea?: boolean;
  placeholder?: string;
  disabled?: boolean;
  info?: React.ReactNode;
  suffix?: React.ReactElement;
  maxLength?: number;
  min?: number;
  max?: number;
  step?: number;
  prefix?: React.ReactElement;
  defaultValue?: string | number;
}

export const InputFormField = ({
  rules = [],
  required,
  type,
  initialValue,
  hideLabel = false,
  textarea,
  disabled,
  info,
  extra,
  suffix,
  prefix,
  className,
  maxLength,
  min,
  max,
  step,
  defaultValue,
  ...restProps
}: InputFormFieldProps) => {
  return (
    <Form.Field
      extra={<FieldWithInfo extra={extra} info={info} />}
      {...restProps}
      rules={[{ required }, ...rules]}
      {...{
        hideLabel,
      }}
      className={relativeClassName(info, className)}
    >
      {textarea ? (
        <Textarea placeholder={restProps.placeholder} disabled={disabled} />
      ) : (
        <Input
          defaultValue={defaultValue}
          disabled={disabled}
          type={type}
          size="large"
          placeholder={restProps.placeholder}
          suffix={suffix}
          prefix={prefix}
          maxLength={maxLength}
          min={min}
          max={max}
          step={step}
        />
      )}
    </Form.Field>
  );
};
