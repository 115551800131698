import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { NumberParam } from "use-query-params";

import api, { querykeys } from "api";
import { WAREHOUSE_STATE_OPTIONS } from "app-constants";
import { Flex, LoadingButton, SelectOptions, WhiteSpace } from "components";
import models from "models";
import { notifyErrors } from "utils";
import { useParam } from "hooks";

interface EditInventoryModalProps extends ModalProps {
  data?: models.Inventory;
  onClose: () => void;
}

export const EditInventoryModal = ({
  data,
  onClose,
  ...props
}: EditInventoryModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const inventoryId = useParam("id", NumberParam.decode) || 0;

  const { mutate, isLoading } = useMutation(
    (body: models.Inventory) => api.inventory.patch(data?.id || 0, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.inventory.one(inventoryId));
        queryClient.invalidateQueries(querykeys.inventory.many());
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("inventory.inventory"),
          }),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Modal
      title={`${t("template.edit_item", {
        item: t("inventory.inventory").toLowerCase(),
      })} - ${data?.id}`}
      size="small"
      onClose={onClose}
      className="edit-service-modal"
      {...props}
    >
      <Form
        form={form}
        name="inventory"
        className="service-form"
        initialValues={data}
        onFinish={mutate}
      >
        <Modal.Content>
          <SelectOptions
            selectOption={WAREHOUSE_STATE_OPTIONS.map((item) => ({
              name: item.value,
              value: item.key,
            }))}
            name="status"
            label={t("common.status")}
          />
        </Modal.Content>

        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton loading={isLoading} submit type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
