import { Card } from "ebs-design";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ArrayParam, useQueryParams } from "use-query-params";

import api, { querykeys } from "api";
import { isDisplayedStatus } from "app-constants";
import { Flex, PageTitle, QueryTable, TableTitle } from "components";
import { ChoiceFilter, Filters } from "components/filters";
import {
  useAuth,
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";
import models from "models";
import { forwardSetState } from "utils";

import { PaymentProviderFormModal, PaymentKeysFormModal } from "../components";
import { paymentProviderSortOptions, providersColumns } from "../config";

export const PaymentProviders = () => {
  const { t } = useTranslation();
  const { organization } = useAuth();
  const paymentProviderFormModal = useModalState<models.PaymentProvider>();
  const paymentKeysFormModal = useModalState<models.PaymentKeys>();
  const pagination = useQueryPagination();
  const [ordering, setOrdering] = useOrderingQueryParam();
  const [filterParams, setFilterParams] = useQueryParams({
    is_active: ArrayParam,
  });

  const [search, setSearch] = React.useState("");

  const queryParams = {
    ...pagination.queryParams,
    search,
    ...filterParams,
    ordering,
  };

  const query = useQuery(querykeys.paymentMethods.many(queryParams), () =>
    api.paymentMethods.get(queryParams),
  );

  const tableColumns = providersColumns({
    editPaymentKeysModal: paymentKeysFormModal.openWith,
    editPaymentProviderModal: paymentProviderFormModal.openWith,
    currency: organization?.currency,
    t,
  });

  return (
    <>
      <PageTitle
        // actions={
        //   <Button type="primary" onClick={paymentProviderFormModal.open}>
        //     Add payment
        //   </Button>
        // }
        title={t("menu.payment")}
        count={query.data?.count}
      />
      <Card>
        <Card.Body>
          <QueryTable
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                  sortOptions={paymentProviderSortOptions}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                  filters={
                    <Filters
                      queryParams={filterParams}
                      setQueryParams={setFilterParams}
                    >
                      <ChoiceFilter
                        value={filterParams.is_active}
                        setValue={forwardSetState(setFilterParams, "is_active")}
                        title={t("common.status")}
                        choice={Object.values(["true", "false"]).map(
                          (is_active) => ({
                            key: is_active,
                            value: isDisplayedStatus[is_active],
                          }),
                        )}
                      />
                    </Filters>
                  }
                />
              </Flex>
            )}
            query={query}
            columns={tableColumns}
          />
        </Card.Body>
      </Card>
      {paymentProviderFormModal.isOpen && (
        <PaymentProviderFormModal
          open={paymentProviderFormModal.isOpen}
          onClose={paymentProviderFormModal.close}
          data={paymentProviderFormModal.data}
          closeOnClickOutside={false}
        />
      )}
      {paymentKeysFormModal.isOpen && (
        <PaymentKeysFormModal
          open={paymentKeysFormModal.isOpen}
          onClose={paymentKeysFormModal.close}
          data={paymentKeysFormModal.data}
          closeOnClickOutside={false}
        />
      )}
    </>
  );
};
