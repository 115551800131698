import { createContext, FC, useCallback, useEffect, useReducer } from "react";

import api, { LanguageType } from "apps/cms/lib/api/languages";

export interface LanguagesStoreType {
  data: LanguageType[];
}

export enum ReducerActionType {
  SET_LANGUAGES = "SET_LANGUAGES",
}

export type ReducerAction = {
  type: ReducerActionType.SET_LANGUAGES;
  data: LanguageType[];
};

export const initialState: LanguagesStoreType = {
  data: [],
};

export const reducer = (
  state: LanguagesStoreType,
  action: ReducerAction,
): LanguagesStoreType => {
  switch (action.type) {
    case ReducerActionType.SET_LANGUAGES: {
      const { data } = action;

      return { ...state, data };
    }
  }
};

export interface LanguagesContextState {
  languages: LanguagesStoreType;
  setLanguages: (data: LanguageType[]) => void;
}

export const LanguagesContext = createContext<LanguagesContextState>({
  languages: initialState,
  setLanguages: () => null,
});

export const LanguagesStore: FC = ({ children }) => {
  const [languages, dispatch] = useReducer(reducer, initialState);

  const setLanguages = useCallback((data: LanguageType[]) => {
    dispatch({ type: ReducerActionType.SET_LANGUAGES, data });
  }, []);

  useEffect(() => {
    api.getLanguages().then(({ results }) =>
      dispatch({
        type: ReducerActionType.SET_LANGUAGES,
        data: results.filter(({ code }) => code !== "ru"),
      }),
    );
  }, []);

  const context: LanguagesContextState = {
    languages,
    setLanguages,
  };

  return (
    <LanguagesContext.Provider value={context}>
      {children}
    </LanguagesContext.Provider>
  );
};
