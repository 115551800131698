import ReactPaginate from "react-paginate";
import { ButtonGroup, Button } from "ebs-design";

import { Flex, WhiteSpace } from "components";
import { UsePaginationResult } from "hooks";
import models from "models";
import defaults from "app-defaults";

import { ArrowLeft } from "./icons";

export interface PaginationProps {
  pagination: UsePaginationResult;
  data: models.WithResults<unknown> | undefined;
  pageSizeChoice?: number[];
}

export const Pagination = ({
  pagination,
  data,
  pageSizeChoice = defaults.pagination.pageSizeChoice,
}: PaginationProps) => {
  return (
    <>
      {data?.results?.length ? (
        <Flex align="center">
          <ButtonGroup className="pagination-items-count">
            {pageSizeChoice.map((choice) => (
              <Button
                key={choice}
                size="small"
                type={choice === pagination.pageSize ? "primary" : "light"}
                onClick={() => pagination.setPageSize(choice)}
                className="pagination-items-count__btn"
              >
                {choice}
              </Button>
            ))}
          </ButtonGroup>
          <WhiteSpace h="1rem" />

          <ReactPaginate
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={data.total_pages || 0}
            previousLabel={<ArrowLeft size="14px" />}
            nextLabel={
              <ArrowLeft size="14px" style={{ transform: "rotate(180deg)" }} />
            }
            containerClassName="pagination"
            nextClassName="pagination__move"
            previousClassName="pagination__move"
            breakClassName="pagination__break"
            pageLinkClassName="pagination__page"
            forcePage={pagination.page - 1}
            onPageChange={({ selected }: { selected: number }) =>
              pagination.setPage(selected + 1)
            }
          />
        </Flex>
      ) : null}
    </>
  );
};
