import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import api from 'apps/cms/lib/api/icons';

class IconSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      value: this.props.value || 'plus',
    };
  }

  componentDidUpdate() {
    if (this.props.value && this.props.value !== this.state.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentDidMount() {
    api.getList().then((data) => this.setState({ data }));
  }

  onChange = (value) => {
    this.setState({ value });

    this.props.onChange(value);
  };

  render() {
    const selectedUrl = this.state.data.find(({ name }) => name === this.state.value)?.url;

    return (
      <Dropdown
        overlayClassName="icon-select"
        overlay={
          <Menu>
            {this.state.data.map(({ name, url }, i) => (
              <Menu.Item
                key={i}
                className={this.state.value === name ? 'selected' : undefined}
                onClick={() => this.onChange(name)}
              >
                <img src={url} alt={name} />
              </Menu.Item>
            ))}
          </Menu>
        }
        placement="bottomLeft"
      >
        <Button>
          {!this.state.data.length ? <LoadingOutlined /> : selectedUrl && <img src={selectedUrl} alt="" />}
        </Button>
      </Dropdown>
    );
  }
}

export default IconSelect;
