import { Loader } from "ebs-design";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query";

import { AlertErrors, Flex, Pagination, Table, TableProps } from "components";
import { UsePaginationResult } from "hooks";
import models from "models";

export interface QueryTableProps<RecordType> extends TableProps<RecordType> {
  query: UseQueryResult<models.WithResults<RecordType>>;
  columns: TableProps<RecordType>["columns"]; // required
  pagination?: UsePaginationResult;
  pageSizeChoice?: number[];
}

export const QueryTable = <RecordType,>({
  query,
  pagination,
  pageSizeChoice,
  ...tableProps
}: QueryTableProps<RecordType>) => {
  const { t } = useTranslation();

  return (
    <>
      <AlertErrors error={query.error} />
      {!query.isError && (
        <Table
          data={query.data?.results}
          emptyText={
            query.isLoading ? (
              <Loader.Inline />
            ) : (
              <div className="py-6 text-center">
                {t("common.no_data_available")}
              </div>
            )
          }
          footer={
            pagination && query.data?.results?.length
              ? () => (
                  <Flex className="py-4 px-4" justify="flex-end">
                    <Pagination
                      pageSizeChoice={pageSizeChoice}
                      pagination={pagination}
                      data={query.data}
                    />
                  </Flex>
                )
              : undefined
          }
          rowKey="id"
          scroll={{ x: true }}
          {...tableProps}
        />
      )}
    </>
  );
};
