import { AxiosError } from "axios";
import { Button, Card, Space, useNotify } from "ebs-design";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateEffect } from "react-use";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { ArrayParam, useQueryParams } from "use-query-params";

import api, { querykeys } from "api";
import { isActiveStatus, isDisplayedStatus } from "app-constants";
import {
  DeleteModal,
  Flex,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import { ChoiceFilter, Filters } from "components/filters";
import {
  useFilterQueryChecked,
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";
import models from "models";
import { forwardSetState, notifyErrors } from "utils";

import { checkboxesCheckedInfo, CheckboxesCheckedInfoType } from "types";
import * as Icons from "components/icons";
import { BrandModal } from "../components";
import { brandsColumns, brandsSortOptions } from "../config";

export const Brands = () => {
  const { t } = useTranslation();
  const pagination = useQueryPagination();
  const brandModal = useModalState<models.OrganizationBrand>();
  const deleteBrand = useModalState<models.OrganizationBrand>();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const history = useHistory();
  const [filterParams, setFilterParams] = useQueryParams({
    is_active: ArrayParam,
    is_displayed: ArrayParam,
  });
  const bulkDeleteModal = useModalState();

  const [search, setSearch] = React.useState("");
  const [ordering, setOrdering] = useOrderingQueryParam();

  const [checkedBrands, setCheckedBrands] = useState<CheckboxesCheckedInfoType>(
    checkboxesCheckedInfo,
  );

  const queryParams = {
    ...pagination.queryParams,
    search,
    ...filterParams,
    ordering,
  };

  const query = useQuery(querykeys.brands.many(queryParams), () =>
    api.brands.get(queryParams),
  );

  const allBrandsIdOnPage = query.data?.results
    ?.map((item) => String(item?.brand_id))
    .filter((item) => item);

  const deleteMutation = useMutation(
    () => api.brands.deleteByID(deleteBrand.data?.brand_id!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.brands.many());
        notify.success({
          title: t("template.item_deleted_successfully", {
            item: t("brand.brand"),
          }),
        });
        deleteBrand.close();
      },
      onError: (errors: AxiosError) => notifyErrors(notify, errors),
    },
  );

  useUpdateEffect(() => {
    setCheckedBrands(checkboxesCheckedInfo);
  }, [search, filterParams]);

  const tableColumns = React.useMemo(
    () =>
      brandsColumns({
        onEdit: brandModal.openWith,
        onDelete: deleteBrand.openWith,
        t,
        setCheckboxInfo: setCheckedBrands,
        checkboxInfo: {
          ...checkedBrands,
          checkboxesOnPage: allBrandsIdOnPage,
        },
      }),
    [
      allBrandsIdOnPage,
      brandModal.openWith,
      checkedBrands,
      deleteBrand.openWith,
      t,
    ],
  );

  const bulkDeleteMutation = useMutation(
    () =>
      api.brands.bulkDelete({
        brands_id: checkedBrands.allCheckedCheckboxes,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.brands.many());
        notify.success({
          title: t("template.item_deleted_successfully", {
            item: t("brand.brands"),
          }),
        });
        bulkDeleteModal.close();
        setCheckedBrands(checkboxesCheckedInfo);
      },
      onError: (errors: AxiosError) => notifyErrors(notify, errors),
    },
  );

  const selectedTitle = useFilterQueryChecked<models.OrganizationBrand>({
    querykeys: querykeys.brands.many(queryParams)[0],
    checked: checkedBrands,
    key: "brand_id",
  }).map(({ title }) => title);

  return (
    <>
      <PageTitle title={t("brand.brands")} count={query.data?.count} />
      <Card>
        <Card.Body>
          <QueryTable
            variants={["highlight-hover"]}
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                  sortOptions={brandsSortOptions}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                  filters={
                    <Filters
                      queryParams={filterParams}
                      setQueryParams={setFilterParams}
                    >
                      <ChoiceFilter
                        value={filterParams.is_active}
                        setValue={forwardSetState(setFilterParams, "is_active")}
                        title={t("brand.brand_status")}
                        choice={Object.values(["true", "false"]).map(
                          (is_active) => ({
                            key: is_active,
                            value: isActiveStatus[is_active],
                          }),
                        )}
                      />
                      <ChoiceFilter
                        value={filterParams.is_displayed}
                        setValue={forwardSetState(
                          setFilterParams,
                          "is_displayed",
                        )}
                        title={t("brand.brand_main_page_status")}
                        choice={Object.values(["true", "false"]).map(
                          (is_displayed) => ({
                            key: is_displayed,
                            value: isDisplayedStatus[is_displayed],
                          }),
                        )}
                      />
                    </Filters>
                  }
                />
                <Space>
                  {!!checkedBrands.allCheckedCheckboxes.length && (
                    <Button
                      type="primary"
                      onClick={bulkDeleteModal.open}
                      prefix={<Icons.Trash size="1rem" />}
                    >
                      {t("common.delete")}
                    </Button>
                  )}

                  <Link to="/product-management/brands/add-brands">
                    <Button type="primary">
                      {t("template.add_item", {
                        item: t("brand.brand").toLowerCase(),
                      })}
                    </Button>
                  </Link>
                </Space>
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            onRow={(record) => ({
              onClick: () =>
                history.push(`/product-management/brands/${record?.id}`),
            })}
          />
        </Card.Body>
      </Card>
      {brandModal.isOpen && (
        <BrandModal
          open={brandModal.isOpen}
          onClose={brandModal.close}
          data={brandModal.data}
          closeOnClickOutside={false}
        />
      )}
      {deleteBrand.isOpen && (
        <DeleteModal
          open={deleteBrand.isOpen}
          onClose={deleteBrand.close}
          size="small"
          mutation={deleteMutation}
          title={t("template.delete_item", {
            item: t("brand.brand").toLowerCase(),
          })}
        >
          <div className="py-30">
            <h3 className="text-center">
              {`${t("template.are_you_sure_you_want_to_delete_this_item", {
                item: t("brand.brand").toLowerCase(),
              })} - `}
              <span className="primary-color">
                {deleteBrand.data?.brand_id}
              </span>
              ?
            </h3>
          </div>
        </DeleteModal>
      )}
      {bulkDeleteModal.isOpen && (
        <DeleteModal
          open={bulkDeleteModal.isOpen}
          onClose={bulkDeleteModal.close}
          size="small"
          mutation={bulkDeleteMutation}
          title={t("template.delete_item", {
            item: t("brand.brands").toLowerCase(),
          })}
        >
          <div className="py-30">
            <h3 className="text-center">
              {`${t("template.are_you_sure_you_want_to_delete_these_items", {
                items: t("brand.brands").toLowerCase(),
              })} - `}
              <Flex inline gap="0.5rem">
                {selectedTitle.map((title, i) => (
                  <span key={i} className="primary-color">
                    {title}
                    {i !== selectedTitle.length - 1 && ","}
                  </span>
                ))}
              </Flex>
              ?
            </h3>
          </div>
        </DeleteModal>
      )}
    </>
  );
};
