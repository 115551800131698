export function arrIncludes(arr: unknown[], what: any) {
  return arr.includes(what);
}
/**
   *
   * @example
   * wrapInArr(['asdf', 'asdf']) === ['asdf', 'asdf'];
   * wrapInArr('asdf') === ['asdf'];
   * wrapInArr(undefined) === [];
  
   */
export function wrapInArr<T>(value: T | readonly T[] | undefined) {
  return value instanceof Array
    ? [...value]
    : value !== undefined
    ? [value]
    : [];
}

export function arrayEquals(arr1: any[], arr2: any[]) {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((v, idx) => v === arr2[idx]);
}
