import { Button, Form, Modal, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { NumberParam } from "use-query-params";

import api, { querykeys } from "api";
import { Flex, InputFormField, LoadingButton, WhiteSpace } from "components";
import { useParam } from "hooks";
import models from "models";
import { notifyErrors } from "utils";

interface ChangeDeviceStatusModalProps extends ModalProps {
  data?: models.TypeWithIds;
  onClose: () => void;
}
export const ChangeDeviceStatusModal = ({
  data,
  onClose,
  ...props
}: ChangeDeviceStatusModalProps) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const orderId = useParam("orderId", NumberParam.decode) || 0;
  const deviceId = useParam("deviceId", NumberParam.decode) || 0;
  const statusIsRejected =
    data?.status === models.OrderStatus.REJECTED ||
    data?.status === models.OrderStatus.PENDING_REJECTION;

  const { mutate, isLoading } = useMutation(
    (body: models.DeviceReason) =>
      statusIsRejected
        ? api.devices.reject(deviceId, body)
        : api.devices.accept(deviceId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.orders.one(orderId));
        notify.success({
          title: t(
            statusIsRejected
              ? "notification.device_rejected_successfully"
              : "notification.device_accepted_successfully",
          ),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Modal
      title={`${
        statusIsRejected ? t("common.reject") : t("common.approve")
      } ${t("device.device")}`}
      size="small"
      onClose={onClose}
      className="edit-service-modal"
      {...props}
    >
      <Form
        initialValues={{ reason: data?.reason }}
        name="device"
        onFinish={mutate}
        className="approve-form"
      >
        <Modal.Content>
          <InputFormField
            initialValue={data?.reason}
            name={statusIsRejected ? "reason" : "remark"}
            label={t(
              statusIsRejected ? "common.reason" : "common.internal_remarks",
            )}
            textarea
          />
        </Modal.Content>

        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton loading={isLoading} submit type="primary">
              {t(statusIsRejected ? "common.reject" : "common.approve")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
