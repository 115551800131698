import React from "react";
import { Row, Col } from "antd";
import {
  BoxPlotTwoTone,
  AppstoreTwoTone,
  ContainerTwoTone,
  ProjectTwoTone,
  ProfileTwoTone,
  ControlTwoTone,
  LayoutTwoTone,
  EnvironmentTwoTone,
  ShoppingTwoTone,
  SecurityScanTwoTone,
  InsertRowBelowOutlined,
  IdcardOutlined,
  LinkOutlined,
  UserOutlined,
  ColumnHeightOutlined,
  InteractionOutlined,
  EyeInvisibleOutlined,
  SplitCellsOutlined,
  FileTextOutlined,
  FileImageOutlined,
  HighlightOutlined,
  BorderOutlined,
  NotificationTwoTone,
  ContactsTwoTone,
} from "@ant-design/icons";

import titles from "./titles.json";

export const Fields = {
  content: (
    <Row gutter={[8, 8]}>
      <Col>
        <ProfileTwoTone />
      </Col>
      <Col>{titles.content}</Col>
    </Row>
  ),
  componentAvatar: (
    <Row gutter={[8, 8]}>
      <Col>
        <UserOutlined />
      </Col>
      <Col>{titles.componentAvatar}</Col>
    </Row>
  ),
  componentButton: (
    <Row gutter={[8, 8]}>
      <Col>
        <InteractionOutlined />
      </Col>
      <Col>{titles.componentButton}</Col>
    </Row>
  ),
  componentCard: (
    <Row gutter={[8, 8]}>
      <Col>
        <IdcardOutlined />
      </Col>
      <Col>{titles.componentCard}</Col>
    </Row>
  ),
  componentCollapse: (
    <Row gutter={[8, 8]}>
      <Col>
        <ColumnHeightOutlined />
      </Col>
      <Col>{titles.componentCollapse}</Col>
    </Row>
  ),
  componentContainer: (
    <Row gutter={[8, 8]}>
      <Col>
        <BorderOutlined />
      </Col>
      <Col>{titles.componentContainer}</Col>
    </Row>
  ),
  componentImage: (
    <Row gutter={[8, 8]}>
      <Col>
        <FileImageOutlined />
      </Col>
      <Col>{titles.componentImage}</Col>
    </Row>
  ),
  componentLabel: (
    <Row gutter={[8, 8]}>
      <Col>
        <HighlightOutlined />
      </Col>
      <Col>{titles.componentLabel}</Col>
    </Row>
  ),
  componentLink: (
    <Row gutter={[8, 8]}>
      <Col>
        <LinkOutlined />
      </Col>
      <Col>{titles.componentLink}</Col>
    </Row>
  ),
  componentResponsive: (
    <Row gutter={[8, 8]}>
      <Col>
        <EyeInvisibleOutlined />
      </Col>
      <Col>{titles.componentResponsive}</Col>
    </Row>
  ),
  componentSpace: (
    <Row gutter={[8, 8]}>
      <Col>
        <SplitCellsOutlined />
      </Col>
      <Col>{titles.componentSpace}</Col>
    </Row>
  ),
  componentText: (
    <Row gutter={[8, 8]}>
      <Col>
        <FileTextOutlined />
      </Col>
      <Col>{titles.componentText}</Col>
    </Row>
  ),
  moduleMap: (
    <Row gutter={[8, 8]}>
      <Col>
        <EnvironmentTwoTone />
      </Col>
      <Col>{titles.moduleMap}</Col>
    </Row>
  ),
  moduleOrder: (
    <Row gutter={[8, 8]}>
      <Col>
        <ShoppingTwoTone />
      </Col>
      <Col>{titles.moduleOrder}</Col>
    </Row>
  ),
  moduleBlog: (
    <Row gutter={[8, 8]}>
      <Col>
        <NotificationTwoTone />
      </Col>
      <Col>{titles.moduleBlog}</Col>
    </Row>
  ),
  moduleSearchModel: (
    <Row gutter={[8, 8]}>
      <Col>
        <SecurityScanTwoTone />
      </Col>
      <Col>{titles.moduleSearchModel}</Col>
    </Row>
  ),
  moduleContactUs: (
    <Row gutter={[8, 8]}>
      <Col>
        <ContactsTwoTone />
      </Col>
      <Col>{titles.moduleContactUs}</Col>
    </Row>
  ),
  sectionRow: (
    <Row gutter={[8, 8]}>
      <Col>
        <InsertRowBelowOutlined />
      </Col>
      <Col>{titles.sectionRow}</Col>
    </Row>
  ),
};

const List = [
  {
    type: "components",
    title: (
      <Row gutter={[8, 8]}>
        <Col>
          <ControlTwoTone />
        </Col>
        <Col>{titles.components}</Col>
      </Row>
    ),
    children: [
      {
        type: "componentContainer",
        title: Fields.componentContainer,
        isMultiple: true,
        canNesting: true,
      },
      {
        type: "sections",
        title: (
          <Row gutter={[8, 8]}>
            <Col>
              <LayoutTwoTone />
            </Col>
            <Col>{titles.sections}</Col>
          </Row>
        ),
        children: [
          {
            type: "sectionMain",
            title: titles.sectionMain,
            isMultiple: true,
            canNesting: true,
          },
          {
            type: "sectionContent",
            title: titles.sectionContent,
            isMultiple: true,
            canNesting: true,
          },
        ],
      },
      {
        type: "componentSpace",
        title: Fields.componentSpace,
        isMultiple: true,
        canNesting: true,
      },
      {
        type: "componentText",
        title: Fields.componentText,
        isMultiple: true,
      },
      {
        type: "componentLink",
        title: Fields.componentLink,
        isMultiple: true,
        canNesting: true,
      },
      {
        type: "componentButton",
        title: Fields.componentButton,
        isMultiple: true,
      },
      {
        type: "componentLabel",
        title: Fields.componentLabel,
        isMultiple: true,
      },
      {
        type: "componentCard",
        title: Fields.componentCard,
        canDrag: true,
        isMultiple: true,
      },
      {
        type: "componentCollapse",
        title: Fields.componentCollapse,
        canDrag: true,
        isMultiple: true,
      },
      {
        type: "componentImage",
        title: Fields.componentImage,
        isMultiple: true,
      },
    ],
  },
  {
    type: "modules",
    title: (
      <Row gutter={[8, 8]}>
        <Col>
          <AppstoreTwoTone />
        </Col>
        <Col>{titles.modules}</Col>
      </Row>
    ),
    children: [
      {
        type: "moduleBlog",
        title: Fields.moduleBlog,
      },
      {
        type: "moduleOrder",
        title: Fields.moduleOrder,
      },
      {
        type: "moduleMap",
        title: Fields.moduleMap,
      },
      {
        type: "moduleSearchModel",
        title: Fields.moduleSearchModel,
      },
      {
        type: "moduleContactUs",
        title: Fields.moduleContactUs,
      },
    ],
  },
  {
    type: "templates",
    title: (
      <Row gutter={[8, 8]}>
        <Col>
          <BoxPlotTwoTone />
        </Col>
        <Col>{titles.templates}</Col>
      </Row>
    ),
    children: [
      {
        type: "banners",
        title: (
          <Row gutter={[8, 8]}>
            <Col>
              <ProjectTwoTone />
            </Col>
            <Col>{titles.banners}</Col>
          </Row>
        ),
        children: [
          { type: "bannerHome", title: titles.bannerHome },
          { type: "bannerTrustUs", title: titles.bannerTrustUs },
          { type: "bannerDownload", title: titles.bannerDownload },
          { type: "bannerHelpSupport", title: titles.bannerHelpSupport },
          { type: "joinUs", title: titles.joinUs },
        ],
      },
      {
        type: "cards",
        title: (
          <Row gutter={[8, 8]}>
            <Col>
              <ContainerTwoTone />
            </Col>
            <Col>{titles.cards}</Col>
          </Row>
        ),
        children: [
          { type: "cardBrands", title: titles.cardBrands },
          { type: "cardHowItWorks", title: titles.cardHowItWorks },
          {
            type: "templateInfo",
            title: titles.templateInfo,
            isMultiple: true,
          },
          {
            type: "templateText",
            title: titles.templateText,
            isMultiple: true,
          },
          { type: "templateConfirmation", title: titles.templateConfirmation },
        ],
      },
    ],
  },
];

export default List;
