import cn from "classnames";
import { Button } from "ebs-design";
import { ElementType } from "react";
import { Link, LinkProps, useHistory } from "react-router-dom";

import { Flex, WhiteSpace } from "components";
import { ArrowLeft } from "./icons";

export interface PageTitleProps {
  to?: LinkProps["to"];
  actions?: React.ReactNode;
  className?: string;
  as?: ElementType;
  goBack?: boolean;
  title?: string;
  count?: number;
}

export const PageTitle = ({
  children,
  to,
  actions = null,
  className,
  as: Tag = "h3",
  goBack = false,
  title,
  count,
}: React.PropsWithChildren<PageTitleProps>) => {
  const history = useHistory();
  const countStr = typeof count === "number" ? `(${count})` : "";
  const content = title ? `${title}${countStr}` : children;

  return (
    <div className={cn("page-title", className)}>
      <Flex align="center">
        {(to || goBack) && (
          <>
            <Link to={to || "#"}>
              <Button
                size="small"
                type="light"
                onClick={() => !to && history.goBack()}
                icon={() => <ArrowLeft size="14px" />}
              />
            </Link>
            <WhiteSpace h="1rem" />
          </>
        )}
        {Tag ? <Tag>{content}</Tag> : <>{content}</>}
      </Flex>
      {actions}
    </div>
  );
};
