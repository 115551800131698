import { QueryParamConfig, SetQuery } from "use-query-params";
import { Select } from "ebs-design";
import { transformEnumToArray } from "utils";
import { useState } from "react";
import { useEffect } from "react";

enum OrderStatusFilter {
  DEVICE_DELIVERED = "DEVICE_DELIVERED",
  PENDING_COMPLETION = "PENDING_COMPLETION",
  PENDING_REJECTION = "PENDING_REJECTION",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
}

const filterOption = transformEnumToArray(OrderStatusFilter);
const valueAll = "all";
filterOption.unshift({ text: "All", value: valueAll });

const allFilter = transformEnumToArray(OrderStatusFilter).map((v) => v.value);

interface FilterByOrderStatusProps {
  currentParam?: string[] | null;
  onChange: SetQuery<{
    hidden_status__in: QueryParamConfig<
      string[] | null | undefined,
      string[] | null | undefined
    >;
  }>;
}

export const FilterByOrderStatus = ({
  onChange,
  currentParam,
}: FilterByOrderStatusProps) => {
  const [value, setValue] = useState(currentParam);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    currentParam = currentParam ? currentParam : [];
    allFilter?.every((arr, i) => arr === currentParam[i]) &&
    !!currentParam.length
      ? setValue(filterOption.map((v) => v.value))
      : setValue(currentParam);
  }, [currentParam]);

  return (
    <Select
      style={{ width: 200 }}
      mode="multiple"
      options={filterOption}
      placeholder="Select status"
      value={value}
      onChange={(v) => {
        if (Array.isArray(v) && v.includes(valueAll)) {
          !value.includes(valueAll)
            ? onChange({ hidden_status__in: allFilter })
            : onChange({
                hidden_status__in: v.filter((i) => i !== valueAll) as string[],
              });
        } else if (value.includes(valueAll)) {
          onChange({ hidden_status__in: [] });
        } else {
          onChange({ hidden_status__in: v as string[] });
        }
      }}
    />
  );
};
