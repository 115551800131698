import React from "react";
import { Form, Select } from "antd";
import { Properties } from "types";

import { formItemStyle } from "../helpers";

const Order = ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "dataType"]}
        label="Type"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="erasure">Data Erasure</Select.Option>
          <Select.Option value="sell">Sell phone</Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};

export default Order;
