import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BarChart as ReBarCharts,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  Brush,
} from "recharts";
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";

import { makeBEM } from "utils";
import { monthTickFormatter, setFormatedData } from ".";

interface ChartProps extends CategoricalChartProps {
  suffix?: string;
  legend?: boolean;
  tooltip?:
    | React.ReactElement
    | ((
        props: TooltipProps<any, any>,
        monthTickFormatter?: (tick: string) => string,
      ) => React.ReactNode);
  xAxisKey?: string;
  yAxisKey?: string;
  clusterKey?: string[];
}

const bem = makeBEM("bar-chart");

export const BarChart: React.FC<ChartProps> = ({
  suffix,
  legend,
  tooltip,
  xAxisKey,
  yAxisKey,
  children,
  data = [],
  clusterKey = [yAxisKey],
  barSize = 22,
  ...props
}) => {
  const [chartData, setChartData] = useState(data);
  const { t } = useTranslation();

  useEffect(() => {
    setFormatedData({ data, clusterKey, setChartData, xAxisKey, t });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  const suffixTickFormatter = (tick: string) => {
    return suffix ? tick + suffix : tick;
  };

  return (
    <ResponsiveContainer className={bem()} width="100%" height={300}>
      <ReBarCharts
        margin={{ bottom: 0, right: 0, left: 0, top: 0 }}
        data={chartData}
        barSize={barSize}
        {...props}
      >
        <CartesianGrid strokeDasharray="6" vertical={false} stroke="#EEF0F2" />
        <XAxis
          dataKey={xAxisKey}
          tickFormatter={monthTickFormatter(data, xAxisKey, t)}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          dataKey={yAxisKey}
          tickFormatter={suffixTickFormatter}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip
          cursor={false}
          content={
            typeof tooltip === "function"
              ? (props) => tooltip(props, monthTickFormatter(data, xAxisKey, t))
              : tooltip
          }
        />
        {legend && (
          <Legend
            iconType="circle"
            iconSize={6}
            formatter={(value: string) => (
              <span className="grey-400">{value}</span>
            )}
          />
        )}
        {children}
        <Brush
          dataKey={xAxisKey}
          stroke="var(--primary-500)"
          tickFormatter={() => ""}
        />
      </ReBarCharts>
    </ResponsiveContainer>
  );
};
