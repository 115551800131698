import { Cell, TooltipProps } from "recharts";

import { CustomTooltip, PieChart } from "components";
import { capitalizeString, orderStatus } from "utils";
import models from "models";

interface OrderTotalChartProps {
  data: models.OrderTotalsByStatus[];
  trendDirection?: number;
  valueLabel?: string | number;
}

const CustomTooltipComponent = ({
  active,
  payload,
}: TooltipProps<string | number, string | number>) => {
  if (active && payload?.length) {
    return (
      <CustomTooltip
        icon
        value={`${payload[0].value}`}
        color={payload[0].payload?.fill}
        name={capitalizeString(payload[0].payload?.status, "_")}
      />
    );
  }
  return null;
};

export const OrderTotalChart = ({
  data,
  trendDirection,
  valueLabel,
}: OrderTotalChartProps) => {
  const isShow = data?.some(({ total }) => total);

  if (!isShow) return null;
  return (
    <PieChart
      dataKey="total"
      data={data}
      tooltip={<CustomTooltipComponent />}
      trendDirection={trendDirection}
      valueLabel={valueLabel}
    >
      {data.map(({ status }, index) => (
        <Cell
          key={`cell-${index}`}
          name={capitalizeString(status, "_")}
          fill={orderStatus[status]}
        />
      ))}
    </PieChart>
  );
};
