const defaults = {
  pagination: {
    pageSize: 10,
    pageSizeChoice: [10, 25, 50, 75, 100],
  },
  bigLimit: 500,
  defaultImageFallback: "assets/images/image-fallback.png",
};

export default defaults;
