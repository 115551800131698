import * as Icons from "components/icons";

export const servicesConfigs = {
  CMS_SERVICE: {
    icon: <Icons.Document />,
    iconBg: "#C479F3",
    title: "Content management",
    name: "CMS_SERVICE",
    description: "Content platform for enterprises",
    path: "/cms/menu",
  },
  AUTH_SERVICE: {
    icon: <Icons.User />,
    iconBg: "#38D9B9",
    title: "User Management",
    name: "AUTH_SERVICE",
    description: "Rapidly integrate authentication",
    path: "/auth/departments",
  },
  PM_SERVICE: {
    icon: <Icons.Devices />,
    iconBg: "#F8DD4E",
    title: "Product Management",
    name: "PM_SERVICE",
    description: "Product management",
    path: "/product-management/brands",
  },
  WAREHOUSE_SERVICE: {
    icon: <Icons.Warehouses />,
    iconBg: "#F76659",
    title: "Warehouse Service",
    name: "WAREHOUSE_SERVICE",
    description: "Warehouse management",
    path: "/warehouse/drop-off-locations",
  },
  CRM_SERVICE: {
    icon: <Icons.CRM />,
    iconBg: "#84C3BE",
    title: "Customer relationship management",
    name: "CRM_SERVICE",
    description: "Content platform for enterprises",
    path: "/crm/orders/buyback-orders",
  },
};
