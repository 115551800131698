import classNames from "classnames";
import { createClassWithModifiers } from "../utils";

export interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {}
export interface DropdownContentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  position?: "left" | "right";
  cover?: boolean;
}

export const Dropdown = ({
  className,
  ...props
}: React.PropsWithChildren<DropdownProps>) => {
  return <div className={classNames("dropdown", className)} {...props} />;
};

Dropdown.Content = ({
  className,
  position,
  cover = true,
  ...props
}: React.PropsWithChildren<DropdownContentProps>) => {
  return (
    <div
      className={classNames(
        className,
        createClassWithModifiers("surface dropdown__content", [position], {
          cover,
        })
      )}
      tabIndex={-1}
      {...props}
    />
  );
};
