import cn from "classnames";
import models from "models";
import { capitalizeString, createClassWithModifiers, makeBEM } from "utils";

const bem = makeBEM("payout-status");

interface PayoutStatusProps {
  status: models.PayoutStatus;
}

export const PayoutStatus = ({ status }: PayoutStatusProps) => {
  const payoutStatusColor = {
    [createClassWithModifiers(bem(), ["completed"])]:
      status === models.PayoutStatus.COMPLETED,
    [createClassWithModifiers(bem(), ["processing"])]:
      status === models.PayoutStatus.PROCESSING ||
      status === models.PayoutStatus.IN_PROCESS,
  };

  return (
    <div className={cn(bem(), payoutStatusColor)}>
      <span className={bem("circle")}></span>
      <span className="ml-5">{capitalizeString(status, "_")}</span>
    </div>
  );
};
