import models from "models";

export interface Device {
  id: number;
  imei?: string;
  client?: models.ShortClientUser;
  brand_model?: models.NomenclatureBrand;
  brand_model_id?: number;
  storage_capacity?: string;
  color?: string;
  color_hex?: string;
  diagnostic_criteria?: models.DiagnosticCriteria[];
  diagnostic_criteria_id?: number[];
  approval?: DeviceApproval;
  status?: DeviceStatus;
  order?: models.ShortInventoryOrder;
  recycle_certificate?: models.File;
  timestamp?: string;
  edited_timestamp?: string;
  blacklisted?: boolean;
  price?: string;
  state?: DeviceApprovalState;
}

export interface OrderClass {
  id: number;
  code?: string;
  status?: string;
  services?: OrderService[];
}
export interface OrderService {
  id: number;
  code?: string;
}
export interface DeviceApproval {
  id?: number;
  state?: DeviceApprovalState;
  reason?: string;
  remark?: string;
  price?: string;
  owner?: models.User;
  client?: string;
  user?: string;
  operator_price?: string;
}

export enum DeviceApprovalState {
  PRICE_UPDATED = "PRICE_UPDATED",
  REJECTED = "REJECTED",
  ACCEPTED = "ACCEPTED",
}

export enum DeviceStatus {
  READY = "READY",
  REMOVED = "REMOVED",
  WH_OK = "WH_OK",
  WH_REJECTED = "WH_REJECTED",
}

export interface Approval {
  id: number;
  state?: DeviceApprovalState;
  reason?: string;
  price?: string;
  owner?: string;
  client?: string;
  user?: string;
}

export interface RecycleDevice {
  recycle_certificate_id: number;
  recycle_certificate?: models.File;
}

export interface DeviceReason {
  reason: string;
}
