import { Button, Card } from "ebs-design";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { useQueryParams } from "use-query-params";
import { CommaArrayParam, forwardSetState } from "utils";

import api, { querykeys } from "api";
import {
  Filters,
  ChoiceFilter,
  Flex,
  Grid,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import {
  useDataErasureVisibility,
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";
import models from "models";
import { checkboxesCheckedInfo, CheckboxesCheckedInfoType } from "types";

import { ordersColumns, ordersSortOptions } from "../config";
import { ChangeOrdersStatusModal } from ".";

export const SupervisorOrders = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dataErasureVisibile = useDataErasureVisibility();

  const [search, setSearch] = useState("");
  const changeOrderStatusModal = useModalState<models.TypeWithIds>();
  const pagination = useQueryPagination();
  const [ordering, setOrdering] = useOrderingQueryParam();
  const [filterParams, setFilterParams] = useQueryParams({
    hidden_status__in: CommaArrayParam,
  });

  const queryParams = {
    parent_id__isnull: false,
    ...pagination.queryParams,
    ordering,
    search,
    ...filterParams,
  };

  const query = useQuery(querykeys.orders.many(queryParams), () =>
    api.orders.get(queryParams),
  );

  const [checkedOrders, setCheckedOrders] = useState<CheckboxesCheckedInfoType>(
    checkboxesCheckedInfo,
  );
  const allOrdersIds = query.data?.results?.map((item) => String(item?.id));

  useUpdateEffect(() => {
    pagination.setPage(1);
    setCheckedOrders(checkboxesCheckedInfo);
  }, [filterParams, ordering, search]);

  const tableColumns = useMemo(
    () =>
      ordersColumns({
        setCheckboxInfo: setCheckedOrders,
        checkboxInfo: {
          ...checkedOrders,
          checkboxesOnPage: allOrdersIds,
        },
        dataErasureVisibile,
        t,
        isSupervisor: true,
      }),
    [allOrdersIds, checkedOrders, t, dataErasureVisibile],
  );
  return (
    <>
      <PageTitle title={t("order.orders")} count={query.data?.count} />
      <Card>
        <Card.Body>
          <QueryTable
            variants={["highlight-hover"]}
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                  sortOptions={ordersSortOptions}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                  filters={
                    <Filters
                      queryParams={filterParams}
                      setQueryParams={setFilterParams}
                    >
                      <ChoiceFilter
                        title={t("order.order_status")}
                        value={queryParams.hidden_status__in}
                        setValue={forwardSetState(
                          setFilterParams,
                          "hidden_status__in",
                        )}
                        choice={Object.values(models.OrderStatus).map(
                          (type) => ({
                            key: type,
                            value: type,
                          }),
                        )}
                        filterType="list"
                      />
                    </Filters>
                  }
                />
                <Grid cols="1fr 1fr" gap="16px">
                  <Button
                    type="light"
                    disabled={!checkedOrders?.allCheckedCheckboxes.length}
                    onClick={() =>
                      changeOrderStatusModal.openWith({
                        status: models.OrderStatus.REJECTED,
                        ids: checkedOrders?.allCheckedCheckboxes,
                      })
                    }
                  >
                    {t("common.reject")}
                  </Button>
                  <Button
                    disabled={!checkedOrders?.allCheckedCheckboxes.length}
                    onClick={() =>
                      changeOrderStatusModal.openWith({
                        status: models.OrderStatus.COMPLETED,
                        ids: checkedOrders?.allCheckedCheckboxes,
                      })
                    }
                    type="primary"
                  >
                    {t("common.approve")}
                  </Button>
                </Grid>
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            onRow={(data) => ({
              onClick: () =>
                history.push(
                  `/crm/orders/warehouse-orders/${data?.id}/device/${data?.device?.id}`,
                ),
            })}
            rowKey="id"
          />
        </Card.Body>
      </Card>
      {changeOrderStatusModal.isOpen && (
        <ChangeOrdersStatusModal
          open={changeOrderStatusModal.isOpen}
          onClose={changeOrderStatusModal.close}
          data={changeOrderStatusModal.data}
        />
      )}
    </>
  );
};
