import { createSvgIcon } from "../utils";

export const FileDocument = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="40"
    viewBox="0 0 30 40"
    fill="none"
  >
    <path
      d="M0.5 36V4C0.5 2.067 2.067 0.5 4 0.5H17.9C18.7987 0.5 19.663 0.845712 20.3138 1.46552L28.4138 9.1798C29.1074 9.8404 29.5 10.7564 29.5 11.7143V36C29.5 37.933 27.933 39.5 26 39.5H4C2.067 39.5 0.5 37.933 0.5 36Z"
      fill="white"
      stroke="#E5E9EB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 14C9 13.4696 9.21071 12.9609 9.58579 12.5858C9.96086 12.2107 10.4696 12 11 12H15.586C16.1164 12.0001 16.625 12.2109 17 12.586L20.414 16C20.7891 16.375 20.9999 16.8836 21 17.414V26C21 26.5304 20.7893 27.0391 20.4142 27.4142C20.0391 27.7893 19.5304 28 19 28H11C10.4696 28 9.96086 27.7893 9.58579 27.4142C9.21071 27.0391 9 26.5304 9 26V14ZM11 20C11 19.7348 11.1054 19.4804 11.2929 19.2929C11.4804 19.1054 11.7348 19 12 19H18C18.2652 19 18.5196 19.1054 18.7071 19.2929C18.8946 19.4804 19 19.7348 19 20C19 20.2652 18.8946 20.5196 18.7071 20.7071C18.5196 20.8946 18.2652 21 18 21H12C11.7348 21 11.4804 20.8946 11.2929 20.7071C11.1054 20.5196 11 20.2652 11 20ZM12 23C11.7348 23 11.4804 23.1054 11.2929 23.2929C11.1054 23.4804 11 23.7348 11 24C11 24.2652 11.1054 24.5196 11.2929 24.7071C11.4804 24.8946 11.7348 25 12 25H18C18.2652 25 18.5196 24.8946 18.7071 24.7071C18.8946 24.5196 19 24.2652 19 24C19 23.7348 18.8946 23.4804 18.7071 23.2929C18.5196 23.1054 18.2652 23 18 23H12Z"
      fill="#1563E2"
    />
  </svg>,
);
