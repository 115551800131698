import { Button, Badge, useNotify, useForm, Loader } from "ebs-design";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

import api, { querykeys } from "api";
import models from "models";
import { formattedDateTime, makeBEM } from "utils";
import { useMapErrors } from "hooks";

import * as Icons from "./icons";

const bem = makeBEM("notifications");

interface Props {
  message: models.Notification;
  onUpdateSuccess: () => void;
}

const NotificationPriority = {
  INFO: {
    component: <Icons.Info color="#1563E2" />,
    className: "info",
  },
  SUCCESS: {
    component: <Icons.Success color="#47D16C" />,
    className: "success",
  },
  WARNING: {
    component: <Icons.Warning color="#F8DD4E" />,
    className: "warning",
  },
  ERROR: {
    component: <Icons.Danger color="#F76659" />,
    className: "danger",
  },
};

export const NotificationsMessage = ({ message, onUpdateSuccess }: Props) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const mapErrors = useMapErrors();

  const { mutate } = useMutation(
    ({ id, is_read }: models.Notification) =>
      is_read
        ? api.notifications.patch(id, { is_read })
        : api.notifications.delete(id),
    {
      onSuccess: () => {
        onUpdateSuccess();
        notify.success({
          title: t("notification.changes_saved_successfully"),
        });
      },
      onError: (errors: any) => mapErrors(errors, notify, form),
    },
  );

  const className = NotificationPriority[message.priority]?.className;
  const NotificationIcon = NotificationPriority[message.priority]?.component;

  return (
    <div
      className={bem("message", [
        message.is_read ? `${className}--read` : className,
      ])}
    >
      <div className={bem("type")}>{NotificationIcon}</div>
      <div className={bem("wrapper")}>
        <h4 className={bem("title")}>{message.title}</h4>
        <p className={bem("description")}>{message.description}</p>
        <p className={bem("time")}>{formattedDateTime(message.timestamp)}</p>
      </div>
      {(!message.is_read && (
        <Icons.Eye
          width="1.4rem"
          height="1.4rem"
          onClick={() => {
            mutate({ id: message.id, is_read: true });
          }}
          className={bem("eye")}
        />
      )) || (
        <Icons.Closed
          width="0.9rem"
          height="0.9rem"
          onClick={() => {
            mutate({ id: message.id });
          }}
          className={bem("close")}
        />
      )}
    </div>
  );
};

export const Notifications = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);
  const [isIntersecting, setIntersecting] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
  });

  const notReadQueryParams = {
    is_read: false,
  };

  const queryNotRead = useQuery(
    querykeys.notification.many(notReadQueryParams),
    () => api.notifications.get(notReadQueryParams),
  );

  const { data, isFetching, fetchNextPage, refetch } = useInfiniteQuery(
    querykeys.notification.many(),
    async ({ pageParam = 1 }) =>
      await api.notifications.get({ page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.current_page < lastPage.total_pages
          ? lastPage.current_page + 1
          : undefined,
    },
  );

  useEffect(() => {
    if (isIntersecting) {
      fetchNextPage();
    }
  }, [fetchNextPage, isIntersecting]);

  const firstPage = data?.pages[0];
  const hasNotications = !!firstPage?.count;

  return (
    <div className={bem()}>
      <Badge count={queryNotRead?.data?.count} type="danger">
        <Button
          type="text"
          size="small"
          onClick={() => setIsOpen(true)}
          icon={() => <Icons.Notifications />}
        />
      </Badge>
      {isOpen && (
        <>
          <div className={bem("outside")} onClick={() => setIsOpen(false)} />
          <div className={bem("window")}>
            <div className={bem("window-inner")}>
              {!hasNotications && (
                <p className="mr-8">
                  {t("common.you_dont_have_any_notifications")}
                </p>
              )}
              {data?.pages.map((page, pageIdx) =>
                page?.results
                  ?.map((item: models.Notification) => (
                    <NotificationsMessage
                      key={item.id}
                      message={item}
                      onUpdateSuccess={() => {
                        refetch({
                          refetchPage: (_, index) => index === pageIdx,
                        });
                        queryClient.invalidateQueries(
                          querykeys.notification.many(notReadQueryParams),
                        );
                      }}
                    />
                  ))
                  .flat(),
              )}
              <div ref={ref} style={{ height: "1px" }}></div>
              <Loader
                loading={isFetching}
                size="small"
                height="20px"
                className={bem("loader")}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
