import { Button, Checkbox } from "ebs-design";
import { useTranslation } from "react-i18next";
import { useQueryPagination } from "hooks";

import { Grid } from "components";
import { capitalizeString, translateIfIsTranslatable } from "utils";
import { FilterCollapse } from ".";

interface Choice {
  key: string;
  value: React.ReactNode;
}

interface ChoiceFilterProps {
  title: React.ReactNode;
  value?: (string | null)[] | null | undefined;
  setValue?: React.Dispatch<
    React.SetStateAction<(string | null)[] | null | undefined>
  >;
  choice?: Choice[];
  multiple?: boolean;
  filterType?: "buttons" | "list";
}

export const ChoiceFilter = ({
  title,
  value,
  setValue,
  choice,
  filterType = "buttons",
  multiple = false,
}: ChoiceFilterProps) => {
  const { t } = useTranslation();
  const pagination = useQueryPagination();

  const handleFilter = (c: Choice) => {
    pagination.setPage(1);
    if (multiple) {
      setValue?.((prev) =>
        prev?.includes(c.key)
          ? prev?.filter((p) => p !== c.key)
          : [...(prev || []), c.key],
      );
    } else {
      setValue?.([c.key]);
    }
  };

  return (
    <FilterCollapse
      title={translateIfIsTranslatable(t, title as string, "common")}
      count={value?.length}
    >
      {(filterType === "buttons" &&
        choice?.map((c, i) => (
          <Button
            key={i}
            className="mb-5 mr-5"
            type={value?.includes(c.key) ? "primary" : "light"}
            onClick={() => handleFilter(c)}
          >
            {c.value}
          </Button>
        ))) ||
        null}
      {filterType === "list" && (
        <Grid gap="0.5rem" className="py-3">
          {choice.map((item: any) => (
            <Checkbox
              checked={value?.includes(item.key || null) || false}
              onChange={(c) =>
                c
                  ? setValue?.((prev) => [...(prev || []), item.key || null])
                  : setValue?.((prev) => prev?.filter((p) => p !== item.key))
              }
              key={item.key}
              text={capitalizeString(item.value, "_")}
            />
          ))}
        </Grid>
      )}
    </FilterCollapse>
  );
};
