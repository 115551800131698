import { createSvgIcon } from "../utils";

export const Staff = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="21"
    viewBox="0 0 8 21"
    fill="none"
  >
    <path
      d="M1.25 20C1.25 20.4142 1.58579 20.75 2 20.75C2.41421 20.75 2.75 20.4142 2.75 20H1.25ZM2 15H2.75C2.75 14.8011 2.67098 14.6103 2.53033 14.4697L2 15ZM1 14H0.25C0.25 14.1989 0.329018 14.3897 0.46967 14.5303L1 14ZM7 14L7.53033 14.5303C7.67098 14.3897 7.75 14.1989 7.75 14H7ZM6 15L5.46967 14.4697C5.32902 14.6103 5.25 14.8011 5.25 15H6ZM5.25 20C5.25 20.4142 5.58579 20.75 6 20.75C6.41421 20.75 6.75 20.4142 6.75 20H5.25ZM5.25 3C5.25 3.69036 4.69036 4.25 4 4.25V5.75C5.51878 5.75 6.75 4.51878 6.75 3H5.25ZM4 4.25C3.30964 4.25 2.75 3.69036 2.75 3H1.25C1.25 4.51878 2.48122 5.75 4 5.75V4.25ZM2.75 3C2.75 2.30964 3.30964 1.75 4 1.75V0.25C2.48122 0.25 1.25 1.48122 1.25 3H2.75ZM4 1.75C4.69036 1.75 5.25 2.30964 5.25 3H6.75C6.75 1.48122 5.51878 0.25 4 0.25V1.75ZM2.75 20V15H1.25V20H2.75ZM2.53033 14.4697L1.53033 13.4697L0.46967 14.5303L1.46967 15.5303L2.53033 14.4697ZM1.75 14V10H0.25V14H1.75ZM1.75 10C1.75 9.9337 1.77634 9.87011 1.82322 9.82322L0.762563 8.76256C0.434375 9.09075 0.25 9.53587 0.25 10H1.75ZM1.82322 9.82322C1.87011 9.77634 1.9337 9.75 2 9.75V8.25C1.53587 8.25 1.09075 8.43437 0.762563 8.76256L1.82322 9.82322ZM2 9.75H6V8.25H2V9.75ZM6 9.75C6.0663 9.75 6.12989 9.77634 6.17678 9.82322L7.23744 8.76256C6.90925 8.43438 6.46413 8.25 6 8.25V9.75ZM6.17678 9.82322C6.22366 9.87011 6.25 9.9337 6.25 10H7.75C7.75 9.53587 7.56563 9.09075 7.23744 8.76256L6.17678 9.82322ZM6.25 10V14H7.75V10H6.25ZM6.46967 13.4697L5.46967 14.4697L6.53033 15.5303L7.53033 14.5303L6.46967 13.4697ZM5.25 15V20H6.75V15H5.25Z"
      fill="currentColor"
    />
  </svg>,
);
