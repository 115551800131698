import { Layout, Sidebar } from "ebs-design";
import { TFunction } from "react-i18next";
import { QueryKey } from "react-query";
import { Link } from "react-router-dom";

import api, { querykeys } from "api";
import logo from "assets/BuyBack.png";
import { BreadCrumbs } from "components";
import { LayoutTopbar } from "./LayoutTopbar";

const BREADCRUMBS_ROOT: {
  path: string;
  showName: (t: TFunction) => string;
  exact?: boolean;
  queryKeys?: (id: string | number) => QueryKey;
  apiCall?: (id: number) => Promise<any>;
  keyData?: string[];
}[] = [
  { path: "/", showName: (t) => t("menu.dashboard"), exact: true },
  {
    path: "/settings",
    showName: (t) => t("menu.settings"),
  },
  {
    path: "/settings/diagnostic-criteria",
    showName: (t) => t("common.diagnostic_criteria"),
  },
  {
    path: "/settings/brand-customization",
    showName: (t) => t("common.customization"),
  },
  {
    path: "/settings/service-management",
    showName: (t) => t("common.extra_service_management"),
  },
  {
    path: "/settings/payments",
    showName: (t) => t("fee.currency_payments_fees"),
  },
  {
    path: "/profile-settings",
    showName: (t) => t("common.profile_settings"),
  },
  {
    path: "/language-settings",
    showName: (t) => t("common.languages"),
  },

  {
    path: "/auth",
    showName: (t) => t("application.user_management"),
  },
  {
    path: "/auth/internal-users",
    showName: (t) => t("menu.internal_users"),
  },
  {
    path: "/auth/internal-users/invitation-send",
    showName: (t) => t("user.invite_internal_users"),
  },
  {
    path: "/auth/internal-users/invite",
    showName: (t) => t("user.invite_internal_users"),
  },
  {
    path: "/auth/departments",
    showName: (t) => t("menu.departments"),
  },

  {
    path: "/cms",
    showName: (t) => t("application.content_management"),
  },

  { path: "/cms/pages", showName: (t) => t("menu.pages") },
  {
    path: "/cms/pages/create",
    showName: (t) => t("template.add_item", { item: t("common.page") }),
  },
  {
    path: "/cms/pages/edit/:id",
    showName: (t) => t("template.edit_item", { item: t("common.page") }),
  },
  { path: "/cms/menu", showName: (t) => t("menu.menu") },
  {
    path: "/cms/menu/create",
    showName: (t) => t("template.add_item", { item: t("common.menu") }),
  },
  {
    path: "/cms/menu/edit/:id",
    showName: (t) => t("template.edit_item", { item: t("common.menu") }),
  },
  { path: "/cms/banners", showName: (t) => t("menu.banners") },
  { path: "/cms/faq", showName: (t) => t("menu.help_and_support") },
  { path: "/cms/i18n", showName: (t) => t("menu.translations") },
  {
    path: "/cms/i18n/:type",
    showName: (t) =>
      t("template.edit_item", {
        item: t("common.code").toLowerCase(),
      }),
  },
  {
    path: "/cms/files",
    showName: (t) => t("application.file_management"),
  },

  {
    path: "/warehouse",
    showName: (t) => t("application.warehouse_service"),
  },
  {
    path: "/warehouse/warehouses/create",
    showName: (t) =>
      t("template.add_item", {
        item: t("process_locations.process_locations").toLowerCase(),
      }),
  },
  {
    path: "/warehouse/warehouses/edit/:id",
    showName: (t) =>
      t("template.edit_item", {
        item: t("process_locations.process_locations").toLowerCase(),
      }),
  },
  {
    path: "/warehouse/drop-off-locations",
    showName: (t) => t("menu.drop_off_locations"),
  },
  {
    path: "/warehouse/drop-off-locations/create",
    showName: (t) =>
      t("template.add_item", {
        item: t("common.drop_off_location").toLowerCase(),
      }),
  },
  {
    path: "/warehouse/drop-off-locations/edit/:id",
    showName: (t) =>
      t("template.edit_item", {
        item: t("common.drop_off_location").toLowerCase(),
      }),
  },
  {
    path: "/warehouse/shipping-providers",
    showName: (t) => t("menu.shipping"),
  },

  {
    path: "/product-management",
    showName: (t) => t("menu.product_management"),
  },
  {
    path: "/product-management/brands",
    showName: (t) => t("menu.brands"),
  },
  {
    path: "/product-management/brands/:id",
    showName: (t) => t("brand.brand"),
    queryKeys: querykeys.brands.one,
    apiCall: api.brands.getById,
    keyData: ["brand_id"],
  },
  {
    path: "/product-management/brands/add-brands",
    showName: (t) =>
      t("template.add_item", { item: t("brand.brands").toLowerCase() }),
  },
  {
    path: "/product-management/models",
    showName: (t) => t("menu.models"),
  },
  {
    path: "/product-management/models/add-models",
    showName: (t) =>
      t("template.add_item", { item: t("model.models").toLowerCase() }),
  },
  {
    path: "/product-management/models/add-models/success-connected",
    showName: (t) =>
      t("template.add_item", { item: t("model.models").toLowerCase() }),
  },
  {
    path: "/product-management/models/:id",
    showName: (t) => t("model.model"),
  },
  {
    path: "/product-management/inventory",
    showName: (t) => t("menu.inventory"),
  },
  {
    path: "/product-management/inventory/:id",
    showName: (t) => t("order.order"),
    queryKeys: querykeys.inventory.one,
    apiCall: api.inventory.getByID,
    keyData: ["order", "code"],
  },

  {
    path: "/crm",
    showName: (t) => t("menu.crm"),
  },
  {
    path: "/crm/orders/buyback-orders",
    showName: (t) => t("menu.buyback_orders"),
  },
  {
    path: "/crm/orders/erasure-orders",
    showName: (t) => t("menu.erasure_orders"),
  },
  {
    path: "/crm/orders/warehouse-orders",
    showName: (t) => t("menu.pending_orders"),
  },
  {
    path: "/crm/orders/warehouse-orders/:orderId/device/:deviceId",
    showName: (t) => t("order.order"),
    queryKeys: querykeys.orders.one,
    apiCall: api.orders.getByID,
    keyData: ["device", "order", "code"],
  },
  {
    path: "/crm/payment-providers",
    showName: (t) => t("menu.payment"),
  },
  {
    path: "/crm/invoices/payouts",
    showName: (t) => t("menu.payout"),
  },
  {
    path: "/crm/invoices/payouts/:id",
    showName: (t) => t("invoice.invoice"),
    queryKeys: querykeys.payouts.one,
    apiCall: api.payouts.getByID,
    keyData: ["invoice", "code"],
  },
  {
    path: "/crm/invoices/payments",
    showName: (t) => t("menu.invoices"),
  },
  {
    path: "/crm/invoices/payments/:id",
    showName: (t) => t("invoice.invoice"),
    queryKeys: querykeys.payments.one,
    apiCall: api.payments.getByID,
    keyData: ["invoice", "code"],
  },
  {
    path: "/crm/clients",
    showName: (t) => t("menu.customers"),
  },
  {
    path: "/crm/recycling-certificates",
    showName: (t) => t("menu.recycling_certificates"),
  },
  {
    path: "/crm/recycling-certificates/:id",
    showName: (t) => t("menu.recycling_certificates"),
  },
];

interface Props {
  className?: string;
  menu: React.ReactNode;
}

export const LayoutTemplate: React.FC<Props> = ({
  className,
  menu,
  children,
}) => {
  return (
    <Layout className={className}>
      <Layout.Topbar>
        <Layout.Topbar.Title style={{ minWidth: 245 }}>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </Layout.Topbar.Title>
        <Layout.Topbar.LeftSide style={{ marginLeft: 25 }}>
          <BreadCrumbs breadcrumbs={BREADCRUMBS_ROOT} />
        </Layout.Topbar.LeftSide>
        <Layout.Topbar.RightSide>
          <LayoutTopbar />
        </Layout.Topbar.RightSide>
      </Layout.Topbar>

      <Sidebar>{menu}</Sidebar>

      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};
