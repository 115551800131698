import cn from "classnames";
import { Icon as EBSIcon } from "ebs-design";
import { useTranslation } from "react-i18next";

import {
  DebouncedInput,
  Flex,
  SortBy,
  SortByProps,
  WhiteSpace,
} from "components";
import { useQueryPagination } from "hooks";

export interface TableTitleProps {
  search?: string;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  filters?: React.ReactNode;
  sortOptions?: SortByProps["options"];
  sortOrdering?: SortByProps["value"];
  onSortChange?: SortByProps["onChange"];
  title?: React.ReactNode;
  extra?: React.ReactNode;
  className?: string;
}

export const TableTitle = ({
  search,
  setSearch,
  extra,
  filters,
  className,
  sortOptions = [],
  sortOrdering,
  onSortChange = () => {},
  title,
}: TableTitleProps) => {
  const { t } = useTranslation();
  const pagination = useQueryPagination();
  const setSearchReset = (params: string) => {
    pagination.setPage(1);
    setSearch(params);
  };
  return (
    <>
      <Flex justify="space-between" align="center" className={cn(className)}>
        <Flex align="center">
          {title && (
            <>
              {title}
              <WhiteSpace h="1rem" />
            </>
          )}
          {setSearch && (
            <>
              <DebouncedInput
                value={search}
                onChange={setSearchReset}
                prefix={<EBSIcon type="search" />}
                size="large"
                placeholder={t("search.search")}
              />
              <WhiteSpace h="1rem" />
            </>
          )}
          {sortOptions.length ? (
            <>
              <SortBy
                options={sortOptions}
                value={sortOrdering}
                onChange={onSortChange}
              />
              <WhiteSpace h="1rem" />
            </>
          ) : null}
          {filters && (
            <>
              {filters}
              <WhiteSpace h="1rem" />
            </>
          )}
        </Flex>
        {extra}
      </Flex>
    </>
  );
};
