import models from "models";
import { useQueryClient } from "react-query";
import { CheckboxesCheckedInfoType, GenericObject } from "types";

export interface UseFilterQueryCheckedOptions<T> {
  querykeys: number | string | GenericObject<any>;
  checked: CheckboxesCheckedInfoType;
  key: keyof T;
}

export const useFilterQueryChecked = <T extends object>({
  querykeys,
  checked,
  key,
}: UseFilterQueryCheckedOptions<T>) => {
  const queryClient = useQueryClient();

  return queryClient
    .getQueriesData(String(querykeys))
    .map(([, data]: [any, models.WithResults<T>]) => data?.results)
    .flat()
    .filter((res) => checked.allCheckedCheckboxes.includes(String(res?.[key])));
};
