import { Alert } from "ebs-design";
import { useTranslation } from "react-i18next";

import { extractErrorMessages } from "utils";

export interface AlertErrorsProps {
  error: any;
  alertsClassName?: string;
}

export const AlertErrors = ({
  error,
  alertsClassName = "my-2",
}: AlertErrorsProps) => {
  const { t } = useTranslation();

  if (!error) return null;
  const messages = extractErrorMessages(error);

  if (messages.length) {
    return (
      <>
        {messages.map((m, i) => (
          <Alert key={i} className={alertsClassName} type="error">
            {m}
          </Alert>
        ))}
      </>
    );
  }

  if (error.message) {
    return (
      <Alert className={alertsClassName} type="error">
        {error.message}
      </Alert>
    );
  }

  return (
    <Alert className={alertsClassName} type="error">
      {t("error.no_available_errors_message")}
    </Alert>
  );
};
