import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Grid,
  InputFormField,
  LanguageTabs,
  LoadingButton,
  WhiteSpace,
} from "components";
import { useDiagnosticURL, useMapErrors } from "hooks";
import models from "models";
import { DiagnosticType } from "types";
import { DEFAULT_LANGUAGE } from "app-constants";

interface EditServiceModalProps extends ModalProps {
  data?: models.DiagnosticCriteria;
  onClose: () => void;
  diagnosticType: DiagnosticType;
  brandId?: number;
}

export const CriteriaModal = ({
  data,
  onClose,
  diagnosticType,
  brandId,
  ...props
}: EditServiceModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const mapErrors = useMapErrors();
  const diagnosticUrl = useDiagnosticURL(diagnosticType, brandId);
  const [translationFields, setTranslationFields] = useState(data?.i18n);

  const requestFunction = (id: number, values: models.DiagnosticCriteria) =>
    data
      ? diagnosticUrl.updateCriterion({ id, body: values })
      : diagnosticUrl.createCriterion({ body: values });

  const { mutate, isLoading } = useMutation(
    (values: models.DiagnosticCriteria) => requestFunction(data?.id!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(diagnosticUrl.criterionsKey);
        notify.success({ title: t("notification.changes_saved_successfully") });
        onClose();
      },
      onError: (errors: unknown) => mapErrors(errors, notify, form),
    },
  );

  return (
    <Modal
      title={`${data ? t("common.edit") : t("common.add")} ${t(
        "criteria.criteria",
      )}`}
      size="small"
      onClose={onClose}
      {...props}
    >
      <Form
        form={form}
        name="service"
        initialValues={{
          ...data,
          price_percentage: Number(data?.price_percentage).toFixed(2),
        }}
        onValuesChange={(values) => {
          setTranslationFields((prev) => ({ ...prev, ...values.i18n }));
          form.setFieldsValue({
            price_percentage:
              values.price_percentage.length > 5
                ? values.price_percentage.slice(0, 5)
                : values.price_percentage,
          });
        }}
        onFinish={(values) =>
          mutate({
            ...data,
            ...values,
            ...(translationFields?.[DEFAULT_LANGUAGE]?.title && {
              title: translationFields?.[DEFAULT_LANGUAGE].title,
            }),
            i18n: { ...translationFields, ...values.i18n },
          })
        }
        className="service-form"
      >
        <Modal.Content>
          <LanguageTabs>
            {(language) => (
              <InputFormField
                className="mb-16"
                name={["i18n", language, "title"]}
                label={t("criteria.criteria_name")}
                required
              />
            )}
          </LanguageTabs>

          <div className="horizontal-line" />
          <Grid cols="1fr 160px" gap="8px">
            <InputFormField
              type="number"
              min={0}
              max={100}
              step={1}
              name="order"
              label={t("criteria.criteria_order")}
            />
            <InputFormField
              name="price_percentage"
              label={t("price.price")}
              type="number"
              min={0.01}
              max={100}
              step={0.01}
              required
              prefix={<span className="fw-600">%</span>}
            />
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton submit loading={isLoading} type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
