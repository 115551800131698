import { Tooltip, Space, Button } from "ebs-design";
import { useTranslation } from "react-i18next";

import { CheckboxesCheckedInfoType } from "types";
import { translateIfIsTranslatable } from "utils";
import { Checkbox } from "./Checkbox";

interface Props {
  checkboxInfo: CheckboxesCheckedInfoType;
  setCheckboxInfo: React.Dispatch<
    React.SetStateAction<CheckboxesCheckedInfoType>
  >;
  word: string;
  wrap?: boolean;
}

export function TableCheckboxSelector({
  checkboxInfo,
  setCheckboxInfo,
  word,
  wrap = true,
}: Props) {
  const { t } = useTranslation();
  const allCheckboxesCheckedOnThisPage =
    !!checkboxInfo.checkboxesOnPage?.length &&
    checkboxInfo.checkboxesOnPage.every((item) =>
      checkboxInfo.allCheckedCheckboxes.includes(item),
    );
  const someCheckboxesCheckedOnThisPage =
    checkboxInfo.checkboxesOnPage?.some((item) =>
      checkboxInfo.allCheckedCheckboxes.includes(item),
    ) && !allCheckboxesCheckedOnThisPage;

  return (
    <Tooltip
      delayHide={100}
      offset={[0, 14]}
      placement="top"
      title={
        <Space size={5} direction="vertical">
          <p>
            {`${t("template.selected_item", {
              item: translateIfIsTranslatable(t, word, "all"),
            })} - ${checkboxInfo?.allCheckedCheckboxes.length}`}
          </p>
          <Button
            onClick={() =>
              setCheckboxInfo((prev) => ({
                ...prev,
                checkboxesCheckedOnPage: [],
                allCheckedCheckboxes: [],
              }))
            }
            disabled={checkboxInfo?.allCheckedCheckboxes.length === 0}
            type="light"
            size="small"
          >
            <p>
              {`${t("template.unselect_item", {
                item: translateIfIsTranslatable(t, `all_${word}`, "all"),
              })} - ${checkboxInfo?.allCheckedCheckboxes.length}`}
            </p>
          </Button>
        </Space>
      }
    >
      <Checkbox
        wrap={wrap}
        onChange={(v) =>
          setCheckboxInfo((prev) =>
            v
              ? {
                  ...prev,
                  checkboxesCheckedOnPage: checkboxInfo.checkboxesOnPage,
                  allCheckedCheckboxes: [
                    ...prev.allCheckedCheckboxes,
                    ...checkboxInfo.checkboxesOnPage,
                  ],
                }
              : {
                  ...prev,
                  checkboxesCheckedOnPage: [],
                  allCheckedCheckboxes: prev.allCheckedCheckboxes.filter(
                    (item) => !checkboxInfo.checkboxesOnPage.includes(item),
                  ),
                },
          )
        }
        checked={allCheckboxesCheckedOnThisPage}
        indeterminate={someCheckboxesCheckedOnThisPage}
      />
    </Tooltip>
  );
}
