import models from "models";
import { GenericObject } from "types";
import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const orders = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/orders", queryParams, {
      arrayFormat: "comma",
    });
    const { data } = await apiAxios.get<models.WithResults<models.Order>>(url);
    return data;
  },
  getByID: async (id: number) => {
    const { data } = await apiAxios.get<models.Order>(`/orders/${id}`);
    return data;
  },
  post: async (body: models.Order) => {
    const { data } = await apiAxios.post<models.Order>("/orders", body);
    return data;
  },
  patch: async (id: number, body: models.Order) => {
    const { data } = await apiAxios.patch<models.Order>(`/orders/${id}`, body);
    return data;
  },
  patchStatusBulk: async (orders_id: number[], status: models.OrderStatus) => {
    const { data } = await apiAxios.patch<models.Order[]>(
      "/orders/status/bulk",
      { orders_id, status },
    );
    return data;
  },

  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/orders/${id}`);
    return data;
  },
  changeStatus: async (id: number, body: models.OrderStatusEntity) => {
    const { data } = await apiAxios.patch<models.OrderStatusEntity>(
      `/order/${id}/status`,
      body,
    );
    return data;
  },
};
