import { AxiosError } from "axios";
import { useNotify } from "ebs-design";
import React from "react";

import { getDownloadFile } from "api/documents";
import models from "models";
import { downloadFile } from "utils";

export const useDownloadFile = () => {
  const notify = useNotify();

  return React.useCallback(
    async (attachment: models.Attachment) => {
      if (attachment && attachment.url) {
        getDownloadFile(attachment.url)
          .then((fileBlob: any) => {
            downloadFile(
              fileBlob,
              attachment.filename,
              `application/${attachment.extension}`,
            );
          })
          .catch((error: AxiosError) => {
            notify.error({ title: error?.message });
          });
      }
    },
    [notify],
  );
};
