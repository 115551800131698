import models from "models";
import { GenericObject, UpdateCriterion } from "types";
import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const settings = {
  getOrganizationData: async () => {
    const { data } = await apiAxios.get<models.OrganizationSettings>(
      "/organization",
    );
    return data;
  },
  updateOrganizationData: async (body: models.OrganizationSettings) => {
    const { data } = await apiAxios.patch<models.OrganizationSettings>(
      "/organization",
      body,
    );
    return data;
  },
  defaultSettings: async () => {
    const { data } = await apiAxios.get<models.DefaultSettings>(
      "/organization/settings/default",
    );
    return data;
  },
  updateDefaultSettings: async <T = models.DefaultSettings>(body: T) => {
    const { data } = await apiAxios.patch<models.DefaultSettings>(
      "/organization/settings/default",
      body,
    );
    return data;
  },

  services: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/organization/services", queryParams);
    const { data } = await apiAxios.get<
      models.WithResults<models.ExtraService>
    >(url);
    return data;
  },
  fees: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/organization/fees", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Fees>>(url);
    return data;
  },
  updateFees: async (id: number, body: models.Fees) => {
    const { data } = await apiAxios.patch<models.Fees>(
      `/organization/fees/${id}`,
      body,
    );
    return data;
  },

  updateService: async (id: number, body: models.ExtraService) => {
    const { data } = await apiAxios.patch<models.ExtraService>(
      `/organization/services/${id}`,
      body,
    );
    return data;
  },
  getDefaultCriteria: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/organization/diagnostic-criteria", queryParams);
    const { data } = await apiAxios.get<
      models.WithResults<models.DiagnosticCriteria>
    >(url);
    return data;
  },
  updateDefaultSwitch: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl(
      "/organization/diagnostic-criteria/default",
      queryParams,
    );
    const { data } = await apiAxios.get<
      models.WithResults<models.DiagnosticCriteria>
    >(url);
    return data;
  },
  updateCriteria: async (values: UpdateCriterion) => {
    const url = `/organization/diagnostic-criteria/${values?.id}`;
    const { data } = await apiAxios.patch<models.DiagnosticCriteria>(
      url,
      values?.body,
    );
    return data;
  },
  reorderCriteria: async (
    payload: models.DiagnosticCriteriaReorderPayload[],
  ) => {
    const { data } = await apiAxios.patch(
      `/organization/diagnostic-criteria/ordering`,
      payload,
    );
    return data;
  },
  createCriteria: async (values: UpdateCriterion) => {
    const url = "/organization/diagnostic-criteria";
    const { data } = await apiAxios.post<models.DiagnosticCriteria>(
      url,
      values.body,
    );
    return data;
  },
  deleteCriteria: async (id: number) => {
    const url = `/organization/diagnostic-criteria/${id}`;
    await apiAxios.delete(url);
  },
  resetCriteria: async () => {
    await apiAxios.post("/organization/diagnostic-criteria/reset");
  },
  bankingDetails: {
    get: async (queryParams: GenericObject = {}) => {
      const url = stringifyUrl("/organization/banking-details", queryParams);
      const { data } = await apiAxios.get<
        models.WithResults<models.BankDetails>
      >(url);
      return data;
    },
    updateById: async (id: number, body: models.BankDetails) => {
      const { data } = await apiAxios.patch<
        models.WithResults<models.BankDetails>
      >(`/organization/banking-details/${id}`, body);
      return data;
    },
    deleteById: async (id: number) => {
      await apiAxios.delete<models.BankDetails>(
        `/organization/banking-details/${id}`,
      );
    },
    create: async (body: models.BankDetails) => {
      const { data } = await apiAxios.post<models.BankDetails>(
        "/organization/banking-details",
        { banking_details: [body] },
      );
      return data;
    },
  },
};
