import models from "models";
import { GenericObject } from "types";
import { stringifyUrl } from "./utils";
import { apiAxios } from "./axios";

export const notifications = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/organization/user/notifications", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Notification>>(
      url
    );
    return data;
  },
  post: async (body: models.Metadata) => {
    const { data } = await apiAxios.post<models.Metadata>(
      "/organization/user/notifications",
      body
      );
    return data;
  },
  patch: async (id: number, body: models.Notification) => {
    const { data } = await apiAxios.patch<models.Notification>(
      `/organization/user/notifications/${id}`,
      body
      );
    return data;
  },
  delete: async (id: number) => {
    const { data } = await apiAxios.delete<models.Notification>(
      `/organization/user/notifications/${id}`
    );
    return data;
  },
};
