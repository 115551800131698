import { Button } from "ebs-design";
import { TFunction } from "react-i18next";

import { Flex, ScheduleViewer, Status } from "components";
import * as Icons from "components/icons";
import models from "models";
import { TableColumnBuilder } from "utils";

interface DropOffLocationsColumnsProps {
  deleteDropOffLocationModal: React.Dispatch<number | string | undefined>;
  t: TFunction;
}

export const dropOffLocationsColumns = ({
  deleteDropOffLocationModal,
  t,
}: DropOffLocationsColumnsProps) =>
  new TableColumnBuilder<models.DropOffLocation>()
    .add(t("common.id"), "id")
    .add(t("common.location_name"), "title")
    .add(t("common.working_hours"), "working_hours", (workingHours) => (
      <ScheduleViewer workingHours={workingHours} />
    ))
    .add(t("common.address"), "address")
    .add(t("common.phone_number"), "phone")
    .add(t("common.status"), "is_active", (status) => (
      <Status
        status={status}
        value={status ? models.StatusValue.ACTIVE : models.StatusValue.INACTIVE}
      />
    ))
    .add("", "", (row: models.DropOffLocation) => (
      <Flex justify="flex-end" onClick={(e) => e.stopPropagation()}>
        <Button
          onClick={() => deleteDropOffLocationModal(row?.id)}
          type="light"
          size="small"
          icon={() => <Icons.Trash size="small" />}
        />
      </Flex>
    ))
    .getColumns();

export const dropOfLocationSortOptions = [
  { title: "Provider name", value: "title" },
];
