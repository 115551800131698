import { Button, Card, useNotify } from "ebs-design";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "react-query";

import { Flex, LoadingButton, PageTitle, Steps, WhiteSpace } from "components";
import { notifyErrors } from "utils";
import api from "api";
import { RoleType } from "models/user";
import { InviteContext } from "../context";

import { peopleTabs } from "../config";

const redirectUrl = `${window.location.origin}/complete-invitation`;

export const InviteStaff = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const history = useHistory();

  const [activeStep, setActiveStep] = React.useState(0);
  const [userRoleType, setUserRoleType] = React.useState<RoleType | null>(null);
  const [invite, setInvite] = React.useState({
    emails: [],
    emailsRoles: [],
  });

  const { mutate, isLoading } = useMutation(api.invites.post, {
    onSuccess: () => {
      notify.success({
        title: t("template.item_sent_successfully", {
          item: t("common.invitation"),
        }),
      });
      history.push("/auth/internal-users/invitation-send");
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const user = invite.emailsRoles[0];
  const isMutateDisabled = useMemo(
    () =>
      userRoleType === null ||
      (userRoleType === RoleType.WAREHOUSE
        ? !(user?.department_id && user?.warehouse_id)
        : !!!user?.applications?.length),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  return (
    <InviteContext.Provider
      value={{ invite, setInvite, userRoleType, setUserRoleType }}
    >
      <PageTitle>{t("user.invite_internal_users")}</PageTitle>
      <div className="invite-staff">
        <Card>
          <Card.Body>
            <Steps
              stepsItems={peopleTabs}
              activeStep={activeStep}
              onChange={setActiveStep}
              disableSecondElement={!invite.emails.length}
            />
          </Card.Body>
        </Card>
        <Flex justify="end" align="center" className="mt-24">
          <Link to="/auth/internal-users">
            <Button type="text">{t("common.cancel")}</Button>
          </Link>
          <WhiteSpace h="8px" />
          {activeStep === 0 && (
            <Button
              type="primary"
              disabled={!invite.emails.length}
              onClick={() => setActiveStep(1)}
            >
              {t("common.next")}
            </Button>
          )}
          {activeStep === 1 && (
            <>
              <Button type="ghost" onClick={() => setActiveStep(0)}>
                {t("common.back")}
              </Button>
              <WhiteSpace h="8px" />
              <LoadingButton
                type="primary"
                disabled={isMutateDisabled}
                loading={isLoading}
                onClick={() =>
                  mutate({
                    emails: invite.emails,
                    emails_roles: invite.emailsRoles,
                    redirect: redirectUrl,
                  })
                }
              >
                {t("common.send_invitation")}
              </LoadingButton>
            </>
          )}
        </Flex>
      </div>
    </InviteContext.Provider>
  );
};
