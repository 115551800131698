interface Props {
  name?: string;
  email?: string;
}
export const UserInfo = ({ name, email }: Props) => {
  return (
    <>
      <div title={name} className="one-line max-w-200">
        {name}
      </div>
      <a
        className="one-line max-w-200"
        href={`mailto:${email}`}
        onClick={(e) => e.stopPropagation()}
      >
        {email}
      </a>
    </>
  );
};
