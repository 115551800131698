import React, { useState } from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useHistory } from "react-router-dom";

import api, { querykeys } from "api";
import { clearLocalStorage } from "api/auth";
import { apiAxios, cmsAxios } from "api/axios";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "app-constants";
import models from "models";
import { useRefreshInterceptor } from "./useRefreshInterceptor";

export interface UseAuthProviderArgs {
  getUser: () => Promise<models.User>;
  userQueryOptions?: UseQueryOptions<models.User>;
  logout: () => Promise<void>;
}

export function useAuthProvider(apiCalls: UseAuthProviderArgs) {
  const history = useHistory();
  const [userEmail, setUserEmail] = useState<models.User["email"]>();
  const [user, setUser] = React.useState<models.User | null>(null);
  const [organization, setOrganization] =
    React.useState<models.OrganizationSettings | null>(null);
  const queryClient = useQueryClient();

  const { refetch, isLoading, error } = useQuery(
    querykeys.profile,
    apiCalls.getUser,
    {
      retry: false,
      onSuccess: (user) => setUser(user),
      onError: () => setUser(null),
      enabled:
        !!localStorage.getItem(ACCESS_TOKEN_KEY) ||
        !!localStorage.getItem(REFRESH_TOKEN_KEY),
    },
  );

  const login = async (credentials: models.LoginCredentials) => {
    const data = await api.authApi.login(credentials);
    setUserEmail(data.email);
  };
  const resendCode = async (credentials: models.CodeConfirmation) => {
    await api.authApi.resendCode(credentials);
  };

  const confirmLogin = async (credentials: models.LoginCredentials) => {
    await api.authApi.confirmLogin(credentials);
    await refetch();
    history.push("/");
  };

  const confirmResetPassword = async (
    credentials: models.ConfirmResetPassword,
  ) => {
    await api.authApi.confirmResetPassword(credentials);
    await refetch();
    history.push("/");
  };

  const logout = async () => {
    try {
      await apiCalls.logout();
    } finally {
      setUser(null);
      queryClient.clear();
      clearLocalStorage();
    }
  };

  const onRefreshFail = () => {
    setUser(null);
    queryClient.clear();
  };

  useRefreshInterceptor(apiAxios, onRefreshFail);
  useRefreshInterceptor(cmsAxios, onRefreshFail);

  useQuery(
    querykeys.settings.organization,
    () => api.settings.getOrganizationData(),
    {
      retry: false,
      onSuccess: (organization) => setOrganization(organization),
      onError: () => setOrganization(null),
    },
  );

  return {
    user,
    organization,
    userEmail,
    roles: user?.profile?.roles,
    isLoading,
    login,
    logout,
    confirmLogin,
    confirmResetPassword,
    resendCode,
    error,
  };
}
