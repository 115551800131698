import models from "models";

import { apiAxios } from "./axios";

export const order = {
  cancel: async (id: number) => {
    const { data } = await apiAxios.get<models.Order>(`/order/${id}/cancel`);
    return data;
  },
  status: async (id: number, body: models.Order) => {
    const { data } = await apiAxios.patch<models.Order>(
      `/order/${id}/status`,
      body,
    );
    return data;
  },
};
