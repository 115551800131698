import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import { CITY_LIST_LIMIT, COUNTRY_LIST_LIMIT } from "app-constants";
import {
  Flex,
  Grid,
  InputFormField,
  LoadingButton,
  SelectOptions,
} from "components";
import models from "models";
import { notifyErrors } from "utils";

interface TaxDetailsFormProps extends ModalProps {
  data?: models.OrganizationSettings;
  onClose: () => void;
}

export const TaxDetailsModal = ({
  data,
  onClose,
  ...props
}: TaxDetailsFormProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { data: countriesOptions } = useQuery(
    querykeys.nomenclature.countries(),
    () => api.nomenclatures.countries.get({ limit: COUNTRY_LIST_LIMIT }),
    {
      select: (data) =>
        data.results?.map((country) => ({
          value: country.id,
          name: country.name,
        })),
    },
  );
  const [countryId, setCountryId] = useState<number>(data?.country?.id);
  const { data: citiesOptions } = useQuery(
    [querykeys.nomenclature.cities(), countryId],
    () =>
      api.nomenclatures.cities.get({
        limit: CITY_LIST_LIMIT,
        country_id: countryId,
      }),
    {
      enabled: countryId > 0,
      select: (data) =>
        data.results?.map((country) => ({
          value: country.id,
          name: country.name,
        })),
    },
  );

  const [cityId, setCityId] = useState<number>(data?.city?.id);

  const { mutate, isLoading: isMutationLoading } = useMutation(
    (body: models.OrganizationSettings) =>
      api.settings.updateOrganizationData(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.settings.organization);
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("finance.tax_details"),
          }),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Modal title={t("finance.tax_details")} onClose={onClose} {...props}>
      <Form
        form={form}
        onFinish={(v) =>
          mutate({
            ...v,
            country_id: countryId,
            city_id: cityId,
          })
        }
        className="service-form"
        initialValues={data}
      >
        <Modal.Content>
          <Grid
            gap="1rem"
            style={{
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: "0 1rem",
            }}
          >
            <SelectOptions
              showSearch
              name={["country", "name"]}
              label={t("common.country")}
              selectOption={countriesOptions || []}
              value={countryId}
              onChange={(v) => {
                setCountryId(
                  +countriesOptions.find((item) => item.name === v)?.value,
                );
                setCityId(null);
              }}
            />
            <SelectOptions
              showSearch
              name={["city", "name"]}
              label={t("common.city")}
              selectOption={citiesOptions || []}
              value={cityId}
              onChange={(v) => {
                setCityId(citiesOptions.find((item) => item.name === v)?.value);
              }}
            />

            <InputFormField
              name="registration_number"
              label={t("finance.registration_number")}
              placeholder={t("finance.registration_number")}
            />
            <InputFormField
              name="vat_number"
              label={t("finance.vat_number")}
              placeholder={t("finance.vat_number")}
            />
            <InputFormField
              name="tax_number"
              label={t("finance.tax_number")}
              placeholder={t("finance.tax_number")}
            />
          </Grid>
        </Modal.Content>

        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button className="mr-8" type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <LoadingButton loading={isMutationLoading} submit type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
