import models from "models";
import { GenericObject, UpdateCriterion } from "types";
import { stringifyUrl } from "./utils";
import { apiAxios } from "./axios";

export const brandsModels = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/organization/brand-models", queryParams, {
      arrayFormat: "comma",
    });
    const { data } = await apiAxios.get<
      models.WithResults<models.ExtendedOrganizationBrandModel>
    >(url);
    return data;
  },
  getById: async (id: number) => {
    const { data } = await apiAxios.get<models.ExtendedOrganizationBrandModel>(
      `/organization/brand-models/${id}`,
    );
    return data;
  },
  export: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/organization/brand-models/export", queryParams, {
      arrayFormat: "comma",
    });

    const { data } = await apiAxios.get<models.Attachment>(url);
    return data;
  },
  import: async (file: models.Attachment) => {
    const { data } = await apiAxios.post<models.Attachment>(
      "/organization/brand-models/import",
      file,
    );
    return data;
  },
  upgrade: async (id: number, body: models.ExtendedOrganizationBrandModel) => {
    const { data } =
      await apiAxios.patch<models.ExtendedOrganizationBrandModel>(
        `/brand-model/${id}/upgrade`,
        body,
      );
    return data;
  },
  updateModels: async (body: models.BrandModelsUpgrade) => {
    const { data } = await apiAxios.post(
      `/organization/brand-models/upgrade`,
      body,
    );
    return data;
  },
  deleteByID: async (id: number) => {
    const { data } = await apiAxios.delete(`/brand-model/${id}/disconnect`);
    return data;
  },
  connectModels: async (body: models.BrandModelsConnect) => {
    const { data } = await apiAxios.post("/brand-models/connect", body);
    return data;
  },
  getDefaultCriteria: async (
    brandId: number,
    queryParams: GenericObject = {},
  ) => {
    const url = stringifyUrl(
      `/organization/brand-model/${brandId}/diagnostic-criteria`,
      queryParams,
    );
    const { data } = await apiAxios.get<
      models.WithResults<models.DiagnosticCriteria>
    >(url);
    return data;
  },

  updateDefaultSwitch: async (
    brandId: number,
    queryParams: GenericObject = {},
  ) => {
    const url = stringifyUrl(
      `/organization/brand-model/${brandId}/diagnostic-criteria/default`,
      queryParams,
    );
    const { data } = await apiAxios.get<
      models.WithResults<models.DiagnosticCriteria>
    >(url);
    return data;
  },
  updateCriteria: async (brandId: number, values: UpdateCriterion) => {
    const url = `/organization/brand-model/${brandId}/diagnostic-criteria/${values?.id}`;
    const { data } = await apiAxios.patch<models.DiagnosticCriteria>(
      url,
      values?.body,
    );
    return data;
  },
  createCriteria: async (brandId: number, values: UpdateCriterion) => {
    const url = `/organization/brand-model/${brandId}/diagnostic-criteria`;
    const { data } = await apiAxios.post<models.DiagnosticCriteria>(
      url,
      values?.body,
    );
    return data;
  },
  deleteCriteria: async (id: number, brandId: number) => {
    const url = `/organization/brand-model/${brandId}/diagnostic-criteria/${id}`;
    await apiAxios.delete(url);
  },
  resetCriteria: async (brandId: number) => {
    const url = `/organization/brand-model/${brandId}/diagnostic-criteria/reset`;
    await apiAxios.post(url);
  },
  bulkDelete: async (body: models.BrandModelsDisconnect) => {
    const { data } = await apiAxios.delete(`/brand-models/disconnect`, {
      data: body,
    });
    return data;
  },
};
