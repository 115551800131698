import { createSvgIcon } from "../utils";

export const FileDownload = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="40"
    viewBox="0 0 30 40"
    fill="none"
  >
    <path
      d="M0.5 36V4C0.5 2.067 2.067 0.5 4 0.5H17.9C18.7987 0.5 19.663 0.845712 20.3138 1.46552L28.4138 9.1798C29.1074 9.8404 29.5 10.7564 29.5 11.7143V36C29.5 37.933 27.933 39.5 26 39.5H4C2.067 39.5 0.5 37.933 0.5 36Z"
      fill="#F6F8F9"
      stroke="#E5E9EB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 27C8 26.7348 8.10536 26.4804 8.29289 26.2929C8.48043 26.1054 8.73478 26 9 26H21C21.2652 26 21.5196 26.1054 21.7071 26.2929C21.8946 26.4804 22 26.7348 22 27C22 27.2652 21.8946 27.5196 21.7071 27.7071C21.5196 27.8946 21.2652 28 21 28H9C8.73478 28 8.48043 27.8946 8.29289 27.7071C8.10536 27.5196 8 27.2652 8 27ZM11.293 19.293C11.4805 19.1055 11.7348 19.0002 12 19.0002C12.2652 19.0002 12.5195 19.1055 12.707 19.293L14 20.586V13C14 12.7348 14.1054 12.4804 14.2929 12.2929C14.4804 12.1054 14.7348 12 15 12C15.2652 12 15.5196 12.1054 15.7071 12.2929C15.8946 12.4804 16 12.7348 16 13V20.586L17.293 19.293C17.3852 19.1975 17.4956 19.1213 17.6176 19.0689C17.7396 19.0165 17.8708 18.9889 18.0036 18.9877C18.1364 18.9866 18.2681 19.0119 18.391 19.0622C18.5139 19.1125 18.6255 19.1867 18.7194 19.2806C18.8133 19.3745 18.8875 19.4861 18.9378 19.609C18.9881 19.7319 19.0134 19.8636 19.0123 19.9964C19.0111 20.1292 18.9835 20.2604 18.9311 20.3824C18.8787 20.5044 18.8025 20.6148 18.707 20.707L15.707 23.707C15.5195 23.8945 15.2652 23.9998 15 23.9998C14.7348 23.9998 14.4805 23.8945 14.293 23.707L11.293 20.707C11.1055 20.5195 11.0002 20.2652 11.0002 20C11.0002 19.7348 11.1055 19.4805 11.293 19.293Z"
      fill="#5B6871"
    />
  </svg>,
);
