import { useState } from "react";
import { Bar, TooltipProps } from "recharts";
import { useTranslation } from "react-i18next";

import { BarChart, CustomTooltip } from "components";
import models from "models";

interface CustomersChartProps {
  data: models.CustomerStatistics[];
}

interface CustomTooltipComponentProps extends TooltipProps<number, string> {
  descriptionSuffix: string;
  open: boolean;
  monthTickFormatter?: (tick: string) => string;
}

const CustomTooltipComponent = ({
  active,
  descriptionSuffix,
  payload,
  open,
  monthTickFormatter,
}: CustomTooltipComponentProps) => {
  if (active && payload?.length && open) {
    return (
      <CustomTooltip
        value={`${payload[0].value} ${descriptionSuffix}`}
        name={monthTickFormatter?.(payload[0].payload?.date)}
      />
    );
  }
  return null;
};

export const CustomersChart = ({ data }: CustomersChartProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <BarChart
      data={data}
      tooltip={(props, monthTickFormatter) => {
        return (
          <CustomTooltipComponent
            descriptionSuffix={t("menu.customers").toLowerCase()}
            open={open}
            monthTickFormatter={monthTickFormatter}
            {...props}
          />
        );
      }}
      xAxisKey="date"
      yAxisKey="total"
    >
      <Bar
        name={t("menu.customers")}
        dataKey="total"
        fill="#4B8FED"
        onPointerEnter={() => setOpen(true)}
        onPointerLeave={() => setOpen(false)}
      />
    </BarChart>
  );
};
