import React from "react";
import { Form, Select } from "antd";
import { Properties } from "types";

import { formItemStyle } from "../helpers";

const Row = ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "size"]}
        label="Size"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="auto">auto</Select.Option>
          <Select.Option value="1">1</Select.Option>
          <Select.Option value="2">2</Select.Option>
          <Select.Option value="3">3</Select.Option>
          <Select.Option value="4">4</Select.Option>
          <Select.Option value="5">5</Select.Option>
          <Select.Option value="6">6</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "g"]}
        label="Gutter"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="0">0</Select.Option>
          <Select.Option value="1">1</Select.Option>
          <Select.Option value="2">2</Select.Option>
          <Select.Option value="3">3</Select.Option>
          <Select.Option value="4">4</Select.Option>
          <Select.Option value="5">5</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "gx"]}
        label="Gutter X"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="0">0</Select.Option>
          <Select.Option value="1">1</Select.Option>
          <Select.Option value="2">2</Select.Option>
          <Select.Option value="3">3</Select.Option>
          <Select.Option value="4">4</Select.Option>
          <Select.Option value="5">5</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "gy"]}
        label="Gutter Y"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="0">0</Select.Option>
          <Select.Option value="1">1</Select.Option>
          <Select.Option value="2">2</Select.Option>
          <Select.Option value="3">3</Select.Option>
          <Select.Option value="4">4</Select.Option>
          <Select.Option value="5">5</Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};

export default Row;
