import React, { useState } from "react";
import { DropTarget } from "react-dnd";
import { useDebounce } from "react-use";
import { Tree, Spin, Empty } from "antd";
import {
  DeleteOutlined,
  FolderOpenOutlined,
  FolderOutlined,
} from "@ant-design/icons";
import { Languages } from "apps/cms/lib/utils";
import { uid } from "utils";
import { Properties } from "types";
import EmptyData from "assets/icons/empty.svg";

import { titles } from "..";
import { Fields } from "../Drag/Fields";
import { loop, normalizeTreeContent, loopObjects } from "../utils";
import Item from "./Item";

const TreeNode = Tree.TreeNode;

const setItemKeys = (Item) => {
  Item = Object.assign({}, Item, { id: `${Item.type}-${uid()}` });

  if (!Item.children || Item.children.length === 0) {
    return Item;
  }

  const children = Item.children.map((value) => {
    return setItemKeys(value);
  });

  const ret = Object.assign({}, Item, { children });

  return ret;
};

export interface DropType {
  lang: Languages;
  initialValue: Properties;
  state: Properties;
  loading: boolean;
  isDrag?: boolean;
  handler: (values: Properties) => void;
  onDrop: () => void;
  onSubmit: (values: Properties) => void;
  connectDropTarget: any;
}

const Drop: React.FC<DropType> = (props) => {
  const [state, setState] = useState(false);
  const [isOver, setIsOver] = useState(false);

  useDebounce(
    () => {
      setIsOver(state);
    },
    50,
    [state],
  );

  const onOver = (value) => {
    setState(value);
  };

  const onDrop = (info) => {
    let dragObj;
    const { treeContent } = props.state;

    const { node: dropNode, dragNode, dropPosition, dropToGap } = info;
    const dropKey = dropNode?.key;
    const dragKey = dragNode?.key;

    const dropPos = dropNode.pos.split("-");
    const dropIndex = dropPosition - Number(dropPos[dropPos.length - 1]);

    if ((dropNode?.canNesting && !dropToGap) || dropToGap) {
      loop(treeContent, dragKey, (item, i, arr) => {
        arr.splice(i, 1);
        dragObj = item;
      });
    }

    loop(treeContent, dropKey, (item, i, arr) => {
      if (!dropToGap && item?.canNesting) {
        item.children = item.children || [];

        item.children.splice(dropIndex, 0, dragObj);
      } else if (dropToGap) {
        if (dropIndex === 1) {
          arr.splice(i + 1, 0, dragObj);
        } else {
          arr.splice(dropIndex === -1 ? i : i + 1, 0, dragObj);
        }
      }

      return item;
    });

    if (dragNode) {
      props.onSubmit(normalizeTreeContent(treeContent, props.initialValue));
      props.handler({
        treeContent,
      });
    } else {
      addItem({
        type: props.state.dragType,
        title: titles[props.state.dragType],
      });
    }

    props.onDrop();
  };

  const onCreate = (index) => {
    const { treeContent } = props.state;

    if (isOver && props.state.dragType) {
      loop(treeContent, isOver, (item, i, arr) => {
        if (item.canNesting) {
          item.children = item.children || [];

          item.children.splice(
            index,
            0,
            setItemKeys({
              type: props.state.dragType,
              canNesting: [
                "componentContainer",
                "section",
                "componentSpace",
                "componentCard",
                "componentCollapse",
              ].includes(props.state.dragType),
            }),
          );
        } else {
          arr.splice(
            i + 1,
            0,
            setItemKeys({
              type: props.state.dragType,
              canNesting: [
                "componentContainer",
                "section",
                "componentSpace",
                "componentCard",
                "componentCollapse",
              ].includes(props.state.dragType),
            }),
          );
        }

        return item;
      });

      props.onSubmit(normalizeTreeContent(treeContent, props.initialValue));
      props.handler({
        dragType: undefined,
        isDrag: false,
        treeContent,
      });
      onOver(false);
    }
  };

  const onNodeSelect = (selectedKeys, e) => {
    if (
      e.nativeEvent.path
        .slice(0, 2)
        .some(({ classList }) =>
          Array.from(classList).includes("drop-tree__node-edit"),
        )
    ) {
      props.handler({
        selectedKey: props.state.selectedKey.some((i) =>
          selectedKeys.includes(i),
        )
          ? props.state.selectedKey.filter((i) => !selectedKeys.includes(i))
          : [...props.state.selectedKey, ...selectedKeys],
      });
    }
  };

  const onExpand = (expandedKeys) => {
    props.handler({
      expandedKeys: expandedKeys,
    });
  };

  const deleteItem = (id) => {
    const { treeContent } = props.state;

    loop(treeContent, id, (_, index, arr) => {
      arr.splice(index, 1);
    });

    props.handler({
      treeContent,
      selectedKey: props.state.selectedKey.filter((key) => key !== id),
    });

    if (props.initialValue) {
      props.onSubmit(normalizeTreeContent(treeContent, props.initialValue));
    }
  };

  const getTreeNode = (treeContent) => {
    if (!treeContent || treeContent.length === 0) {
      return null;
    }

    const treeNode = treeContent.map((value, i) => {
      let item;

      if (value) {
        loopObjects(
          props.initialValue,
          value.id,
          (params) => (item = { ...value, ...params }),
          props.lang,
        );
      }

      if (!item) {
        item = value;
      }

      return (
        <TreeNode
          key={item.id}
          switcherIcon={
            value.children ? (
              !props.state.expandedKeys.includes(value.id) ? (
                <FolderOutlined />
              ) : (
                <FolderOpenOutlined />
              )
            ) : undefined
          }
          className={`drop-tree__node ant-col ant-col-${
            (item?.col && item.col * 2) || 12 * 2
          }${value?.children?.length ? " has-children" : ""}${
            props.state.dragType && props.isDrag && isOver === value.id
              ? " is-over"
              : ""
          }`}
          title={
            <>
              <div
                className="drop-tree__node-edit"
                onDragOver={() => props.isDrag && onOver(value.id)}
                onDragLeave={() => props.isDrag && onOver(false)}
                onDrop={() => onCreate(i)}
              >
                <span>{Fields[item.type] || titles[item.type]}</span>
                {props.state.selectedKey.includes(item?.id) && (
                  <Item
                    lang={props.lang}
                    initialValue={props.initialValue}
                    item={item}
                    state={props.state}
                    handler={props.handler}
                    onSaved={props.onSubmit}
                  />
                )}
              </div>

              {(typeof value.canRemove === "undefined" || value.canRemove) && (
                <DeleteOutlined onClick={() => deleteItem(item.id)} />
              )}
            </>
          }
          {...(value?.canNesting && { canNesting: value.canNesting })}
        >
          {getTreeNode(value.children)}
        </TreeNode>
      );
    });

    return treeNode;
  };

  const addItem = (Item) => {
    const { treeContent } = props.state;

    Item = setItemKeys({
      ...Item,
      ...(["componentCard", "componentCollapse"].includes(Item.type) && {
        children: [
          {
            type: "componentText",
            title: Fields.componentText,
          },
        ],
      }),
    });

    treeContent.push(Item);

    props.handler({
      dragType: undefined,
      treeContent,
      selectedKey: [...props.state.selectedKey, Item.id],
    });

    props.onSubmit(normalizeTreeContent(treeContent, props.initialValue));

    props.onDrop();
  };

  return props.connectDropTarget(
    <div className="drop-tree drop-tree--hide-switcher">
      {props.state.loading ? (
        <Spin className="full-width" />
      ) : props.state.treeContent.length ? (
        <Tree
          showLine
          blockNode
          draggable
          expandedKeys={props.state.expandedKeys}
          onSelect={onNodeSelect}
          onExpand={onExpand}
          onDrop={onDrop}
        >
          {getTreeNode(props.state.treeContent)}
        </Tree>
      ) : (
        <Empty
          image={EmptyData}
          description={<span>Drag & Drop elements here</span>}
        />
      )}
    </div>,
  );
};

const spec = {
  drop(props, monitor) {
    const addItem = (Item) => {
      const { treeContent } = props.state;

      Item = setItemKeys({
        ...Item,
        ...(["componentCard", "componentCollapse"].includes(Item.type) && {
          children: [
            {
              type: "componentText",
              title: Fields.componentText,
            },
          ],
        }),
      });

      treeContent.push(Item);

      props.handler({
        dragType: undefined,
        treeContent,
        selectedKey: [...props.state.selectedKey, Item.id],
      });

      props.onSubmit(normalizeTreeContent(treeContent, props.initialValue));

      props.onDrop();
    };
    addItem(monitor.getItem());
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

export default DropTarget("components", spec, collect)(Drop);
