import React, { useCallback, useEffect, useMemo } from "react";
import { Form, Row, Col } from "antd";
import { Languages } from "apps/cms/lib/utils";
import { Properties } from "types";

import { GenericFields } from "../Fields";
import { formItemLayout } from "../helpers";
import { loopChildren } from "../utils";

interface ItemFormProps {
  treeContent: Properties[];

  item: Properties;
  lang: Languages;
  showIcon?: boolean;

  onSaved: (values: Properties) => void;
}

const ItemForm: React.FC<ItemFormProps> = ({
  showIcon,
  item,
  treeContent,
  onSaved,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ ...item.data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const onChange = useCallback(
    (values: Properties): void => {
      let data = [...treeContent];

      data = loopChildren(data, item.id, (item) => ({
        ...item,
        data: { ...item.data, ...values },
      }));

      onSaved(data);
    },
    [item.id, treeContent, onSaved],
  );

  const props = useMemo(
    () => ({
      showIcon,
      data: item.data
    }),
    [showIcon, item.data],
  );

  return (
    <div>
      <Form
        form={form}
        {...formItemLayout}
        colon={false}
        onValuesChange={onChange}
      >
        <GenericFields {...props} />

        <Row gutter={[16, 16]} justify="space-between">
          <Col span={24}>
            {(() => {
              switch (item?.type) {
                default:
                  return null;
              }
            })()}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ItemForm;
