import { useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useNotify } from "ebs-design";

import api, { querykeys } from "api";
import { ButtonGroup, ButtonGroupProps } from "components";
import { notifyErrors } from "utils";
import { CardWithFilterWrapper, OrderTotalChart, OrderTotalTable } from ".";

type ComponetTypes = "TABLE" | "CHART";

export const OrderTotalsByStatus = () => {
  const { t } = useTranslation();
  const notify = useNotify();

  const list: ButtonGroupProps<ComponetTypes>["list"] = [
    { label: t("common.table"), value: "TABLE" },
    { label: t("common.chart"), value: "CHART" },
  ];
  const [component, setComponent] = useState<ComponetTypes>("TABLE");

  const defaultPeriodParam = {
    from_date: null,
    to_date: null,
  };

  const [periodParams, setPeriodParams] = useState<typeof defaultPeriodParam>({
    ...defaultPeriodParam,
  });

  const queryParams = {
    ...periodParams,
  };

  const queryOrders = useQuery(
    querykeys.nomenclature.statistics.orderTotalByStatus(queryParams),
    () => api.statistics.getOrderTotalByStatus(queryParams),
    {
      onError: (error: Error) => notifyErrors(notify, error),
    },
  );

  return (
    <CardWithFilterWrapper
      title={t("order.orders_per_status")}
      action={setPeriodParams}
      extra={
        <ButtonGroup
          initValue={component}
          list={list}
          onChange={setComponent}
        />
      }
      query={queryOrders}
    >
      {component === "TABLE" ? (
        <OrderTotalTable
          data={queryOrders.data?.statuses}
          totalData={queryOrders.data?.total}
          trendDirection={queryOrders.data?.percentage}
        />
      ) : (
        <OrderTotalChart
          data={queryOrders.data?.statuses}
          valueLabel={queryOrders.data?.total}
          trendDirection={queryOrders.data?.percentage}
        />
      )}
    </CardWithFilterWrapper>
  );
};
