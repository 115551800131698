import { Control, LatLngLiteral } from "leaflet";
import { GeoSearchControl } from "leaflet-geosearch";
import { SearchControlOptions } from "leaflet-geosearch/dist/SearchControl";
import { useEffect } from "react";
import { useMap } from "react-leaflet";

type UseStateType = {
  inputValue: string;
  setInputValue: (arg: string) => void;
};

// interface SearchProps extends

export const Search = (
  props: SearchControlOptions &
    UseStateType & {
      setPosition: React.Dispatch<React.SetStateAction<LatLngLiteral>>;
    },
) => {
  const map = useMap();
  useEffect(() => {
    const searchControl: Control = GeoSearchControl(props);

    map.addControl(searchControl);
    map.on("geosearch/showlocation", (e: any) => {
      props.setInputValue(e.location?.label);
      props.setPosition({ lat: e.location?.y, lng: e.location?.x });
    });

    return () => {
      map.removeControl(searchControl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, props.inputValue]);

  return null;
};
