import { RefAttributes } from "react";
import { Marker, MarkerProps } from "react-leaflet";

export const MarkerWithOpenPopUp = (
  props: JSX.IntrinsicAttributes & MarkerProps & RefAttributes<any>,
) => {
  const initMarker = (ref: any) => {
    if (ref) {
      ref.openPopup();
    }
  };

  return <Marker ref={initMarker} {...props} />;
};
