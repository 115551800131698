import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import commonDe from "./languages/de.json";
import commonEn from "./languages/en.json";
import commonIt from "./languages/it.json";

export const resources = {
  en: {
    translation: commonEn,
  },
  de: {
    translation: commonDe,
  },
  it: {
    translation: commonIt,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
