import React from "react";
import { useTranslation } from "react-i18next";

import { DAYS_OF_THE_WEEK } from "app-constants";
import models from "models";
import { translateIfIsTranslatable } from "utils";

export const ScheduleViewer = ({
  workingHours,
}: {
  workingHours: models.WorkingHours[];
}) => {
  const { t } = useTranslation();

  workingHours?.sort((a, b) => a.index - b.index);

  return (
    <div>
      {workingHours?.map((item, idx) => {
        if (!Object.keys(item).length) return null;

        return (
          <React.Fragment key={idx}>
            <div className="one-line">
              <span className="grey-400 mr-5">
                {translateIfIsTranslatable(
                  t,
                  DAYS_OF_THE_WEEK[item.index!].shortName as string,
                  "time",
                )}
                :
              </span>
              <span>{`${item.time_from} to ${item.time_to}; `}</span>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
