import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import models from "models";
import { Divider, QueryTable } from "components";
import { useAuth, useModalState } from "hooks";
import api, { querykeys } from "api";
import { CURRENCY_CODE_FALLBACK } from "app-constants";
import { CurrencySymbolType } from "types";

import { feesColumns } from "../config";
import { EditFeesModal } from "./EditFeesModal";

export const FeesSettings = () => {
  const { t } = useTranslation();
  const feesModal = useModalState<models.Fees>();
  const { organization } = useAuth();
  const currency =
    organization?.currency?.code ||
    (CURRENCY_CODE_FALLBACK as CurrencySymbolType);

  const query = useQuery(querykeys.settings.fees.many(), () =>
    api.settings.fees(),
  );

  return (
    <>
      <h2 className="font-size-18">{t("fee.return_fees")}</h2>
      <Divider space="8px" />

      <QueryTable
        query={query}
        columns={feesColumns(feesModal.openWith, currency, t)}
      />

      {feesModal.isOpen && (
        <EditFeesModal
          currency={currency}
          open={feesModal.isOpen}
          onClose={feesModal.close}
          data={feesModal.data}
          closeOnClickOutside={false}
        />
      )}
    </>
  );
};
