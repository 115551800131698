import cn from "classnames";

import models from "models";
import { createClassWithModifiers, fileToken, makeBEM } from "utils";

interface ThumbnailImageAttachmentProps {
  file?: models.Attachment;
  background?: boolean;
}

const bem = makeBEM("thumbnail-image");

export const ThumbnailImageAttachment = ({
  file,
  background,
}: ThumbnailImageAttachmentProps) => {
  return (
    <div
      className={cn(
        createClassWithModifiers(bem(), {
          bg: background,
        }),
      )}
    >
      {file?.url && <img src={fileToken(file)} alt="thumbnail" />}
    </div>
  );
};
