import { Button } from "ebs-design";
import { TFunction } from "react-i18next";
import { Link } from "react-router-dom";

import {
  Flex,
  OrderStatus,
  PayoutStatus,
  UserInfo,
  WhiteSpace,
} from "components";
import * as Icons from "components/icons";
import models from "models";
import { formatPrice, formattedDateTime, TableColumnBuilder } from "utils";

interface BuybackOrdersColumnsProps {
  t: TFunction;
}

export const buybackOrdersColumns = ({ t }: BuybackOrdersColumnsProps) =>
  new TableColumnBuilder<models.Order>()
    .add(t("common.id"), "code")
    .add(t("device.devices"), "devices__count")
    .add(t("price.price"), "", (row: models.Order) =>
      formatPrice(row?.price, row?.currency),
    )
    .add(t("common.service_cost"), "", (row: models.Order) =>
      formatPrice(row?.cost, row?.currency),
    )
    .add(t("common.creation_date"), "timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .getColumns();

interface BuybackOrdersChildColumnsProps {
  t: TFunction;
  dataErasureVisible: boolean;
  cancelOrder: React.Dispatch<{ id: number; parent_id: number }>;
  editOrder: React.Dispatch<models.Order>;
  parent_id: number;
}
export const buybackOrdersChildColumns = ({
  t,
  dataErasureVisible,
  editOrder,
  cancelOrder,
  parent_id,
}: BuybackOrdersChildColumnsProps) =>
  new TableColumnBuilder<models.Order>()
    .add(t("common.id"), "code")
    .add(t("brand.brand"), ["device", "brand_model", "brand", "title"])
    .add(t("model.model"), ["device", "brand_model", "title"])
    .addIf(
      dataErasureVisible,
      t("common.data_erasure"),
      "services",
      (services: models.Service[]) => (
        <>
          {services?.filter(
            (item: models.Service) =>
              item.code === models.ServiceCode.CERTUS_SOFTWARE_DATA_ERASURE,
          ).length === 1 ? (
            <span className="success-color">{t("common.yes")}</span>
          ) : (
            <span className="danger-color">{t("common.no")}</span>
          )}
        </>
      ),
    )
    .add(t("common.secure_box"), "services", (services: models.Service[]) => (
      <>
        {services?.filter(
          (item: models.Service) => item.code === models.ServiceCode.SECURE_BOX,
        ).length === 1 ? (
          <span className="success-color">{t("common.yes")}</span>
        ) : (
          <span className="danger-color">{t("common.no")}</span>
        )}
      </>
    ))
    .add(t("common.imei"), ["device", "imei"])
    .add(t("common.tags"), "tags", (record: models.Tag[]) =>
      record.map((v) => <Link to="/">#{v.code}; </Link>),
    )
    .add(t("common.seller"), "client", (client: models.ShortClientUser) => (
      <UserInfo name={client?.name} email={client?.email} />
    ))
    .add(t("common.pickup_drop_off_address"), "", (record: models.Order) => (
      <span>
        {record?.drop_off_location?.address || record?.address?.title || ""}
      </span>
    ))
    .add(t("price.price"), "", (row: models.Order) =>
      formatPrice(row?.price, row?.currency),
    )
    .add(t("common.service_cost"), "", (row: models.Order) =>
      formatPrice(row?.cost, row?.currency),
    )
    .add(t("common.creation_date"), "timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add(
      t("payout.payout_status"),
      "payout_status",
      (payoutStatus: models.PayoutStatus) => (
        <PayoutStatus status={payoutStatus} />
      ),
    )
    .add(t("order.order_status"), "hidden_status", (status) => (
      <OrderStatus orderStatus={status} />
    ))
    .add(t("order.client_order_status"), "status", (status) => (
      <OrderStatus orderStatus={status} />
    ))
    .add("", "", (row) => (
      <Flex justify="flex-end">
        <Button
          onClick={() => editOrder({ ...row, parent_id })}
          type="light"
          size="small"
          icon={() => <Icons.Edit size="small" />}
        />
        <WhiteSpace h="8px" />
        <Button
          onClick={() => cancelOrder({ id: row.id, parent_id })}
          type="light"
          size="small"
          icon={() => <Icons.Close size="small" />}
        />
      </Flex>
    ))
    .getColumns();

export const buybackOrdersSortOptions = [
  { title: "Creation date", value: "timestamp" },
  { title: "Price", value: "price" },
  { title: "Service cost", value: "cost" },
];

interface ErasureOrdersProps {
  cancelOrder: React.Dispatch<models.Order>;
  t: TFunction;
}

export const erasureOrdersColumns = ({ cancelOrder, t }: ErasureOrdersProps) =>
  new TableColumnBuilder<models.Order>()
    .add(t("common.id"), "code")
    .add(t("brand.brand"), ["device", "brand_model", "brand", "title"])
    .add(t("model.model"), ["device", "brand_model", "title"])
    .add(t("common.certificate"), "")

    .add(t("common.imei"), ["device", "imei"])
    .add(t("common.tags"), "tags", (record: models.Tag[]) =>
      record.map((v) => <Link to="/">#{v.code}; </Link>),
    )
    .add(t("common.client"), "client", (client: models.ShortClientUser) => (
      <UserInfo name={client?.name} email={client?.email} />
    ))
    .add(t("common.service_cost"), "", (row: models.Order) =>
      formatPrice(row?.cost, row?.currency),
    )
    .add(t("common.creation_date"), "timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add(t("order.client_order_status"), "status", (status) => (
      <OrderStatus orderStatus={status} />
    ))
    .add(t("order.order_status"), "hidden_status", (status) => (
      <OrderStatus orderStatus={status} />
    ))
    .add(
      "",
      "",
      (record) =>
        record.status !== models.OrderStatus.CANCELLED && (
          <Flex justify="flex-end">
            <Button
              type="light"
              size="small"
              icon={() => <Icons.Close size="small" />}
              onClick={() => cancelOrder(record)}
            />
          </Flex>
        ),
    )
    .getColumns();

export const erasureOrdersSortOptions = [
  { title: "Creation date", value: "timestamp" },
  { title: "Service cost", value: "cost" },
];
