import { useTranslation } from "react-i18next";

import { Flex, PageTitle, SuccessInviteCard } from "components";

export const InviteStuffSuccess = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t("user.invite_internal_user")}</PageTitle>
      <Flex align="center" className="success-invite-wrapper">
        <SuccessInviteCard
          redirect={{
            url: "/auth/internal-users",
            title: t("user.go_to_internal_users"),
          }}
        />
      </Flex>
    </>
  );
};
