import { TFunction } from "react-i18next";

import { AxiosError } from "axios";
import { FormInstance } from "ebs-design";
import { UseNotifyResult } from "types";

import { wrapInArr } from "./array";
import { setFieldsErrors } from "./errors";

export const mapErrors = (
  errors: any,
  notify: UseNotifyResult,
  form: FormInstance,
  t: TFunction,
) => {
  const err = errors as AxiosError<any>;
  const wrapError = wrapInArr(err.response?.data?.detail);

  if (wrapError.length) wrapError.map((v) => notify.error({ title: v }));

  if (errors.response?.status === 400)
    setFieldsErrors(errors?.response?.data, form, notify, t);
};
