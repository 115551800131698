import { Card, Col, Row } from "ebs-design";
import { useTranslation } from "react-i18next";

import { NO_VALUE_FALLBACK } from "app-constants";
import { Flex, SettingsCard } from "components";
import * as Icons from "components/icons";
import { useModalState } from "hooks";
import models from "models";

import { EditLoadTimeModal } from "./EditLoadTimeModal";

interface ReminderCardProps {
  defaultSettings?: models.DefaultSettings;
}

export interface ReminderModalArgs {
  dataDefault: models.DefaultSettingsData | undefined;
  type: string;
}

export const ReminderCard = ({ defaultSettings }: ReminderCardProps) => {
  const { t } = useTranslation();
  const serviceModal = useModalState<ReminderModalArgs>();

  const openEditModal = (type: string) => {
    const dataDefault = defaultSettings?.data;
    serviceModal.openWith({
      dataDefault,
      type,
    });
  };

  return (
    <>
      <Card className="full-height">
        <Card.Header>
          <Flex align="center">
            <h3>{t("common.price_and_recycling_reminder")}</h3>
          </Flex>
        </Card.Header>
        <Card.Body>
          <Row g={3}>
            <Col size={12} sm={6} lg={12} xxl={6}>
              <SettingsCard
                title={t("price.price_update_approval")}
                action={() => openEditModal("price")}
                description={
                  <div className="grey-100 font-size-12 mt-8">
                    <Flex align="center">
                      <Icons.Calendar className="mr-5" size="12px" />
                      <div className="grey-400 font-size-12 line-height-21">
                        {`${t("common.calendar")} `}
                        <span style={{ textTransform: "lowercase" }}>
                          {defaultSettings?.data?.PRICE_UPDATE_APPROVAL_PERIOD}:
                        </span>
                        <span className="primary-color fw-600 ml-5 ">
                          {defaultSettings?.data?.PRICE_UPDATE_APPROVAL}
                        </span>
                      </div>
                    </Flex>
                    <Flex align="center">
                      <Icons.Notifications className="mr-5" size="12px" />
                      <ul className="list-restart">
                        {defaultSettings?.data?.PRICE_UPDATE_APPROVAL_EMAILS
                          ?.length
                          ? defaultSettings?.data?.PRICE_UPDATE_APPROVAL_EMAILS?.map(
                              (email) => (
                                <li key={email}>
                                  <span className="grey-400 font-size-12">
                                    {email}
                                  </span>
                                </li>
                              ),
                            )
                          : NO_VALUE_FALLBACK}
                      </ul>
                    </Flex>
                  </div>
                }
                alignContent="flex-start"
              />
            </Col>
            <Col size={12} sm={6} lg={12} xxl={6}>
              <SettingsCard
                title={t("common.rejection_status_approval")}
                action={() => openEditModal("reject")}
                description={
                  <div className="grey-100 font-size-12 mt-8">
                    <Flex align="center">
                      <Icons.Calendar className="mr-5" size="12px" />
                      <div className="grey-400 font-size-12 line-height-21">
                        {`${t("common.calendar")} `}
                        <span style={{ textTransform: "lowercase" }}>
                          {
                            defaultSettings?.data
                              ?.REJECTION_STATUS_APPROVAL_PERIOD
                          }
                          :
                        </span>
                        <span className="primary-color fw-600 ml-5 ">
                          {defaultSettings?.data?.REJECTION_STATUS_APPROVAL}
                        </span>
                      </div>
                    </Flex>
                    <Flex align="center">
                      <Icons.Notifications className="mr-5" size="12px" />
                      <ul className="list-restart">
                        {defaultSettings?.data?.REJECTION_STATUS_APPROVAL_EMAILS
                          ?.length
                          ? defaultSettings?.data?.REJECTION_STATUS_APPROVAL_EMAILS?.map(
                              (email) => (
                                <li key={email}>
                                  <span className="grey-400 font-size-12">
                                    {email}
                                  </span>
                                </li>
                              ),
                            )
                          : NO_VALUE_FALLBACK}
                      </ul>
                    </Flex>
                  </div>
                }
                alignContent="flex-start"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {serviceModal.isOpen && (
        <EditLoadTimeModal
          open={serviceModal.isOpen}
          onClose={serviceModal.close}
          data={serviceModal.data}
          closeOnClickOutside={false}
        />
      )}
    </>
  );
};
