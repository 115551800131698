import { Space } from "ebs-design";

import { Sorter } from ".";

interface Props {
  right?: boolean;
  title?: string;
  column?: string;
  ordering?: object;
  setOrdering?: React.Dispatch<any>;
}

export const ColumnSorter = ({
  right,
  title,
  column,
  ordering,
  setOrdering,
}: Props) => {
  const needToReset = ordering && Object.keys(ordering)[0] !== column;

  return (
    <Space justify={`${right ? "end" : null}`}>
      <Sorter
        reset={needToReset}
        onChange={(v) => setOrdering({ [column]: v })}
      >
        {title}
      </Sorter>
    </Space>
  );
};
