import { createSvgIcon } from "../utils";

export const Upload = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 14C1.75 13.5858 1.41421 13.25 1 13.25C0.585786 13.25 0.25 13.5858 0.25 14H1.75ZM1 16H0.25H1ZM17.75 14C17.75 13.5858 17.4142 13.25 17 13.25C16.5858 13.25 16.25 13.5858 16.25 14H17.75ZM3.46967 5.46967C3.17678 5.76257 3.17678 6.23744 3.46967 6.53033C3.76256 6.82323 4.23744 6.82323 4.53033 6.53033L3.46967 5.46967ZM9 1L9.53033 0.46967C9.38968 0.329018 9.19891 0.25 9 0.25C8.80109 0.25 8.61032 0.329018 8.46967 0.46967L9 1ZM13.4697 6.53033C13.7626 6.82323 14.2374 6.82323 14.5303 6.53033C14.8232 6.23744 14.8232 5.76257 14.5303 5.46967L13.4697 6.53033ZM8.25 13C8.25 13.4142 8.58579 13.75 9 13.75C9.41422 13.75 9.75 13.4142 9.75 13H8.25ZM0.25 14V16H1.75V14H0.25ZM0.25 16C0.25 16.7293 0.539731 17.4288 1.05546 17.9445L2.11612 16.8839C1.8817 16.6495 1.75 16.3315 1.75 16H0.25ZM1.05546 17.9445C1.57118 18.4603 2.27065 18.75 3 18.75V17.25C2.66848 17.25 2.35054 17.1183 2.11612 16.8839L1.05546 17.9445ZM3 18.75H15V17.25H3V18.75ZM15 18.75C15.7293 18.75 16.4288 18.4603 16.9445 17.9445L15.8839 16.8839C15.6495 17.1183 15.3315 17.25 15 17.25V18.75ZM16.9445 17.9445C17.4603 17.4288 17.75 16.7293 17.75 16H16.25C16.25 16.3315 16.1183 16.6495 15.8839 16.8839L16.9445 17.9445ZM17.75 16V14H16.25V16H17.75ZM4.53033 6.53033L9.53033 1.53033L8.46967 0.46967L3.46967 5.46967L4.53033 6.53033ZM8.46967 1.53033L13.4697 6.53033L14.5303 5.46967L9.53033 0.46967L8.46967 1.53033ZM8.25 1L8.25 13H9.75L9.75 1L8.25 1Z"
      fill="currentColor"
    />
  </svg>
);
