import { Button } from "ebs-design";
import React from "react";
import { withTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Row, Col, Tooltip } from "antd";
import { copy, read } from "apps/cms/components/Articles/Builder/components";
import {
  CopyOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MergeCellsOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
} from "@ant-design/icons";

import { Drag, Drop } from "./components";
import { loopAll, normalizeTree } from "./utils";
import "./index.scss";

class Builder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dragItem: undefined,
      selectedKey: [],
      expandedKeys: [],
      treeContent: [],
      mounted: false,
    };
  }

  componentDidUpdate() {
    if (!this.state.mounted && this.props.isEdit && this.props.value.length) {
      this.setState({
        treeContent: normalizeTree(this.props.value).sort(
          ({ data: prevData }, { data: currentData }) =>
            prevData.order > currentData.order
              ? 1
              : currentData.order > prevData.order
              ? -1
              : 0,
        ),
        mounted: true,
      });
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  handler = (state) =>
    this.setState((prevState) => ({
      ...prevState,
      ...state,
    }));

  onExpandAll = () => {
    let expandedKeys = [];

    loopAll(
      this.state.treeContent,
      ({ id, children }) =>
        children?.length && expandedKeys.push(id.toString()),
    );

    this.setState({ expandedKeys });
  };

  render() {
    const { t } = this.props;

    return (
      <DndProvider backend={HTML5Backend}>
        <Row
          gutter={[16, 16]}
          className={`full-width${
            this.state.isFullScreen ? " full-screen" : ""
          }`}
          style={{ width: "calc(100% + 16px)" }}
        >
          <Col span={6}>
            <Drag
              name="components"
              lang={this.props.activeTabKey}
              state={this.state}
              handler={this.handler}
              onDrag={() => this.setState({ isDrag: true })}
              onDrop={() =>
                this.setState({ dragItem: undefined, isDrag: false })
              }
            />
          </Col>
          <Col span={18}>
            <Row
              className="drag-tree__bar"
              gutter={[8, 8]}
              justify="end"
              style={{ paddingBottom: 8 }}
            >
              <Col>
                <Row gutter={[4, 4]} align="middle">
                  <Col>
                    <Button
                      type="light"
                      onClick={() =>
                        copy(JSON.stringify({ state: this.state }))
                      }
                    >
                      <CopyOutlined className="pr-10" />
                      {t("common.copy")}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="light"
                      onClick={() =>
                        read().then((val) => {
                          try {
                            const { state } = JSON.parse(val);
                            if (state) {
                              this.setState({ ...state, dragItem: undefined });

                              this.props.onChange(state.treeContent);
                            }
                          } catch (err) { }
                        })
                      }
                    >
                      <MergeCellsOutlined className="pr-10" />
                      {t("common.paste")}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={[4, 4]} align="middle">
                  <Col>
                    <Tooltip
                      placement="topLeft"
                      title={t("common.hide_internal_components")}
                    >
                      <Button
                        type="light"
                        onClick={() => this.setState({ expandedKeys: [] })}
                      >
                        <NodeCollapseOutlined className="pr-10" />
                        {t("common.collapse")}
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip
                      placement="topLeft"
                      title={t("common.show_internal_components")}
                    >
                      <Button onClick={this.onExpandAll} type="light">
                        <NodeExpandOutlined className="pr-10" />
                        {t("common.expand")}
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
              {/* <Col>
                <Button
                  type={this.state.isFullScreen ? 'showPreview' : 'default'}
                  onClick={() => this.setState({ showPreview: !this.state.showPreview })}
                >
                  <RocketOutlined /> {this.state.showPreview ? 'Hide' : 'Preview'}
                </Button>
              </Col> */}
              <Col>
                <Button
                  type={this.state.isFullScreen ? "fill" : "light"}
                  onClick={() =>
                    this.setState({ isFullScreen: !this.state.isFullScreen })
                  }
                >
                  {this.state.isFullScreen ? (
                    <FullscreenExitOutlined />
                  ) : (
                    <FullscreenOutlined />
                  )}
                </Button>
              </Col>
            </Row>
            <Row gutter={[8, 8]} style={{ height: "100%" }}>
              <Col span={24}>
                <Drop
                  lang={this.props.activeTabKey}
                  initialValue={this.props.value}
                  loading={this.props.loading}
                  state={this.state}
                  isDrag={this.state.isDrag}
                  handler={this.handler}
                  showIcon={this.props.showIcon}
                  onDrop={() =>
                    this.setState({ dragItem: undefined, isDrag: false })
                  }
                  onSubmit={this.props.onChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </DndProvider>
    );
  }
}

export default withTranslation()(Builder);
