import { cmsAxios } from "api/axios";
import { stringifyUrl } from "utils/query";
import models from "models";

export interface LanguageType {
  id?: number;
  code: string;
  is_default?: boolean;
}

const languages = {
  getLanguages: async (
    params?: any,
  ): Promise<models.WithResults<LanguageType>> => {
    const { data } = await cmsAxios.get(
      stringifyUrl("nomenclatures/languages", params, {
        api: process.env.REACT_APP_BASE_API,
      }),
    );
    return data;
  },

  addLanguage: async (params?: any) => {
    const { data } = await cmsAxios.post(stringifyUrl("language/"), params);
    return data;
  },

  getLanguageById: async (params: any, id: string) => {
    const { data } = await cmsAxios.get(stringifyUrl(`language/${id}/`), {
      params,
    });

    return data;
  },

  editLanguage: async (params: any, id: string | number) => {
    const { data } = await cmsAxios.patch(
      stringifyUrl(`language/${id}/`),
      params,
    );
    return data;
  },

  deleteLanguage: async (id: string | number) => {
    const { data } = await cmsAxios.delete(stringifyUrl(`language/${id}/`));
    return data;
  },
};

export default languages;
