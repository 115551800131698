import { TFunction } from "react-i18next";

import { ColumnSorter } from "components";
import models from "models";
import { capitalizeString, formatPercent, TableColumnBuilder } from "utils";

interface Props {
  ordering: object;
  setOrdering: React.Dispatch<any>;
  t: TFunction;
}

export const ordersColumns = ({ ordering, setOrdering, t }: Props) =>
  new TableColumnBuilder<models.OrderTotalsByStatus>()
    .add(
      <ColumnSorter
        title={t("common.status")}
        column="status"
        ordering={ordering}
        setOrdering={setOrdering}
      />,
      "status",
      (status: string) => <>{capitalizeString(status, "_")}</>,
    )
    .add(
      <ColumnSorter
        title={t("order.orders")}
        column="total"
        ordering={ordering}
        setOrdering={setOrdering}
      />,
      "total",
    )
    .add(
      <ColumnSorter
        right
        title={t("order.in_percents")}
        column="percentage"
        ordering={ordering}
        setOrdering={setOrdering}
      />,
      "percentage",
      (value) => (value ? formatPercent(value) : "-"),
    )
    .getColumns();

export const devicesColumns = ({ ordering, setOrdering, t }: Props) =>
  new TableColumnBuilder<models.DeviceTotalsByBrandModel>()
    .add(
      <ColumnSorter
        title={t("device.device_name")}
        column="brand_model"
        ordering={ordering}
        setOrdering={setOrdering}
      />,
      "brand_model",
    )
    .add(
      <ColumnSorter
        right
        title={t("device.device_count")}
        column="total"
        ordering={ordering}
        setOrdering={setOrdering}
      />,
      "total",
    )
    .getColumns();

export const departmentsColumns = ({ ordering, setOrdering, t }: Props) =>
  new TableColumnBuilder<models.Department>()
    .add(
      <ColumnSorter
        title={t("department.department")}
        column="title"
        ordering={ordering}
        setOrdering={setOrdering}
      />,
      "title",
    )
    .add(
      <ColumnSorter
        right
        title={t("user.number_of_users")}
        column="users__count"
        ordering={ordering}
        setOrdering={setOrdering}
      />,
      "users__count",
    )
    .getColumns();
