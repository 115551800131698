import models from "models";
import { makeBEM } from "utils";

interface Props {
  diagnosticCriteria?: models.DiagnosticCriteria[];
}

const bem = makeBEM("diagnostic-criteria");

export const DiagnosticCriteria = ({ diagnosticCriteria }: Props) => {
  if (!diagnosticCriteria) return null;

  const goodCondition = diagnosticCriteria.filter(
    (item) => item?.is_correct === true,
  );
  const badCondition = diagnosticCriteria.filter(
    (item) => item?.is_correct === false,
  );

  return (
    <div className={bem()}>
      {goodCondition.map((item) => (
        <span key={item.id} className={bem("item", ["correct"])} />
      ))}
      {badCondition.map((item) => (
        <span key={item.id} className={bem("item", ["wrong"])} />
      ))}
    </div>
  );
};
