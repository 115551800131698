import cn from "classnames";
import { Button } from "ebs-design";
import * as React from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { usePopperTooltip } from "react-popper-tooltip";

import { makeBEM } from "utils";
import { GenericObject } from "types";
import { useLayoutContainer } from "hooks";
import * as Icons from "../icons";
import { Badge, Flex } from "..";

export interface FiltersProps {
  queryParams?: GenericObject;
  setQueryParams?: React.Dispatch<React.SetStateAction<GenericObject>>;
}

const bem = makeBEM("filters");

export const Filters = ({
  queryParams,
  setQueryParams,
  children,
}: React.PropsWithChildren<FiltersProps>) => {
  const { t } = useTranslation();
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({ trigger: "click", placement: "bottom-start" });

  const $container = useLayoutContainer();

  if (!$container) return <></>;

  const filterCount = Object.values(queryParams || {})
    .map((item) => (item instanceof Array ? item.length : item ? 1 : 0))
    .reduce((prev, c) => prev + c, 0);

  return (
    <>
      <div className={bem()} ref={setTriggerRef}>
        <Button
          className={bem("tooltip-trigger")}
          style={{ textAlign: "left" }}
          prefix={<Icons.Filter />}
          type="light"
        >
          <Flex align="center" justify="space-between">
            <span>{t("common.filters")}</span>
            {filterCount ? (
              <Badge size="sm" variant="fill" round>
                {filterCount}
              </Badge>
            ) : null}
          </Flex>
        </Button>
      </div>
      {createPortal(
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: bem("tooltip", { visible }),
          })}
        >
          <div className={cn("filter-surface", bem("tooltip-content"))}>
            <section>
              <h3>{t("common.filters")}</h3>
            </section>
            <section>{visible && children}</section>
            <section>
              <Flex justify="end">
                <Button
                  type="light"
                  onClick={() =>
                    setQueryParams?.((prev) =>
                      Object.fromEntries(
                        Object.entries(prev).map((e) => [e[0], undefined]),
                      ),
                    )
                  }
                >
                  {t("common.reset")}
                </Button>
              </Flex>
            </section>
          </div>
        </div>,
        $container,
      )}
    </>
  );
};
