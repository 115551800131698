import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { FormInstance } from "ebs-design";

import { UseNotifyResult } from "types";
import { mapErrors } from "utils";

export function useMapErrors() {
  const { t } = useTranslation();
  return useCallback(
    (data: any, notify: UseNotifyResult, form: FormInstance) =>
      mapErrors(data, notify, form, t),
    [t],
  );
}
