export const maxImageSizeInMb = 4;

export const formItemStyle = {
  marginTop: 8,
  marginBottom: 8,
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
