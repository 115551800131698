import { createSvgIcon } from "../utils";

export const AngleDownSmall = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 6 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L3 3L5 1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
