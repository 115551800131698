import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import {
  Flex,
  Grid,
  InputFormField,
  LoadingButton,
  OrDivider,
  WhiteSpace,
} from "components";
import models from "models";
import { useAuth, useMapErrors } from "hooks";
import { CURRENCY_SYMBOL_FALLBACK } from "app-constants";

interface UpdatePriceModalProps extends ModalProps {
  data?: string[];
  onClose: () => void;
}
export const UpdatePriceModal = ({
  data,
  onClose,
  ...props
}: UpdatePriceModalProps) => {
  const { t } = useTranslation();
  const mapErrors = useMapErrors();
  const [form] = useForm();
  const notify = useNotify();
  const { organization } = useAuth();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (values: models.BrandModelsUpgrade) =>
      api.brandsModels.updateModels(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.brandsModels.many());
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("model.model"),
          }),
        });
        onClose();
      },
      onError: (errors: unknown) => mapErrors(errors, notify, form),
    },
  );

  return (
    <Modal
      title={`${t("price.update_price")} - (${data.join(", ")})`}
      size="small"
      onClose={onClose}
      {...props}
    >
      <Form
        form={form}
        onFinish={(values) => mutate({ ...values, brand_models_id: data })}
      >
        <Modal.Content>
          <InputFormField
            name="max_price"
            label={t("price.max_price")}
            prefix={
              <span className="fw-600">
                {organization.currency?.symbol || CURRENCY_SYMBOL_FALLBACK}
              </span>
            }
          />
          <OrDivider />
          <Grid cols="1fr 1fr" gap="20px">
            <InputFormField
              name="add_price_percentage"
              label={t("template.add_item", {
                item: t("price.price_percentage"),
              })}
              prefix={<span className="fw-600">+%</span>}
            />
            <InputFormField
              name="remove_price_percentage"
              label={t("template.remove_item", {
                item: t("price.price_percentage").toLowerCase(),
              })}
              prefix={<span className="fw-600">-%</span>}
            />
          </Grid>
          <OrDivider />
          <Grid cols="1fr 1fr" gap="20px">
            <InputFormField
              name="add_price"
              label={t("template.add_item", {
                item: t("common.amount").toLowerCase(),
              })}
              prefix={
                <span className="fw-600">
                  +{organization.currency?.symbol || CURRENCY_SYMBOL_FALLBACK}
                </span>
              }
            />
            <InputFormField
              name="remove_price"
              label={t("template.remove_item", {
                item: t("common.amount").toLowerCase(),
              })}
              prefix={
                <span className="fw-600">
                  -{organization.currency?.symbol || CURRENCY_SYMBOL_FALLBACK}
                </span>
              }
            />
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton submit loading={isLoading} type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
