import { createSvgIcon } from "../utils";

export const Book = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
  >
    <path
      d="M1 14.9997H0.25C0.25 15.2677 0.392949 15.5153 0.625 15.6492C0.857051 15.7832 1.14295 15.7832 1.375 15.6492L1 14.9997ZM10 14.9997L9.625 15.6492C9.85705 15.7832 10.1429 15.7832 10.375 15.6492L10 14.9997ZM19 14.9997L18.625 15.6492C18.8571 15.7832 19.143 15.7832 19.375 15.6492C19.6071 15.5153 19.75 15.2677 19.75 14.9997H19ZM1 1.99972L0.625 1.3502C0.392949 1.48417 0.25 1.73177 0.25 1.99972H1ZM5.5 0.793945V1.54395V0.793945ZM14.5 0.793945V1.54395V0.793945ZM19 1.99972H19.75C19.75 1.73177 19.6071 1.48417 19.375 1.3502L19 1.99972ZM1.375 15.6492C2.62916 14.9251 4.05182 14.5439 5.5 14.5439V13.0439C3.78852 13.0439 2.10719 13.4945 0.625 14.3502L1.375 15.6492ZM5.5 14.5439C6.94818 14.5439 8.37084 14.9251 9.625 15.6492L10.375 14.3502C8.89281 13.4945 7.21148 13.0439 5.5 13.0439V14.5439ZM10.375 15.6492C11.6292 14.9251 13.0518 14.5439 14.5 14.5439V13.0439C12.7885 13.0439 11.1072 13.4945 9.625 14.3502L10.375 15.6492ZM14.5 14.5439C15.9482 14.5439 17.3708 14.9251 18.625 15.6492L19.375 14.3502C17.8928 13.4945 16.2115 13.0439 14.5 13.0439V14.5439ZM1.375 2.64924C2.62916 1.92515 4.05182 1.54395 5.5 1.54395V0.0439453C3.78852 0.0439453 2.10719 0.494457 0.625 1.3502L1.375 2.64924ZM5.5 1.54395C6.94818 1.54395 8.37084 1.92515 9.625 2.64924L10.375 1.3502C8.89281 0.494457 7.21148 0.0439453 5.5 0.0439453V1.54395ZM10.375 2.64924C11.6292 1.92515 13.0518 1.54395 14.5 1.54395V0.0439453C12.7885 0.0439453 11.1072 0.494457 9.625 1.3502L10.375 2.64924ZM14.5 1.54395C15.9482 1.54395 17.3708 1.92515 18.625 2.64924L19.375 1.3502C17.8928 0.494457 16.2115 0.0439453 14.5 0.0439453V1.54395ZM0.25 1.99972V14.9997H1.75V1.99972H0.25ZM9.25 1.99972V14.9997H10.75V1.99972H9.25ZM18.25 1.99972V14.9997H19.75V1.99972H18.25Z"
      fill="currentColor"
    />
  </svg>,
);
