type NormalizeDataParamsType = (
  fn: (args: FnParams) => void,
  startDate: Date,
  endDate: Date,
) => void;

interface FnParams {
  from_date: string;
  to_date: string;
}

export const normalizeDataParams: NormalizeDataParamsType = (
  fn,
  startDate,
  endDate,
) => {
  fn({
    from_date: startDate.toLocaleDateString("sv-SE"),
    to_date: endDate.toLocaleDateString("sv-SE"),
  });
};
