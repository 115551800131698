import { Card, Col, Row, useNotify } from "ebs-design";
import { ColProps } from "ebs-design/dist/components/atoms/Grid/Col/Col";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import api, { querykeys } from "api";
import * as Icon from "components/icons";
import { useAuth } from "hooks";
import { notifyErrors } from "utils";

import { StatisticCard } from ".";

const props: ColProps[] = [
  { size: 12, sm: 12, lg: 6, xxl: 3 },
  { size: 12, sm: 6, lg: 4, xxl: 4 },
];

export const OrderStatistics = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const { organization } = useAuth();
  const colSizes = organization?.is_data_erasure_active ? props[0] : props[1];

  const { data } = useQuery(
    querykeys.nomenclature.statistics.order(),
    () => api.statistics.getOrderTotals(),
    { onError: (error: Error) => notifyErrors(notify, error) },
  );

  return (
    <Card>
      <Card.Body>
        <h3 className="font-size-18">Order Statistics</h3>
        <Row g={3} className="mt-12">
          <Col {...colSizes}>
            <StatisticCard
              icon={<Icon.Menu />}
              title={t("menu.buyback_orders")}
              value={data?.total}
              bgColor="#F1FDFD"
              color="#1563E2"
            />
          </Col>
          <Col {...colSizes}>
            <StatisticCard
              icon={<Icon.Pending />}
              title={t("menu.pending_orders")}
              value={data?.pending}
              bgColor="#FEFAFF"
              color="#C479F3"
            />
          </Col>
          {organization?.is_data_erasure_active && (
            <Col {...colSizes}>
              <StatisticCard
                icon={<Icon.Eraser />}
                title={t("menu.erasure_orders")}
                value={data?.erasure}
                bgColor="#FFFEEB"
                color="#F8C51B"
              />
            </Col>
          )}
          <Col {...colSizes}>
            <StatisticCard
              icon={<Icon.File />}
              title={t("menu.invoices")}
              value={data?.invoices}
              bgColor="#F1FDFD"
              color="#38D9B9"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
