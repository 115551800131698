import { Button, useNotify } from "ebs-design";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import {
  Collapse,
  Form,
  Affix,
  Row,
  Col,
  Input,
  Radio,
  Tabs,
  Modal,
  Spin,
  DatePicker,
  Tag as Label,
} from "antd";
import { ArrowRightOutlined, MacCommandOutlined } from "@ant-design/icons";
import { LanguagesContext } from "apps/cms/context/Languages";
import api, {
  Article,
  ArticleState,
  LayoutVariant,
} from "apps/cms/lib/api/pages";
//import { GalleryWall } from "apps/cms/components/Articles";
import tagsApi, { ArticleTagsCode, Tag } from "apps/cms/lib/api/tags";
import { Languages } from "apps/cms/lib/utils";
import { isIOS } from "utils";
import { Properties } from "types";

import Builder from "./Builder";
import "./style.css";

const { Panel } = Collapse;
const { TabPane } = Tabs;

const fields = ["title", "content", "preview"];

const customPanelStyle = {
  border: 0,
  borderRadius: 4,
  marginBottom: 5,
  overflow: "hidden",
};

// const hasPreview = [ArticleTags.INFORMATION];

const formItemStyle = {
  marginBottom: 0,
  lineHeight: "initial",
};

interface ArticleFormProps {
  /**
   * Article to edit, this is used only on first render.
   */
  initialArticle?: Article;

  /**
   * Called after succesful save.
   */
  onSaved: () => void;
  defaultTag?: ArticleTagsCode;
  type?: string;
  noTags?: boolean;
  AdditionalModules?: React.FunctionComponent<any>;
  dynamicSlug?: boolean;
}

const ArticleForm: React.FC<ArticleFormProps> = ({
  onSaved,
  initialArticle,
  defaultTag = ArticleTagsCode.PAGE,
  noTags = false,
  type = "pages",
  //dynamicSlug = true,
}) => {
  const notify = useNotify();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { search, pathname } = useLocation();
  const {
    languages: { data: languages },
  } = React.useContext(LanguagesContext);
  const [loading, setLoading] = React.useState(false);
  const [tagList, setTagList] = React.useState<Tag[]>([]);
  const [tagsLoading, setTagsLoading] = React.useState(true);
  const [activeTabKey, setActiveTabKey] = React.useState<Languages>(
    (localStorage.getItem("key") || "en") as Languages,
  );
  const isEdit = pathname.includes("edit");

  const fetchTags = async () => {
    setTagsLoading(true);
    try {
      setTagList(await tagsApi.all({}).then(({ results }) => results));
    } catch (e) {
      console.error(e);
    }
    setTagsLoading(false);
  };

  React.useEffect(() => {
    fetchTags();
  }, []);

  React.useEffect(() => {
    const resetTags = async () => {
      await tagsApi.resetAll();
    };

    if (!tagsLoading && !tagList.length) {
      resetTags().then(fetchTags);
    }
  }, [tagsLoading, tagList]);

  React.useEffect(() => {
    form.setFieldsValue({
      ...initialArticle,
      publish_date: initialArticle?.publish_date
        ? moment(initialArticle?.publish_date)
        : moment(new Date()),
      tags: initialArticle?.tags?.length
        ? Array.isArray(initialArticle?.tags) &&
          initialArticle?.tags.map(({ id }) => id).pop()
        : tagList.find((i) => i.code === defaultTag)?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialArticle, tagList, defaultTag]);

  React.useEffect(() => {
    const lang = new URLSearchParams(search).get("lang");

    if (lang) {
      setActiveTabKey(lang as Languages);
    }
  }, [search]);

  React.useEffect(() => {
    const handleKeyDown = (e) => {
      const charCode = String.fromCharCode(e.which).toLowerCase();

      if (e.ctrlKey || e.metaKey) {
        const code = languages.find(
          (_, i) => (i + 1).toString() === charCode,
        )?.code;

        if (code) {
          e.preventDefault();
          onChange(code);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [languages]);

  // getFieldDecorator('id', { initialValue: initialArticle!.id });
  // getFieldDecorator('order', { initialValue: initialArticle!.order });
  // getFieldDecorator('state', { initialValue: initialArticle!.state });
  // getFieldDecorator('parents', { initialValue: initialArticle!.parents || [] });
  // getFieldDecorator('gallery', { initialValue: initialArticle!.gallery });
  // getFieldDecorator('videos', { initialValue: initialArticle!.videos });
  // getFieldDecorator('background', { initialValue: initialArticle!.background });
  // getFieldDecorator('timestamp', {
  //   initialValue: initialArticle?.timestamp ? moment(initialArticle!.timestamp) : undefined,
  // });
  // getFieldDecorator('tags', {
  //   initialValue: initialArticle?.tags?.length
  //     ? Array.isArray(initialArticle!.tags) && initialArticle!.tags.map(({ id }) => id)
  //     : [defaultTags.find((i) => i.code === defaultTag)?.id],
  // });

  // const tags = form.getFieldValue('tags');
  //const background = form.getFieldValue('background');

  const onChange = (key: string): void => {
    localStorage.setItem("key", key);
    setActiveTabKey(key as Languages);
  };

  // const verifyTreeInclude = (_rule: any, value: any, callback: any): void => {
  //   if (Array.isArray(value) && value.includes(`${initialArticle!.id}`)) {
  //     callback("Parent can't be self-referenced");

  //     form.setFieldsValue({
  //       parents: value.filter((id) => id !== `${initialArticle!.id}`),
  //     });

  //     Modal.error({
  //       title: 'Error.',
  //       content: 'Chosen page is not compatible to be referenced.',
  //     });
  //   } else {
  //     callback();
  //   }
  // };

  const handleSubmit = async (values: any): Promise<void> => {
    if (!form.getFieldsError().some((field) => field.errors.length > 0)) {
      setLoading(true);
      try {
        const i18n = { ...values.i18n };
        const data = { ...values };

        Object.keys(i18n).forEach((lang) => {
          if (initialArticle?.i18n[lang]?.slug === i18n[lang]?.slug) {
            if (i18n[lang].slug) {
              delete i18n[lang].slug;
            }
          }
        });

        await api.upsert({
          ...(initialArticle && { id: initialArticle.id }),
          ...data,
          i18n,
          tags: data.tags
            ? [data.tags]
            : [tagList.find((i) => i.code === defaultTag)?.id],
        } as any);
        onSaved();
      } catch (e) {
        const { message = "Error. The article could not be saved!" } = e;

        Modal.error({ content: message });
      }
      setLoading(false);
      notify.success({
        title: t(
          isEdit
            ? "template.item_updated_successfully"
            : "template.item_created_successfully",
          { item: t("common.page") },
        ),
      });
    } else {
      languages.forEach(({ code }) => {
        let valid = true;

        fields.forEach((field) => {
          if (
            !values["i18n"][code][field] ||
            !values["i18n"][code][field].length
          ) {
            valid = false;
          }
        });

        if (!valid) {
          setActiveTabKey(code as Languages);
        }
      });
    }
  };

  // const withPreview = React.useMemo(() => hasPreview.some((type) => tags.includes(type)), [tags]);

  return (
    <Form form={form} onFinish={handleSubmit} id="article-form">
      <Row justify="space-between">
        <Col span={24} xl={18}>
          <Tabs
            className="custom-tabs static-pages"
            activeKey={activeTabKey}
            onChange={onChange}
            animated={false}
          >
            <TabPane tab={isIOS() ? <MacCommandOutlined /> : "CTRL"} disabled />
            {languages.map(({ code }, i) => {
              const source = initialArticle!.i18n[code];

              const { title, preview } = source || {};

              return (
                <TabPane
                  forceRender
                  tab={
                    <>
                      {code.toUpperCase()}
                      <Label style={{ marginLeft: 10 }}>{i + 1}</Label>
                    </>
                  }
                  key={code}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        name={["i18n", code, "title"]}
                        label="Title"
                        initialValue={title || `Title [${code}]`}
                        style={formItemStyle}
                      >
                        <Input placeholder={`Enter article title [${code}]`} />
                      </Form.Item>
                    </Col>
                    {/* User shouldn't have to see the slug input */}
                    {/* <Col span={24}>
                      <Form.Item
                        name={["i18n", code, "slug"]}
                        initialValue={slug || `Slug [${code}]`}
                        style={formItemStyle}
                      >
                        <Input
                          size="small"
                          addonAfter="page slug"
                          placeholder={`Insert page slug [${code}]`}
                        />
                      </Form.Item>
                    </Col> */}

                    <Col span={24}>
                      <Form.Item
                        name={["i18n", code, "preview"]}
                        label="Preview"
                        initialValue={preview}
                        style={formItemStyle}
                      >
                        <Input.TextArea
                          placeholder={`Enter page preview [${code}]`}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name={["data", "components"]}
                        label=""
                        initialValue={initialArticle?.data.components}
                        style={formItemStyle}
                      >
                        <Builder
                          activeTabKey={activeTabKey}
                          loading={loading}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              );
            })}
          </Tabs>
          {/* <Form.Item
            name={`gallery`}
            initialValue={initialArticle?.gallery}
            style={formItemStyle}
          >
            <GalleryWall />
          </Form.Item> */}
        </Col>
        <Col span={24} xl={5}>
          <Affix offsetTop={10}>
            <>
              <Collapse
                className="article-menu"
                bordered={false}
                defaultActiveKey={["4", "3", "2"]}
                expandIcon={({ isActive }) => (
                  <ArrowRightOutlined rotate={isActive ? 90 : 0} />
                )}
              >
                {!noTags && (
                  <Panel header="Tag" key="2" style={customPanelStyle}>
                    <Form.Item name="tags" style={formItemStyle}>
                      <Radio.Group className="checkbox-group-tags">
                        {tagsLoading && <Spin />}
                        {tagList.map((tag) => (
                          <Radio
                            key={tag.id}
                            className={tag.pid && "pid"}
                            value={tag.id}
                          >
                            {tag.i18n.en}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Panel>
                )}

                {/* <Panel
                  forceRender
                  header={
                    <>
                      Background image<sub>(menu)</sub>
                    </>
                  }
                  key="4"
                  style={customPanelStyle}
                >
                  {background && (
                    <img src={background} alt="background" className="background-preview" />
                  )}
                  <Form.Item
                    name={`background`}
                    normalize={(value) => (Array.isArray(value) ? value[0] : value)}
                    style={formItemStyle}
                  >
                    <UploadImage />
                  </Form.Item>

                  <span>
                    <b>Note: </b> This image will only appear for menu pages that do not have other
                    child items
                  </span>
                </Panel> */}

                <Panel header="Status" key="3" style={customPanelStyle}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        name={`state`}
                        initialValue={
                          initialArticle?.state || ArticleState.DRAFT
                        }
                        style={formItemStyle}
                      >
                        <Radio.Group
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Radio value={ArticleState.PUBLIC}>
                            {t("common.public")}
                          </Radio>
                          <Radio value={ArticleState.DRAFT}>
                            {t("common.draft")}
                          </Radio>
                          <Radio value={ArticleState.INACTIVE}>
                            {t("common.inactive")}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="publish_date"
                        labelCol={{ span: 24 }}
                        label={
                          <>
                            {t("common.publication_date")}
                            <sub>({t("common.optional")})</sub>
                          </>
                        }
                        className="date-picker-form-item"
                      >
                        <DatePicker showTime />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel header="Settings" key="4" style={customPanelStyle}>
                  <Form.Item
                    name={["data", "layout"]}
                    initialValue={initialArticle?.data?.layout || "default"}
                  >
                    <Radio.Group
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {Object.keys(LayoutVariant).map((key, i) => (
                        <Radio
                          key={i}
                          value={
                            LayoutVariant[key as keyof typeof LayoutVariant]
                          }
                        >
                          {key[0]}
                          {key.toLowerCase().slice(1, key.length)}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Panel>
              </Collapse>

              <Row justify="space-between">
                <Link to={`/cms/${type}`}>
                  <Button>{t("common.cancel")}</Button>
                </Link>

                <Button
                  form="article-form"
                  type="primary"
                  submit
                  loading={loading}
                >
                  {t("common.save")}
                </Button>
              </Row>
            </>
          </Affix>
        </Col>
      </Row>
    </Form>
  );
};

ArticleForm.defaultProps = {
  initialArticle: {
    id: 0,
    order: 1,
    i18n: {},
    gallery: [],
    videos: [],
    parents: [],
    data: {},
    background: "",
    timestamp: "",
    state: ArticleState.DRAFT,
    tags: [],
  },
} as Properties;

export default ArticleForm;
