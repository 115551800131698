import React, { useState } from "react";
import { DropTarget } from "react-dnd";
import { useDebounce } from "react-use";
import { Spin, Tree, Empty } from "antd";
import {
  FolderOutlined,
  FolderOpenOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Properties } from "types";
import EmptyData from "assets/icons/empty.svg";

import { loop } from "../utils";
import EditItem from "./EditItem";

const TreeNode = Tree.TreeNode;
const Drop: React.FC<Properties> = (props) => {
  const [state, setState] = useState(false);
  const [isOver, setIsOver] = useState(false);

  useDebounce(
    () => {
      setIsOver(state);
    },
    50,
    [state],
  );

  const onOver = (value) => {
    setState(value);
  };

  const onDrop = (info) => {
    let dragObj;
    const { treeContent } = props.state;

    const { node: dropNode, dragNode, dropPosition, dropToGap } = info;
    const dropKey = dropNode?.key;
    const dragKey = dragNode?.key;

    const dropPos = dropNode.pos.split("-");
    const dropIndex = dropPosition - Number(dropPos[dropPos.length - 1]);

    loop(treeContent, dragKey, (item, i, arr) => {
      arr.splice(i, 1);
      dragObj = item;
    });

    loop(treeContent, dropKey, (item, i, arr) => {
      if (!dropToGap) {
        item.children = item.children || [];

        item.children.splice(dropIndex, 0, dragObj);
      } else {
        if (dropIndex === 1) {
          arr.splice(i + 1, 0, dragObj);
        } else {
          arr.splice(dropIndex === -1 ? i : i + 1, 0, dragObj);
        }
      }

      return item;
    });

    props.onSubmit(treeContent);
    props.handler({
      treeContent,
    });
    props.onDrop();
  };

  const onNodeSelect = (selectedKeys, e) => {
    if (
      e.nativeEvent.path
        .slice(0, 2)
        .some(({ classList }) =>
          Array.from(classList).includes("drop-tree__node-edit"),
        )
    ) {
      props.handler({
        selectedKey: props.state.selectedKey.some((i) =>
          selectedKeys.includes(i),
        )
          ? props.state.selectedKey.filter((i) => !selectedKeys.includes(i))
          : [...props.state.selectedKey, ...selectedKeys],
      });
    }
  };

  const deleteItem = (id) => {
    const { treeContent } = props.state;

    loop(treeContent, id, (_, index, arr) => {
      arr.splice(index, 1);
    });

    props.handler({
      treeContent,
      selectedKey: props.state.selectedKey.filter((key) => key !== id),
    });

    if (props.initialValue) {
      props.onSubmit(treeContent);
    }
  };

  const onSaved = (treeContent) => {
    props.onSubmit(treeContent);
    props.handler({ treeContent });
  };

  const getTreeNode = (treeContent) => {
    if (!treeContent || treeContent.length === 0) {
      return null;
    }

    const treeNode = treeContent.map((value, i) => (
      <TreeNode
        key={value?.id}
        className={`drop-tree__node ant-col ant-col-${(value?.col || 12) * 2}${
          props.state.dragItem && isOver === value.id ? " is-over" : ""
        }`}
        switcherIcon={
          value?.children?.length ? (
            !props.state.expandedKeys?.includes(value.id) ? (
              <FolderOutlined />
            ) : (
              <FolderOpenOutlined />
            )
          ) : undefined
        }
        title={
          <>
            <div
              className="drop-tree__node-edit"
              onDragOver={() => props.isDrag && onOver(value.id)}
              onDragLeave={() => props.isDrag && onOver(false)}
              onDrop={() => onCreate(i)}
              style={{ maxHeight: 34 }}
            >
              <span>{value?.i18n && value?.i18n[props.lang]?.title}</span>
              <EditItem
                lang={props.lang}
                treeContent={props.state.treeContent}
                item={value}
                showIcon={props.showIcon}
                onSaved={onSaved}
              />
            </div>
            <DeleteOutlined onClick={() => deleteItem(value?.id)} />
          </>
        }
      >
        {getTreeNode(value?.children)}
      </TreeNode>
    ));

    return treeNode;
  };

  const onCreate = (index) => {
    const { treeContent } = props.state;

    if (isOver && props.state.dragItem) {
      loop(treeContent, isOver, (item, i, arr) => {
        if (item.canNesting) {
          item.children = item.children || [];

          item.children.splice(index, 0, props.state.dragItem);
        } else {
          arr.splice(i + 1, 0, props.state.dragItem);
        }

        return item;
      });

      props.onSubmit(treeContent);
      props.handler({
        dragItem: undefined,
        treeContent,
      });
      onOver(false);
    }
  };

  const onExpand = (expandedKeys) => {
    props.handler({
      expandedKeys: expandedKeys,
    });
  };

  return (
    <>
      {props.connectDropTarget(
        <div className="drop-tree">
          {props.loading ? (
            <Spin className="full-width" />
          ) : props.state.treeContent.length ? (
            <Tree
              draggable
              blockNode
              showLine
              expandedKeys={props.state.expandedKeys}
              onDrop={onDrop}
              onSelect={onNodeSelect}
              onExpand={onExpand}
            >
              {getTreeNode(props.state.treeContent)}
            </Tree>
          ) : (
            <Empty
              image={EmptyData}
              description={<span>Drag & Drop elements here</span>}
            />
          )}
        </div>,
      )}
    </>
  );
};

const spec = {
  drop(props, monitor) {
    const addItem = (Item) => {
      const { treeContent } = props.state;

      treeContent.push({
        id: Item.id,
        data: { order: treeContent.length },
        i18n: Item.i18n,
        edited_timestamp: Item.edited_timestamp,
        children: Item.children_objects,
      });

      props.handler({
        treeContent,
        selectedKey: [...props.state.selectedKey, Item.id],
      });

      props.onSubmit(treeContent);

      props.onDrop();
    };

    addItem(monitor.getItem());
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

export default DropTarget("components", spec, collect)(Drop);
