import { createSvgIcon } from "../utils";

export const Invoices = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1L10.5303 0.46967C10.3897 0.329018 10.1989 0.25 10 0.25V1ZM15 6H15.75C15.75 5.80109 15.671 5.61032 15.5303 5.46967L15 6ZM1 17H0.25H1ZM3 1V0.25V1ZM15 17H15.75H15ZM5 4.25C4.58579 4.25 4.25 4.58579 4.25 5C4.25 5.41421 4.58579 5.75 5 5.75V4.25ZM6 5.75C6.41421 5.75 6.75 5.41421 6.75 5C6.75 4.58579 6.41421 4.25 6 4.25V5.75ZM5 10.25C4.58579 10.25 4.25 10.5858 4.25 11C4.25 11.4142 4.58579 11.75 5 11.75V10.25ZM11 11.75C11.4142 11.75 11.75 11.4142 11.75 11C11.75 10.5858 11.4142 10.25 11 10.25V11.75ZM9 14.25C8.58579 14.25 8.25 14.5858 8.25 15C8.25 15.4142 8.58579 15.75 9 15.75V14.25ZM11 15.75C11.4142 15.75 11.75 15.4142 11.75 15C11.75 14.5858 11.4142 14.25 11 14.25V15.75ZM9.25 1V5H10.75V1H9.25ZM9.25 5C9.25 5.46413 9.43438 5.90925 9.76256 6.23744L10.8232 5.17678C10.7763 5.12989 10.75 5.0663 10.75 5H9.25ZM9.76256 6.23744C10.0908 6.56563 10.5359 6.75 11 6.75V5.25C10.9337 5.25 10.8701 5.22366 10.8232 5.17678L9.76256 6.23744ZM11 6.75H15V5.25H11V6.75ZM13 18.25H3V19.75H13V18.25ZM3 18.25C2.66848 18.25 2.35054 18.1183 2.11612 17.8839L1.05546 18.9445C1.57118 19.4603 2.27065 19.75 3 19.75V18.25ZM2.11612 17.8839C1.8817 17.6495 1.75 17.3315 1.75 17H0.25C0.25 17.7293 0.539731 18.4288 1.05546 18.9445L2.11612 17.8839ZM1.75 17V3H0.25V17H1.75ZM1.75 3C1.75 2.66848 1.8817 2.35054 2.11612 2.11612L1.05546 1.05546C0.539731 1.57118 0.25 2.27065 0.25 3H1.75ZM2.11612 2.11612C2.35054 1.8817 2.66848 1.75 3 1.75V0.25C2.27066 0.25 1.57118 0.539731 1.05546 1.05546L2.11612 2.11612ZM3 1.75H10V0.25H3V1.75ZM9.46967 1.53033L14.4697 6.53033L15.5303 5.46967L10.5303 0.46967L9.46967 1.53033ZM14.25 6L14.25 17H15.75L15.75 6H14.25ZM14.25 17C14.25 17.3315 14.1183 17.6495 13.8839 17.8839L14.9445 18.9445C15.4603 18.4288 15.75 17.7293 15.75 17H14.25ZM13.8839 17.8839C13.6495 18.1183 13.3315 18.25 13 18.25V19.75C13.7293 19.75 14.4288 19.4603 14.9445 18.9445L13.8839 17.8839ZM5 5.75H6V4.25H5V5.75ZM5 11.75H11V10.25H5V11.75ZM9 15.75H11V14.25H9V15.75Z"
      fill="currentColor"
    />
  </svg>,
);
