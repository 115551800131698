import React, { useEffect, useRef } from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import { inputAutoResize } from "utils";
import * as Icons from "components/icons";
import { InviteContext } from "../context";

export const InviteStaffUsers = () => {
  const { invite, setInvite, setUserRoleType } =
    React.useContext(InviteContext);
  const input = useRef<ReactMultiEmail>(null);

  useEffect(() => {
    inputAutoResize(input);
    setUserRoleType(null);
  }, [setUserRoleType]);

  return (
    <>
      <p className="grey-400 mb-8">Add multiple or single email address</p>
      <ReactMultiEmail
        ref={input}
        emails={invite.emails}
        onChange={(value: string[]) =>
          setInvite({
            ...invite,
            emails: value,
            emailsRoles: value.map((e) => ({ email: e })),
          })
        }
        validateEmail={(email) => isEmail(email)}
        getLabel={(email, index, removeEmail) => (
          <div data-tag key={index}>
            {email}
            <span
              className="remove-email"
              data-tag-handle
              onClick={() => removeEmail(index)}
            >
              <Icons.Close size="8px" />
            </span>
          </div>
        )}
      />
    </>
  );
};
