import { capitalizeString } from "utils";

interface Props {
  orderStatus?: string;
}

export const OrderStatus = ({ orderStatus }: Props) => {
  if (!orderStatus) return null;

  return (
    <div className="badge one-line">{capitalizeString(orderStatus, "_")}</div>
  );
};
