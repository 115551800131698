import { AxiosError } from "axios";
import { Form, useForm, useNotify } from "ebs-design";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

import {
  Flex,
  InputFormField,
  InputPasswordShowField,
  LoadingButton,
} from "components";
import { ArrowRight } from "components/icons";
import { useAuth } from "hooks";
import models from "models";
import {
  confirmPasswordValidatorRule,
  makeBEM,
  notifyErrors,
  passwordValidatorRule,
} from "utils";

const bem = makeBEM("auth-layout");

export const ConfirmResetPasswordForm = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const auth = useAuth();
  const history = useHistory();

  const [query] = useQueryParams({
    email: StringParam,
  });

  const loginMutation = useMutation(auth.confirmResetPassword, {
    onSuccess: () => {
      notify.success({
        title: t("notification.password_was_reset_successfully"),
      });
      form.resetFields();
      history.push("/login");
    },
    onError: (error: AxiosError) => notifyErrors(notify, error),
  });

  return (
    <>
      <Form
        form={form}
        onFinish={(values: models.ConfirmResetPassword) =>
          loginMutation.mutate({ ...values, username: query.email as string })
        }
      >
        <InputFormField
          name="code"
          placeholder={t("common.code")}
          type="text"
          hideLabel
          rules={[
            {
              required: true,
              message: t("validation.required_code"),
            },
          ]}
        />
        <InputPasswordShowField
          size="large"
          placeholder={t("common.password")}
          name="password"
          validateTrigger="onSubmit"
          rules={[
            {
              validateTrigger: "onSubmit",
            },
            passwordValidatorRule(t),
          ]}
        />

        <InputPasswordShowField
          size="large"
          placeholder={t("common.confirm_password")}
          name="confirm_password"
          validateTrigger="onSubmit"
          rules={[
            {
              validateTrigger: "onSubmit",
            },
            confirmPasswordValidatorRule(form, t),
          ]}
        />

        <LoadingButton
          size="large"
          className="full-width "
          type="primary"
          submit
          loading={loginMutation.isLoading}
        >
          <Flex justify="center" align="center">
            <span className="mr-13 fw-600">{t("common.submit")}</span>
            <ArrowRight size="14" />
          </Flex>
        </LoadingButton>
        <h4 className="mt-15">
          {t("common.go_to")}{" "}
          <Link to="/login" className={bem("link")}>
            {t("common.login")}
          </Link>
        </h4>
      </Form>
    </>
  );
};
