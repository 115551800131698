import { createSvgIcon } from "../utils";

export const Dashboard = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4V3.25C3.58579 3.25 3.25 3.58579 3.25 4H4ZM10 4H10.75C10.75 3.58579 10.4142 3.25 10 3.25V4ZM10 12V12.75C10.4142 12.75 10.75 12.4142 10.75 12H10ZM4 12H3.25C3.25 12.4142 3.58579 12.75 4 12.75V12ZM4 16V15.25C3.58579 15.25 3.25 15.5858 3.25 16H4ZM10 16H10.75C10.75 15.5858 10.4142 15.25 10 15.25V16ZM10 20V20.75C10.4142 20.75 10.75 20.4142 10.75 20H10ZM4 20H3.25C3.25 20.4142 3.58579 20.75 4 20.75V20ZM14 12V11.25C13.5858 11.25 13.25 11.5858 13.25 12H14ZM20 12H20.75C20.75 11.5858 20.4142 11.25 20 11.25V12ZM20 20V20.75C20.4142 20.75 20.75 20.4142 20.75 20H20ZM14 20H13.25C13.25 20.4142 13.5858 20.75 14 20.75V20ZM14 4V3.25C13.5858 3.25 13.25 3.58579 13.25 4H14ZM20 4H20.75C20.75 3.58579 20.4142 3.25 20 3.25V4ZM20 8V8.75C20.4142 8.75 20.75 8.41421 20.75 8H20ZM14 8H13.25C13.25 8.41421 13.5858 8.75 14 8.75V8ZM4 4.75H10V3.25H4V4.75ZM9.25 4V12H10.75V4H9.25ZM10 11.25H4V12.75H10V11.25ZM4.75 12V4H3.25V12H4.75ZM4 16.75H10V15.25H4V16.75ZM9.25 16V20H10.75V16H9.25ZM10 19.25H4V20.75H10V19.25ZM4.75 20V16H3.25V20H4.75ZM14 12.75H20V11.25H14V12.75ZM19.25 12V20H20.75V12H19.25ZM20 19.25H14V20.75H20V19.25ZM14.75 20V12H13.25V20H14.75ZM14 4.75H20V3.25H14V4.75ZM19.25 4V8H20.75V4H19.25ZM20 7.25H14V8.75H20V7.25ZM14.75 8V4H13.25V8H14.75Z"
      fill="currentColor"
    />
  </svg>,
);
