import { Button } from "ebs-design";

import { NO_VALUE_FALLBACK } from "app-constants";
import {
  Flex,
  TableCheckboxSelector,
  TableCheckbox,
  PaymentStatus,
} from "components";
import * as Icons from "components/icons";
import models from "models";
import {
  capitalizeString,
  formatPrice,
  formattedDateTime,
  TableColumnBuilder,
} from "utils";
import { CheckboxesCheckedInfoType } from "types";

interface PaymentsProps {
  editPaymentModal: React.Dispatch<models.Payment>;
  currency?: models.Currency;
  t?: (str: string) => string;
  setCheckboxInfo: React.Dispatch<
    React.SetStateAction<CheckboxesCheckedInfoType>
  >;
  checkboxInfo?: CheckboxesCheckedInfoType;
}

export const paymentsColumns = ({
  editPaymentModal,
  currency,
  t,
  setCheckboxInfo,
  checkboxInfo,
}: PaymentsProps) =>
  new TableColumnBuilder<models.Payment>()
    .add(
      <TableCheckboxSelector
        word="invoices"
        checkboxInfo={checkboxInfo}
        setCheckboxInfo={setCheckboxInfo}
      />,
      "",
      (row: models.Order) => (
        <TableCheckbox
          itemId={row?.id}
          checkboxInfo={checkboxInfo}
          setCheckboxInfo={setCheckboxInfo}
        />
      ),
    )
    .add(t("common.purchase_id"), "reference")
    .add(t("invoice.invoice_id"), ["invoice", "code"])
    .add(t("order.order_id"), ["order", "code"])
    .add(t("common.seller_id"), ["client", "id"])
    .add(t("common.seller_email"), ["client", "email"])
    .add(
      t("common.service_name"),
      ["order", "services"],
      (services: models.Service[]) => (
        <>
          {services?.map((item) => (
            <p className="one-line">{capitalizeString(item?.code, "_")};</p>
          ))}
        </>
      ),
    )
    .add(
      t("common.amount"),
      "amount",
      (amount?: string) =>
        formatPrice(amount, currency.code) || NO_VALUE_FALLBACK,
    )
    .add(t("common.creation_date"), "timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add(
      t("common.purchase_status"),
      "status",
      (status: models.PaymentStatus) => <PaymentStatus status={status} />,
    )
    .add(
      t("invoice.invoice_status"),
      ["invoice", "status"],
      (status: models.PaymentInvoiceStatus) => (
        <PaymentStatus status={status} />
      ),
    )
    .add("", "", (row) => (
      <Flex justify="flex-end" onClick={(e) => e.stopPropagation()}>
        <Button
          onClick={() => {
            editPaymentModal(row);
          }}
          type="light"
          size="small"
          icon={() => <Icons.Edit size="small" />}
        />
      </Flex>
    ))
    .getColumns();

export const paymentsSortOptions = [
  { title: "Creation date", value: "timestamp" },
  { title: "Amount", value: "amount" },
];
