import { default as queryString } from "query-string";
import { Properties, VersionType } from "types";

type Settings = {
  version?: VersionType;
  api?: string;
};

export const stringifyUrl = (
  url: string,
  query: null | Properties = {},
  params?: Settings,
): string => {
  return queryString.stringifyUrl(
    {
      url: `${
        params?.api
          ? params.api
          : `${process.env.REACT_APP_STATIC_API}/v${params?.version || 1}`
      }/${url}`,
      ...(query && { query }),
    },
    { skipEmptyString: true, skipNull: true, arrayFormat: "comma" },
  );
};

export const defaultOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
};
