import { useRef } from "react";
import {
  PieChart as RePieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  Label,
  Legend,
} from "recharts";
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";
import { DataKey } from "recharts/types/util/types";

import { useScreenResize } from "hooks";
import { makeBEM } from "utils";
import { CustomLabel, CustomLegend } from ".";

const bem = makeBEM("pie-chart");

interface PieChartProps extends CategoricalChartProps {
  dataKey: DataKey<any>;
  tooltip?: TooltipProps<string | number, string | number>["content"];
  trendDirection?: number;
  valueLabel?: number | string;
  resizeValue?: number;
}

export const PieChart: React.FC<PieChartProps> = ({
  tooltip,
  trendDirection,
  valueLabel,
  children,
  resizeValue = 500,
  ...props
}) => {
  const ref = useRef<{ current: HTMLDivElement }>(null);
  const breakPoint = useScreenResize(ref.current?.current, resizeValue);

  return (
    <ResponsiveContainer
      className={bem()}
      width="100%"
      aspect={!breakPoint ? 1.4 : 1}
      maxHeight={600}
      ref={ref}
    >
      <RePieChart>
        <Pie
          innerRadius="60%"
          outerRadius="100%"
          {...props}
          isAnimationActive={false}
        >
          {children}
          <Label
            content={
              <CustomLabel trendDirection={trendDirection} value={valueLabel} />
            }
            position="center"
          />
        </Pie>
        <Tooltip content={tooltip} />
        <Legend
          {...(!breakPoint && {
            verticalAlign: "middle",
            layout: "vertical",
            align: "left",
          })}
          content={<CustomLegend />}
        />
      </RePieChart>
    </ResponsiveContainer>
  );
};
