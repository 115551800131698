import { useAuth } from "hooks";
import models from "models";
import { SupervisorOrders, OperatorOrders } from "../components";

export const Orders = () => {
  //FIXME: temporary solution
  const { roles } = useAuth();
  const isWarehouseSupervisor = roles.some(
    (role) => role.name === models.Roles.WAREHOUSE_SUPERVISOR,
  );

  return isWarehouseSupervisor ? <SupervisorOrders /> : <OperatorOrders />;
};
