import { useEffect, useState } from "react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { notification, Spin } from "antd";
import { ArticleForm } from "apps/cms/components/Articles";
import api, { Article } from "apps/cms/lib/api/pages";

enum Operation {
  CREATE = "create",
  EDIT = "edit",
}

const EditPage: React.FC = () => {
  const history = useHistory();
  const { id, tab } = useParams<{ id: string; tab: Operation }>();
  const [article, setArticle] = useState<Article>();

  useEffect(() => {
    if (id && tab === Operation.EDIT) {
      try {
        api.bySlug(id).then(setArticle);
      } catch (e) {
        history.push("/cms/pages");

        return notification.error({ message: "Articol inexistent" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tab]);

  switch (tab) {
    case Operation.CREATE:
      return <ArticleForm onSaved={() => history.push("/cms/pages")} />;
    case Operation.EDIT:
      return (
        <Spin spinning={!article} delay={300}>
          {article && (
            <ArticleForm
              initialArticle={article}
              onSaved={() => history.push("/cms/pages")}
            />
          )}
        </Spin>
      );

    default:
      return <Redirect to="/cms/pages" />;
  }
};

export default EditPage;
