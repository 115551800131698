import models from "models";
import { GenericObject } from "types";

import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const devices = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/devices", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Device>>(url);
    return data;
  },
  getById: async (id: number) => {
    const { data } = await apiAxios.get<models.Device>(`/devices/${id}`);
    return data;
  },
  reject: async (id: number, body: models.DeviceReason) => {
    const { data } = await apiAxios.post<models.Device>(
      `/devices/${id}/reject`,
      body,
    );
    return data;
  },
  accept: async (id: number, body: models.DeviceReason) => {
    const { data } = await apiAxios.post<models.Device>(
      `/devices/${id}/accept`,
      body,
    );
    return data;
  },
  recycle: async (id: number, body: models.RecycleDevice) => {
    const { data } = await apiAxios.post<models.RecycleDevice>(
      `/devices/${id}/recycle`,
      body,
    );
    return data;
  },
  recycleCertificate: async (id: number) => {
    const { data } = await apiAxios.post<void>(
      `/devices/${id}/recycle-certificate`,
    );
    return data;
  },
  updatePrice: async (id: number, body: models.DeviceReason) => {
    const { data } = await apiAxios.post(`/devices/${id}/price-update`, body);
    return data;
  },
  priceUpdateAccept: async (id: number) => {
    const { data } = await apiAxios.post(`/devices/${id}/price-update/accept`);
    return data;
  },
  diagnosticCriteria: {
    get: async (id: number) => {
      const { data } = await apiAxios.get<
        models.WithResults<models.DiagnosticCriteria>
      >(`/device/${id}/diagnostic-criteria`);
      return data;
    },
    getById: async (deviceId: number, diagnosticCriteriaId: number) => {
      const { data } = await apiAxios.get<models.DiagnosticCriteria>(
        `/device/${deviceId}/diagnostic-criteria/${diagnosticCriteriaId}`,
      );
      return data;
    },
    put: async (
      deviceId: number,
      diagnosticCriteriaId: number,
      body: models.DiagnosticCriteria,
    ) => {
      const { data } = await apiAxios.put<models.DiagnosticCriteria>(
        `/device/${deviceId}/diagnostic-criteria/${diagnosticCriteriaId}`,
        body,
      );
      return data;
    },
    putIsCorrect: async (
      deviceId: number,
      diagnosticCriteriaId: number,
      body: models.DiagnosticCriteria,
    ) => {
      const { data } = await apiAxios.put<models.DiagnosticCriteria>(
        `/device/${deviceId}/diagnostic-criteria/${diagnosticCriteriaId}/correct`,
        body,
      );
      return data;
    },
  },
};
