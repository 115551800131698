import models from "models";
import { GenericObject } from "types";

import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const dropOffLocations = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/drop-off-locations", queryParams);
    const { data } = await apiAxios.get<
      models.WithResults<models.DropOffLocation>
    >(url);
    return data;
  },
  getById: async (id: number) => {
    const { data } = await apiAxios.get<models.DropOffLocation>(
      `/drop-off-locations/${id}`,
    );
    return data;
  },
  post: async (body: models.DropOffLocation) => {
    const { data } = await apiAxios.post<models.DropOffLocation>(
      `/drop-off-locations`,
      body,
    );
    return data;
  },
  patch: async (id: number, body: models.DropOffLocation) => {
    const { data } = await apiAxios.patch<models.DropOffLocation>(
      `/drop-off-locations/${id}`,
      body,
    );
    return data;
  },
  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/drop-off-locations/${id}`);
    return data;
  },
};
