export const delay = (timeout: number) =>
  new Promise((r) => setTimeout(r, timeout));

export const apiCallWithData =
  (data: { value: string; text: string }[]) =>
  ({ search }: { search: string }) =>
    Promise.resolve({
      results: data.filter(({ text }) =>
        text.toLowerCase().includes(search.toLowerCase()),
      ),
    });
