import { Button, Card, useNotify } from "ebs-design";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { ArrayParam, useQueryParams } from "use-query-params";

import api, { querykeys } from "api";
import { isDisplayedStatus } from "app-constants";
import {
  ConfirmationModal,
  Flex,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import { ChoiceFilter, Filters } from "components/filters";
import {
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";
import { forwardSetState, notifyErrors } from "utils";

import { dropOffLocationsColumns, dropOfLocationSortOptions } from "../config";

export const DropOffLocations = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const deleteDropOffLocationsModal = useModalState<string | number>();
  const [search, setSearch] = React.useState("");
  const pagination = useQueryPagination();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const [ordering, setOrdering] = useOrderingQueryParam();
  const [filterParams, setFilterParams] = useQueryParams({
    is_active: ArrayParam,
  });

  const queryParams = {
    ...pagination.queryParams,
    search,
    ...filterParams,
    ordering,
  };

  const query = useQuery(querykeys.dropOffLocations.many(queryParams), () =>
    api.dropOffLocations.get(queryParams),
  );

  const mutation = useMutation(
    (id: number) => api.dropOffLocations.delete(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.dropOffLocations.many());
        notify.success({
          title: t("template.item_deleted_successfully", {
            item: t("common.drop_off_location"),
          }),
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const tableColumns = dropOffLocationsColumns({
    deleteDropOffLocationModal: deleteDropOffLocationsModal.openWith,
    t,
  });

  return (
    <>
      <PageTitle
        actions={
          <Button
            type="primary"
            onClick={() => history.push("/warehouse/drop-off-locations/create")}
          >
            {t("template.add_item", { item: t("common.drop_off_location") })}
          </Button>
        }
        title={t("menu.drop_off_locations")}
        count={query.data?.count}
      />

      <Card>
        <Card.Body>
          <QueryTable
            variants={["highlight-hover"]}
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                  sortOptions={dropOfLocationSortOptions}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                  filters={
                    <Filters
                      queryParams={filterParams}
                      setQueryParams={setFilterParams}
                    >
                      <ChoiceFilter
                        value={filterParams.is_active}
                        setValue={forwardSetState(setFilterParams, "is_active")}
                        title={t("common.status")}
                        choice={Object.values(["true", "false"]).map(
                          (is_active) => ({
                            key: is_active,
                            value: isDisplayedStatus[is_active],
                          }),
                        )}
                      />
                    </Filters>
                  }
                />
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            onRow={(data) => ({
              onClick: () =>
                history.push(`/warehouse/drop-off-locations/edit/${data?.id}`),
            })}
            rowKey="id"
          />
        </Card.Body>
      </Card>

      {deleteDropOffLocationsModal.isOpen && (
        <ConfirmationModal
          open={deleteDropOffLocationsModal.isOpen}
          onClose={deleteDropOffLocationsModal.close}
          onConfirm={() =>
            mutation.mutate(Number(deleteDropOffLocationsModal.data) || 0)
          }
          isLoading={mutation.isLoading}
          title={t(
            "template.delete_item",
            t("common.drop_off_location").toLowerCase(),
          )}
          confirmText={t("common.delete")}
        >
          <p>
            {`${t("template.are_you_sure_you_want_to_delete_this_item", {
              item: t("common.drop_off_location").toLowerCase(),
            })} - ${deleteDropOffLocationsModal.data}?`}
          </p>
        </ConfirmationModal>
      )}
    </>
  );
};
