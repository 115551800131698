import { Button, Tooltip } from "ebs-design";
import { ButtonProps } from "ebs-design/dist/components/atoms/Button/Button";
import { TooltipProps } from "ebs-design/dist/components/atoms/Tooltip/Tooltip";

export interface TooltipButtonProps extends ButtonProps {
  tooltip?: TooltipProps;
}

export const TooltipButton: React.FC<TooltipButtonProps> = ({
  tooltip,
  ...props
}) => {
  return tooltip ? (
    <Tooltip {...tooltip} className="tooltip-button">
      <Button {...props} />
    </Tooltip>
  ) : (
    <Button {...props} />
  );
};
