import models from "models";

export interface Payment {
  id?: number;
  order?: PaymentOrder;
  timestamp?: string;
  status?: PaymentStatus;
  attachment?: models.Attachment;
  client?: models.PaymentClient;
  amount?: string;
  invoice?: PaymentInvoice;
  currency?: string;
  reference?: string;
}

export interface PaymentClient {
  id?: string;
  email?: string;
}
export interface PaymentInvoice {
  id?: number;
  code?: string;
  status?: PaymentInvoiceStatus;
  attachment?: models.Attachment;
  type?: PaymentInvoiceType;
}

export interface PaymentOrder {
  id?: number;
  code?: string;
  service?: models.Service;
}

export enum PaymentInvoiceType {
  PAYMENT = "PAYMENT",
  PAYOUT = "PAYOUT ",
}

export enum PaymentStatus {
  CREATED = "CREATED",
  SUCCEEDED = "SUCCEEDED",
  PROCESSING = "PROCESSING",
  CANCELED = "CANCELED",
  FAILED = "FAILED",
}

export enum PaymentInvoiceStatus {
  UNPAID = "UNPAID",
  DRAFT = "DRAFT",
  PAID = "PAID",
  VOID = "VOID",
  OPEN = "OPEN",
  UNCOLLECTIBLE = "UNCOLLECTIBLE",
}
