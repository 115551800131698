import { NoImage } from "assets";

export const onErrorImage = (
  e?: React.SyntheticEvent<HTMLImageElement, Event>,
): void => {
  if (e) {
    e.currentTarget.className = [e.currentTarget.className, "no-image"].join(
      " ",
    );
    e.currentTarget.src = NoImage;
  }
};

export function getBase64(
  img: any,
  callback: (value: string | ArrayBuffer | null) => void,
) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
