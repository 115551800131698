import { NumberParam, useQueryParams } from "use-query-params";
import { Flex, PageTitle } from "components";
import { useTranslation } from "react-i18next";
import * as queryString from "query-string";

import { CommaArrayParam } from "utils";
import { SuccessBrandCard } from "../components";

export const BrandsSuccessConnected = () => {
  const { t } = useTranslation();

  const [brandParams] = useQueryParams({
    brand__id: NumberParam,
    brand__id__in: CommaArrayParam,
  });
  const { brand__id, brand__id__in } = brandParams;

  const url = queryString.stringifyUrl(
    {
      url: "/product-management/models/add-models",
      query: brand__id__in ? { brand__id__in } : { brand__id },
    },
    {
      arrayFormat: "comma",
    },
  );

  return (
    <>
      <PageTitle>
        {t("template.add_item", { item: t("brand.brands").toLowerCase() })}
      </PageTitle>

      <Flex align="center" className="success-brand-card-wrapper">
        <SuccessBrandCard
          type="brands"
          message={t("template.you_have_successfully_added_a_new_item", {
            item: t("brand.brands").toLowerCase(),
          })}
          redirect={{
            url,
            title: t("brand.add_model_for_this_brand"),
          }}
        />
      </Flex>
    </>
  );
};
