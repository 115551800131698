import { Button } from "ebs-design";
import { Col, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { Properties } from "types";
import * as Icons from "components/icons";

interface PageActionsProps {
  type?: string;
  menu: Properties;
  onRemove: (menu: Properties) => void;
  vizualization?: boolean;
}

/**
 * `ArticleActions` are used in `Article table`.
 * Containing 3 buttons: edit, remove and view.
 *
 * `Edit` button will redirect user to `/pages/edit/id`
 * `Delete` button will remove data and trigger refetch table content.
 * `View` button is a link to the article for preview.
 */
const MenuActions: React.FC<PageActionsProps> = ({
  menu,
  onRemove,
  type = "menu",
}) => {
  const history = useHistory();

  return (
    <Row gutter={[10, 10]} justify="end" className="flex-no-wrap">
      <Col>
        <Button
          icon={() => <Icons.Edit size="small" />}
          type="light"
          onClick={() => history.push(`/cms/${type}/edit/${menu.id}`)}
        />
      </Col>

      <Col>
        <Button
          type="light"
          icon={() => <Icons.Trash size="small" />}
          onClick={() => onRemove(menu)}
        />
      </Col>
    </Row>
  );
};

export default MenuActions;
