import { KeyMessages, Properties } from "types";
import ns1 from "translation/languages/en.json";

export const getMissingTranslationsKeys = (i18n: Properties) => {
  const keys: Properties = {};

  Object.keys(i18n)
    .filter((i) => i !== "no")
    .forEach((lang) => {
      let data = i18n[lang as keyof typeof i18n];

      if (typeof data === "string") {
        data = JSON.parse(data);
      }

      if (!keys[lang]) {
        keys[lang] = 0;
      }

      if (
        data[0] &&
        [KeyMessages.TRANSLATIONS, KeyMessages.FIELD].includes(data[0])
      ) {
        keys[lang] = keys[lang] + 1;
      } else {
        keys[lang] =
          keys[lang] + !Object.keys(data).length && i18n?.en
            ? Object.keys(i18n.en).length
            : Object.keys(data).filter((key) => {
                const check = data[key as keyof typeof data];

                return (
                  check?.includes(`[${lang}]`) ||
                  [KeyMessages.TRANSLATIONS, KeyMessages.FIELD]?.some((rule) =>
                    rule.includes(check),
                  )
                );
              }).length;
      }
    });

  return keys;
};

export const normalizeForTranslation = (str: string) =>
  str?.toLowerCase().replaceAll(" ", "_").replaceAll("-", "_");

export const translateIfIsTranslatable = (
  translationFunction: (str: string) => string,
  expression: string,
  key: keyof typeof ns1 | "all",
) => {
  //if expression is not translatable, return it as is
  if (typeof expression !== "string") return expression;

  if (key === "all") {
    //go through all object try to translate and stop at first match
    let translation = expression;
    Object.keys(ns1).every((key) => {
      if (
        !translationFunction(
          `${key}.${normalizeForTranslation(expression)}`,
        ).includes(`${key}.`)
      ) {
        translation = translationFunction(
          `${key}.${normalizeForTranslation(expression)}`,
        );
        return false;
      }
      return true;
    });
    return translation;
  }

  return !translationFunction(
    `${key}.${normalizeForTranslation(expression)}`,
  ).includes(`${key}.`)
    ? translationFunction(`${key}.${normalizeForTranslation(expression)}`)
    : expression;
};
