import React from "react";
import { Form, Input } from "antd";
import { Properties } from "types";

import { formItemStyle } from "../helpers";

const TextCard = ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "title"]}
        label="Title"
        style={formItemStyle}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "subtitle"]}
        label="Subtitle"
        style={formItemStyle}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "description"]}
        label="Description"
        style={formItemStyle}
      >
        <Input.TextArea />
      </Form.Item>
    </>
  );
};

export default TextCard;
