import { createSvgIcon } from "../utils";

export const Check = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 6L6 11L16 1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
