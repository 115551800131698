import { Card, useNotify } from "ebs-design";
import { useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import api, { querykeys } from "api";
import { PageTitle, Table } from "components";
import { LanguagesContext } from "apps/cms/context/Languages";
import models from "models";
import { notifyErrors } from "utils";
import { languageColumns } from "../config";

export const LanguageSettings = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    languages: { data: availableLanguages },
  } = useContext(LanguagesContext);

  const { data: activeLanguages } = useQuery(
    querykeys.settings.defaultSettings,
    api.settings.defaultSettings,
    {
      select: (data) => data?.data?.LANGUAGES,
    },
  );

  const { mutate, isLoading } = useMutation(
    querykeys.settings.defaultSettings,
    (body: models.DefaultSettingsData) =>
      api.settings.updateDefaultSettings(body),
    {
      onSuccess: () => {
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("common.languages"),
          }),
        });
        queryClient.invalidateQueries(querykeys.settings.defaultSettings);
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const dataLanguage = availableLanguages
    ?.map((language) => ({
      id: language.id,
      language: models.Language[language.code],
      code: language.code,
      is_active: activeLanguages?.includes(
        language.code as models.LanguageCode,
      ),
      is_default: language.code === models.LanguageCode.EN,
    }))
    .sort((a, b) => (a.is_active ? -1 : 0));

  const handleChange = (
    lang: typeof models.Language[keyof typeof models.Language],
  ) => {
    let languageKey = Object.keys(models.Language)[
      Object.values(models.Language).indexOf(lang)
    ];

    const body = {
      LANGUAGES: (activeLanguages?.includes(languageKey as models.LanguageCode)
        ? activeLanguages.filter((lang) => lang !== languageKey)
        : [...activeLanguages, languageKey]) as models.LanguageCode[],
    };
    mutate(body);
  };

  return (
    <>
      <PageTitle>{t("common.languages")}</PageTitle>
      <Card>
        <Card.Body>
          <Table
            data={dataLanguage}
            columns={languageColumns(
              t,
              handleChange,
              isLoading,
              history.location.search,
            )}
            onRow={(data) => ({
              onClick: () =>
                history.push({
                  pathname: "/language-settings",
                  search: `?lang=${data.code}`,
                }),
            })}
          />
        </Card.Body>
      </Card>
    </>
  );
};
