import $axios from "axios";

import { API_POST_CONTENT_TYPE } from "app-constants";
import { getXAPIKey } from "./utils";
import { languageInterceptor } from "./interceptors";

export const authAxios = $axios.create();

if (!authAxios.defaults.headers.common) authAxios.defaults.headers.common = {};
authAxios.defaults.headers.common["Content-Type"] = API_POST_CONTENT_TYPE;

const apiKey = getXAPIKey();
if (apiKey) authAxios.defaults.headers.common["X-API-Key"] = apiKey;

authAxios.defaults.baseURL = process.env.REACT_APP_BASE_API;
authAxios.defaults.withCredentials = true;
authAxios.interceptors.request.use(languageInterceptor);
