export const iconList = [
  "arrow-narrow-left",
  "arrow-narrow-right",
  "check",
  "chevron-down",
  "chevron-right",
  "chevron-top",
  "clock",
  "close",
  "dots",
  "eraser",
  "eye-off",
  "help",
  "house",
  "info-circle",
  "license",
  "link",
  "map-pins",
  "phone",
  "plus",
  "minus",
  "printer",
  "search",
  "trash",
  "user",
  "warehouse",
  "play",
  "flag",
  "bell",
  "secure",
  "archive",
  "google-play",
  "facebook",
  "instagram",
  "linkedin",
  "twitter",
  "apple",
  "htc",
  "huawei",
  "lg",
  "mi",
  "nokia",
  "oneplus",
  "pixel",
  "samsung",
  "sony",
  "co2",
  "sync",
];
