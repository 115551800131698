import { cmsAxios } from "api/axios";
import { stringifyUrl } from "utils";

export enum MenuType {
  HEADER = "header",
  FOOTER = "footer",
}

const menu = {
  getMenu: async (params: any) => {
    const { data } = await cmsAxios.get(
      stringifyUrl(`menu/`, params, { version: 2 }),
    );

    return data;
  },
  getMenuById: async (id: string | number) => {
    const { data } = await cmsAxios.get(
      stringifyUrl(`menu/${id}/`, {}, { version: 2 }),
    );

    return data;
  },
  addMenu: async (params: any) => {
    const { data } = await cmsAxios.post(
      stringifyUrl("menu/", {}, { version: 2 }),
      params,
    );

    return data;
  },
  editMenu: async (params: any, id: string | number) => {
    const { data } = await cmsAxios.patch(
      stringifyUrl(`menu/${id}/`, {}, { version: 2 }),
      params,
    );

    return data;
  },
  deleteMenu: async (id: string | number) => {
    const { data } = await cmsAxios.delete(
      stringifyUrl(`menu/${id}/`, {}, { version: 2 }),
    );

    return data;
  },
};

export default menu;
