import { Button, Form, Modal, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { NumberParam } from "use-query-params";

import api, { querykeys } from "api";
import { Flex, LoadingButton, WhiteSpace } from "components";
import models from "models";
import { capitalizeString, notifyErrors } from "utils";
import { useParam } from "hooks";

interface ChangeOrdersStatusModalProps extends ModalProps {
  data?: models.TypeWithIds;
  onClose: () => void;
}
export const ChangeOrdersStatusModal = ({
  data,
  onClose,
  ...props
}: ChangeOrdersStatusModalProps) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const orderId = useParam("orderId", NumberParam.decode) || 0;

  //array of strings to array of numbers
  const ids = data?.ids.map((id) => Number(id));
  const isOneOrder = ids.length === 1;

  const { mutate, isLoading } = useMutation(
    () => api.orders.patchStatusBulk(ids, data?.status),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.orders.one(orderId));
        queryClient.invalidateQueries(querykeys.orders.many());
        notify.success({
          title: t("template.item_approved_successfully", {
            item: isOneOrder ? t("order.order") : t("order.orders"),
          }),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Modal
      title={`${t("common.change_status")} - ${capitalizeString(
        data?.status,
        "_",
      )}`}
      size="small"
      onClose={onClose}
      className="edit-service-modal"
      {...props}
    >
      <Form name="order" onFinish={mutate} className="approve-form">
        <Modal.Content>
          <p>
            {t("order.you_will_change_status_for_this_orders")}
            <span className="fw-500">({data.ids.sort().join(", ")})</span>
          </p>
        </Modal.Content>

        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton loading={isLoading} submit type="primary">
              {t("common.change")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
