import { createSvgIcon } from "../utils";

export const TrendUp = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.13642 7.80301C0.843525 8.0959 0.843525 8.57077 1.13642 8.86367C1.42931 9.15656 1.90418 9.15656 2.19708 8.86367L1.13642 7.80301ZM5.66675 4.33334L6.19708 3.80301C5.90418 3.51011 5.42931 3.51011 5.13642 3.80301L5.66675 4.33334ZM8.33342 7L7.80309 7.53033C8.09598 7.82323 8.57085 7.82323 8.86375 7.53033L8.33342 7ZM13.6667 1.66667H14.4167C14.4167 1.25246 14.081 0.916672 13.6667 0.916672V1.66667ZM9.00008 0.916672C8.58587 0.916672 8.25008 1.25246 8.25008 1.66667C8.25008 2.08089 8.58587 2.41667 9.00008 2.41667V0.916672ZM12.9167 6.33334C12.9167 6.74755 13.2525 7.08334 13.6667 7.08334C14.081 7.08334 14.4167 6.74755 14.4167 6.33334H12.9167ZM2.19708 8.86367L6.19708 4.86367L5.13642 3.80301L1.13642 7.80301L2.19708 8.86367ZM5.13642 4.86367L7.80309 7.53033L8.86375 6.46967L6.19708 3.80301L5.13642 4.86367ZM8.86375 7.53033L14.1971 2.197L13.1364 1.13634L7.80309 6.46967L8.86375 7.53033ZM9.00008 2.41667H13.6667V0.916672H9.00008V2.41667ZM12.9167 1.66667V6.33334H14.4167V1.66667H12.9167Z"
      fill="currentColor"
    />
  </svg>,
);
