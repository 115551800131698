import { Card, Col, Row } from "ebs-design";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

import api, { querykeys } from "api";
import { CURRENCY_CODE_FALLBACK } from "app-constants";
import { Flex, SettingsCard, Status } from "components";
import * as Icons from "components/icons";
import models from "models";
import { formatPrice } from "utils";

export interface ServiceManagementCardProps {
  organizationData?: models.OrganizationSettings;
}

const serviceIconMap: Record<models.ServiceCode, React.ReactNode> = {
  [models.ServiceCode.CERTUS_SOFTWARE_DATA_ERASURE]: <Icons.Box />,
  [models.ServiceCode.SECURE_BOX]: <Icons.Phone />,
};

export const ServiceManagementCard = ({
  organizationData,
}: ServiceManagementCardProps) => {
  const { t } = useTranslation();

  const { data } = useQuery(querykeys.settings.service.many(), () =>
    api.settings.services(),
  );
  const currency = organizationData?.currency?.code || CURRENCY_CODE_FALLBACK;

  return (
    <Card className="full-height">
      <Card.Header>
        <Flex align="center" justify="space-between">
          <h3>{t("common.extra_service_management")}</h3>
          <Link className="fw-500" to="/settings/service-management">
            {t("common.edit")}
          </Link>
        </Flex>
      </Card.Header>
      <Card.Body>
        <Row g={3}>
          {data?.results.map((service) => (
            <Col key={service.id} size={12} sm={6} lg={12} xxl={6}>
              <SettingsCard
                title={service.title}
                description={
                  <span className="grey-400">
                    {formatPrice(service.price, currency) || ""}
                  </span>
                }
                icon={serviceIconMap[service.code]}
                imageUrl={service.image?.url}
                children={<Status status={service.is_active} />}
              />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};
