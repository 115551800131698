export const capitalizeString = (
  string: string | undefined,
  split?: string,
  fallback = "-",
) => {
  if (!string) return fallback;

  let sentence = string.toLowerCase();
  let titleCaseSentence =
    sentence.charAt(0).toUpperCase() + sentence.substring(1, sentence.length);
  if (split) {
    return titleCaseSentence.split(split).join(" ");
  } else {
    return titleCaseSentence;
  }
};

export const capitalizeStringWithSplit = <T>(
  arg: T[] | undefined,
  key: (keyof T)[],
  split: string,
) => {
  if (!arg) return [];

  const result = arg.map((item: T) => {
    const rewriteObject = key.reduce((prev, next) => {
      if (!item[next]) return {};
      prev[next as string] = capitalizeString(item[next as string], split);
      return prev;
    }, {});
    return { ...item, ...rewriteObject };
  });

  return result;
};
