import { mixins } from "./mixins";

export const querykeys = {
  profile: "profile",
  settings: {
    organization: "organization-settings",
    defaultSettings: "default-settings",
    diagnostic: { many: mixins.many("settings-diagnostic-criteria") },
    service: {
      many: mixins.many("extra-services"),
    },
    fees: {
      many: mixins.many("organization-fees"),
    },
    bankingDetails: {
      many: mixins.many("banking-details"),
    },
  },
  people: {
    many: mixins.many("people"),
  },
  warehouses: {
    one: mixins.one("warehouse"),
    many: mixins.many("warehouses"),
  },
  orders: {
    one: mixins.one("order"),
    many: mixins.many("orders"),
  },
  devices: {
    one: mixins.one("device"),
    many: mixins.many("devices"),
    diagnosticCriteria: {
      one: mixins.one("diagnostic-criteria"),
    },
  },
  clients: {
    many: mixins.many("clients"),
  },
  staff: {
    many: mixins.many("staff"),
  },
  deletedUsers: {
    many: mixins.many("deleted-users"),
  },
  notification: {
    many: mixins.many("notifications"),
  },
  nomenclature: {
    departments: mixins.many("departments"),
    roles: mixins.many("roles"),
    warehouses: mixins.many("warehouses"),
    applicationList: mixins.many("application-list"),
    countries: mixins.many("countries"),
    cities: mixins.many("cities"),
    currency: { many: mixins.many("currencies") },
    fees: { many: mixins.many("fees") },
    statistics: {
      all: mixins.many("all-statistics"),
      payout: mixins.many("payout"),
      order: mixins.many("order"),
      payment: mixins.many("payment"),
      orderTotalByStatus: mixins.many("order-total-by-status"),
      orderTotalByBrandModel: mixins.many("order-total-by-brand-model"),
      payoutPayment: mixins.many("payout-payment"),
      customer: mixins.many("customer"),
      departments: mixins.many("departments"),
      dropProcessLocation: mixins.many("drop-process-location"),
    },
    brands: {
      many: mixins.many("brands-nomenclatures"),
    },
    models: {
      many: mixins.many("brands-nomenclatures"),
    },
    orderStatus: {
      many: mixins.many("order-status-nomenclatures"),
    },
  },
  attachments: {
    many: mixins.many("attachments"),
    one: mixins.one("attachment"),
  },
  departments: {
    many: mixins.many("departments"),
  },
  brands: {
    many: mixins.many("brands"),
    one: mixins.one("brand"),
    diagnostic: { many: mixins.many("brand-criteria") },
  },
  brandsModels: {
    many: mixins.many("brands-models"),
    one: mixins.one("brand-model"),
    diagnostic: { many: mixins.many("brand-model-criteria") },
  },
  dropOffLocations: {
    many: mixins.many("drop-off-location"),
    one: mixins.one("drop-off-location"),
  },
  inventory: {
    many: mixins.many("inventories"),
    one: mixins.one("inventory"),
    export: {
      one: mixins.one("inventory-exports"),
    },
  },
  payments: {
    many: mixins.many("payments"),
    one: mixins.one("payment"),
  },
  payouts: {
    many: mixins.many("pay-outs"),
    one: mixins.one("pay-out"),
  },
  shippingProviders: {
    many: mixins.many("shipping-providers"),
  },
  paymentMethods: {
    many: mixins.many("payment-methods"),
  },
  crmOrders: {
    many: mixins.many("orders-list"),
    one: mixins.one("orders-list-by-id"),
  },
  cms: {
    banners: {
      many: mixins.many("banners"),
      one: mixins.one("banner"),
    },
  },
  recyclingCertificates: {
    many: mixins.many("recycling-certificates"),
    one: mixins.one("recycling-certificates"),
  },
};
