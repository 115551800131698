import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import {
  Divider,
  Flex,
  Grid,
  InputFormField,
  LanguageTabs,
  LoadingButton,
  SwitchFormField,
  WhiteSpace,
} from "components";
import models from "models";
import { CurrencySymbolType } from "types";
import { notifyErrors } from "utils";
interface EditFeesModalProps extends ModalProps {
  currency: CurrencySymbolType;
  data?: models.Fees;
  onClose: () => void;
}
export const EditFeesModal = ({
  currency,
  data,
  onClose,
  ...props
}: EditFeesModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [translationFields, setTranslationFields] = useState(data.i18n);

  const { mutate, isLoading } = useMutation(
    (body: models.Fees) => api.settings.updateFees(data?.id || 0, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.settings.fees.many());
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("fee.fees"),
          }),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const [canEditPriceTranslation, setCanEditPriceTranslation] = useState(
    Number(data?.price) === 0,
  );

  return (
    <Modal
      header={<h3>{t("fee.return_fee_charge_verification_fee")}</h3>}
      size="small"
      onClose={onClose}
      className="edit-service-modal"
      {...props}
    >
      <Form
        form={form}
        name="fees"
        onValuesChange={(values) =>
          setTranslationFields((prevState) => ({
            ...prevState,
            ...values.i18n,
          }))
        }
        onFinish={(values) =>
          mutate({
            ...data,
            ...values,
            i18n: {
              ...translationFields,
              ...values.i18n,
            },
          })
        }
        className="service-form"
        initialValues={data}
      >
        <Modal.Content>
          <LanguageTabs>
            {(language) => (
              <>
                <InputFormField
                  name={["i18n", language, "title"]}
                  label={t("fee.fee_name")}
                />
                <InputFormField
                  name={["i18n", language, "description"]}
                  label={t("common.description")}
                  textarea
                />
              </>
            )}
          </LanguageTabs>
          <Divider space={16} />
          <Grid cols="160px 1fr" gap="16px">
            <InputFormField
              getValueFromEvent={(price) => {
                setCanEditPriceTranslation(
                  parseInt(price) === 0 || price === "",
                );
                return price;
              }}
              name="price"
              label={t("price.price")}
              prefix={<span className="fw-600">{currency}</span>}
            />
            <InputFormField
              disabled={!canEditPriceTranslation}
              name="price_translation"
              label={t("price.price_translation")}
            />
          </Grid>
          <Divider space={16} />
          <SwitchFormField
            labelOptions={{ col: 1 }}
            controlOptions={{
              col: 1,
              justify: "end",
              className: "d-flex",
            }}
            name="is_active"
            label={t("common.active")}
          />
        </Modal.Content>

        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton loading={isLoading} submit type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
