import React from "react";
import models from "models";

interface Users {
  emails: string[];
  emailsRoles: models.EmailRole[];
}
export interface InviteProps {
  invite: Users;
  setInvite: React.Dispatch<React.SetStateAction<Users>>;
  userRoleType: models.RoleType;
  setUserRoleType: React.Dispatch<React.SetStateAction<models.RoleType | null>>;
}

export const InviteContext = React.createContext<InviteProps>({
  invite: { emails: [], emailsRoles: [] },
  setInvite: () => [],
  userRoleType: null,
  setUserRoleType: () => [],
});
