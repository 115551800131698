import cn from "classnames";
import { Card } from "ebs-design";
import { useTranslation } from "react-i18next";

import { NO_VALUE_FALLBACK } from "app-constants";
import { Flex, Status } from "components";
import { useAuth } from "hooks";
import models from "models";
import {
  capitalizeString,
  formattedDateTime,
  formatPrice,
  makeBEM,
} from "utils";

const bem = makeBEM("model-card");
export interface ModelCardProps {
  data: models.ExtendedOrganizationBrandModel;
}

export const ModelCard = ({ data }: ModelCardProps) => {
  const { t } = useTranslation();
  const { organization } = useAuth();

  return (
    <Card>
      <Card.Body>
        <Flex align="center" className={bem()}>
          <div className={bem("featured-image")}>
            {data?.logo?.url ? (
              <img src={data?.logo?.url} alt="" />
            ) : (
              t("common.no_image")
            )}
          </div>
          <div className={cn(bem("brand-tables"), "ml-16")}>
            <table className="brand-table mb-16">
              <thead>
                <tr>
                  <th>{t("common.status")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Status status={data?.is_active} />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="brand-table mb-16">
              <thead>
                <tr>
                  <th className="model-table-head">{t("brand.brand_id")}</th>
                  <th className="model-table-head">{t("brand.brand_name")}</th>
                  <th className="model-table-head">{t("model.model_name")}</th>
                  <th className="model-table-head">
                    {t("device.device_type")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data?.brand_model_id}</td>
                  <td>{data?.brand?.title}</td>
                  <td>{data?.title}</td>
                  <td>
                    {data?.type
                      ? capitalizeString(data.type)
                      : NO_VALUE_FALLBACK}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="brand-table">
              <thead>
                <tr>
                  <th className="model-table-head">{t("common.storage")}</th>
                  <th className="model-table-head">{t("common.colors")}</th>
                  <th className="model-table-head">{t("price.max_price")}</th>
                  <th className="model-table-head">
                    {t("common.creation_date")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data?.storage_capacity || NO_VALUE_FALLBACK}</td>
                  <td>{data?.colors.join(", ") || NO_VALUE_FALLBACK}</td>
                  <td>
                    {formatPrice(data?.max_price, organization?.currency?.code)}
                  </td>
                  <td>{formattedDateTime(data?.timestamp)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Flex>
      </Card.Body>
    </Card>
  );
};
