import React from "react";
import { useSetState } from "react-use";
import { Tree, Row, Col } from "antd";
import {
  DragOutlined,
  FolderOpenOutlined,
  FolderOutlined,
} from "@ant-design/icons";
import { Properties } from "types";

import { StateType } from "../..";
import Item from "./Item";
import treeContent from "./Fields";

const TreeNode = Tree.TreeNode;
const selectedKeys = localStorage.getItem("selectedKeys");

export interface DragProps {
  name?: string;
  state: StateType;
  handler: (values: Properties) => void;
  onDrag: () => void;
  onDrop: () => void;
}

const Drag: React.FC<DragProps> = (props) => {
  const [state, setState] = useSetState({
    selectedKeys: selectedKeys ? JSON.parse(selectedKeys) : [],
    treeContent,
  });

  const onMouseDown = (dragType) => {
    props.handler({ dragType });
    props.onDrag();
  };

  const getTreeNode = (treeContent) => {
    if (!treeContent || treeContent.length === 0) {
      return null;
    }

    const treeNode = treeContent.map((value) => {
      const disabled = !!props.state.treeContent.find(
        (item) => item.type === value.type && !value.isMultiple,
      );

      return (
        <TreeNode
          key={value.type}
          title={
            <Item
              type={value.type}
              canDrag={
                value.canDrag ||
                (typeof value.canDrag === "undefined" &&
                  !value?.children?.length)
              }
              value={value}
              onMouseDown={() => onMouseDown(value.type)}
              onMouseOut={() => props.onDrop()}
              disabled={disabled}
            />
          }
          className="drag-tree__node"
          icon={
            <Row gutter={8}>
              {value?.children?.length ? (
                <Col>
                  {state.selectedKeys.includes(value.type) ? (
                    <FolderOpenOutlined />
                  ) : (
                    <FolderOutlined />
                  )}
                </Col>
              ) : null}
              {value.canDrag ||
              (typeof value.canDrag === "undefined" &&
                !value?.children?.length) ? (
                <Col>
                  <DragOutlined />
                </Col>
              ) : null}
            </Row>
          }
          disabled={disabled}
        >
          {getTreeNode(value?.children || [])}
        </TreeNode>
      );
    });

    return treeNode;
  };

  const onNodeSelect = (selectedKeys) => {
    if (
      ["components", "modules", "templates"].some(
        (key) => !selectedKeys.length || selectedKeys.includes(key),
      )
    ) {
      localStorage.setItem("selectedKeys", JSON.stringify(selectedKeys));

      setState({
        selectedKeys,
      });
    }
  };

  return (
    <div className="drag-tree">
      <Tree
        showLine
        blockNode
        showIcon
        selectedKeys={state.selectedKeys}
        expandedKeys={[...state.selectedKeys, "sections", "banners", "cards"]}
        onExpand={onNodeSelect}
        onSelect={onNodeSelect}
      >
        {getTreeNode(state.treeContent)}
      </Tree>
    </div>
  );
};

export default Drag;
