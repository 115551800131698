import { Option } from "ebs-design/dist/components/molecules/Select/interfaces";
import React from "react";
import { useQuery } from "react-query";

import api, { querykeys } from "api";
import { usePagination } from "hooks";
import models from "models";

type nomenclaturesType = "departments" | "roles" | "warehouses";
interface OptionNomenclature extends Option {
  is_active?: string
}

interface userNomenclatureEntity {
  data?: OptionNomenclature[];
  isLoading: boolean;
}
interface userNomenclatures {
  departments?: userNomenclatureEntity;
  roles?: userNomenclatureEntity;
  warehouses?: userNomenclatureEntity;
}

export const useUserNomenclatures = (nomenclature: nomenclaturesType[]) => {
  const departmentsPagination = usePagination();
  const rolesPagination = usePagination();
  const warehousesPagination = usePagination();

  const departmentsQueryParams = {
    ...departmentsPagination.queryParams,
  };
  const rolesQueryParams = {
    ...rolesPagination.queryParams,
  };
  const warehouseQueryParams = {
    ...warehousesPagination.queryParams,
  };

  const requests = {
    departments: useQuery(
      querykeys.nomenclature.departments(departmentsQueryParams),
      () => api.nomenclatures.department(departmentsQueryParams),
      {
        select: React.useCallback(
          (data: models.WithResults<models.Department>) =>
            data?.results?.map((v) => ({
              value: v.id,
              text: v.title,
              is_active: v.is_active,
            })),
          [],
        ),
      },
    ),
    roles: useQuery(
      querykeys.nomenclature.roles(rolesQueryParams),
      () => api.nomenclatures.roles(rolesQueryParams),
      {
        select: React.useCallback(
          (data: models.WithResults<models.Role>) =>
            data?.results?.map((v) => ({
              value: v.id,
              text: v.name,
            })),
          [],
        ),
      },
    ),
    warehouses: useQuery(
      querykeys.nomenclature.warehouses(warehouseQueryParams),
      () => api.nomenclatures.warehouses(warehouseQueryParams),
      {
        select: React.useCallback(
          (data: models.WithResults<models.Warehouse>) =>
            data?.results?.map((v) => ({
              value: v.id,
              text: v.title,
              is_active: v.is_active
            })),
          [],
        ),
      },
    ),
  };

  const nomenclaturesData = nomenclature?.map((key) =>
    requests.hasOwnProperty(key)
      ? {
          [key]: {
            data: requests[key as keyof typeof requests].data,
            isLoading: requests[key as keyof typeof requests].isLoading,
          },
        }
      : undefined,
  );

  const nomenclatureObj: userNomenclatures = Object.assign(
    {},
    ...nomenclaturesData,
  );

  return nomenclatureObj;
};
