import { createSvgIcon } from "../utils";

export const Tablet = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <rect x="5" y="3" width="14" height="18" rx="1" />
    <circle cx="12" cy="17" r="1" />
  </svg>,
);
