import { languages } from "apps/cms/lib/utils";
import { Properties } from "types";

export const loop = (data, id, callback) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].id === id) {
      return callback(data[i], i, data);
    }
    if (data[i].children) {
      loop(data[i].children, id, callback);
    }
  }
};

export const loopAll = (data, callback) => {
  for (let i = 0; i < data.length; i++) {
    callback(data[i], i, data);

    if (data[i].children) {
      loopAll(data[i].children, callback);
    }
  }
};

export const loopChildren = (
  data: Properties[],
  id: string,
  callback: (item: Properties, index: number, arr: Properties) => Properties,
) =>
  data?.map((item, index, arr) => {
    if (item?.id === id) {
      item = callback(item, index, arr);
    }
    if (item?.children?.length) {
      item.children = loopChildren(item.children, id, callback);
    }

    return item;
  });

export const loopObjects = (
  data: Properties,
  id: string,
  callback: (item: Properties, index: number, arr: Properties) => Properties,
  lang: string,
) => {
  data &&
    Object.keys(data).forEach((itemId) => {
      if (!data[itemId]?.[lang]) return;

      Object.keys(data[itemId][lang]).forEach((_, index, arr) => {
        if (id === itemId) {
          const item = callback(data[itemId][lang], index, arr);

          if (item) {
            data[itemId][lang] = item;
          }
        }
        if (Array.isArray(data[itemId][lang]?.children)) {
          data[itemId][lang].children = loopChildren(
            data[itemId][lang].children,
            id,
            callback,
          );
        }
      });
    });

  return data;
};

export const normalizeTreeChildren = (arr, values, lang) => {
  let data: Properties[] = [];

  arr.forEach((item, i) => {
    let value;

    loopObjects(
      values,
      item.id,
      (val) => {
        value = val;

        return val;
      },
      lang,
    );

    data = [
      ...data,
      {
        ...item,
        ...value,
        ...(item?.children && {
          children: normalizeTreeChildren(item?.children, values, lang),
        }),
        order: i,
      },
    ];
  });

  return data;
};

export const normalizeTreeContent = (arr, values) => {
  const data = {};

  arr.forEach((item, i) => {
    let value;

    languages.forEach((lang) => {
      value = undefined;
      loopObjects(
        values,
        item.id,
        (val) => {
          value = val;

          return val;
        },
        lang,
      );

      if (!data[item.id]) {
        data[item.id] = {};
      }

      data[item.id][lang] = {
        ...item,
        ...value,
        ...(item?.children && {
          children: normalizeTreeChildren(item?.children, values, lang),
        }),
        order: i,
      };
    });
  });

  return data;
};

export const copy = async (text) => {
  navigator.permissions
    .query({ name: "clipboard-write" as PermissionName })
    .then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard.writeText(text);
      }
    });
};

export const read = async () => {
  return navigator.clipboard
    .readText()
    .then((result) => Promise.resolve(result));
};
