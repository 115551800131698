import { Button, Card, useNotify } from "ebs-design";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import {
  ConfirmationModal,
  DepartmentFormModal,
  Flex,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import {
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";
import models from "models";
import { notifyErrors } from "utils";

import { departmentsColumns, departmentsSortOptions } from "../config";

export const Departments = () => {
  const { t } = useTranslation();
  const departmentFormModal = useModalState<models.Department>();
  const deleteDepartmentModal = useModalState<string | number>();
  const [search, setSearch] = React.useState("");
  const pagination = useQueryPagination();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [ordering, setOrdering] = useOrderingQueryParam();

  const queryParams = {
    ...pagination.queryParams,
    search,
    ordering,
  };

  const query = useQuery(querykeys.departments.many(queryParams), () =>
    api.departments.get(queryParams),
  );
  const mutation = useMutation(
    (id: number | string) => api.departments.delete(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.departments.many());
        notify.success({
          title: t("template.item_deleted_successfully", {
            item: t("department.department"),
          }),
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const tableColumns = React.useMemo(
    () =>
      departmentsColumns(
        departmentFormModal.openWith,
        deleteDepartmentModal.openWith,
        t,
      ),
    [deleteDepartmentModal.openWith, departmentFormModal.openWith, t],
  );

  return (
    <>
      <PageTitle
        actions={
          <Button type="primary" onClick={departmentFormModal.open}>
            {t("template.add_item", {
              item: t("department.department").toLowerCase(),
            })}
          </Button>
        }
        title={t("menu.departments")}
        count={query.data?.count}
      />
      <Card>
        <Card.Body>
          <QueryTable
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                  sortOptions={departmentsSortOptions}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                />
              </Flex>
            )}
            query={query}
            columns={tableColumns}
          />
        </Card.Body>
      </Card>

      {departmentFormModal.isOpen && (
        <DepartmentFormModal
          open={departmentFormModal.isOpen}
          onClose={departmentFormModal.close}
          data={departmentFormModal.data}
          closeOnClickOutside={false}
        />
      )}
      {deleteDepartmentModal.isOpen && (
        <ConfirmationModal
          open={deleteDepartmentModal.isOpen}
          onClose={deleteDepartmentModal.close}
          onConfirm={() =>
            mutation.mutate(Number(deleteDepartmentModal.data) || 0)
          }
          isLoading={mutation.isLoading}
          title={t("template.delete_item", {
            item: t("department.department"),
          })}
          confirmText={t("common.delete")}
        >
          <p>
            {`${t("template.are_you_sure_you_want_to_delete_this_item", {
              item: t("department.department").toLowerCase(),
            })} - ${deleteDepartmentModal.data}?`}
          </p>
        </ConfirmationModal>
      )}
    </>
  );
};
