import { useEffect, useState } from "react";
import { Alert, Select } from "ebs-design";
import { OptionValue } from "ebs-design/dist/components/molecules/Select/interfaces";
import { HexColorPicker } from "react-colorful";
import chroma from "chroma-js";

import { makeBEM } from "utils";
import { DebouncedInput } from "../DebouncedInput";
import { Grid } from "../Grid";
import { colorPickerOptions, stringRGBToArray, transparentSvg } from "./utils";

const bem = makeBEM("color-picker");

interface ColorPickerProps {
  color: string;
  onChange: (value: string) => void;
}

export const ColorPicker = ({ color, onChange }: ColorPickerProps) => {
  const [colorType, setColorType] = useState<OptionValue | OptionValue[]>(
    "hex"
  );
  const [inputColor, setInputColor] = useState<string | number[] | undefined>();
  const [colorError, setColorError] = useState<string | null>(null);

  const onChangeInputColor = (value: string) => {
    setInputColor(value);

    if (chroma.valid(value)) {
      setColorError(null);
      onChange(chroma(value).hex());
    } else if (chroma.valid(stringRGBToArray(value))) {
      setColorError(null);
      onChange(chroma(stringRGBToArray(value)).hex());
    } else {
      setColorError("Invalid color");
    }
  };

  useEffect(() => {
    if (colorType === "rgb") {
      setColorError(null);
      setInputColor(chroma(color).rgb());
    }
    if (colorType === "hex") {
      setColorError(null);
      setInputColor(chroma(color).hex());
    }
  }, [colorType, color]);

  return (
    <div>
      <div className="color-picker mb-18">
        <HexColorPicker color={color} onChange={onChange} />
        <div
          className={bem("color-wrapper")}
          style={{
            backgroundImage: transparentSvg,
          }}
        >
          <div
            className={bem("color-wrapper__color")}
            style={{
              backgroundColor: color,
            }}
          />
        </div>
      </div>
      <Grid cols="auto 247px" gap="8px">
        <DebouncedInput
          className={bem("color-value")}
          value={inputColor?.toString()}
          onChange={onChangeInputColor}
        />
        <Select
          options={colorPickerOptions}
          value={colorType}
          onChange={(option) => {
            setColorType(option);
          }}
          size="medium"
          disabled={!!colorError}
          className={bem("color-type", { disabled: !!colorError })}
        />
      </Grid>
      {!!colorError && (
        <Alert closable icon className="mt-4" type="error">
          {colorError}
        </Alert>
      )}
    </div>
  );
};
