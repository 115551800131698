import { AxiosError } from "axios";
import { Button, Card, Loader, Space, useNotify } from "ebs-design";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { NumberParam } from "use-query-params";

import api, { querykeys } from "api";
import {
  DeviceCard,
  Flex,
  Grid,
  PageTitle,
  Table,
  WhiteSpace,
  Divider,
  Restricted,
} from "components";
import {
  useIsRestrictedTo,
  useModalState,
  useModalStateType,
  useParam,
} from "hooks";
import models from "models";
import { notifyErrors } from "utils";

import { diagnosticCriteriaColumns } from "../config";
import {
  ChangeDeviceStatusModal,
  OrderDetailsBottom,
  OrderDetailsCard,
} from ".";

export interface OrderDetailsProps {
  diagnosticCriteria?: models.DiagnosticCriteria[];
  data?: models.Order;
  orderId?: number;
  changeDeviceStatusModal?: useModalStateType;
}
export interface Props {
  OrderDetails: React.FC<OrderDetailsProps>;
}

export const OrderDetailsTemplate = ({ OrderDetails }: Props) => {
  const { t } = useTranslation();

  const changeDeviceStatusModal = useModalState<models.TypeWithIds>();
  const notify = useNotify();
  const orderId = useParam("orderId", NumberParam.decode) || 0;
  const deviceId = useParam("deviceId", NumberParam.decode) || 0;
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    querykeys.orders.one(orderId),
    () => api.orders.getByID(orderId),
    {
      enabled: !!orderId,
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  const { mutate: recycle, isLoading: recycleIsLoading } = useMutation(
    () => api.devices.recycleCertificate(deviceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.orders.one(orderId));
        notify.success({
          title: t("notification.device_recycled_successfully"),
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const diagnosticCriteria = useMemo(() => {
    if (!data) return [];
    return data?.device?.diagnostic_criteria;
  }, [data]);

  const areNoneCriteriaCorrect = useMemo(() => {
    if (!diagnosticCriteria) return false;
    return diagnosticCriteria.every(
      (criteria) => !criteria.is_correct || criteria.is_default,
    );
  }, [diagnosticCriteria]);

  const deviceCantBeRecycled =
    data?.hidden_status !== models.OrderStatus.RECYCLED &&
    data?.hidden_status === models.OrderStatus.PENDING_RECYCLING;

  const deviceWasEvaluatedByWarehouseOperator =
    models.OrderStatus.PENDING_REJECTION === data?.hidden_status ||
    models.OrderStatus.PENDING_COMPLETION === data?.hidden_status;

  const deviceIsNotInFinishedStatus = ![
    models.OrderStatus.REJECTED,
    models.OrderStatus.PRICE_UPDATED,
    models.OrderStatus.COMPLETED,
  ].includes(data?.hidden_status);

  const checkboxIsDisabled = !useIsRestrictedTo()({
    roles: [
      data?.hidden_status === models.OrderStatus.DEVICE_DELIVERED
        ? models.Roles.WAREHOUSE_OPERATOR
        : null,
      deviceWasEvaluatedByWarehouseOperator && deviceIsNotInFinishedStatus
        ? models.Roles.WAREHOUSE_SUPERVISOR
        : null,
      models.Roles.ADMINISTRATOR,
    ],
    mainAdmin: false,
  });

  const tableColumns = useMemo(
    () =>
      diagnosticCriteriaColumns({
        disabled: checkboxIsDisabled,
        areNoneCorrect: areNoneCriteriaCorrect,
        t,
      }),
    [checkboxIsDisabled, areNoneCriteriaCorrect, t],
  );

  if (isLoading) return <Loader loading />;

  return (
    <>
      <PageTitle goBack>
        {t("order.order")} - {data?.code}
      </PageTitle>
      <Card>
        <Card.Body>
          <OrderDetailsCard order={data} />
          <WhiteSpace v={24} />
          <Grid cols="1fr 1fr" gap="24px">
            <DeviceCard
              device={data?.device}
              price={data?.price}
              currency={data?.currency}
            />
            <Table
              variants={["no-borders"]}
              data={diagnosticCriteria}
              columns={tableColumns}
              rowKey="id"
            />
          </Grid>

          <Divider space={28} />

          <Flex justify="space-between" align="center" wrap gap="1rem">
            <OrderDetailsBottom order={data} />

            <Restricted
              toRoles={[
                models.Roles.WAREHOUSE_SUPERVISOR,
                models.Roles.WAREHOUSE_OPERATOR,
                models.Roles.ADMINISTRATOR,
              ]}
              toMainAdmin={false}
            >
              <Space>
                {deviceCantBeRecycled && (
                  <Flex align="center" justify="flex-end">
                    <Button
                      type="light"
                      onClick={recycle}
                      loading={recycleIsLoading}
                    >
                      {t("common.mark_recycled")}
                    </Button>
                  </Flex>
                )}
                {changeDeviceStatusModal.isOpen && (
                  <ChangeDeviceStatusModal
                    open={changeDeviceStatusModal.isOpen}
                    onClose={changeDeviceStatusModal.close}
                    data={changeDeviceStatusModal.data}
                  />
                )}
                <OrderDetails
                  data={data}
                  orderId={orderId}
                  changeDeviceStatusModal={changeDeviceStatusModal}
                />
              </Space>
            </Restricted>
          </Flex>
        </Card.Body>
      </Card>
    </>
  );
};
