import { useQuery } from "react-query";

import api, { querykeys } from "api";

export const useRoleList = (options?: any) => {
  const query = useQuery(
    querykeys.nomenclature.roles(),
    () => api.nomenclatures.roles(),
    options,
  );

  return query;
};
