import { AvatarInline, Button, Space } from "ebs-design";
import { Dropdown } from "components";
import { Notifications } from "components";
import { useAuth } from "hooks";

import { ChevronDown } from "components/icons";
import { TopBarTooltip } from "components/TopBarTooltip";

export const LayoutTopbar = () => {
  const { user } = useAuth();

  return (
    <div className="top-bar">
      <Space size="small">
        <Notifications />
        <AvatarInline
          circle
          size="small"
          img={user?.profile?.picture?.url}
          alt={user?.name || user?.email}
        />
        <Dropdown>
          <Button type="text" size="small" icon={() => <ChevronDown />} />
          <Dropdown.Content position="right" cover={false}>
            <section>
              <TopBarTooltip />
            </section>
          </Dropdown.Content>
        </Dropdown>
      </Space>
    </div>
  );
};
