import React, { useRef } from "react";
import { Button, Space, useNotify } from "ebs-design";
import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import api from "api";
import {
  MAX_DOCUMENT_UPLOAD_SIZE_2,
  RECOMMENDED_RESOLUTION_1000_1000,
  ACCEPTED_IMAGE_EXTENSIONS,
} from "app-constants";
import { Flex } from "components";
import models from "models";
import { makeBEM, notifyErrors } from "utils";
import * as Icons from "components/icons";

export interface UploadImageProps {
  file?: models.File;
  setFile?: React.Dispatch<React.SetStateAction<models.File | undefined>>;
  formats?: string[];
  publicFile?: boolean;
  showImage?: boolean;
  value?: File;
  fromOrganization?: boolean;
  onChange?: (value: File) => void;
  maxDocSize?: number;
  recommendedResolution?: string;
}

const bem = makeBEM("image-input");

export const UploadImage = ({
  file,
  setFile,
  formats = ACCEPTED_IMAGE_EXTENSIONS,
  publicFile,
  value,
  onChange,
  showImage = false,
  fromOrganization = true,
  maxDocSize = MAX_DOCUMENT_UPLOAD_SIZE_2,
  recommendedResolution = RECOMMENDED_RESOLUTION_1000_1000,
}: UploadImageProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const notify = useNotify();
  const maxDocSizeMB = `${(maxDocSize / 1024 / 1024).toFixed(2)} MB`;

  const [fileName, setFileName] = React.useState<string | undefined>(
    file?.url?.split("/")?.pop(),
  );

  const mutation = useMutation(
    (body: models.Attachment) =>
      fromOrganization
        ? api.attachments.organization.post(body)
        : api.attachments.post(body),
    {
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const file = event.target.files[0];
    const fileExtension = file.name.split(".").slice(-1).join();
    const requiredFormats = formats;

    if (file.size > maxDocSize) {
      return notify.error({
        title: t("error.max_size_limit") + maxDocSizeMB,
      });
    }
    if (formats.length && !requiredFormats.includes(fileExtension)) {
      return notify.error({ title: t("error.image_required") });
    }

    //set file name
    setFileName(file.name);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("public", publicFile?.toString() || "false");

    typeof onChange === "function" ? onChange(file) : mutation.mutate(formData);
  };

  useEffect(() => {
    if (mutation.data) {
      setFile?.({
        id: mutation?.data?.id!,
        url: mutation?.data?.url,
        size: mutation?.data?.size,
        extension: "." + mutation?.data?.url?.split(".").slice(-1).join(),
      });
    }
  }, [mutation.data, setFile]);

  return (
    <div className={bem()}>
      <Flex>
        {showImage && (
          <div className={bem("frame")}>
            {file?.url && (
              <img alt={t("common.not_found")} width={250} src={file.url} />
            )}
          </div>
        )}
        <div className={bem("upload")}>
          <Icons.Upload style={{ color: "var(--grey-200)" }} size={16} />
          <p className="mb-5 text-center">
            {`${t("file.maximum_file_size")} - ${maxDocSizeMB}.`} <br />
            {`${t("file.recommended_resolution")} - ${recommendedResolution}.`}
            <br />
            {`${t("file.accepted_image_file_extensions")} - ${formats.join(
              ", ",
            )}.`}
          </p>
          <Space direction="horizontal">
            <p className="mb-5 text-center one-line max-w-200">
              {fileName || t("file.no_file")}
            </p>
            <label>
              <Button onClick={() => inputRef.current.click()} size="small">
                {t("common.browse")}
              </Button>
              <input type="file" onChange={handleUpload} ref={inputRef} />
            </label>
          </Space>
        </div>
      </Flex>
    </div>
  );
};
