import { FC, forwardRef, useState, useEffect } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import api from "apps/cms/lib/api/pages";

interface GalleryWallProps {
  showFileList?: boolean;
  value?: string;
  onChange?: (content: string[], index?: number) => void;
  fieldIndex?: number;
}

const maxImageSizeInMb = 4;

const UploadImage: FC<GalleryWallProps> = forwardRef(
  ({ value, onChange, showFileList, fieldIndex }) => {
    const [fileList, setFileList] = useState<UploadFile[]>(() => {
      const initialList: UploadFile[] = [];

      initialList.push({
        url: value,
        uid: "backgournd",
        size: 1,
        name: `backgournd`,
        status: "done",
        type: "image",
      });

      return initialList;
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const images: string[] = [];

      fileList.forEach((file) => {
        if (file.url) {
          images.push(file.url);
        }
      });

      if (onChange && typeof fieldIndex !== "number") {
        onChange(images);
      }
      if (onChange && typeof fieldIndex === "number") {
        onChange(images, fieldIndex);
      }
    }, [fileList, fieldIndex, onChange]);

    const handleChange = ({
      fileList: newFileList,
    }: {
      fileList: any[];
    }): void => {
      if (fileList.length > newFileList.length) {
        setFileList(newFileList);
      }
    };

    const handleUpload = async (file: any): Promise<void> => {
      setLoading(true);
      try {
        const data = await api.uploadFile(file);
        const { id, upload, uploaded } = data;

        const newFile: UploadFile<any> = {
          uid: `${id}`,
          size: 1,
          name: upload,
          status: uploaded ? "done" : "error",
          url: `${process.env.REACT_APP_STATIC_API}/v1${upload}`,
          type: "image",
        };

        setFileList([newFile]);
      } catch (e) {
        message.error("Unsuccessfull image upload!");
      }
      setLoading(false);
    };

    const handleBeforeUpload = (file: any): boolean => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload images in the format of JPG/PNG!");
        return false;
      }

      const isMbLt = file.size / 1024 / 1024 <= maxImageSizeInMb;
      if (!isMbLt) {
        message.error(
          `Image should not be larger than ${maxImageSizeInMb} MB!`,
        );
        return false;
      }
      handleUpload(file);

      return false;
    };

    return (
      <div>
        <Upload
          fileList={showFileList ? fileList : []}
          onChange={handleChange}
          beforeUpload={handleBeforeUpload}
        >
          <Button loading={loading}>
            Choose File <UploadOutlined />
          </Button>
        </Upload>
      </div>
    );
  },
);

export default UploadImage;
