import React from "react";
import cn from "classnames";

import { TabsEntity } from "types";

interface Props {
  stepsItems: TabsEntity[];
  className?: string;
  activeStep: number;
  onChange: (value: number) => void;
  disableSecondElement?: boolean;
}

const clsBase = "steps";

export const Steps: React.FC<Props> = ({
  className,
  activeStep,
  stepsItems,
  onChange,
  disableSecondElement,
}) => {
  return (
    <div className={clsBase}>
      <div className={cn(`${clsBase}__header`, className)}>
        {stepsItems?.map?.((item, index) => (
          <CustomLink
            key={index}
            label={item.label}
            clsBase={clsBase}
            onChange={() => onChange(index)}
            activeStep={activeStep}
            index={index}
            disableSecondElement={disableSecondElement}
          />
        ))}
      </div>
      {stepsItems?.map?.(
        (item, idx) =>
          idx === activeStep && <div key={idx}>{item.content}</div>,
      )}
    </div>
  );
};

interface CustomLinkProps {
  label: React.ReactNode;
  clsBase: string;
  onChange: () => void;
  index: number;
  activeStep: number;
  disableSecondElement?: boolean;
}

const CustomLink = ({
  label,
  clsBase,
  onChange,
  activeStep,
  index,
  disableSecondElement,
}: CustomLinkProps) => {
  return (
    <div
      className={cn(`${clsBase}__header__item`, {
        [`${clsBase}__header__item--active`]: activeStep >= index,
      })}
    >
      <div
        className={cn("steps__label", {
          "steps__label--disabled": index === 1 && disableSecondElement,
        })}
        onClick={onChange}
      >
        {label}
      </div>
    </div>
  );
};
