import React from "react";
import { Loader } from "ebs-design";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";

import { Clients } from "apps/auth/clients/pages";
import { Departments } from "apps/auth/departments/pages";
import { InviteStaff, InviteStuffSuccess, Staff } from "apps/auth/staff/pages";
import ArticlePage from "apps/cms/pages/Article";
import Banners from "apps/cms/pages/Banners";
import EditArticle from "apps/cms/pages/Article/Edit";
import EditMenu from "apps/cms/pages/Menu/Edit";
import FrequentlyAskedQuestion from "apps/cms/pages/FrequentlyAskedQuestion";
import i18n from "apps/cms/pages/i18n";
import i18nNamespace from "apps/cms/pages/i18n/Namespace";
import MenuPage from "apps/cms/pages/Menu";
import { MenuForm } from "apps/cms/components/Menu";
import {
  BrandDetails,
  Brands,
  BrandsSuccessConnected,
  Models,
  ModelsDetails,
} from "apps/crm/catalog/pages";
import { AddBrands } from "apps/crm/catalog/pages/AddBrands";
import { AddModels } from "apps/crm/catalog/pages/AddModels";
import { ModelsSuccessConnected } from "apps/crm/catalog/pages/ModelsSuccessConnected";
import {
  DropOffLocationForm,
  DropOffLocations,
} from "apps/crm/drop-off-locations/pages";
import { Inventory, InventoryDetails } from "apps/crm/inventory/pages";
import { BuyBackOrders, CdeOrders } from "apps/crm/orders/pages";
import { PaymentProviders } from "apps/crm/payment/pages";
import { Invoices, PaymentDetails } from "apps/crm/payments/pages";
import { PayoutDetails, Payouts } from "apps/crm/payouts/pages";
import { ShippingProviders } from "apps/crm/shipping/pages";
import { Files } from "apps/files/pages";
import { OrderDetails, Orders } from "apps/warehouse/orders/pages";
import { WarehouseForm, Warehouses } from "apps/warehouse/warehouses/pages";
import { Dashboard } from "features/dashboard/pages";
import {
  Customization,
  LanguageSettings,
  Payments,
  ProfileSettings,
  ServiceManagement,
  Settings,
} from "features/settings/pages";
import { DiagnosticCriteria } from "features/settings/pages";
import { useAuth, useIsRestrictedTo, IsRestrictedToOptions } from "hooks";
import models from "models";

import {
  RecyclingCertificateDetails,
  RecyclingCertificates,
} from "apps/crm/recycling-certificates/pages";
import { DashboardLayout } from "./DashboardLayout";

export const DashboardRouter = () => {
  const { isLoading, user } = useAuth();
  const isRestrictedTo = useIsRestrictedTo();

  const restrictedRoutes = (
    routes: React.ReactElement<RouteProps>[],
    options?: IsRestrictedToOptions,
  ) => {
    const isRestricted = isRestrictedTo(options || {});
    if (!isRestricted) return null;
    return routes.map((route, idx) => React.cloneElement(route, { key: idx }));
  };

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (isLoading) {
    return <Loader loading />;
  }

  return (
    <DashboardLayout>
      <Switch>
        {restrictedRoutes([<Route path="/" exact component={Dashboard} />], {
          roles: [models.Roles.ADMINISTRATOR],
        })}

        {/* Settings */}
        {restrictedRoutes([
          <Route
            path="/settings/diagnostic-criteria"
            component={DiagnosticCriteria}
          />,
          <Route
            path="/settings/brand-customization"
            component={Customization}
          />,
          <Route
            path="/settings/service-management"
            component={ServiceManagement}
          />,
          <Route path="/settings/payments" component={Payments} />,
          <Route path="/settings" exact component={Settings} />,
        ])}

        <Route path="/profile-settings" component={ProfileSettings} />

        {/* Language settings */}
        {restrictedRoutes([
          <Route
            path="/language-settings"
            component={() => <LanguageSettings />}
          />,
        ])}

        {/* Product Management */}
        {restrictedRoutes(
          [
            <Route path={`/product-management`} exact>
              <Redirect to="/product-management/brands" />
            </Route>,
            <Route
              path={`/product-management/brands`}
              exact
              component={Brands}
            />,
            <Route
              path={`/product-management/brands/add-brands`}
              exact
              component={AddBrands}
            />,
            <Route
              path={`/product-management/brands/add-brands/success-connected`}
              component={BrandsSuccessConnected}
            />,
            <Route
              path={`/product-management/brands/:id`}
              component={BrandDetails}
            />,
            <Route
              path={`/product-management/models`}
              exact
              component={Models}
            />,
            <Route
              path={`/product-management/models/add-models`}
              exact
              component={AddModels}
            />,
            <Route
              path={`/product-management/models/add-models/success-connected`}
              component={ModelsSuccessConnected}
            />,
            <Route
              path={`/product-management/models/:id`}
              exact
              component={ModelsDetails}
            />,
            <Route
              path={`/product-management/inventory`}
              exact
              component={Inventory}
            />,
            <Route
              path={`/product-management/:id`}
              exact
              component={InventoryDetails}
            />,
          ],
          {
            app: models.Apps.PM_SERVICE,
          },
        )}

        {/* CMS */}
        {restrictedRoutes(
          [
            <Route path={`/cms`} exact>
              <Redirect to="/cms/pages" />
            </Route>,
            <Route path={`/cms/pages`} exact component={ArticlePage} />,
            <Route
              path={`/cms/pages/:tab/:id?`}
              exact
              component={EditArticle}
            />,
            <Route path={`/cms/menu`} exact component={MenuPage} />,
            <Route path={`/cms/menu/create`} exact component={MenuForm} />,
            <Route path={`/cms/menu/:tab/:id?`} exact component={EditMenu} />,
            <Route path={`/cms/banners`} exact component={Banners} />,
            <Route
              path={`/cms/faq`}
              exact
              component={FrequentlyAskedQuestion}
            />,
            <Route path={`/cms/i18n`} exact component={i18n} />,
            <Route path={`/cms/i18n/:type`} exact component={i18nNamespace} />,
            <Route path={`/cms/files`} exact component={Files} />,
          ],
          {
            app: models.Apps.CMS_SERVICE,
          },
        )}

        {/* Auth */}
        {restrictedRoutes(
          [
            <Route path="/auth" exact>
              <Redirect to="/auth/departments" />
            </Route>,
            <Route
              path={`/auth/internal-users/invitation-send`}
              component={InviteStuffSuccess}
            />,
            <Route
              path={`/auth/internal-users/invite`}
              component={InviteStaff}
            />,
            <Route path={`/auth/internal-users`} exact component={Staff} />,
            <Route path={`/auth/departments`} component={Departments} />,
          ],
          {
            app: models.Apps.AUTH_SERVICE,
          },
        )}

        {/* Warehouse */}
        {restrictedRoutes(
          [
            <Route path={`/warehouse`} exact>
              <Redirect to="/warehouse/warehouses" />
            </Route>,

            <Route
              path={`/warehouse/warehouses`}
              exact
              component={Warehouses}
            />,
            <Route
              path={`/warehouse/warehouses/create`}
              exact
              component={WarehouseForm}
            />,
            <Route
              path={`/warehouse/warehouses/edit/:id`}
              exact
              component={() => <WarehouseForm edit />}
            />,
            <Route
              path={`/warehouse/drop-off-locations`}
              exact
              component={DropOffLocations}
            />,
            <Route
              path={`/warehouse/drop-off-locations/create`}
              exact
              component={DropOffLocationForm}
            />,
            <Route
              path={`/warehouse/drop-off-locations/edit/:id`}
              exact
              component={() => <DropOffLocationForm edit />}
            />,
            <Route
              path={`/warehouse/shipping-providers`}
              exact
              component={ShippingProviders}
            />,
          ],
          {
            app: models.Apps.WAREHOUSE_SERVICE,
          },
        )}

        {restrictedRoutes(
          [
            <Route
              path={`/crm/orders/warehouse-orders/:orderId/device/:deviceId`}
              component={OrderDetails}
            />,
            <Route
              path={`/crm/orders/warehouse-orders`}
              exact
              component={Orders}
            />,
          ],
          {
            app: models.Apps.CRM_SERVICE,
            roles: [
              models.Roles.WAREHOUSE_OPERATOR,
              models.Roles.WAREHOUSE_SUPERVISOR,
            ],
          },
        )}

        {/* CRM */}
        {restrictedRoutes(
          [
            <Route path={`/crm`} exact>
              <Redirect to="/crm/orders/buyback-orders" />
            </Route>,
            <Route
              path={`/crm/orders/buyback-orders`}
              exact
              component={BuyBackOrders}
            />,
            <Route
              path={`/crm/orders/erasure-orders`}
              exact
              component={CdeOrders}
            />,
            // FIX: restrictions regarding warehouse orders
            <Route
              path={`/crm/orders/warehouse-orders/:orderId/device/:deviceId`}
              component={OrderDetails}
            />,
            <Route
              path={`/crm/orders/warehouse-orders`}
              exact
              component={Orders}
            />,
            <Route
              path={`/crm/payment-providers`}
              exact
              component={PaymentProviders}
            />,
            <Route
              path={`/crm/invoices/payments`}
              exact
              component={Invoices}
            />,
            <Route
              path={`/crm/invoices/payments/:id`}
              component={PaymentDetails}
            />,
            <Route path={`/crm/invoices/payouts`} exact component={Payouts} />,
            <Route
              path={`/crm/invoices/payouts/:id`}
              component={PayoutDetails}
            />,
            <Route path={`/crm/clients`} component={Clients} />,
            <Route
              path={`/crm/recycling-certificates`}
              exact
              component={RecyclingCertificates}
            />,
            <Route
              path={`/crm/recycling-certificates/:id`}
              component={RecyclingCertificateDetails}
            />,
          ],
          {
            app: models.Apps.CRM_SERVICE,
          },
        )}

        {restrictedRoutes([<Redirect to="/crm/orders/warehouse-orders" />], {
          app: models.Apps.CRM_SERVICE,
          roles: [
            models.Roles.WAREHOUSE_OPERATOR,
            models.Roles.WAREHOUSE_SUPERVISOR,
          ],
        })}

        <Redirect to="/" />
      </Switch>
    </DashboardLayout>
  );
};
