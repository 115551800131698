import models from "models";
import { fileToken, getFilePreview, isFileImage } from "utils";

import { ThumbnailImageAttachment } from "./ThumbnailImageAttachment";

interface ImageOrIconPreviewProps {
  file: models.Attachment;
  onFeatured: () => void;
}

export const ImageOrIconPreview = ({
  file,
  onFeatured,
}: ImageOrIconPreviewProps) => {
  const format = file?.extension?.split(".")[1]!;

  const isImage = isFileImage(format);

  const isDocumentViewable = format === "pdf" || format === "xml";

  return (
    <div>
      {isImage && (
        <span className="cursor-pointer" onClick={onFeatured}>
          <ThumbnailImageAttachment file={file} background />
        </span>
      )}
      {!isImage && (
        <a
          href={fileToken(file)}
          target={isDocumentViewable ? "_blank" : "_self"}
          rel="noreferrer"
          download
        >
          {getFilePreview(format)}
        </a>
      )}
    </div>
  );
};
