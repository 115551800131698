import { Form, Select, Checkbox } from "antd";
import { Properties } from "types";

import { Link } from "../Custom";
import { formItemStyle } from "../helpers";

const Button = ({ item, lang, setFieldsValue }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "size"]}
        initialValue="medium"
        label="Size"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="small">Small</Select.Option>
          <Select.Option value="medium">Medium</Select.Option>
          <Select.Option value="large">Large</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "dataType"]}
        initialValue="primary"
        label="Type"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="text">Text</Select.Option>
          <Select.Option value="primary">Primary</Select.Option>
          <Select.Option value="light">Light</Select.Option>
          <Select.Option value="fill">Fill</Select.Option>
          <Select.Option value="ghost">Ghost</Select.Option>
          <Select.Option value="dark">Dark</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "block"]}
        valuePropName="checked"
        label="Full width"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  block: !item.block,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "round"]}
        valuePropName="checked"
        label="Round"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  round: !item.round,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "action"]}
        initialValue={item?.action}
        label="Acțiune"
        style={formItemStyle}
      >
        <Link lang={lang} />
      </Form.Item>
    </>
  );
};

export default Button;
