import queryString from "query-string";

import { GenericObject } from "types";

export const stringifyUrl = (
  url: string,
  query: GenericObject = {},
  options?: queryString.StringifyOptions,
): string =>
  queryString.stringifyUrl(
    {
      url,
      query,
    },
    { ...options, skipEmptyString: true, skipNull: true },
  );
