import cn from "classnames";

import models from "models";
import { createClassWithModifiers, makeBEM } from "utils";

interface ThumbnailImageProps {
  file?: models.File;
  background?: boolean;
}

const bem = makeBEM("thumbnail-image");

export const ThumbnailImage = ({ file, background }: ThumbnailImageProps) => {
  return (
    <div
      className={cn(
        createClassWithModifiers(bem(), {
          bg: background,
        }),
      )}
    >
      {file?.url && <img src={file?.url} alt="thumbnail" />}
    </div>
  );
};
