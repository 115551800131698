import { Button } from "ebs-design";
import { FC, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { stringifyUrl } from "query-string";
import { Row, Table, Tag, Badge, Col, Input } from "antd";
import api, { Ii18n, Ii18nType } from "apps/cms/lib/api/i18n";
import { ColumnProps } from "antd/lib/table";
import { PageTitle } from "components";
import { getMissingTranslationsKeys } from "utils";
import models from "models";
import * as Icons from "components/icons";

const typeColors = {
  [Ii18nType.INTERNAL]: "blue",
  [Ii18nType.EXTERNAL]: "cyan",
  [Ii18nType.NAMESPACE]: "geekblue",
};

const I18n: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [namespaces, setNamespaces] = useState<models.WithResults<Ii18n>>();
  const [searchValue, setSearchValue] = useState<string>();

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const search = params.search || undefined;

  const onSearchHandler = (): void => {
    history.push(
      stringifyUrl({
        url: "/cms/i18n",
        query: {
          ...(searchValue && { search: searchValue }),
        },
      }),
    );
  };

  const fetchNamespaces = useCallback(async (): Promise<void> => {
    const data = await api.getAll({ search, page, ordering: "-type" });
    let results: Ii18n[] = [];

    data.results.forEach((item: Ii18n) => {
      const namespace = item.code.split(".")[0];
      if (
        !results.some(
          (result) =>
            item.type !== Ii18nType.NAMESPACE &&
            result.code.split(".")[0] === namespace,
        )
      ) {
        results.push({
          ...item,
          code: namespace,
          count:
            item.type === Ii18nType.NAMESPACE
              ? Object.keys(item.i18n.en).length
              : 1,
          codes: [item.code],
        });
      } else if (
        results.some(
          (result) =>
            result.type !== Ii18nType.NAMESPACE &&
            result.code.split(".")[0] === namespace,
        )
      ) {
        results.map((result) => {
          if (
            result.code.split(".")[0] === namespace &&
            result.type !== Ii18nType.NAMESPACE
          ) {
            result.count = (result.count || 0) + 1;
            result.codes.push(item.code);
          }

          return result;
        });
      } else {
        results.push({
          ...item,
          code: namespace,
          count:
            item.type === Ii18nType.NAMESPACE
              ? Object.keys(item.i18n.en).length
              : 1,
          codes: [item.code],
        });
      }
    });

    setNamespaces({
      ...data,
      results,
    });

    setLoading(false);
  }, [search, page]);

  useEffect(() => {
    fetchNamespaces();
  }, [fetchNamespaces]);

  const columns: ColumnProps<Ii18n>[] = [
    {
      title: t("common.code"),
      dataIndex: "code",
    },
    {
      title: t("common.type"),
      dataIndex: "type",
      width: 50,
      className: "no-wrap",
      render: (value) => (
        <Tag color={typeColors[value as Ii18nType]}>{value}</Tag>
      ),
    },
    {
      title: t("common.count"),
      dataIndex: "count",
      className: "no-wrap",
      width: 50,
      render: (value) => <Tag>{value}</Tag>,
    },
    {
      title: t("common.translations"),
      width: 50,
      className: "no-wrap",
      render: ({ codes, i18n, type }) => {
        const keys = getMissingTranslationsKeys(i18n);

        return (
          <Row gutter={[8, 8]} justify="end" className="flex-no-wrap">
            {Object.keys(keys).map((key: any, i) => (
              <Col style={{ ...(!!i && { marginLeft: 10 }) }}>
                <Badge
                  count={keys[key]}
                  size="small"
                  status={keys[key] ? "error" : "success"}
                >
                  <Button
                    size="small"
                    type="ghost"
                    onClick={() =>
                      history.push(
                        stringifyUrl({
                          url: `/cms/i18n/${type}`,
                          query: {
                            code__in: codes,
                            lang: key,
                          },
                        }),
                      )
                    }
                  >
                    {key.toUpperCase()}
                  </Button>
                </Badge>
              </Col>
            ))}
          </Row>
        );
      },
    },
    {
      title: t("common.actions"),
      width: 50,
      align: "right",
      className: "no-wrap",
      render: ({ codes, type }) => (
        <Row
          gutter={[10, 10]}
          justify="center"
          align="middle"
          className="flex-no-wrap"
        >
          <Button
            icon={() => <Icons.Edit size="small" />}
            type="light"
            onClick={() =>
              history.push(
                stringifyUrl({
                  url: `/cms/i18n/${type}`,
                  query: { code__in: codes },
                }),
              )
            }
          />
        </Row>
      ),
    },
  ];

  return (
    <>
      <PageTitle title={t("menu.translations")} count={namespaces?.count} />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input.Search
            enterButton={t("search.search")}
            placeholder={t("search.search_by_code")}
            size="large"
            value={searchValue}
            style={{ width: "auto" }}
            onSearch={onSearchHandler}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            loading={loading}
            dataSource={namespaces?.results}
            rowKey="id"
            childrenColumnName=""
            pagination={{
              hideOnSinglePage: true,
              current: page,
              pageSize: 100,
              total: namespaces ? namespaces.count : 0,
            }}
            onChange={({ current }, filters: any) => {
              history.push(
                stringifyUrl(
                  {
                    url: "/cms/i18n",
                    query: {
                      ...params,
                      ...filters,
                      page: current,
                    },
                  },
                  { arrayFormat: "comma" },
                ),
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default I18n;
