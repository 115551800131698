import api, { querykeys } from "api";
import { useNotify } from "ebs-design";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { notifyErrors } from "utils";
import { CardWithFilterWrapper, CustomersChart } from ".";

export const Customers = () => {
  const { t } = useTranslation();
  const notify = useNotify();

  const [periodParams, setPeriodParams] = useState({
    from_date: null,
    to_date: null,
  });

  const queryParams = {
    ...periodParams,
  };

  const query = useQuery(
    querykeys.nomenclature.statistics.customer(queryParams),
    () => api.statistics.getCustomers(queryParams),
    {
      onError: (error: Error) => notifyErrors(notify, error),
    },
  );

  const total =
    query.data?.customers?.reduce((prev, next) => prev + next.total, 0) || 0;

  const list = [{ label: t("common.number_of_customers"), value: total }];

  return (
    <CardWithFilterWrapper
      title={t("menu.customers")}
      list={list}
      query={query}
      action={setPeriodParams}
    >
      <CustomersChart data={query.data?.customers} />
    </CardWithFilterWrapper>
  );
};
