import { AxiosError } from "axios";
import { Button, Card, useNotify } from "ebs-design";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useUpdateEffect } from "react-use";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ArrayParam, useQueryParams } from "use-query-params";

import api, { querykeys } from "api";
import { WAREHOUSE_STATE_OPTIONS } from "app-constants";
import {
  ConfirmationModal,
  ExportInFile,
  Flex,
  PageTitle,
  QueryTable,
  SelectOptions,
  TableTitle,
} from "components";
import {
  ChoiceFilter,
  Filters,
  SelectQueryListFilter,
} from "components/filters";
import { useModalState, useQueryPagination } from "hooks";
import models from "models";
import { CommaArrayParam, forwardSetState } from "utils";
import { notifyErrors } from "utils";
import { checkboxesCheckedInfo, CheckboxesCheckedInfoType } from "types";

import { EditInventoryModal } from "../components";
import { inventoryColumns } from "../config";

export const Inventory = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const notify = useNotify();
  const changeStatusModal = useModalState<string[]>();
  const editInventoryModal = useModalState<models.Inventory>();
  const [stateOption, setStateOption] = useState(
    WAREHOUSE_STATE_OPTIONS[0].value,
  );
  const pagination = useQueryPagination();
  const queryClient = useQueryClient();
  const [search, setSearch] = useState("");
  const [filterParams, setFilterParams] = useQueryParams({
    device__brand_model__brand__id__in: CommaArrayParam,
    device__brand_model__id__in: CommaArrayParam,
    status: ArrayParam,
  });

  const queryParams = {
    ...pagination.queryParams,
    search,
    ...filterParams,
  };

  const query = useQuery(querykeys.inventory.many(queryParams), () =>
    api.inventory.get(queryParams),
  );

  const [checkedOrders, setCheckedOrders] = useState<CheckboxesCheckedInfoType>(
    checkboxesCheckedInfo,
  );

  const allOrdersIds = query.data?.results?.map((item) => String(item?.id));
  const orderIdNumberList = checkboxesCheckedInfo.allCheckedCheckboxes.map(
    (item) => Number(item),
  );

  const selectedValue = WAREHOUSE_STATE_OPTIONS.filter(
    (item) => item?.value === stateOption,
  ).map((item) => item.key)?.[0];

  const { mutate, isLoading } = useMutation(
    () =>
      api.inventory.patchStatusBulk(orderIdNumberList, selectedValue as any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.inventory.many(queryParams));
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("invoice.invoice_status"),
          }),
        });
      },
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );
  useUpdateEffect(() => {
    pagination.setPage(1);
    setCheckedOrders(checkboxesCheckedInfo);
  }, [search, filterParams]);

  const tableColumns = inventoryColumns({
    editInventoryModal: editInventoryModal.openWith,
    setCheckboxInfo: setCheckedOrders,
    checkboxInfo: {
      ...checkedOrders,
      checkboxesOnPage: allOrdersIds,
    },
    t,
  });

  return (
    <>
      <PageTitle
        actions={
          <Button
            type="primary"
            disabled={checkedOrders.allCheckedCheckboxes.length === 0}
            onClick={() => {
              changeStatusModal.openWith(checkedOrders.allCheckedCheckboxes);
            }}
          >
            {t("common.change_status")}
          </Button>
        }
        title={t("menu.inventory")}
        count={query.data?.count}
      />
      <Card>
        <Card.Body>
          <QueryTable
            variants={["highlight-hover"]}
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                  filters={
                    <Filters
                      queryParams={filterParams}
                      setQueryParams={setFilterParams}
                    >
                      <ChoiceFilter
                        title={t("common.status")}
                        value={queryParams.status}
                        setValue={forwardSetState(setFilterParams, "status")}
                        choice={WAREHOUSE_STATE_OPTIONS}
                      />
                      <SelectQueryListFilter
                        title={t("brand.brands")}
                        value={filterParams.device__brand_model__brand__id__in}
                        setValue={forwardSetState(
                          setFilterParams,
                          "device__brand_model__brand__id__in",
                        )}
                        querykey={querykeys.brands.many}
                        apiCall={api.brands.get}
                        getKey={(u: models.Brand) => String(u.brand_id)}
                        getValue={(u) => u.title}
                      />
                      <SelectQueryListFilter
                        title={t("model.models")}
                        value={filterParams.device__brand_model__id__in}
                        setValue={forwardSetState(
                          setFilterParams,
                          "device__brand_model__id__in",
                        )}
                        querykey={querykeys.brandsModels.many}
                        apiCall={api.brandsModels.get}
                        getKey={(u: models.BrandModel) =>
                          String(u.brand_model_id)
                        }
                        getValue={(u) => u.title}
                      />
                    </Filters>
                  }
                />
                <ExportInFile
                  idList={checkedOrders.allCheckedCheckboxes}
                  queryKey="inventory-exports"
                  apiFunction={api.inventory.export}
                />
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            onRow={(data) => ({
              onClick: () => history.push(`inventory/${data?.id}`),
            })}
            rowKey="id"
          />
        </Card.Body>
      </Card>
      {changeStatusModal.isOpen && (
        <ConfirmationModal
          open={changeStatusModal.isOpen}
          onClose={changeStatusModal.close}
          onConfirm={mutate}
          isLoading={isLoading}
          title={t("template.edit_item", {
            item: t("inventory.inventories").toLowerCase(),
          })}
        >
          <div>
            {t("inventory.you_will_change_status_for_this_inventories")}
            <span className="fw-500">
              {` (${checkedOrders.allCheckedCheckboxes.sort().join(", ")})`}?
            </span>
            <SelectOptions
              style={{ marginTop: 10 }}
              selectOption={WAREHOUSE_STATE_OPTIONS.map((item) => ({
                name: item.value,
                value: item.key,
              }))}
              value={stateOption}
              onChange={setStateOption}
            />
          </div>
        </ConfirmationModal>
      )}

      {editInventoryModal.isOpen && (
        <EditInventoryModal
          open={editInventoryModal.isOpen}
          onClose={editInventoryModal.close}
          data={editInventoryModal.data}
          closeOnClickOutside={false}
        />
      )}
    </>
  );
};
