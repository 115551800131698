import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import {
  MAX_DOCUMENT_UPLOAD_SIZE_10,
  RECOMMENDED_RESOLUTION_2000_1500,
} from "app-constants";
import {
  Flex,
  InputFormField,
  LoadingButton,
  UploadImage,
  WhiteSpace,
} from "components";
import { SwitchFormField } from "components/form-fields";
import { useAuth, useMapErrors } from "hooks";
import models from "models";

interface ModelModalProps extends ModalProps {
  data?: models.ExtendedOrganizationBrandModel;
  onClose: () => void;
}
export const ModelModal = ({ data, onClose, ...props }: ModelModalProps) => {
  const { t } = useTranslation();
  const { organization } = useAuth();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const mapErrors = useMapErrors();

  const [modelImage, setModelImage] = React.useState<models.File>();

  const { mutate, isLoading } = useMutation(
    (values: models.ExtendedOrganizationBrandModel) =>
      api.brandsModels.upgrade(data?.brand_model_id, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.brandsModels.many());
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("model.model"),
          }),
        });
        onClose();
      },
      onError: (errors: unknown) => mapErrors(errors, notify, form),
    },
  );

  React.useEffect(() => {
    data?.logo && setModelImage(data?.logo);
  }, [data?.logo]);

  return (
    <Modal
      title={`${t("template.edit_item", {
        item: t("model.model").toLowerCase(),
      })} - ${data?.brand_model_id}`}
      size="small"
      onClose={onClose}
      {...props}
    >
      <Form
        form={form}
        onFinish={(values) => mutate({ logo_id: modelImage?.id, ...values })}
        initialValues={{
          max_price: Number(data?.max_price).toFixed(2),
          is_active: data?.is_active,
        }}
      >
        <Modal.Content>
          <UploadImage
            file={modelImage}
            setFile={setModelImage}
            showImage
            publicFile
            maxDocSize={MAX_DOCUMENT_UPLOAD_SIZE_10}
            recommendedResolution={RECOMMENDED_RESOLUTION_2000_1500}
          />
          <InputFormField
            name="max_price"
            label={t("price.max_price")}
            prefix={
              <span className="fw-500">{organization?.currency?.symbol}</span>
            }
          />
          <SwitchFormField
            label={t("common.active")}
            name="is_active"
            labelOptions={{
              col: {
                size: 10,
              },
              className: "d-flex",
              align: "center",
            }}
            controlOptions={{
              col: { size: 2 },
              className: "d-flex",
              justify: "end",
              align: "center",
            }}
          />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton submit loading={isLoading} type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
