import { createSvgIcon } from "../utils";

export const Eraser = createSvgIcon(
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0001 16.9998H5.50006L1.29006 12.6998C1.10381 12.5125 0.999268 12.259 0.999268 11.9948C0.999268 11.7306 1.10381 11.4772 1.29006 11.2898L11.2901 1.28982C11.4774 1.10357 11.7309 0.999023 11.9951 0.999023C12.2592 0.999023 12.5127 1.10357 12.7001 1.28982L17.7001 6.28982C17.8863 6.47718 17.9909 6.73063 17.9909 6.99482C17.9909 7.259 17.8863 7.51245 17.7001 7.69982L8.50006 16.9998M15.0001 10.2998L8.70006 3.99982"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
