import React from "react";
import { useDebounce } from "react-use";

import { Input } from "ebs-design";
import { InputProps } from "ebs-design/dist/components/atoms/Input/Input";

export interface DebouncedInputProps extends Omit<InputProps, "onChange"> {
  timeout?: number;
  value?: string;
  onChange?: (v: string) => void;
}

export const DebouncedInput = ({
  timeout = 500,
  value = "",
  onChange = () => {},
  ...props
}: DebouncedInputProps) => {
  const [currentValue, setCurrentValue] = React.useState(value);

  useDebounce(
    () => {
      // eslint-disable-next-line
      value != currentValue && onChange(String(currentValue || ""));
    },
    timeout,
    [currentValue],
  );

  React.useEffect(() => {
    // eslint-disable-next-line
    value != currentValue && setCurrentValue(value);
    // eslint-disable-next-line
  }, [value]);

  return (
    <Input
      {...props}
      value={currentValue}
      onChange={(v) => setCurrentValue(String(v))}
    />
  );
};
