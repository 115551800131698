import { Space } from "ebs-design";
import { Checkbox } from "./Checkbox";

interface Props {
  title: string;
  column: string;
  selectedColumns: string[];
  setSelectedColumns: (columns: string[]) => void;
}

export const ColumnCheckbox = ({
  title,
  column,
  selectedColumns,
  setSelectedColumns,
}: Props) => (
  <Space align="center">
    {title}
    <Checkbox
      onChange={(value) => {
        if (value) {
          setSelectedColumns([...selectedColumns, column]);
        } else {
          setSelectedColumns(selectedColumns.filter((c) => c !== column));
        }
      }}
    />
  </Space>
);
