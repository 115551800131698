import { AxiosError } from "axios";
import { Button, Loader, useNotify } from "ebs-design";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { NumberParam } from "use-query-params";

import { notifyErrors } from "utils";
import { useParam } from "hooks";
import api, { querykeys } from "api";
import { PageTitle, PDFViewer } from "components";
import { ACCESS_TOKEN_KEY } from "app-constants";

export const PayoutDetails = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const id = useParam("id", NumberParam.decode) || 0;

  const { data, isLoading } = useQuery(
    querykeys.payouts.one(id),
    () => api.payouts.getByID(id),
    {
      onError: (error: AxiosError) => notifyErrors(notify, error),
    },
  );

  const pdfDocument = `${
    data?.invoice?.attachment?.url
  }?token=${localStorage.getItem(ACCESS_TOKEN_KEY)}`;

  return (
    <>
      <PageTitle
        to="/crm/invoices/payouts"
        actions={
          <a href={pdfDocument} download target="_blank" rel="noreferrer">
            <Button type="primary"> {t("invoice.download_invoice")}</Button>
          </a>
        }
      >
        {t("invoice.invoice")} - {data?.invoice?.code}
      </PageTitle>
      <Loader loading={isLoading}>
        <PDFViewer file={pdfDocument} options={{}} />
      </Loader>
    </>
  );
};
