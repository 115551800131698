import { createSvgIcon } from "../utils";

export const ForSelect = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L7 7L13 1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
