import { FC, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Tabs, Spin, Row, Col } from "antd";
import Translations from "apps/cms/components/i18n/Translations";
import api, { Ii18n, Ii18nType } from "apps/cms/lib/api/i18n";

const { TabPane } = Tabs;

const I18n: FC = () => {
  const location = useLocation();
  const { type } = useParams<{ type: Ii18nType }>();
  const [loading, setLoading] = useState(true);
  const [namespaces, setNamespaces] = useState<Ii18n[]>([]);
  const [namespace, selectNamespace] = useState<string>();

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  useEffect(() => {
    const fetchNamespaces = async (): Promise<void> => {
      const data = await api.getAll({ code__in: params.code__in });

      setNamespaces(data.results);

      // set first namespace as selected
      if (data.results.length) {
        selectNamespace(String(data.results[0].id));
      }

      setLoading(false);
    };

      fetchNamespaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {loading ? (
          <Spin />
        ) : (
          <Tabs
            className="custom-tabs custom-tabs--hide-content"
            animated={false}
            onChange={selectNamespace}
            activeKey={namespace}
          >
            {namespaces.map((ns: Ii18n) => (
              <TabPane tab={ns.code} key={`${ns.id}`} />
            ))}
          </Tabs>
        )}
      </Col>
      <Col span={24}>
        <Translations
          loading={!namespace}
          lang={params?.lang}
          type={type}
          namespace={namespace}
        />
      </Col>
    </Row>
  );
};

export default I18n;
