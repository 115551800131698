import { useMemo } from "react";
import { Button } from "ebs-design";
import { useTranslation } from "react-i18next";

import { Flex } from "components";
import { useModalState } from "hooks";
import models from "models";

import { UpdatePriceModal } from "./UpdatePriceModal";
import { ChangeOrdersStatusModal, OrderDetailsProps } from ".";

export const SupervisorOrderDetails = ({
  data,
  orderId,
  changeDeviceStatusModal,
}: OrderDetailsProps) => {
  const { t } = useTranslation();

  const changeOrderStatusModal = useModalState<models.TypeWithIds>();
  const updatePriceModal = useModalState<string>();

  const canApproveReject =
    data?.hidden_status === models.OrderStatus.PENDING_COMPLETION ||
    data?.hidden_status === models.OrderStatus.PENDING_REJECTION;

  const deviceIsRejected =
    data?.device?.approval?.state === models.DeviceApprovalState.REJECTED;

  const needToUpdatePrice = useMemo(
    () =>
      data?.device?.diagnostic_criteria?.some(
        (item) => item.is_correct !== item.client_is_correct,
      ) &&
      data.hidden_status !== models.OrderStatus.PRICE_UPDATED &&
      canApproveReject,
    [canApproveReject, data?.device?.diagnostic_criteria, data.hidden_status],
  );

  if (
    [
      models.OrderStatus.REJECTED,
      models.OrderStatus.PRICE_UPDATED,
      models.OrderStatus.COMPLETED,
    ].includes(data?.hidden_status)
  ) {
    return null;
  }

  return (
    <>
      <Flex align="center" justify="flex-end">
        {needToUpdatePrice && (
          <Button
            type="dark"
            className="mr-24"
            onClick={() =>
              updatePriceModal.openWith(data?.device?.approval?.reason)
            }
          >
            {t("price.update_price")}
          </Button>
        )}
        {canApproveReject && (
          <>
            <Button
              type="light"
              onClick={() =>
                changeDeviceStatusModal.openWith({
                  status: models.OrderStatus.REJECTED,
                  ids: [String(orderId)],
                  reason: deviceIsRejected
                    ? data?.device?.approval?.reason
                    : "",
                })
              }
            >
              {t("common.reject")}
            </Button>

            {!needToUpdatePrice && (
              <Button
                className="ml-24"
                disabled={
                  data?.device?.status === models.DeviceStatus.WH_REJECTED
                }
                onClick={() =>
                  changeDeviceStatusModal.openWith({
                    status: models.OrderStatus.COMPLETED,
                    ids: [String(orderId)],
                    reason: deviceIsRejected
                      ? ""
                      : data?.device?.approval?.reason,
                  })
                }
                type="primary"
              >
                {t("common.approve")}
              </Button>
            )}
          </>
        )}
      </Flex>
      {changeOrderStatusModal.isOpen && (
        <ChangeOrdersStatusModal
          open={changeOrderStatusModal.isOpen}
          onClose={changeOrderStatusModal.close}
          data={changeOrderStatusModal.data}
        />
      )}
      {updatePriceModal.isOpen && (
        <UpdatePriceModal
          data={updatePriceModal.data}
          open={updatePriceModal.isOpen}
          onClose={updatePriceModal.close}
        />
      )}
    </>
  );
};
