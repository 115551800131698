import models from "models";

export interface PaymentProvider extends Omit<models.DefaultModel, "i18n"> {
  code: string;
  logo: models.File;
  type: string;
  fee: string;
  fee_type: FeeType;
}

export interface PaymentKeys extends Omit<models.DefaultModel, "i18n"> {
  api_key: string;
  public_api_key: string;
}

export type FeeType = "CURRENCY" | "PERCENT";

export enum FeeTypeSymbol {
  CURRENCY = "$",
  PERCENT = "%",
}

export enum PaymentMethodType {
  STRIPE = "STRIPE",
}
export interface PaymentMethod {
  id?: number;
  timestamp?: string;
  edited_timestamp?: string;
  code?: string;
  title?: string;
  fee?: string;
  fee_type?: FeeType;
  is_active?: boolean;
  type?: PaymentMethodType;
  logo?: number;
}

export enum FeeEnum {
  CURRENCY = "CURRENCY",
  PERCENT = "PERCENT",
}
