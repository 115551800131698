import { Select, useNotify } from "ebs-design";
import { OptionValue } from "ebs-design/dist/components/molecules/Select/interfaces";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { NumberParam, useQueryParams } from "use-query-params";

import { useDownloadFile } from "hooks";
import { CommaArrayParam, notifyErrors } from "utils";
import { GenericObject } from "types";
import models from "models";

const selectOption = [
  {
    value: ".xml",
    name: "XML",
  },
  {
    value: ".xlsx",
    name: "XLSX",
  },
  {
    value: ".csv",
    name: "CSV",
  },
  {
    value: ".pdf",
    name: "PDF",
  },
];

interface Props {
  columns?: string[];
  idList: string[];
  queryKey: string;
  apiFunction: (queryParams?: GenericObject<any>) => Promise<models.Attachment>;
}

export const ExportInFile = ({
  idList,
  queryKey,
  apiFunction,
  columns,
}: Props) => {
  const { t } = useTranslation();

  const notify = useNotify();
  const downloadFile = useDownloadFile();
  const [idParams, setIdParams] = useQueryParams({
    id__in: CommaArrayParam,
    limit: NumberParam,
  });

  useEffect(() => {
    setIdParams({ id__in: idList, limit: idList.length });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idList]);

  const [typeOfFile, setTypeOfFile] = useState<OptionValue | OptionValue[]>();
  const queryParams = {
    download_file_type: typeOfFile,
    ...idParams,
    keys: columns,
  };

  const { isLoading } = useQuery(
    [queryKey, typeOfFile],
    () => apiFunction(queryParams),
    {
      enabled: !!typeOfFile,
      onSuccess: (res) => downloadFile(res),
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Select
      loading={isLoading}
      className="select-no-mp-right export-files"
      placeholder={t("common.export")}
      size="medium"
      mode="single"
      value={typeOfFile}
      onChange={(value) => setTypeOfFile(value)}
    >
      <Select.Options emptyLabel={t("common.not_found")}>
        {selectOption.map((item) => (
          <Select.Options.Item
            id={item.name}
            key={item.name}
            value={item.value}
          >
            {item.name}
          </Select.Options.Item>
        ))}
      </Select.Options>
    </Select>
  );
};
