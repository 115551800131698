import { CSSProperties } from "react";

export interface DividerProps {
  space?: CSSProperties["padding"];
  style?: CSSProperties;
}

export const Divider = ({ space, style }: DividerProps) => {
  return (
    <div
      className="divider"
      style={{
        paddingTop: space,
        marginBottom: space,
        ...style,
      }}
    ></div>
  );
};
