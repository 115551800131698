import { Form, Input, Select } from "antd";
import { Properties } from "types";

import { formItemStyle } from "../helpers";

const Confirmation = ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "dataType"]}
        initialValue="success"
        label="Type"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="success">Success</Select.Option>
          <Select.Option value="blocked">Blocked</Select.Option>
        </Select>
      </Form.Item>
      {/* <Form.Item
        name={[item.id, lang, 'icon']}
        initialValue={getFieldValue('icon')}
        label="Icon"
        style={formItemStyle}
      >
        <IconSelect />
      </Form.Item> */}
      <Form.Item
        name={[item.id, lang, "title"]}
        label="Title"
        style={formItemStyle}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "description"]}
        label="Description"
        style={formItemStyle}
      >
        <Input.TextArea />
      </Form.Item>
    </>
  );
};

export default Confirmation;
