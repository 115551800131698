import { CURRENCY_CODE_TYPE } from "app-constants";
import models from "./index";

export enum StatusValue {
  ACTIVE = "active",
  INACTIVE = "inactive",
  BLOCKED = "blocked",
}

export enum PersonStatus {
  ONLINE = "online",
  OFFLINE = "offline",
}
export interface WithResultsDeprecated<T> {
  count?: number;
  next?: number;
  previous?: number;
  results?: T[];
}

export interface WithResults<T> {
  count?: number;
  total_pages?: number;
  current_page?: number;
  per_page?: number;
  results?: T[];
  unread?: number;
}

export interface PaginationQueryParams {
  limit: number;
  page: number;
}

export type ErrorResponse =
  | string[]
  | ErrorResponse[]
  | { [key: string]: ErrorResponse }
  | string;

export interface Currency {
  id?: number;
  code?: keyof CURRENCY_CODE_TYPE;
  is_active?: boolean;
  symbol?: string;
}

export interface Owner {
  id?: number;
  name?: string;
  email?: string;
}

export interface Attachment extends FormData {
  id?: number;
  public?: boolean;
  location?: string;
  url?: string;
  timestamp?: string;
  edited_timestamp?: string;
  title?: string;
  extension?: string;
  filename?: string;
  type?: string;
  size?: number;
  tags?: Tag[];
}

export interface Tag {
  id?: number;
  code?: string;
}

export enum Period {
  DAYS = "DAYS",
  SECONDS = "SECONDS",
  MINUTES = "MINUTES",
  HOURS = "HOURS",
}

export enum LanguageCode {
  EN = "en",
  RU = "ru",
  RO = "ro",
  DE = "de",
  IT = "it",
}

export enum Language {
  en = "English",
  ru = "Russian",
  ro = "Romanian",
  de = "Deutsch",
  it = "Italian",
}

export interface DefaultModel {
  id?: number;
  i18n?: I18N;
  timestamp?: string;
  edited_timestamp?: string;
  title?: string;
  description?: string;
  is_active?: boolean;
}

export interface TypeWithIds {
  status: models.OrderStatus;
  ids: string[];
  reason?: string;
}

export interface I18N {
  de: LanguageInfo;
  en: LanguageInfo;
  ro: LanguageInfo;
  ru: LanguageInfo;
}

export interface LanguageInfo {
  title: string;
  description: string;
  popup_description: string;
  price_translation: string;
}

export interface Image {
  id: number;
  url: string;
  size: number;
  extension: string;
}

export interface ShortCountry {
  id?: number;
  name?: string;
  code2?: string;
}

export enum AddressType {
  DROP_OFF_LOCATION = "DROP_OFF_LOCATION",
  PICK_UP_LOCATION = "PICK_UP_LOCATION",
  LOCATION = "LOCATION",
}

export interface Address {
  id?: number;
  title?: string;
  name?: string;
  first_name?: string;
  last_name?: string;
  city?: City;
  city_id?: number;
  region?: Region;
  region_id?: number;
  country?: ShortCountry;
  country_id?: number;
  zipcode?: string;
  address_lines?: string[];
  phone?: string;
  timestamp?: string;
  edited_timestamp?: string;
  company?: string;
  type?: AddressType;
}

export enum PayoutStatus {
  COMPLETED = "COMPLETED",
  PROCESSING = "PROCESSING",
  IN_PROCESS = "IN_PROCESS",
}

export interface City {
  id?: number;
  name?: string;
  region_id?: string;
  country_id?: string;
}

export interface Region {
  id?: number;
  name?: string;
  country_id?: string;
}

export type DayOfTheWeek =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";
