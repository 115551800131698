import { Button } from "ebs-design";
import { TFunction } from "react-i18next";

import { Flex, Status, WhiteSpace } from "components";
import * as Icons from "components/icons";
import models from "models";
import {
  formatPercent,
  formatPrice,
  formattedDateTime,
  TableColumnBuilder,
} from "utils";

interface PaymentColumnsProps {
  editPaymentKeysModal: React.Dispatch<models.PaymentKeys>;
  editPaymentProviderModal: React.Dispatch<models.PaymentProvider>;
  currency: models.Currency;
  t: TFunction;
}
export const providersColumns = ({
  editPaymentKeysModal,
  editPaymentProviderModal,
  currency,
  t,
}: PaymentColumnsProps) =>
  new TableColumnBuilder<models.PaymentProvider>()
    .add(t("common.id"), "id")
    .add(t("payment.payment_provider_name"), "title")
    .add(t("common.logo"), "", (record) => (
      <div className="table-img">
        {record?.logo && <img src={record?.logo?.url} alt={record.title} />}
      </div>
    ))
    .add(t("fee.app_fee"), "", (row: models.PaymentProvider) =>
      row.fee_type === models.FeeEnum.CURRENCY
        ? formatPrice(row.fee, currency?.code)
        : formatPercent(row.fee),
    )
    .add(t("common.creation_date"), "timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add(t("common.modified_date"), "edited_timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add(t("common.status"), "is_active", (status) => (
      <Status
        status={status}
        value={status ? models.StatusValue.ACTIVE : models.StatusValue.INACTIVE}
      />
    ))
    .add("", "", (row) => (
      <Flex justify="flex-end">
        <Button
          onClick={() => editPaymentProviderModal(row)}
          type="light"
          size="small"
          icon={() => <Icons.Edit size="small" />}
        />
        <WhiteSpace h="10px" />
        <Button
          onClick={() => editPaymentKeysModal(row)}
          type="light"
          size="small"
          icon={() => <Icons.Key size="small" />}
        />
      </Flex>
    ))
    .getColumns();

export const paymentProviderSortOptions = [
  { title: "Provider name", value: "title" },
];
