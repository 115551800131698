export const languages = ["en", "de", "ru", "ro"] as const;

export const defaultLocale = languages[0];

export type Languages = "en" | "de" | "ru" | "ro";

export const i18n: { [key: string]: any } = {
  language: {
    ro: "Romanian",
    ru: "Russian",
    en: "English",
    de: "German",
    it: "Italian",
  },
};

export const isLanguage = (str: string): str is Languages => {
  return languages.includes(str as Languages);
};
