import { AxiosError } from "axios";
import { Card, useNotify } from "ebs-design";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useQueryParams } from "use-query-params";

import api, { querykeys } from "api";
import {
  ConfirmationModal,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import { Filters, SelectQueryListFilter } from "components/filters";
import {
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";
import models from "models";
import { CommaArrayParam, forwardSetState, notifyErrors } from "utils";

import { erasureOrdersColumns, erasureOrdersSortOptions } from "../config";

export const CdeOrders = () => {
  const { t } = useTranslation();
  const pagination = useQueryPagination();
  const queryClient = useQueryClient();
  const cancelOrderModal = useModalState<models.Order>();
  const notify = useNotify();
  const [filterParams, setFilterParams] = useQueryParams({
    brands: CommaArrayParam,
    models: CommaArrayParam,
  });

  const [ordering, setOrdering] = useOrderingQueryParam();
  const [search, setSearch] = React.useState("");

  const queryParams = {
    ...pagination.queryParams,
    search,
    ordering,
    is_data_erasure_only: true,
    device__brand_model__brand__id__in: filterParams.brands,
    device__brand_model__id__in: filterParams.models,
  };

  const query = useQuery(querykeys.crmOrders.many(queryParams), () =>
    api.orders.get(queryParams),
  );

  const cancelMutation = useMutation(
    (values: models.OrderStatusEntity) =>
      api.orders.changeStatus(cancelOrderModal.data?.id!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.crmOrders.many());
        notify.success({
          title: t("notification.order_cancelled_successfully"),
        });
        cancelOrderModal.close();
      },
      onError: (errors: AxiosError) => notifyErrors(notify, errors),
    },
  );

  const tableColumns = React.useMemo(
    () =>
      erasureOrdersColumns({
        cancelOrder: cancelOrderModal.openWith,
        t,
      }),
    [cancelOrderModal.openWith, t],
  );

  return (
    <>
      <PageTitle title={t("menu.erasure_orders")} count={query.data?.count} />
      <Card>
        <Card.Body>
          <QueryTable
            pagination={pagination}
            title={() => (
              <TableTitle
                className="px-0"
                search={search}
                setSearch={setSearch}
                sortOptions={erasureOrdersSortOptions}
                sortOrdering={ordering}
                onSortChange={setOrdering}
                filters={
                  <Filters
                    queryParams={filterParams}
                    setQueryParams={setFilterParams}
                  >
                    <SelectQueryListFilter
                      title={t("brand.brands")}
                      value={filterParams.brands}
                      setValue={forwardSetState(setFilterParams, "brands")}
                      querykey={querykeys.brands.many}
                      apiCall={api.brands.get}
                      getKey={(u) => String(u.brand_id)}
                      getValue={(u) => u.title}
                    />
                    <SelectQueryListFilter
                      title={t("model.models")}
                      value={filterParams.models}
                      setValue={forwardSetState(setFilterParams, "models")}
                      querykey={querykeys.brandsModels.many}
                      apiCall={api.brandsModels.get}
                      getKey={(u) => String(u.brand_model_id)}
                      getValue={(u) => u.title}
                    />
                  </Filters>
                }
              />
            )}
            query={query}
            columns={tableColumns}
            className="people-table"
          />
        </Card.Body>
      </Card>
      {cancelOrderModal.isOpen && (
        <ConfirmationModal
          size="small"
          open={cancelOrderModal.isOpen}
          onClose={cancelOrderModal.close}
          isLoading={cancelMutation.isLoading}
          title={t("order.cancel_order")}
          onConfirm={() =>
            cancelMutation.mutate({ status: models.OrderStatus.CANCELLED })
          }
        >
          <div className="py-30">
            <h3 className="text-center">
              {t("template.are_you_sure_you_want_to_cancel_this_item", {
                item: t("order.order").toLowerCase(),
              })}
              {" - "}
              <span className="primary-color">
                {cancelOrderModal.data?.code}
              </span>
              ?
            </h3>
          </div>
        </ConfirmationModal>
      )}
    </>
  );
};
