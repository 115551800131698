import React from "react";
import { Form } from "antd";
import { Properties } from "types";
import { ArticleEditor } from "apps/cms/components/Articles";

import { formItemStyle } from "../helpers";

const Text = ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        wrapperCol={{ span: 24 }}
        name={[item.id, lang, "text"]}
        style={{ ...formItemStyle, width: "calc(100% + 8px)" }}
      >
        <ArticleEditor features={false} height={60} />
      </Form.Item>
    </>
  );
};

export default Text;
