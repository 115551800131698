export const orderTableByColumn = (dataTable: any, column: string) => {
  if (!dataTable) return null;

  const fieldName = column && Object.keys(column)?.[0];
  const fieldValue = dataTable?.[0]?.[fieldName];

  if (typeof fieldValue === "string" && !fieldValue?.includes(".00")) {
    return column?.[fieldName] === "desc"
      ? [
          ...dataTable?.sort((a, b) =>
            a?.[fieldName]?.localeCompare(b?.[fieldName]),
          ),
        ]
      : [
          ...dataTable?.sort((a, b) =>
            b?.[fieldName]?.localeCompare(a?.[fieldName]),
          ),
        ];
  }

  if (typeof fieldValue === "string" && fieldValue?.includes(".00")) {
    dataTable = dataTable.map((item) => ({
      ...item,
      [fieldName]: Number(item[fieldName]),
    }));
  }

  if (typeof dataTable?.[0]?.[fieldName] === "number") {
    return column?.[fieldName] === "desc"
      ? [...dataTable?.sort((a, b) => a?.[fieldName] - b?.[fieldName])]
      : [...dataTable?.sort((a, b) => b?.[fieldName] - a?.[fieldName])];
  }
  return dataTable;
};

export const addCorrectId = (dataTable: any) => {
  if (!dataTable) return null;

  return dataTable.map((item, index) => {
    return {
      ...item,
      id: index + 1,
    };
  });
};
