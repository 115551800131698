import { AxiosResponse } from "axios";

import { apiAxios, authAxios } from "api/axios";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "app-constants";
import models from "models";

import api from ".";

export const clearLocalStorage = () => {
  // Remove tokens from local storage
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const authApi = {
  getUser: async () => {
    try {
      // Verify if the user token is valid
      // await api.authentication.verify(); //TODO: FIX THIS
      const user = await api.profile.get();
      return user;
    } catch (e) {
      throw e;
    }
  },

  logout: async () => {
    try {
      await authAxios.get("/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
        },
      });
    } finally {
      clearLocalStorage();
    }
  },

  // Reset password
  resetPassword: async (body: models.ResetPassword) => {
    const { data } = await apiAxios.post<
      models.ResetPassword,
      AxiosResponse<models.User>
    >(`/organization/authentication/reset`, body);

    return data;
  },
  confirmResetPassword: async (body: models.ConfirmResetPassword) => {
    const { data: tokens } = await apiAxios.post<
      models.ResetPassword,
      AxiosResponse<models.TokensResponse>
    >(`/organization/authentication/reset-confirm`, body);

    tokens?.refresh && localStorage.setItem(REFRESH_TOKEN_KEY, tokens?.refresh);
    tokens?.access && localStorage.setItem(ACCESS_TOKEN_KEY, tokens?.access);
    return tokens;
  },
  login: async (credentials: models.LoginCredentials) => {
    const { data } = await authAxios.post<
      models.LoginCredentials,
      AxiosResponse<models.User>
    >("/organization/authentication/login", credentials);

    return data;
  },
  resendCode: async (credentials: models.CodeConfirmation) => {
    await authAxios.post<models.CodeConfirmation>(
      "/organization/authentication/code",
      {
        ...credentials,
        type: models.CodeType.SIGN_IN_ACCOUNT,
      },
    );
  },

  confirmLogin: async (credentials: models.LoginCredentials) => {
    const { data: tokens } = await authAxios.post<
      models.LoginCredentials,
      AxiosResponse<models.TokensResponse>
    >("/organization/authentication/login-confirm", credentials);

    tokens?.refresh && localStorage.setItem(REFRESH_TOKEN_KEY, tokens?.refresh);
    tokens?.access && localStorage.setItem(ACCESS_TOKEN_KEY, tokens?.access);
    return tokens;
  },
  register: async (credentials: models.RegisterCredentials) => {
    const { data } = await authAxios.post<
      models.LoginCredentials,
      AxiosResponse<models.User>
    >("/organization/authentication/register", credentials);

    return data;
  },
  confirmRegister: async (credentials: models.RegisterConfirmCredentials) => {
    const { data: tokens } = await authAxios.post<
      models.LoginCredentials,
      AxiosResponse<models.TokensResponse>
    >("/organization/authentication/register-confirm", credentials);

    tokens?.refresh && localStorage.setItem(REFRESH_TOKEN_KEY, tokens?.refresh);
    tokens?.access && localStorage.setItem(ACCESS_TOKEN_KEY, tokens?.access);
    return tokens;
  },
  refresh: async (refresh: string) => {
    const { data: tokens } = await authAxios.post<models.TokensResponse>(
      `/organization/authentication/refresh`,
      {
        refresh: refresh,
      },
    );

    tokens?.refresh && localStorage.setItem(REFRESH_TOKEN_KEY, tokens?.refresh);
    localStorage.setItem(ACCESS_TOKEN_KEY, tokens?.access || "");
  },
};
