import { Card, Col, Row } from "ebs-design";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Flex, SettingsCard } from "components";
import models from "models";

export interface CustomizationCardProps {
  organizationData?: models.OrganizationSettings;
}
export const CustomizationCard = ({
  organizationData,
}: CustomizationCardProps) => {
  const { t } = useTranslation();
  const publicPageLink = organizationData?.domain
    ? `https://${organizationData?.domain}`
    : "";

  return (
    <Card className="full-height">
      <Card.Header>
        <Flex justify="space-between" align="center">
          <h3>{t("common.customization")}</h3>
          <Link className="fw-500" to="/settings/brand-customization">
            {t("common.edit")}
          </Link>
        </Flex>
      </Card.Header>
      <Card.Body>
        <Row g={3}>
          <Col size={12} sm={6} lg={12} xxl={6}>
            <SettingsCard
              thumbBgColor={organizationData?.accent_color}
              title={t("brand.brand_color")}
            />
          </Col>
          <Col size={12} sm={6} lg={12} xxl={6}>
            <SettingsCard
              imageUrl={organizationData?.logo?.url}
              title={t("brand.brand_logo")}
            />
          </Col>
          <Col size={12} sm={6} lg={12} xxl={6}>
            <SettingsCard
              imageUrl={organizationData?.settings?.logo?.url}
              title={t("common.certus_logo")}
            />
          </Col>
          <Col size={12} sm={6} lg={12} xxl={6}>
            <div className="settings-card">
              <h4>Link to public page</h4>
              <a href={publicPageLink} target="_blank" rel="noreferrer">
                {publicPageLink}
              </a>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
