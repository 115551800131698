import { AuthContainer } from "components";
import { useState } from "react";

import { LoginForm } from "../components";

export const Login = () => {
  const [needToConfirm, setNeedToConfirm] = useState(false);

  return (
    <AuthContainer
      header={needToConfirm ? <h3>Confirm email</h3> : <h3>Login</h3>}
    >
      <LoginForm
        needToConfirm={needToConfirm}
        setNeedToConfirm={setNeedToConfirm}
      />
    </AuthContainer>
  );
};
