import { Card, Space } from "ebs-design";
import { QueryParamConfigMap, SetQuery } from "use-query-params";

import {
  Flex,
  QueryContent,
  QueryContentProps,
  SelectPeriod,
  WhiteSpace,
} from "components";
import { compose } from "utils";

interface CardWithFilterWrapperProps {
  title: React.ReactNode;
  extra?: React.ReactNode;
  list?: {
    label: React.ReactNode;
    value: React.ReactNode;
  }[];
  action?: SetQuery<QueryParamConfigMap>;
  query?: QueryContentProps["query"] | QueryContentProps["query"][];
}

const CardWithFilterWrapperBody: React.FC<{
  query?: CardWithFilterWrapperProps["query"];
}> = ({ query, children }) => {
  return query ? (
    Array.isArray(query) ? (
      compose(
        ...query.map((query) => (composed: React.ReactNode) => (
          <QueryContent query={query}>{composed}</QueryContent>
        )),
      )(children)
    ) : (
      <QueryContent query={query}>{children}</QueryContent>
    )
  ) : (
    <>{children}</>
  );
};

export const CardWithFilterWrapper: React.FC<CardWithFilterWrapperProps> = ({
  title,
  extra,
  list,
  action = () => {},
  query,
  children,
}) => {
  return (
    <Card>
      <Card.Body>
        <Flex justify="space-between" align="center">
          <h3 className="font-size-18">{title}</h3>
          <Space>
            <SelectPeriod action={action} />
            {extra}
          </Space>
        </Flex>
        <WhiteSpace v="1rem" />
        <CardWithFilterWrapperBody query={query}>
          {list && (
            <>
              <Flex gap="1rem" wrap>
                {list.map(({ label, value }, i) => (
                  <Space key={i} size={6}>
                    <span className="grey-400">{label}:</span>
                    <b>{value}</b>
                  </Space>
                ))}
              </Flex>
              <WhiteSpace v="1rem" />
            </>
          )}
          {children}
        </CardWithFilterWrapperBody>
      </Card.Body>
    </Card>
  );
};
