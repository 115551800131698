import { Card, Col, Row, useNotify } from "ebs-design";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import api, { querykeys } from "api";
import { notifyErrors } from "utils";

import { LocationCard } from "./LocationCard";

export const DropOffAndProcessLocation = () => {
  const { t } = useTranslation();
  const notify = useNotify();

  const { data } = useQuery(
    querykeys.nomenclature.statistics.dropProcessLocation(),
    () => api.statistics.getDropProcessLocations(),
    { onError: (error: Error) => notifyErrors(notify, error) },
  );

  return (
    <Card>
      <Card.Body>
        <h3 className="font-size-18">
          {`${t("common.drop_off_location")} & ${t(
            "process_locations.process_locations",
          )}`}
        </h3>
        <Row g={3} className="mt-12">
          <Col size={12} md={12} xxl={6}>
            <LocationCard
              title={t("common.drop_off_location")}
              link="/warehouse/drop-off-locations/create"
              value={data?.drop_off_locations?.total}
              statistics={[
                {
                  title: t("common.active"),
                  count: data?.drop_off_locations?.active,
                  className: "success-color",
                },
                {
                  title: t("common.inactive"),
                  count: data?.drop_off_locations?.inactive,
                  className: "danger-color",
                },
              ]}
            />
          </Col>
          <Col size={12} md={12} xxl={6}>
            <LocationCard
              title={t("process_locations.process_locations")}
              link="/warehouse/warehouses/create"
              value={data?.process_locations?.total}
              statistics={[
                {
                  title: t("common.active"),
                  count: data?.process_locations?.active,
                  className: "success-color fw-600",
                },
                {
                  title: t("common.inactive"),
                  count: data?.process_locations?.inactive,
                  className: "danger-color fw-600",
                },
              ]}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
