import { NotifyContainer, NotifyProvider } from "ebs-design";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { I18nextProvider } from "react-i18next";

import api from "api";
import { AuthProvider } from "context";
import i18n from "translation/i18n";

import { AppRouter } from "./AppRouter";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <QueryParamProvider ReactRouterRoute={Route}>
            <AuthProvider {...api.authApi}>
              <NotifyProvider>
                <NotifyContainer />
                <AppRouter />
              </NotifyProvider>
            </AuthProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </I18nextProvider>
  );
}
