import { createSvgIcon } from "../utils";

export const Filter = createSvgIcon(
  <svg
    height="1em"
    width="1em"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 5H18.5C18.644 5.05051 18.7746 5.13331 18.8816 5.24206C18.9887 5.35082 19.0695 5.48264 19.1177 5.62742C19.166 5.77221 19.1805 5.92612 19.1601 6.07737C19.1397 6.22861 19.0849 6.37318 19 6.5L14 12V19L10 16V12L5 6.5C4.91509 6.37318 4.86034 6.22861 4.83993 6.07737C4.81952 5.92612 4.834 5.77221 4.88226 5.62742C4.93053 5.48264 5.01129 5.35082 5.11836 5.24206C5.22544 5.13331 5.35598 5.05051 5.5 5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    ></path>
  </svg>,
);
