export const TIME_PERIOD = [
  {
    value: "DAYS",
    text: "days",
  },
  {
    value: "SECONDS",
    text: "seconds",
  },
  {
    value: "MINUTES",
    text: "minutes",
  },
  {
    value: "HOURS",
    text: "hours",
  },
];
