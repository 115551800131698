import { Button } from "ebs-design";
import { TFunction } from "react-i18next";

import { Flex, ScheduleViewer, Status } from "components";
import * as Icons from "components/icons";
import models from "models";
import { TableColumnBuilder } from "utils";

interface WarehouseColumnsProps {
  deleteWarehouseModal: React.Dispatch<number | string | undefined>;
  t: TFunction;
}

export const warehouseColumns = ({
  deleteWarehouseModal,
  t,
}: WarehouseColumnsProps) =>
  new TableColumnBuilder<models.Profile>()
    .add(t("common.id"), "id")
    .add(t("common.location_name"), "title")
    .add(t("common.description"), "description")
    .add(t("common.address"), "address", (row) => (
      <div className="min-w-200">{row}</div>
    ))
    .add(t("common.working_hours"), "working_hours", (workingHours) => (
      <ScheduleViewer workingHours={workingHours} />
    ))
    .add(t("common.default"), "is_default", (is_default) => (
      <>{is_default ? t("common.yes") : t("common.no")}</>
    ))
    .add(t("common.status"), "is_active", (status) => (
      <Status
        status={status}
        value={status ? models.StatusValue.ACTIVE : models.StatusValue.INACTIVE}
      />
    ))
    .add("", "", (row: models.Warehouse) => (
      <Flex justify="flex-end" onClick={(e) => e.stopPropagation()}>
        <Button
          type="light"
          onClick={() => deleteWarehouseModal(row?.id)}
          size="small"
          icon={() => <Icons.Trash size="small" />}
        />
      </Flex>
    ))
    .getColumns();
