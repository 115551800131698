import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import {
  Flex,
  Grid,
  InputFormField,
  LoadingButton,
  UploadImage,
  WhiteSpace,
} from "components";
import { EmailFields, SwitchFormField } from "components/form-fields";
import models from "models";
import { CurrencySymbolType } from "types";
import { formatNumber } from "utils";
import { useMapErrors } from "hooks";

interface EditServiceModalProps extends ModalProps {
  currency: CurrencySymbolType;
  data?: models.ExtraService;
  onClose: () => void;
}
export const EditServiceModal = ({
  data,
  currency,
  onClose,
  ...props
}: EditServiceModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const mapErrors = useMapErrors();

  const [serviceImage, setServiceImage] = useState<models.File>();

  const { mutate, isLoading } = useMutation(
    (values: models.ExtraService) =>
      api.settings.updateService(data?.id!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.settings.service.many());
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("common.theme_settings"),
          }),
        });
        onClose();
      },
      onError: (errors: any) => mapErrors(errors, notify, form),
    },
  );

  useEffect(() => {
    data?.image && setServiceImage(data?.image);
  }, [data?.image]);

  const [canEditPriceTranslation, setCanEditPriceTranslation] = useState(
    Number(data?.price) === 0,
  );

  return (
    <Modal
      title={t("common.edit_service_certified_data_erasure")}
      size="small"
      onClose={onClose}
      className="edit-service-modal"
      {...props}
    >
      <Form
        form={form}
        name="service"
        onFinish={(values) => mutate({ image_id: serviceImage?.id, ...values })}
        initialValues={{
          description: data?.description,
          emails: data?.emails,
          is_active: data?.is_active,
          is_email_notification_active: data?.is_email_notification_active,
          is_mandatory: data?.is_mandatory,
          popup_description: data?.popup_description,
          price: formatNumber(data?.price),
          price_translation: data?.price_translation,
          title: data?.title,
        }}
        className="service-form"
      >
        <Modal.Content>
          <InputFormField name="title" label={t("common.service_name")} />
          <UploadImage
            file={serviceImage}
            setFile={setServiceImage}
            showImage
            publicFile
          />
          <InputFormField
            name="description"
            label={t("common.description")}
            textarea
          />
          <InputFormField
            name="popup_description"
            label={t("common.pop_up_description")}
            textarea
          />
          <div className="horizontal-line" />
          <Grid cols="160px 1fr" gap="8px">
            <InputFormField
              getValueFromEvent={(price) => {
                setCanEditPriceTranslation(
                  parseInt(price) === 0 || price === "",
                );
                return price;
              }}
              name="price"
              label={t("price.price")}
              prefix={<span className="fw-600">{currency}</span>}
            />
            <InputFormField
              disabled={!canEditPriceTranslation}
              name="price_translation"
              label={t("price.price_translation")}
            />
          </Grid>
          <div className="horizontal-line" />
          <Flex align="center" justify="space-between" className="mb-4">
            <div>{t("common.email_notification")}</div>
            <SwitchFormField name="is_email_notification_active" />
          </Flex>
          <Form.Field>
            {(_, __, form) =>
              form.getFieldValue("is_email_notification_active") ? (
                <EmailFields />
              ) : null
            }
          </Form.Field>
          <SwitchFormField
            label={t("common.mandatory")}
            name="is_mandatory"
            labelOptions={{
              col: {
                size: 10,
              },
            }}
            controlOptions={{
              col: { size: 2 },
              className: "d-flex",
              justify: "end",
              align: "center",
            }}
          />
          <div className="horizontal-line" />
          <SwitchFormField
            label="Active"
            name="is_active"
            labelOptions={{
              col: {
                size: 10,
              },
              className: "d-flex",
              align: "center",
            }}
            controlOptions={{
              col: { size: 2 },
              className: "d-flex",
              justify: "end",
              align: "center",
            }}
          />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton submit loading={isLoading} type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
