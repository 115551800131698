import { cmsAxios } from "api/axios";
import { stringifyUrl } from "utils";
import { Properties } from "types";
import { Tag } from "./tags";

export enum Locale {
  EN = "en",
  RO = "ro",
  RU = "ru",
}

export interface Content {
  title: string;
  content?: string;
  preview?: string;
  contact?: string;
  slug?: string;
}

export interface GalleryItem {
  url: string;
  caption?: string;
}

export type VersionType = 1 | 2;
// export type PersonType = VersionType;
export type StateType = VersionType | 3;

export interface Article {
  id: number;
  timestamp: string;
  edited_timestamp: string;
  publish_date: string;
  data: Properties;
  images: string[];
  videos: string[];
  gallery: Properties[];
  state: StateType;
  author: number;
  state_changed: string;
  order: number;
  background: string;
  email_sent: boolean;
  parents: number[];
  parents_objects: Properties[];
  i18n: I18n;
  children: Article[];
  tags: Tag[];
  children_objects: ChildrenObjects[];
}

export interface ChildrenObjects {
  id: number;
  i18n: I18n;
  tags: Tag[];
}

export interface I18n {
  [key: string]: I18nLanguageArticle;
}

export interface I18nLanguageArticle {
  contact: string | null;
  content: string;
  preview: string | null;
  slug: string;
  title: string;
}

export enum ArticleState {
  PUBLIC = 1,
  DRAFT = 2,
  INACTIVE = 3,
}

export enum LayoutVariant {
  DEFAULT = "default",
  // BANNER = 'banner',
  FULLSCREEN = "fullscreen",
}

export const StateT: { [key in ArticleState]: string } = {
  [ArticleState.PUBLIC]: "Public",
  [ArticleState.DRAFT]: "Draft",
  [ArticleState.INACTIVE]: "Inactive",
};

export const StateTColors: { [key in ArticleState]: string } = {
  [ArticleState.PUBLIC]: "green",
  [ArticleState.DRAFT]: "orange",
  [ArticleState.INACTIVE]: "",
};

const pages = {
  all: async (params: any = {}) => {
    const query = new URLSearchParams(params);

    [...query.entries()].forEach(([name, value]) => {
      if (!value || value === "undefined") {
        query.delete(name);
      }
    });

    const { data } = await cmsAxios.get(
      stringifyUrl(`article/?${query.toString()}`, query),
    );

    return data;
  },
  bySlug: async (slug: string) => {
    const { data } = await cmsAxios.get(stringifyUrl(`article/${slug}/`));

    return data;
  },
  upsert: async (article: Article) => {
    const id = article.id ? `${article.id}/` : "";
    const method = id ? cmsAxios.patch : cmsAxios.post;

    const { data } = await method(stringifyUrl(`article/${id}`), article);

    return data;
  },
  uploadFile: async (file: File) => {
    const formData = new FormData();
    formData.append("upload", file);

    const { data } = await cmsAxios.post(
      stringifyUrl("upload-file/?upload"),
      formData,
    );

    return data;
  },
  remove: async (id: number) => {
    const { data } = await cmsAxios.delete(stringifyUrl(`article/${id}/`));

    return data;
  },
};

export default pages;
