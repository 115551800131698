import { Button, Input, List } from "ebs-design";
import { useTranslation } from "react-i18next";

import { Flex, WhiteSpace } from "components";
import * as Icons from "components/icons";

import { LabelField } from "./LabelField";

interface Props {
  disabled?: boolean;
  name?: string;
}
export const EmailFields = ({ disabled, name = "emails" }: Props) => {
  const { t } = useTranslation();

  return (
    <List name={name}>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <LabelField
                {...field}
                rules={[
                  {
                    type: "email",
                    message: t("validation.required_email"),
                  },
                ]}
              >
                {(control, _, form) => {
                  const values = form.getFieldValue(name);
                  if (values?.filter((v) => v === control.value).length > 1) {
                    remove(index);
                  }

                  return (
                    <Flex className="full-width">
                      <Input size="large" {...control} disabled={disabled} />
                      <WhiteSpace h="8px" />
                      <Button
                        type="light"
                        size="large"
                        disabled={fields.length < 2}
                        onClick={() => remove(index)}
                        icon={() => <Icons.Trash size="small" />}
                      />
                    </Flex>
                  );
                }}
              </LabelField>
            ))}

            <Button type="primary" onClick={() => add()}>
              {t("template.add_item", {
                item: t("common.email").toLowerCase(),
              })}
            </Button>
          </div>
        );
      }}
    </List>
  );
};
