import { Button } from "ebs-design";
import { TFunction } from "react-i18next";

import { NO_VALUE_FALLBACK } from "app-constants";
import { Flex, Status, UserInfo, WhiteSpace } from "components";
import * as Icons from "components/icons";
import { StepsLabel } from "components/steps/StepsLabel";
import models from "models";
import { capitalizeString, formattedDateTime, TableColumnBuilder } from "utils";

import { InviteStaffUsers, PermissionsForm } from "./components";

export const peopleTabs = [
  {
    label: <StepsLabel index={1} title="Invite internal users" />,
    content: <InviteStaffUsers />,
  },
  {
    label: <StepsLabel index={2} title="Set permissions" />,
    content: <PermissionsForm />,
  },
];
interface PeopleColumnsProps {
  activeUsers: boolean;
  onEditClick: React.Dispatch<models.User>;
  onDelete: React.Dispatch<models.User>;
  t: TFunction;
}

interface InviteColumnsProps {
  t: TFunction;
}

export const staffColumns = ({
  activeUsers,
  onEditClick,
  onDelete,
  t,
}: PeopleColumnsProps) =>
  new TableColumnBuilder<models.User>()
    .add(t("user.user"), "", (record: models.User) => (
      <Status status={record?.is_online} value={record?.name} person />
    ))
    .add(t("common.email"), "email")
    .add(t("department.department"), ["department", "title"], (title) =>
      capitalizeString(title, "_"),
    )
    .add(t("common.role"), ["profile", "roles_name"], (role: string[]) => {
      const roleCapitalize = role?.map((item) => capitalizeString(item, "_"));
      return roleCapitalize?.length
        ? roleCapitalize.join(", ")
        : NO_VALUE_FALLBACK;
    })
    .add(
      t("process_locations.process_locations"),
      ["warehouse", "title"],
      (title) => capitalizeString(title, "_"),
    )
    .add(t("common.last_activity"), "", (record: models.User) =>
      record?.is_online ? "online" : formattedDateTime(record?.last_login),
    )
    .add(t("common.creation_date"), "timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add(t("common.created_by"), "created_by", (created_by) => (
      <UserInfo name={created_by?.name} email={created_by?.email} />
    ))
    .addIf(
      !activeUsers,
      t("common.deleted_date"),
      "deleted_timestamp",
      (timestamp) => formattedDateTime(timestamp),
    )
    .addIf(!activeUsers, t("common.deleted_by"), "deleted_by", (deleted_by) => (
      <UserInfo name={deleted_by?.name} email={deleted_by?.email} />
    ))
    .addIf(activeUsers, t("common.status"), "is_active", (value: boolean) => (
      <Status status={value} isBlocked />
    ))
    .addIf(activeUsers, "", "", (record: models.User) => (
      <Flex justify="flex-end">
        <Button
          type="light"
          size="small"
          icon={() => <Icons.Edit size="small" />}
          onClick={() => onEditClick(record)}
        />
        <WhiteSpace h="8px" />
        <Button
          type="light"
          size="small"
          icon={() => <Icons.Trash size="small" />}
          onClick={() => onDelete(record)}
        />
      </Flex>
    ))
    .getColumns();

export const invitedColumns = ({ t }: InviteColumnsProps) =>
  new TableColumnBuilder<models.Invite>()
    .add(t("common.name"), ["user", "name"])
    .add(t("common.email"), ["user", "email"])
    .add(t("common.role"), "roles", (role: { name: string }[]) => {
      const roleCapitalize = role?.map(({ name }) =>
        capitalizeString(name, "_"),
      );
      return roleCapitalize?.length
        ? roleCapitalize.join(", ")
        : NO_VALUE_FALLBACK;
    })
    .add(t("common.last_activity"), ["user", "last_login"], formattedDateTime)
    .add(t("common.invite_email"), "email")
    .add(t("common.invite_date"), "timestamp", formattedDateTime)
    .getColumns();

export const StaffSortOptions = [
  { title: "Name", value: "name" },
  { title: "Creation date", value: "timestamp" },
  { title: "Last activity", value: "last_login" },
];
