import { Form, Select, useForm, useNotify } from "ebs-design";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import { Divider } from "components";
import { notifyErrors } from "utils";
import models from "models";

export const CurrencySettings = () => {
  const { t } = useTranslation();

  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const query = useQuery(querykeys.settings.organization, () =>
    api.settings.getOrganizationData(),
  );

  const { data: currencyOptions, isLoading } = useQuery(
    querykeys.nomenclature.currency.many(),
    () => api.nomenclatures.currencies.get(),
    {
      select: (data) => data.results,
    },
  );

  const { mutate } = useMutation(
    (body: models.OrganizationSettings) =>
      api.settings.updateOrganizationData(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.settings.organization);
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const selectedCurrency = query.data?.currency;

  useEffect(() => {
    form.setFieldsValue({ currency_id: selectedCurrency?.id });
  }, [form, selectedCurrency?.id]);

  return (
    <Form
      form={form}
      onValuesChange={(value) =>
        mutate({
          currency_id: value.currency_id,
        })
      }
    >
      <h2 className="font-size-18">{t("common.currency")}</h2>
      <Divider space="16px" />
      <Form.Field name="currency_id" initialValue={selectedCurrency?.id}>
        <Select
          style={{ maxWidth: 280, marginBottom: 0 }}
          loading={isLoading}
          mode="single"
        >
          <Select.Options emptyLabel={t("common.not_found")}>
            {currencyOptions?.map((item) => {
              return (
                <Select.Options.Item key={item?.id} value={item.id || 0}>
                  {item.code}
                </Select.Options.Item>
              );
            })}
          </Select.Options>
        </Select>
      </Form.Field>
    </Form>
  );
};
