import { cmsAxios } from "api/axios";
import { stringifyUrl } from "utils";

export interface QandAContent {
  question: string;
  answer: string;
}

export interface QandA {
  id: number;
  order: number;
  upvote: number;
  downvote: number;
  i18n: Record<string, QandAContent>;
  edited_timestamp: string;
}

const faq = {
  /**
   * Get all FAQs
   */
  getAll: async (params: any = {}) => {
    const { data } = await cmsAxios.get(
      stringifyUrl(`faqs/`, { page_size: "10", ...params }, { version: 2 }),
    );

    return data;
  },

  /**
   * get FAQ by ID
   */
  byId: async (id: number) => {
    const { data } = await cmsAxios.get(
      stringifyUrl(`faqs/${id}/`, {}, { version: 2 }),
    );

    return data;
  },
  /**
   * Remove a FAQ by id
   */
  remove: async (id: number) => {
    await cmsAxios.delete(stringifyUrl(`faqs/${id}/`, {}, { version: 2 }));
  },
  /**
   * `Insert`, either `Update` a FAQ item based on existence of id.
   */
  upsert: async (question: QandA): Promise<QandA> => {
    const id = question.id ? `${question.id}/` : "";

    const method = id ? cmsAxios.patch : cmsAxios.post;

    const { data } = await method(
      stringifyUrl(`faqs/${id}`, {}, { version: 2 }),
      question,
    );

    return data as QandA;
  },
};

export default faq;
