import { Button } from "ebs-design";
import { TFunction } from "react-i18next";
import { Link } from "react-router-dom";

import defaults from "app-defaults";
import { Flex, ImageOrIconPreview, WhiteSpace } from "components";
import * as Icons from "components/icons";
import models from "models";
import {
  fileToken,
  formatBytes,
  formattedDateTime,
  TableColumnBuilder,
  transformFileExtension,
} from "utils";

interface FilesColumnsProps {
  onEdit: React.Dispatch<models.Attachment>;
  onDelete: React.Dispatch<models.Attachment>;
  onFeatured: (imageUrl: string) => void;
  t: TFunction;
}

export const filesColumns = ({
  onEdit,
  onDelete,
  onFeatured,
  t,
}: FilesColumnsProps) =>
  new TableColumnBuilder<models.User>()
    .add(t("file.thumbnail"), "", (file: models.Attachment) => (
      <ImageOrIconPreview
        file={file}
        onFeatured={() =>
          onFeatured(fileToken(file) || defaults.defaultImageFallback)
        }
      />
    ))

    .add(t("common.name"), "title")
    .add(t("common.tag"), "tags", (record: models.Tag[]) =>
      record.map((v) => <Link to="/">#{v.code}; </Link>),
    )
    .add(t("file.size"), "size", (record: number) => formatBytes(record))
    .add(t("common.type"), "extension", transformFileExtension)
    .add(t("common.public"), "public", (isPublic: boolean) =>
      isPublic ? t("common.yes") : t("common.no"),
    )
    .add(t("common.last_activity"), "edited_timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add(t("common.creation_date"), "timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add("", "", (record: models.Attachment) => (
      <Flex justify="flex-end">
        <Button
          type="light"
          size="small"
          icon={() => <Icons.Edit size="small" />}
          onClick={() => onEdit(record)}
        />
        <WhiteSpace h="8px" />
        <Button
          type="light"
          size="small"
          icon={() => <Icons.Trash size="small" />}
          onClick={() => onDelete(record)}
        />
      </Flex>
    ))
    .getColumns();

export const filesSortOptions = [
  { title: "Creation date", value: "timestamp" },
  { title: "Last activity", value: "edited_timestamp" },
];
