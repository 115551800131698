import React from "react";
import { Form, Select } from "antd";
import { Properties } from "types";

import { formItemStyle } from "../helpers";

const SectionMain = ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "g"]}
        initialValue={"4"}
        label="Size"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="0">None</Select.Option>
          <Select.Option value="1">Extra Small</Select.Option>
          <Select.Option value="2">Small</Select.Option>
          <Select.Option value="3">Medium</Select.Option>
          <Select.Option value="4">Large</Select.Option>
          <Select.Option value="5">Extra Large</Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};

export default SectionMain;
