import { createSvgIcon } from "../utils";

export const PhoneClassic = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <rect x="7" y="4" width="10" height="16" rx="1" />
    <line x1="11" y1="5" x2="13" y2="5" />
    <line x1="12" y1="17" x2="12" y2="17.01" />
  </svg>,
);
