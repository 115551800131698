import { Button, Form, Modal, Select, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import api, { querykeys } from "api";
import {
  Flex,
  InputFormField,
  LoadingButton,
  WhiteSpace,
  Divider,
  EmailFields,
  SwitchFormField,
} from "components";
import { notifyErrors, TIME_PERIOD, translateIfIsTranslatable } from "utils";
import models from "models";
import { ReminderModalArgs } from "./ReminderCard";

interface EditLoadTimeModalProps extends ModalProps {
  data?: ReminderModalArgs;
  onClose: () => void;
}

enum DataField {
  titlePrice = "price_update_approval",
  periodPrice = "PRICE_UPDATE_APPROVAL_PERIOD",
  approvalPrice = "PRICE_UPDATE_APPROVAL",
  emailsPrice = "PRICE_UPDATE_APPROVAL_EMAILS",
  titleReject = "rejection_status_approval",
  periodReject = "REJECTION_STATUS_APPROVAL_PERIOD",
  approvalReject = "REJECTION_STATUS_APPROVAL",
  emailsReject = "REJECTION_STATUS_APPROVAL_EMAILS",
}

const NAME_FIELD = {
  price: {
    title: DataField.titlePrice,
    period: DataField.periodPrice,
    approval: DataField.approvalPrice,
    emails: DataField.emailsPrice,
  },
  reject: {
    title: DataField.titleReject,
    period: DataField.periodReject,
    approval: DataField.approvalReject,
    emails: DataField.emailsReject,
  },
};

export const EditLoadTimeModal = ({
  data,
  onClose,
  ...props
}: EditLoadTimeModalProps) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (body: models.DefaultSettingsData) => {
      return api.settings.updateDefaultSettings({
        ...data?.dataDefault,
        ...body,
        [NAME_FIELD[type].emails]: body[NAME_FIELD[type].emails] || [],
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.settings.defaultSettings);
        notify.success({
          title: t("template.item_updated_successfully", {
            item: t("common.loading_time"),
          }),
        });
        onClose();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const type = data?.type;

  const emails = data.dataDefault[NAME_FIELD[type].emails];
  const approval = data.dataDefault[NAME_FIELD[type].approval];
  const period = data.dataDefault[NAME_FIELD[type].period];

  const timePeriodTranslated = TIME_PERIOD.map((item) => {
    return {
      ...item,
      text: translateIfIsTranslatable(t, item.text, "time"),
    };
  });

  return (
    <Modal
      title={translateIfIsTranslatable(t, NAME_FIELD[type].title, "all")}
      size="small"
      onClose={onClose}
      className="edit-service-modal"
      {...props}
    >
      <Form
        onFinish={(values) => {
          const arrayWithNotNullishEmails =
            values[NAME_FIELD[type]?.emails].filter(Boolean);
          mutate({
            ...values,
            [NAME_FIELD[type]?.emails]: arrayWithNotNullishEmails.length
              ? arrayWithNotNullishEmails
              : [],
          });
        }}
        className="service-form"
        initialValues={{
          [NAME_FIELD[type]?.emails]: emails,
          [NAME_FIELD[type].approval]: approval || "",
          [NAME_FIELD[type].period]: period,
        }}
      >
        <Modal.Content>
          <Flex className="select-modal">
            <InputFormField
              style={{ width: "80%", margin: 0 }}
              name={[NAME_FIELD[type].approval]}
              defaultValue={approval}
            />
            <Form.Field
              style={{ width: "20%", margin: 0 }}
              name={NAME_FIELD[type].period}
              initialValue={period}
            >
              <Select options={timePeriodTranslated} size="large" />
            </Form.Field>
          </Flex>

          <Divider space={16} />
          <Flex align="center" justify="space-between" className="mb-4">
            <div>{t("common.email_notification")}</div>
            <SwitchFormField
              name="is_email_notification_active"
              initialValue={true}
            />
          </Flex>
          <Form.Field>
            {(_, __, form) =>
              form.getFieldValue("is_email_notification_active") ? (
                <EmailFields name={NAME_FIELD[type].emails} />
              ) : null
            }
          </Form.Field>
        </Modal.Content>

        <Modal.Footer>
          <Flex justify="flex-end" align="center">
            <Button type="light" onClick={onClose}>
              {t("common.cancel")}
            </Button>
            <WhiteSpace h="8px" />
            <LoadingButton loading={isLoading} submit type="primary">
              {t("common.save")}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
