import { TFunction } from "react-i18next";

import {
  CardInfo,
  DiagnosticCriteria,
  TableCheckboxSelector,
  OrderStatus,
  TableCheckbox,
  UserInfo,
} from "components";
import { Check, Close } from "components/icons";
import models from "models";
import { CheckboxesCheckedInfoType } from "types";
import {
  capitalizeString,
  formatPercent,
  formatPrice,
  formattedDateTime,
  TableColumnBuilder,
  formatAddressInJSX,
} from "utils";
import { CriteriaUpdateCheckbox } from "./components";

interface OrdersColumnsProps {
  setCheckboxInfo?: React.Dispatch<
    React.SetStateAction<CheckboxesCheckedInfoType>
  >;
  checkboxInfo?: CheckboxesCheckedInfoType;
  t: TFunction;
  isSupervisor?: boolean;
  dataErasureVisibile: boolean;
}

export const ordersColumns = ({
  setCheckboxInfo,
  checkboxInfo,
  t,
  isSupervisor,
  dataErasureVisibile,
}: OrdersColumnsProps) => {
  const builder = new TableColumnBuilder<models.Order>();
  if (isSupervisor)
    builder.add(
      <TableCheckboxSelector
        word="orders"
        checkboxInfo={checkboxInfo}
        setCheckboxInfo={setCheckboxInfo}
      />,
      "",
      (row: models.Order) => (
        <TableCheckbox
          itemId={row?.id}
          checkboxInfo={checkboxInfo}
          setCheckboxInfo={setCheckboxInfo}
        />
      ),
    );
  builder
    .add(t("order.order_id"), ["parent", "code"])
    .add(t("order.suborder_id"), "code")
    .add(t("brand.brand"), ["device", "brand_model", "brand", "title"])
    .add(t("model.model"), "", (row) => (
      <div className="one-line max-w-150">
        {row?.device?.brand_model?.title}
      </div>
    ))
    .add(t("common.imei"), ["device", "imei"]);
  if (dataErasureVisibile)
    builder.add(t("common.data_erasure"), "", (row: models.Order) => {
      const dataErasure = row?.services?.find(
        (item) =>
          item?.code === models.ServiceCode.CERTUS_SOFTWARE_DATA_ERASURE,
      );
      return (
        <CardInfo
          show={!!dataErasure}
          info={[formatPrice(dataErasure?.price, row?.currency)]}
        />
      );
    });
  builder
    .add(t("common.secure_box"), "", (row: models.Order) => {
      const secureBox = row.services?.find(
        (item) => item.code === models.ServiceCode.SECURE_BOX,
      );
      return (
        <CardInfo
          show={!!secureBox}
          info={[formatPrice(secureBox?.price, row?.currency)]}
        />
      );
    })
    .add(t("payment.payment_method"), "", (row: models.Order) => {
      return (
        <CardInfo
          show={!!row?.payment_method}
          title={row?.payment_method?.title}
          info={[
            row?.payment_method?.fee_type === models.FeeEnum.CURRENCY
              ? formatPrice(row.fee, row?.currency)
              : formatPercent(row.fee),
          ]}
        />
      );
    })
    .add(
      t("common.drop_off_location"),
      "drop_off_location",
      (drop_off_location: models.DropOffLocation) => (
        <CardInfo
          left
          show={!!drop_off_location}
          title={drop_off_location?.title}
          info={[drop_off_location?.address, drop_off_location?.phone]}
        />
      ),
    )

    .add(t("shipping.shipping_provider"), "", (row: models.Order) => (
      <CardInfo
        left
        show={!!row?.shipping_provider}
        title={row?.shipping_provider?.title}
        infoWithFields={{
          type: row?.shipping_provider?.type,
          price: formatPrice(row?.shipping_provider?.price, row?.currency),
        }}
      />
    ))
    .add(t("common.address"), "address", (address: models.Address) =>
      formatAddressInJSX({
        postalCountry: address?.country?.code2,
        administrativeArea: address?.region?.name,
        locality: address?.country?.name,
        postalCode: address?.zipcode,
        organization: address?.company,
        addressLines: address?.address_lines,
      }),
    )
    .add(t("shipping.delivery_shipping_provider"), "", (row: models.Order) => (
      <CardInfo
        left
        show={!!row?.delivery_shipping_provider}
        title={row?.delivery_shipping_provider?.title}
        infoWithFields={{
          type: row?.delivery_shipping_provider?.type,
          price: formatPrice(
            row?.delivery_shipping_provider?.price,
            row?.currency,
          ),
        }}
      />
    ))
    .add(
      t("common.delivery_address"),
      "delivery_address",
      (delivery_address: models.Address) =>
        formatAddressInJSX({
          postalCountry: delivery_address?.country?.code2,
          administrativeArea: delivery_address?.region?.name,
          locality: delivery_address?.country?.name,
          postalCode: delivery_address?.zipcode,
          organization: delivery_address?.company,
          addressLines: delivery_address?.address_lines,
        }),
    )
    .add(t("common.approval"), "", (row: models.Order) => (
      <CardInfo
        left
        show={!!row?.device?.approval}
        infoWithFields={{
          state: capitalizeString(row?.device?.approval?.state, "_"),
          price: formatPrice(row?.device?.approval?.price, row?.currency),
          reason: row?.device?.approval?.reason,
        }}
      />
    ))
    .add(t("common.seller"), "", (row: models.Order) => (
      <UserInfo name={row?.client?.name} email={row?.client?.email} />
    ));

  if (isSupervisor) {
    builder.add(
      t("common.operator"),
      ["device", "approval", "owner"],
      (owner) => <UserInfo name={owner?.name} email={owner?.email} />,
    );
  }

  builder
    .add(t("common.condition"), "device", (device) => (
      <DiagnosticCriteria
        diagnosticCriteria={device?.diagnostic_criteria || []}
      />
    ))
    .add(t("common.arriving_date"), "timestamp", (timestamp) =>
      formattedDateTime(timestamp),
    )
    .add(t("order.order_status"), "hidden_status", (hidden_status) => (
      <OrderStatus orderStatus={hidden_status} />
    ))
    .add(t("order.client_order_status"), "status", (status) => (
      <OrderStatus orderStatus={status} />
    ));
  return builder.getColumns();
};

interface DiagnosticCriteriaColumnsProps {
  disabled?: boolean;
  areNoneCorrect?: boolean;
  t: TFunction;
}

export const diagnosticCriteriaColumns = ({
  disabled = false,
  areNoneCorrect,
  t,
}: DiagnosticCriteriaColumnsProps) =>
  new TableColumnBuilder<models.DiagnosticCriteria>()
    .add(t("common.client"), "", (row: models.DiagnosticCriteria) =>
      row?.client_is_correct ? (
        <Check color="success" />
      ) : (
        <Close color="danger" />
      ),
    )
    .add(t("common.operator"), "", (row: models.DiagnosticCriteria) => (
      <CriteriaUpdateCheckbox
        disabled={row?.is_default || disabled || !row?.client_is_correct}
        checked={
          (row?.is_default && areNoneCorrect) ||
          (!row?.is_default && row?.is_correct)
        }
        diagnosticCriteria={row}
        areNoneCorrect={areNoneCorrect}
      />
    ))
    .add(t("common.title"), "title")
    .getColumns();

export const warehouseOrdersOptions = [
  { title: "Provider name", value: "title" },
];

export const ordersSortOptions = [
  { title: "ID", value: "id" },
  { title: "Arriving time", value: "timestamp" },
  { title: "Seller", value: "client__name" },
  { title: "Operator", value: "operator" },
  { title: "Status", value: "hidden_status" },
];
