import React from "react";
import { Card } from "ebs-design";

import { makeBEM } from "utils";
import { Flex } from "components";
import logoImageSrc from "assets/BuyBack.png";
interface AuthContainerProps {
  header?: React.ReactNode;
  children?: React.ReactNode;
}

const bem = makeBEM("auth-layout");

export const AuthContainer = ({ header, children }: AuthContainerProps) => {
  return (
    <Flex className="auth-layout" direction="column" justify="center">
      <img src={logoImageSrc} alt="Logo" className="mb-24" />
      <Card className={bem("auth-card")}>
        <Card.Header>{header}</Card.Header>
        <Card.Body>{children}</Card.Body>
      </Card>
    </Flex>
  );
};
