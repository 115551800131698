import models from "models";
import { useAuth } from "./useAuth";

export interface IsRestrictedToOptions {
  /**
   * If logged user is admin and has this application in profile
   */
  app?: models.Apps;

  /**
   * If logged user has one of these roles
   */
  roles?: models.Roles[];

  mainAdmin?: boolean;
}

export function useIsRestrictedTo() {
  const { user } = useAuth();

  return ({ app, roles, mainAdmin = true }: IsRestrictedToOptions): boolean => {
    //
    roles = [
      ...(roles || []),
      ...(mainAdmin ? [models.Roles.MAIN_ADMINISTRATOR] : []),
    ];

    if (app && user?.profile?.applications?.includes(app)) {
      return true;
    }

    if (roles && user?.profile?.roles_name?.some((r) => roles.includes(r))) {
      return true;
    }

    return false;
  };
}
