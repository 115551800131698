import { Card } from "ebs-design";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { useQuery } from "react-query";
import { useQueryParams } from "use-query-params";
import { CommaArrayParam } from "utils";

import api, { querykeys } from "api";
import {
  FilterByOrderStatus,
  Flex,
  PageTitle,
  QueryTable,
  TableTitle,
} from "components";
import {
  useDataErasureVisibility,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";

import { ordersColumns, ordersSortOptions } from "../config";

export const OperatorOrders = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dataErasureVisibile = useDataErasureVisibility();

  const pagination = useQueryPagination();
  const [search, setSearch] = useState("");
  const [ordering, setOrdering] = useOrderingQueryParam();
  const [filterParams, setFilterParams] = useQueryParams({
    hidden_status__in: CommaArrayParam,
  });

  const queryParams = {
    parent_id__isnull: false,
    ...pagination.queryParams,
    ordering,
    search,
    ...filterParams,
  };

  const query = useQuery(querykeys.orders.many(queryParams), () =>
    api.orders.get(queryParams),
  );

  useUpdateEffect(
    () => pagination.setPage(1),
    [filterParams, ordering, search],
  );

  return (
    <>
      <PageTitle title={t("order.orders")} count={query.data?.count} />
      <Card>
        <Card.Body>
          <QueryTable
            variants={["highlight-hover"]}
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                  sortOptions={ordersSortOptions.filter(
                    (item) => item.title !== "Seller",
                  )}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                  filters={
                    <FilterByOrderStatus
                      currentParam={filterParams.hidden_status__in}
                      onChange={setFilterParams}
                    />
                  }
                />
              </Flex>
            )}
            query={query}
            columns={ordersColumns({
              isSupervisor: false, //hide selector
              t,
              dataErasureVisibile,
            })}
            onRow={(data) => ({
              onClick: () =>
                history.push(
                  `/crm/orders/warehouse-orders/${data?.id}/device/${data?.device?.id}`,
                ),
            })}
            rowKey="id"
          />
        </Card.Body>
      </Card>
    </>
  );
};
