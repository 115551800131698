import { QueryKey, useQuery, UseQueryResult } from "react-query";
import { useTranslation } from "react-i18next";
import { Link, Route } from "react-router-dom";

import * as Icons from "components/icons";
import { accessObjectField, makeBEM } from "../utils";

const bem = makeBEM("breadcrumbs");

interface ObjectBreadCrumbs {
  path: string;
  showName: (TFunction) => string;
  queryKeys?: (id: string | number) => QueryKey;
  apiCall?: (id: number) => Promise<unknown>;
  keyData?: string[];
  exact?: boolean;
}

interface BreadCrumbsItemType extends Omit<ObjectBreadCrumbs, "path"> {
  id?: string | number;
  url: string;
}

interface BreadCrumbsPropsType {
  breadcrumbs: ObjectBreadCrumbs[];
}

const BreadCrumbsItem = ({
  id = "",
  url,
  showName,
  queryKeys,
  apiCall,
  keyData,
}: BreadCrumbsItemType) => {
  const { t } = useTranslation();
  const query: UseQueryResult<any> = useQuery(
    queryKeys?.(id) || "",
    () => apiCall?.(+id),
    {
      enabled: !!id && !Number.isNaN(+id),
    },
  );

  if ((!!id && Number.isNaN(+id)) || query.isLoading) return null;
  const idCode = accessObjectField(query?.data, keyData) || id;
  const dataName = `${showName(t)} ${idCode && `- ${idCode}`}`;

  return (
    <div className={bem("item")}>
      <Link to={url}>{dataName}</Link>
      <Icons.ChevronRight width="0.6rem" height="0.6rem" />
    </div>
  );
};

export const BreadCrumbs = ({ breadcrumbs }: BreadCrumbsPropsType) => {
  return (
    <div className={bem()}>
      {breadcrumbs.map(
        ({ path, showName, queryKeys, apiCall, keyData, exact }, key) => (
          <Route
            path={path}
            exact={exact}
            key={key}
            render={(props) => {
              let { id, orderId } = props.match.params;
              !id && (id = orderId);

              const { url } = props.match;
              return (
                <BreadCrumbsItem
                  id={id}
                  url={url}
                  showName={showName}
                  queryKeys={queryKeys}
                  apiCall={apiCall}
                  keyData={keyData}
                />
              );
            }}
          />
        ),
      )}
    </div>
  );
};
