import { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { createRefreshTokenInterceptors } from "../api/axios/interceptors";

export function useRefreshInterceptor(
  axios: AxiosInstance,
  failCallback: () => void,
) {
  // Need to set the interceptor instantly (before the first request)
  const [id] = useState(() =>
    axios.interceptors.response.use(
      ...createRefreshTokenInterceptors(axios, failCallback),
    ),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => axios.interceptors.response.eject(id), []);
}
