import React from "react";

import { useAuthProvider, UseAuthProviderArgs } from "../hooks";

export type AuthContextValue = ReturnType<typeof useAuthProvider>;

export const AuthContext = React.createContext<AuthContextValue>({
  user: null,
  userEmail: undefined,
  isLoading: true,
  login: async () => {},
  logout: async () => {},
  confirmLogin: async () => {},
  confirmResetPassword: async () => {},
  resendCode: async () => {},
  error: null,
  organization: null,
  roles: undefined,
});

export const AuthProvider = ({
  children,
  ...apiCalls
}: React.PropsWithChildren<UseAuthProviderArgs>) => {
  const value = useAuthProvider(apiCalls);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
