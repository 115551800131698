import { useContext } from "react";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import { Button } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { onErrorImage } from "utils";

import GalleryContext from "./context";

type SortableItemType = { value: string; elIndex: number };

const SortableItem = SortableElement(({ value, elIndex }: SortableItemType) => {
  const { setActive, handleRemove } = useContext(GalleryContext);

  return (
    <div className="sortable-item">
      <img src={value} height="100%" alt="" onError={onErrorImage} />

      <div className="image-actions">
        <Button
          size="small"
          icon={<DeleteOutlined />}
          //type="danger"
          onClick={() => handleRemove(value)}
        />

        <Button
          size="small"
          icon={<EyeOutlined />}
          onClick={() => setActive({ index: elIndex, type: "preview" })}
        />

        <Button
          size="small"
          icon={<EditOutlined />}
          onClick={() => setActive({ index: elIndex, type: "description" })}
        />
      </div>
    </div>
  );
});

const SortableList = SortableContainer(({ items }: { items: string[] }) => (
  <div className="sortable-grid">
    {items.map((value: string, index: number) => (
      <SortableItem
        key={`item-${value}`}
        index={index}
        value={value}
        elIndex={index}
      />
    ))}
  </div>
));

const SortableItems: React.FC = () => {
  const { fileList, setFileList } = useContext(GalleryContext);

  const onSortEnd = ({ oldIndex, newIndex }: any): void => {
    setFileList(arrayMove(fileList, oldIndex, newIndex));
  };

  const items = fileList.map(({ url }) => url!);

  return <SortableList axis="xy" items={items} onSortEnd={onSortEnd} />;
};

export default SortableItems;
