import { Button, Card, Space, useNotify } from "ebs-design";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import api, { querykeys } from "api";
import { DepartmentFormModal, Flex, Table } from "components";
import { useModalState } from "hooks";
import models from "models";
import { notifyErrors, orderTableByColumn } from "utils";

import { departmentsColumns } from "../config";

export const UsersAndDepartments = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const departmentFormModal = useModalState<models.Department>();
  const [ordering, setOrdering] = useState(null);

  const { data } = useQuery(
    querykeys.nomenclature.statistics.departments(),
    () => api.statistics.getDepartments(),
    { onError: (error: Error) => notifyErrors(notify, error) },
  );
  const departmentsStatistic = useMemo(() => {
    return orderTableByColumn(data?.departments, ordering);
  }, [data, ordering]);

  return (
    <Card>
      <Card.Body>
        <Table
          title={() => (
            <Flex justify="space-between" align="flex-start">
              <div>
                <h3 className="font-size-18 mb-16">{`${t("user.users")} & ${t(
                  "menu.departments",
                )}`}</h3>
                <div>
                  {t("menu.departments")}:{" "}
                  <span className="mr-16 fw-600">
                    {data?.total_departments}
                  </span>
                  {t("menu.internal_users")}:{" "}
                  <span className="fw-600">{data?.total_users}</span>
                </div>
              </div>

              <Space>
                <Button onClick={departmentFormModal.open} type="light">
                  {t("template.add_item", {
                    item: t("department.department").toLowerCase(),
                  })}
                </Button>
                <Link to="/auth/internal-users/invite">
                  <Button type="light">{t("user.invite_user")}</Button>
                </Link>
              </Space>
            </Flex>
          )}
          data={departmentsStatistic}
          columns={departmentsColumns({ ordering, setOrdering, t })}
          variants={["white-title"]}
          rowKey="title"
        />
      </Card.Body>

      {departmentFormModal.isOpen && (
        <DepartmentFormModal
          open={departmentFormModal.isOpen}
          onClose={departmentFormModal.close}
          data={departmentFormModal.data}
          closeOnClickOutside={false}
        />
      )}
    </Card>
  );
};
