import React from "react";
import { Form, Select, Checkbox, Input } from "antd";
import { Properties } from "types";

import { formItemStyle } from "../helpers";
import { IconSelect } from "../";

const Label = ({ item, lang, setFieldsValue }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, "dataType"]}
        initialValue="regular"
        label="Type"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="regular">Regular</Select.Option>
          <Select.Option value="fill">Fill</Select.Option>
          <Select.Option value="ghost">Ghost</Select.Option>
          <Select.Option value="primary">Primary</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "status"]}
        label="Status"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="success">Success</Select.Option>
          <Select.Option value="warning">Warning</Select.Option>
          <Select.Option value="danger">Danger</Select.Option>
          <Select.Option value="info">Info</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "text"]}
        label="Text"
        style={formItemStyle}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "icon"]}
        label="Icon"
        style={formItemStyle}
      >
        <IconSelect />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "circle"]}
        valuePropName="checked"
        label="Circle"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  circle: !item.circle,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, "disabled"]}
        valuePropName="checked"
        label="Disabled"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  disabled: !item.disabled,
                },
              },
            });
          }}
        />
      </Form.Item>
    </>
  );
};

export default Label;
