import { FC, createContext } from "react";
import { Modal } from "antd";
import pagesApi from "apps/cms/lib/api/pages";
import tagsApi from "apps/cms/lib/api/tags";
import menuApi from "apps/cms/lib/api/menu";
import i18nApi, { Ii18nType } from "apps/cms/lib/api/i18n";
import { Properties } from "types";

import { saveMenu } from "../components/Menu/Form";
import initialData from "./initialData.json";
import initialTranslations from "./initialTranslations.json";

export const ContentContext = createContext({
  getInitialContent: () => null,
});

export const ContentProvider: FC = ({ children }) => {
  const [modal, contextHolder] = Modal.useModal();

  const onLoadInitialContent = async (isCreatePages?: boolean) => {
    let i = 0;
    let tags: Properties = {};
    const data: Properties = {};

    // Tags
    await tagsApi.all({}).then(async ({ count, results }) => {
      if (!count) {
        await tagsApi.resetAll().then(async () => {
          await tagsApi.all({}).then((response) => {
            tags = response.results.reduce((results, u) => {
              results[u.code] = u;

              return results;
            }, {});
          });
        });
      } else {
        tags = results.reduce((results, u) => {
          results[u.code] = u;

          return results;
        }, {});
      }
    });

    // Pages

    const onCreatePage = async (item) => {
      await pagesApi
        .upsert({
          ...item,
          tags: [tags[item.tags].id],
          state: 1,
          timestamp: new Date(),
          ...(item.i18n.en.slug === "home" && {
            data: {
              ...item.data,
              components: {
                ...item.data.components,
                "bannerHome-1646566643519": Object.keys(
                  item.data.components["bannerHome-1646566643519"],
                ).reduce((results, u) => {
                  results[u] = {
                    ...item.data.components["bannerHome-1646566643519"][u],
                    action: {
                      ...item.data.components["bannerHome-1646566643519"][u]
                        .action,
                      link: data["sell-phone"].id,
                    },
                  };

                  return results;
                }, {}),
              },
            },
          }),
        } as any)
        .then(async (response) => {
          data[initialData[i].i18n.en.slug] = response;

          i++;

          if (i < initialData.length) {
            await onCreatePage(initialData[i]);
          } else {
            // Menu
            const menu = [
              {
                data: { order: 0 },
                article_id: data["sell-phone"].id,
              },
              {
                data: { order: 1 },
                article_id: data["data-erasure"].id,
              },
              {
                data: { order: 2 },
                article_id: data["blog"].id,
              },
              {
                data: { order: 3 },
                article_id: data["about-us"].id,
              },
              {
                data: { order: 5 },
                article_id: data["contact-us"].id,
              },
            ];

            await menuApi
              .addMenu({ title: "Footer", type: "footer" })
              .then(({ id }) => saveMenu(menu, id));

            await menuApi
              .addMenu({ title: "Header", type: "header" })
              .then(({ id }) => saveMenu(menu, id));

            window.location.reload();
          }
        });
    };

    if (isCreatePages) {
      await onCreatePage(initialData[i]);
    }

    await i18nApi.getAll().then(({ results }) => {
      if (!results.some(({ code }) => code === "order")) {
        i18nApi.create("order").then(({ id }) => {
          i18nApi.update(
            id.toString(),
            {
              en: initialTranslations.order,
            },
            Ii18nType.NAMESPACE,
          );
        });
      }

      if (!results.some(({ code }) => code === "common")) {
        i18nApi.create("common").then(({ id }) => {
          i18nApi.update(
            id.toString(),
            {
              en: initialTranslations.common,
            },
            Ii18nType.NAMESPACE,
          );
        });
      }
    });
  };

  const getInitialContent = () => {
    modal.confirm({
      title: "Warning",
      content: (
        <>
          Organization does not have a home page, do you want to automatically
          fill the system with default data?
        </>
      ),
      onOk: () => onLoadInitialContent(true),
      onCancel: () => onLoadInitialContent(false),
    });
  };

  return (
    <ContentContext.Provider value={{ getInitialContent }}>
      {contextHolder}
      {children}
    </ContentContext.Provider>
  );
};
