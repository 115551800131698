import { Card, Form, Select, useForm, useNotify } from "ebs-design";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useMutation, useQueryClient } from "react-query";

import api, { querykeys } from "api";
import i18n, { resources } from "translation/i18n";
import { useAuth } from "hooks";
import { notifyErrors } from "utils";
import models from "models";
import {
  PageContainer,
  Divider,
  Flex,
  InputFormField,
  LoadingButton,
  PageTitle,
  UploadImage,
  SwitchFormField,
  PhoneFormField,
} from "components";

const languageOptions = Object.keys(resources).map((key) => ({
  value: key,
  text: models.Language[key],
}));

export const ProfileSettings = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { user } = useAuth();
  const notify = useNotify();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [picture, setPicture] = useState<models.File>(user.profile.picture);

  const mutation = useMutation((body: models.User) => api.profile.patch(body), {
    onError: (error) => notifyErrors(notify, error),
    onSuccess: ({ language }) => {
      queryClient.invalidateQueries(querykeys.profile);
      i18n.changeLanguage(language);
      notify.success({
        title: t("template.item_updated_successfully", {
          item: t("common.profile"),
        }),
      });
      history.push("/");
    },
  });

  useEffect(() => {
    form.setFieldsValue(user);
  }, [form, user]);

  return (
    <PageContainer size="100%" breakpoint={1100}>
      <>
        <PageTitle>{t("common.profile_settings")}</PageTitle>

        <Card className="mx-auto max-w-550">
          <Card.Body>
            <Form
              form={form}
              id="profile-settings"
              onFinish={(values) =>
                mutation.mutate({
                  ...values,
                  picture_id: picture?.id,
                  notifications: {
                    EMAIL_NOTIFICATIONS: values.email_notifications,
                    PUSH_NOTIFICATIONS: values.push_notifications,
                  },
                })
              }
            >
              <label className="mb-6">{t("common.avatar_picture")}</label>
              <UploadImage
                file={picture}
                setFile={setPicture}
                showImage
                publicFile
                fromOrganization={false}
              />
              <InputFormField
                name="first_name"
                label={t("common.first_name")}
              />
              <InputFormField name="last_name" label={t("common.last_name")} />
              <PhoneFormField
                name="phone"
                label={t("common.phone_number")}
                placeholder={t("common.phone_number")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required_phone"),
                  },
                ]}
              />
              <InputFormField disabled name="email" label={t("common.email")} />
              <Divider style={{ marginTop: "-1rem" }} space="1rem" />
              <Form.Field name="language" label={t("common.system_language")}>
                <Select
                  options={languageOptions}
                  size="large"
                  placeholder="Empty"
                  value={user.language}
                />
              </Form.Field>
              <Divider style={{ marginTop: "-0.5rem" }} space="1rem" />
              <SwitchFormField
                className="mb-24"
                name="push_notifications"
                label={t("common.in-app_notification")}
                initialValue={user.notifications.PUSH_NOTIFICATIONS}
                labelOptions={{
                  col: {
                    size: 8,
                  },
                }}
                controlOptions={{
                  col: { size: 4 },
                  className: "d-flex",
                  justify: "end",
                  align: "center",
                }}
              />
              <SwitchFormField
                name="email_notifications"
                label={t("common.email_notification")}
                initialValue={user.notifications.EMAIL_NOTIFICATIONS}
                labelOptions={{
                  col: {
                    size: 8,
                  },
                }}
                controlOptions={{
                  col: { size: 4 },
                  className: "d-flex",
                  justify: "end",
                  align: "center",
                }}
              />
            </Form>
          </Card.Body>
          <Card.Footer>
            <Flex justify="flex-end">
              <LoadingButton
                loading={mutation.isLoading}
                submit
                type="primary"
                form="profile-settings"
              >
                {t("common.save")}
              </LoadingButton>
            </Flex>
          </Card.Footer>
        </Card>
      </>
    </PageContainer>
  );
};
