import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Flex, Table } from "components";
import { orderTableByColumn } from "utils";

import { TrendDown, TrendUp } from "components/icons";
import models from "models";
import { ordersColumns } from "../config";

interface OrderTotalTableProps {
  data: models.OrderTotalsByStatus[];
  totalData?: React.ReactNode;
  trendDirection?: number;
}

export const OrderTotalTable = ({
  data,
  totalData,
  trendDirection,
}: OrderTotalTableProps) => {
  const { t } = useTranslation();

  const [ordering, setOrdering] = useState(null);

  const orderTotalsByStatus = useMemo(() => {
    return orderTableByColumn(data, ordering);
  }, [data, ordering]);

  return (
    <>
      <Flex align="baseline" gap={8}>
        <h2
          className="primary-color"
          style={{ fontWeight: 700, fontSize: "2.6rem" }}
        >
          {totalData}
        </h2>
        {!!trendDirection && (
          <Flex gap={5} className="grey-400">
            {`${Math.abs(+trendDirection)}%`}
            {trendDirection > 0 ? (
              <TrendUp color="success" />
            ) : (
              <TrendDown color="danger" />
            )}
          </Flex>
        )}
      </Flex>
      <Table
        data={orderTotalsByStatus}
        columns={ordersColumns({ ordering, setOrdering, t })}
        variants={["white-title"]}
        rowKey="status"
      />
    </>
  );
};
