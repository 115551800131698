import { Table } from "components";

import models from "models";
import { diagnosticCriteriaColumns } from "../config";

interface Props {
  diagnosticCriteria?: models.DiagnosticCriteria[];
}
export const DiagnosticCriteriaTable = ({ diagnosticCriteria }: Props) => {
  return (
    <Table
      variants={["simple-list"]}
      data={diagnosticCriteria}
      columns={diagnosticCriteriaColumns()}
      rowKey="id"
    />
  );
};
