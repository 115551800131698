import { Switch } from "ebs-design";
import { useTranslation } from "react-i18next";

import { Flex, Grid, InputTime } from "components";
import { useControlledState } from "hooks";
import models from "models";
import { makeBEM, translateIfIsTranslatable } from "utils";

export interface ScheduleProps {
  index: number;
  day: models.DayOfTheWeek;
  dayWorkingHours?: models.WorkingHours;
  schedule?: models.WorkingHours[];
  onChange: () => void;
}

export const bem = makeBEM("schedule-form");

export const Schedule = ({
  day,
  index,
  dayWorkingHours,
  schedule,
  onChange,
}: ScheduleProps) => {
  const { t } = useTranslation();
  let isWorkingDay = dayWorkingHours ? true : false;
  let dayWorkHours = dayWorkingHours;
  const [, setValue] = useControlledState(schedule, onChange);

  return (
    <>
      <div className={bem("input")}>
        <Grid cols="1fr min-content" gap="16px">
          <Flex justify="space-between" align="center">
            {translateIfIsTranslatable(t, day as string, "time")}
            <Switch
              checked={isWorkingDay}
              onChange={() => {
                setValue((prevState) => {
                  if (!Array.isArray(prevState)) prevState = [];
                  if (isWorkingDay) {
                    return prevState.filter((item) => item.index !== index);
                  } else
                    return [
                      ...prevState,
                      {
                        index,
                        time_from: dayWorkHours?.time_from || "08:00",
                        time_to: dayWorkHours?.time_to || "18:00",
                      },
                    ];
                });
              }}
            />
          </Flex>

          <Grid
            cols="repeat(3, min-content)"
            gap="16px"
            style={{ alignItems: "baseline" }}
          >
            <InputTime
              className={bem("time")}
              disabled={!isWorkingDay}
              value={dayWorkHours?.time_from || "08:00"}
              onChange={(val) => {
                dayWorkHours.time_from = String(val);
                setValue((prevState) => {
                  if (!Array.isArray(prevState)) prevState = [];
                  return [
                    ...prevState.filter((item) => item.index !== index),
                    dayWorkHours,
                  ];
                });
              }}
            />

            <div className="grey-200">to</div>
            <InputTime
              className={bem("time")}
              disabled={!isWorkingDay}
              value={dayWorkHours?.time_to || "18:00"}
              onChange={(val) => {
                dayWorkHours.time_to = String(val);
                setValue((prevState) => {
                  if (!Array.isArray(prevState)) prevState = [];
                  return [
                    ...prevState.filter((item) => item.index !== index),
                    dayWorkHours,
                  ];
                });
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
