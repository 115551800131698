import cn from "classnames";

import { NO_VALUE_FALLBACK } from "app-constants";
import { Flex } from "components";
import models from "models";
import { capitalizeString, createClassWithModifiers, makeBEM } from "utils";

interface PaymentStatusProps {
  status?: models.PaymentStatus | models.PaymentInvoiceStatus;
}

const bem = makeBEM("status");

const paymentStatusColors = {
  [models.PaymentStatus.CANCELED]: "danger",
  [models.PaymentStatus.CREATED]: "success",
  [models.PaymentStatus.FAILED]: "danger",
  [models.PaymentStatus.PROCESSING]: "warning",
  [models.PaymentStatus.SUCCEEDED]: "success",
  [models.PaymentInvoiceStatus.DRAFT]: "danger",
  [models.PaymentInvoiceStatus.OPEN]: "success",
  [models.PaymentInvoiceStatus.PAID]: "success",
  [models.PaymentInvoiceStatus.VOID]: "danger",
  [models.PaymentInvoiceStatus.UNPAID]: "danger",
  [models.PaymentInvoiceStatus.UNCOLLECTIBLE]: "danger",
};

export const PaymentStatus = ({ status }: PaymentStatusProps) => {
  const statusColor = {
    [createClassWithModifiers(bem(), [paymentStatusColors[status]])]: !!status,
  };

  return (
    <Flex justify="start" align="center" className={cn(statusColor)}>
      <span className={bem("circle mr-5")} />
      <span>{status ? capitalizeString(status) : NO_VALUE_FALLBACK}</span>
    </Flex>
  );
};
