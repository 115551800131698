import { createSvgIcon } from "../utils";

export const Applications = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1L9.36769 0.346318C9.13939 0.217894 8.86061 0.217894 8.6323 0.346318L9 1ZM17 5.5H17.75C17.75 5.22904 17.6039 4.97916 17.3677 4.84632L17 5.5ZM17 14.5L17.3677 15.1537C17.6039 15.0208 17.75 14.771 17.75 14.5H17ZM9 19L8.6323 19.6537C8.86061 19.7821 9.13939 19.7821 9.36769 19.6537L9 19ZM1 14.5H0.25C0.25 14.771 0.396146 15.0208 0.632304 15.1537L1 14.5ZM1 5.5L0.632304 4.84632C0.396146 4.97916 0.25 5.22904 0.25 5.5H1ZM8.6323 1.65368L16.6323 6.15368L17.3677 4.84632L9.36769 0.346318L8.6323 1.65368ZM16.25 5.5V14.5H17.75V5.5H16.25ZM16.6323 13.8463L8.6323 18.3463L9.36769 19.6537L17.3677 15.1537L16.6323 13.8463ZM9.36769 18.3463L1.3677 13.8463L0.632304 15.1537L8.6323 19.6537L9.36769 18.3463ZM1.75 14.5V5.5H0.25V14.5H1.75ZM1.3677 6.15368L9.36769 1.65368L8.6323 0.346318L0.632304 4.84632L1.3677 6.15368ZM9.36769 10.6537L17.3677 6.15368L16.6323 4.84632L8.6323 9.34632L9.36769 10.6537ZM8.25 10L8.25 19H9.75L9.75 10H8.25ZM9.36769 9.34632L1.3677 4.84632L0.632304 6.15368L8.6323 10.6537L9.36769 9.34632Z"
      fill="currentColor"
    />
  </svg>
);
