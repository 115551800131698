import { createSvgIcon } from "../utils";

export const Document = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 3L14.5303 2.46967C14.3897 2.32902 14.1989 2.25 14 2.25V3ZM19 8H19.75C19.75 7.80109 19.671 7.61032 19.5303 7.46967L19 8ZM5 19H4.25H5ZM7 3V2.25V3ZM19 19H19.75H19ZM9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75V8.25ZM10 9.75C10.4142 9.75 10.75 9.41421 10.75 9C10.75 8.58579 10.4142 8.25 10 8.25V9.75ZM9 12.25C8.58579 12.25 8.25 12.5858 8.25 13C8.25 13.4142 8.58579 13.75 9 13.75V12.25ZM15 13.75C15.4142 13.75 15.75 13.4142 15.75 13C15.75 12.5858 15.4142 12.25 15 12.25V13.75ZM9 16.25C8.58579 16.25 8.25 16.5858 8.25 17C8.25 17.4142 8.58579 17.75 9 17.75V16.25ZM15 17.75C15.4142 17.75 15.75 17.4142 15.75 17C15.75 16.5858 15.4142 16.25 15 16.25V17.75ZM13.25 3V7H14.75V3H13.25ZM13.25 7C13.25 7.46413 13.4344 7.90925 13.7626 8.23744L14.8232 7.17678C14.7763 7.12989 14.75 7.0663 14.75 7H13.25ZM13.7626 8.23744C14.0908 8.56562 14.5359 8.75 15 8.75V7.25C14.9337 7.25 14.8701 7.22366 14.8232 7.17678L13.7626 8.23744ZM15 8.75H19V7.25H15V8.75ZM17 20.25H7V21.75H17V20.25ZM7 20.25C6.66848 20.25 6.35054 20.1183 6.11612 19.8839L5.05546 20.9445C5.57118 21.4603 6.27065 21.75 7 21.75V20.25ZM6.11612 19.8839C5.8817 19.6495 5.75 19.3315 5.75 19H4.25C4.25 19.7293 4.53973 20.4288 5.05546 20.9445L6.11612 19.8839ZM5.75 19V5H4.25V19H5.75ZM5.75 5C5.75 4.66848 5.8817 4.35054 6.11612 4.11612L5.05546 3.05546C4.53973 3.57118 4.25 4.27065 4.25 5H5.75ZM6.11612 4.11612C6.35054 3.8817 6.66848 3.75 7 3.75V2.25C6.27065 2.25 5.57118 2.53973 5.05546 3.05546L6.11612 4.11612ZM7 3.75H14V2.25H7V3.75ZM13.4697 3.53033L18.4697 8.53033L19.5303 7.46967L14.5303 2.46967L13.4697 3.53033ZM18.25 8L18.25 19H19.75L19.75 8H18.25ZM18.25 19C18.25 19.3315 18.1183 19.6495 17.8839 19.8839L18.9445 20.9445C19.4603 20.4288 19.75 19.7293 19.75 19H18.25ZM17.8839 19.8839C17.6495 20.1183 17.3315 20.25 17 20.25V21.75C17.7293 21.75 18.4288 21.4603 18.9445 20.9445L17.8839 19.8839ZM9 9.75H10V8.25H9V9.75ZM9 13.75H15V12.25H9V13.75ZM9 17.75H15V16.25H9V17.75Z"
      fill="currentColor"
    />
  </svg>,
);
