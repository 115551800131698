import { useState, useRef, useEffect } from "react";
import cn from "classnames";
import { DocumentProps } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { throttle } from "../utils";

export const PDFViewer = ({
  className,
  renderMode = "svg",
  ...props
}: DocumentProps) => {
  const pdfWrapper = useRef<HTMLDivElement>(null);
  const [pageWidth, setPageWidth] = useState<number | undefined>();

  useEffect(() => {
    window.addEventListener("resize", throttle(setPDFWidth, 500));
    setPDFWidth();

    return () => {
      window.removeEventListener("resize", throttle(setPDFWidth, 500));
    };
  }, []);

  // Resize the PDF wrapper to fit the window width
  useEffect(() => setPDFWidth());

  const setPDFWidth = () => {
    if (pdfWrapper && pdfWrapper.current) {
      setPageWidth(pdfWrapper.current.getBoundingClientRect().width);
    }
  };

  return (
    <div className={cn("pdf-viewer", className)} ref={pdfWrapper}>
      <Document {...props}>
        <Page width={pageWidth} pageNumber={1} />
      </Document>
    </div>
  );
};
