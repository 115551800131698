import { AxiosError } from "axios";
import { Card, useNotify } from "ebs-design";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useQueryParams } from "use-query-params";

import api, { querykeys } from "api";
import {
  ConfirmationModal,
  Filters,
  Flex,
  PageTitle,
  QueryTable,
  SelectQueryListFilter,
  TableTitle,
} from "components";
import {
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "hooks";
import models from "models";
import {
  apiCallWithData,
  CommaArrayParam,
  forwardSetState,
  notifyErrors,
  transformEnumToArray,
} from "utils";
import { buybackOrdersColumns, buybackOrdersSortOptions } from "../config";

import { ChangeStatusModal } from "../components/ChangeStatusModal";
import { CustomExpandIcon } from "../components/CustomExpandIcon";

// FIXME: There is a problem with these imports with webpack and the code doesn't work.
import { RowTable } from "../components/RowTable";

interface ParentId {
  parent_id: number;
}
interface OrderWithParentId extends models.Order, ParentId {}

const orderStatusOptions = transformEnumToArray(models.OrderStatus);

export const BuyBackOrders = () => {
  const { t } = useTranslation();
  const pagination = useQueryPagination();
  const cancelOrderModal = useModalState<{ id: number; parent_id: number }>();
  const editOrderModal = useModalState<OrderWithParentId>();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const [filterParams, setFilterParams] = useQueryParams({
    status: CommaArrayParam,
    models: CommaArrayParam,
    brands: CommaArrayParam,
  });

  const [ordering, setOrdering] = useOrderingQueryParam();
  const [search, setSearch] = React.useState("");
  const [expandedRows, setExpandedRows] = React.useState<number[]>([]);

  const queryParams = {
    ...pagination.queryParams,
    search,
    ordering,
    parent_id__isnull: true,
    children__device__brand_model__brand__id__in: filterParams.brands,
    children__device__brand_model__id__in: filterParams.models,
    children__hidden_status__in: filterParams.status,
  };

  const query = useQuery(querykeys.crmOrders.many(queryParams), () =>
    api.orders.get(queryParams),
  );

  const cancelMutation = useMutation(
    (values: models.OrderStatusEntity) =>
      api.orders.changeStatus(cancelOrderModal.data.id, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          querykeys.crmOrders.one(cancelOrderModal.data.parent_id),
        );
        notify.success({
          title: t("notification.order_cancelled_successfully"),
        });
        cancelOrderModal.close();
      },
      onError: (errors: AxiosError) => notifyErrors(notify, errors),
    },
  );

  //toggle class to expanded rows
  const onExpand = (expanded: boolean, record: models.Order) => {
    if (expanded && record) {
      setExpandedRows((prev) => [...prev, record.id!]);
    }
    if (!expanded && record) {
      setExpandedRows((prev) => prev.filter((v) => v !== record.id));
    }
  };

  const tableColumns = React.useMemo(() => buybackOrdersColumns({ t }), [t]);

  return (
    <>
      <PageTitle title={t("menu.buyback_orders")} count={query.data?.count} />
      <Card>
        <Card.Body>
          <QueryTable
            pagination={pagination}
            title={() => (
              <Flex justify="space-between" align="center">
                <TableTitle
                  className="px-0"
                  search={search}
                  setSearch={setSearch}
                  sortOptions={buybackOrdersSortOptions}
                  sortOrdering={ordering}
                  onSortChange={setOrdering}
                  filters={
                    <Filters
                      queryParams={filterParams}
                      setQueryParams={setFilterParams}
                    >
                      <SelectQueryListFilter
                        title={t("common.status")}
                        value={filterParams.status}
                        setValue={forwardSetState(setFilterParams, "status")}
                        querykey={querykeys.nomenclature.orderStatus.many}
                        apiCall={apiCallWithData(orderStatusOptions)}
                        getKey={(u) => String(u.value)}
                        getValue={(u) => u.text}
                      />
                      <SelectQueryListFilter
                        title={t("brand.brands")}
                        value={filterParams.brands}
                        setValue={forwardSetState(setFilterParams, "brands")}
                        querykey={querykeys.brands.many}
                        apiCall={api.brands.get}
                        getKey={(u) => String(u.brand_id)}
                        getValue={(u) => u.title}
                      />
                      <SelectQueryListFilter
                        title={t("model.models")}
                        value={filterParams.models}
                        setValue={forwardSetState(setFilterParams, "models")}
                        querykey={querykeys.brandsModels.many}
                        apiCall={api.brandsModels.get}
                        getKey={(u) => String(u.brand_model_id)}
                        getValue={(u) => u.title}
                      />
                    </Filters>
                  }
                />
              </Flex>
            )}
            query={query}
            columns={tableColumns}
            expandable={{
              expandedRowRender: (row) => (
                <RowTable
                  orderQueryParams={{
                    parent__id: row.id,
                    device__brand_model__brand__id__in: filterParams.brands,
                    device__brand_model__id__in: filterParams.models,
                  }}
                  cancelOrder={cancelOrderModal.openWith}
                  editOrder={editOrderModal.openWith}
                />
              ),
              onExpand,
              expandIcon: CustomExpandIcon,
            }}
            className="orders-table"
            rowClassName={(record) =>
              expandedRows.includes(record?.id!)
                ? "sbb-table-row--expanded"
                : ""
            }
          />
        </Card.Body>
      </Card>
      {cancelOrderModal.isOpen && (
        <ConfirmationModal
          size="small"
          open={cancelOrderModal.isOpen}
          onClose={cancelOrderModal.close}
          isLoading={cancelMutation.isLoading}
          title={t("order.cancel_order")}
          onConfirm={() =>
            cancelMutation.mutate({ status: models.OrderStatus.CANCELLED })
          }
        >
          <div className="py-30">
            <h3 className="text-center">
              {t("template.are_you_sure_you_want_to_cancel_this_item", {
                item: t("order.order").toLowerCase(),
              }).replace("?", "")}
              {" - "}
              <span className="primary-color">{cancelOrderModal.data.id}</span>?
            </h3>
          </div>
        </ConfirmationModal>
      )}
      {editOrderModal.isOpen && (
        <ChangeStatusModal
          size="small"
          open={editOrderModal.isOpen}
          onClose={editOrderModal.close}
          title={`${t("template.edit_item", {
            item: t("order.order").toLowerCase(),
          })} - ${editOrderModal.data?.code}`}
          status={editOrderModal.data?.status}
          orderId={editOrderModal.data?.id}
          parent_id={editOrderModal.data?.parent_id}
        />
      )}
    </>
  );
};
